import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card mb-5" }
const _hoisted_2 = { class: "card-body w-100" }
const _hoisted_3 = { class: "container p-0 w-100" }
const _hoisted_4 = { class: "d-flex align-items-center justify-content-between w-100 m-0 admin-header-top" }
const _hoisted_5 = { class: "ps-0" }
const _hoisted_6 = { class: "container p-0" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = {
  key: 2,
  class: "text-center datepicker-dropdown"
}
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_11 = {
  class: "ps-3 pe-3 pt-3 pb-3 mr-10",
  style: {"border-radius":"0"}
}
const _hoisted_12 = { class: "top-title" }
const _hoisted_13 = { class: "dashboard-text-orange" }
const _hoisted_14 = {
  class: "ps-3 pe-3 pt-3 pb-3",
  style: {"border-radius":"0"}
}
const _hoisted_15 = { class: "top-title" }
const _hoisted_16 = { class: "dashboard-text-orange" }
const _hoisted_17 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_18 = {
  class: "ps-3 pe-3 pt-3 pb-3 mr-10",
  style: {"border-radius":"0"}
}
const _hoisted_19 = { class: "top-title" }
const _hoisted_20 = { class: "text-danger" }
const _hoisted_21 = {
  class: "ps-3 pe-3 pt-3 pb-3",
  style: {"border-radius":"0"}
}
const _hoisted_22 = { class: "top-title" }
const _hoisted_23 = { class: "text-danger" }
const _hoisted_24 = { class: "text-center datepicker-dropdown" }
const _hoisted_25 = { class: "justify-content-end" }
const _hoisted_26 = {
  key: 0,
  class: "col-xxl-12 mt-5 dashboard-filter-row"
}
const _hoisted_27 = { class: "card h-xl-100" }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "col-md-12" }
const _hoisted_30 = { class: "leads-policy nav nav-tabs nav-line-tabs fs-5" }
const _hoisted_31 = { class: "nav-item" }
const _hoisted_32 = { class: "nav-item" }
const _hoisted_33 = { class: "nav-item" }
const _hoisted_34 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_date_picker_range = _resolveComponent("vc-date-picker-range")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                ([1, 14].includes(_ctx.user.role_id) || _ctx.allowAll)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeDateTab('today'))),
                      class: _normalizeClass([{
                            'btn btn-outline btn-outline-dashboard-filter active':
                              _ctx.filter.type == 'today',
                            'btn btn-outline btn-dashboard-filter border': _ctx.filter.type != 'today'
                          }, "ps-3 pe-3 pt-3 me-3"]),
                      style: {"border-radius":"0"}
                    }, "Today ", 2))
                  : _createCommentVNode("", true),
                ([1, 14].includes(_ctx.user.role_id) || _ctx.allowAll)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeDateTab('yesterday'))),
                      class: _normalizeClass([{
                            'btn btn-outline btn-outline-dashboard-filter active':
                              _ctx.filter.type == 'yesterday',
                            'btn btn-outline btn-dashboard-filter border': _ctx.filter.type != 'yesterday'
                          }, "ps-3 pe-3 pt-3 me-3"]),
                      style: {"border-radius":"0"}
                    }, "Yesterday ", 2))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeDateTab('month'))),
                  class: _normalizeClass([{
                            'btn btn-outline btn-outline-dashboard-filter active':
                              _ctx.filter.type == 'month',
                            'btn btn-outline btn-dashboard-filter border': _ctx.filter.type != 'month'
                          }, "ps-3 pe-3 pt-3 pb-3 me-3"]),
                  style: {"border-radius":"0"}
                }, "This Month ", 2),
                _createElementVNode("a", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeDateTab('lastMonth'))),
                  class: _normalizeClass([{
                            'btn btn-outline btn-outline-dashboard-filter active':
                              _ctx.filter.type == 'lastMonth',
                            'btn btn-outline btn-dashboard-filter border': _ctx.filter.type != 'lastMonth'
                          }, "ps-3 pe-3 pt-3 me-3"]),
                  style: {"border-radius":"0"}
                }, "Last Month ", 2),
                _createElementVNode("a", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChangeDateTab('PreviousMonth'))),
                  class: _normalizeClass([{
                            'btn btn-outline btn-outline-dashboard-filter active':
                              _ctx.filter.type == 'PreviousMonth',
                            'btn btn-outline btn-dashboard-filter border': _ctx.filter.type != 'PreviousMonth'
                          }, "ps-3 pe-3 pt-3 me-3"]),
                  style: {"border-radius":"0"}
                }, "Previous Month ", 2),
                ([2,9,13].includes(_ctx.user.role_id) && _ctx.showOverDues)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_vc_date_picker_range, {
                        modelValue: _ctx.daterange,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.daterange) = $event)),
                        onChange: _ctx.onChangeDatePicker
                      }, null, 8, ["modelValue", "onChange"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.showOverDues)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  ([2, 9, 13].includes(_ctx.user.role_id) && _ctx.salesOverview)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, [
                          _createElementVNode("h4", _hoisted_12, [
                            _cache[13] || (_cache[13] = _createTextVNode("Over Due Tasks: ")),
                            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.salesOverview.over_due_tasks), 1)
                          ])
                        ]),
                        _createElementVNode("span", _hoisted_14, [
                          _createElementVNode("h4", _hoisted_15, [
                            _cache[14] || (_cache[14] = _createTextVNode("Tasks Today: ")),
                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.salesOverview.todays_task_count), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  ([4].includes(_ctx.user.role_id))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("span", _hoisted_18, [
                          _createElementVNode("h4", _hoisted_19, [
                            _cache[15] || (_cache[15] = _createTextVNode("Over Due: ")),
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.underwriterOverview.overdue), 1)
                          ])
                        ]),
                        _createElementVNode("span", _hoisted_21, [
                          _createElementVNode("h4", _hoisted_22, [
                            _cache[16] || (_cache[16] = _createTextVNode("Assigned Status Today: ")),
                            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.underwriterOverview.assigned_today), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            ([1, 14].includes(_ctx.user.role_id) || _ctx.allowAll)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_vc_date_picker_range, {
                      modelValue: _ctx.daterange,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.daterange) = $event)),
                      onChange: _ctx.onChangeDatePicker
                    }, null, 8, ["modelValue", "onChange"])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode(_component_el_select, {
                      filterable: "",
                      clearable: "",
                      modelValue: _ctx.filter.year,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filter.year) = $event)),
                      onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onYearSelect($event))),
                      placeholder: "Select Year",
                      size: "large"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearsList, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            label: item,
                            value: item
                          }, null, 8, ["label", "value"]))
                        }), 256))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      ([1, 12, 14].includes(_ctx.user.role_id) || _ctx.allowAll)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("ul", _hoisted_30, [
                    _createElementVNode("li", _hoisted_31, [
                      _createElementVNode("a", {
                        class: "nav-link cursor-pointer active",
                        "data-bs-toggle": "pill",
                        id: "kt_forms_Overview_1_tab_1",
                        href: "#kt_forms_Overview_1_tab_content_1",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.tabHandle(0)))
                      }, " Overview ")
                    ]),
                    _createElementVNode("li", _hoisted_32, [
                      _createElementVNode("a", {
                        class: "nav-link cursor-pointer",
                        "data-bs-toggle": "pill",
                        id: "kt_forms_Overview_1_tab_2",
                        href: "#kt_forms_Overview_1_tab_content_2",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.tabHandle(1)))
                      }, " Sales Person Info ")
                    ]),
                    _createElementVNode("li", _hoisted_33, [
                      _createElementVNode("a", {
                        class: "nav-link cursor-pointer",
                        "data-bs-toggle": "pill",
                        id: "kt_forms_Overview_1_tab_3",
                        href: "#kt_forms_Overview_1_tab_content_3",
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.tabHandle(2)))
                      }, " Statistics ")
                    ]),
                    _createElementVNode("li", _hoisted_34, [
                      _createElementVNode("a", {
                        class: "nav-link cursor-pointer",
                        "data-bs-toggle": "pill",
                        id: "kt_forms_Overview_1_tab_4",
                        href: "#kt_forms_Overview_1_tab_content_4",
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.tabHandle(3)))
                      }, " Other Info ")
                    ]),
                    _cache[17] || (_cache[17] = _createElementVNode("li", { class: "nav-item" }, null, -1))
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}