
import { defineComponent, computed } from 'vue'
import { store } from "@/store";
import Invoice from '@/core/services/Callable/Invoice';

export default defineComponent({
  setup() {

    const filter = computed(() => {
      return store.state.invoice.filter
    })

    const invoiceCount = computed(() => {
      return store.state.invoice.invoiceListCounts
    })

    const lists = computed(() => {
      return store.state.invoice.invoiceLists
    })

    const getCodDirectIntrasact = () => {
      filter.value.is_discrepancy = 0;
      filter.value.status = 1;
      filter.value.payment_type = [2, 3, 4, 5, 6, 8, 9 ];
      filter.value.is_manual_order = 0;

      getInvoiceListFor(filter.value);
    };

    const getDiscrepancy = () => {
      filter.value.is_discrepancy = 1;
      filter.value.status = -1;
      filter.value.payment_type = [1, 2, 3];
      filter.value.is_manual_order = [0, 1];
      getInvoiceListFor(filter.value);
    };

    const getOnline = () => {
      filter.value.is_discrepancy = 0;
      filter.value.status = 1;
      filter.value.payment_type = [1,7];
      filter.value.is_manual_order = [0, 1];
      getInvoiceListFor(filter.value);
    };
    const getManualOrders = () => {
      filter.value.is_discrepancy = 0;
      filter.value.status = 1;
      filter.value.payment_type = [2, 3];
      filter.value.is_manual_order = 1;
      getInvoiceListFor(filter.value);
    };

    const getFailed = () => {
      filter.value.is_discrepancy = 0;
      filter.value.status = 6;
      filter.value.payment_type = -1;
      filter.value.is_manual_order = [0, 1];
      getInvoiceListFor(filter.value);
    };

    const getInvoiceListFor = async (e) => {
      filter.value.page = 1;
      await Invoice.invoiceLists(filter.value)
    }



    return {
      store,
      filter,
      invoiceCount,
      lists,
      getInvoiceListFor,
      getCodDirectIntrasact,
      getDiscrepancy,
      getManualOrders,
      getOnline,
      getFailed
    }
  },
})
