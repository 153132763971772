import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row-reverse mx-5" }
const _hoisted_2 = { class: "align-self-center text-gray-700" }
const _hoisted_3 = { class: "m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_document_table_data = _resolveComponent("vc-document-table-data")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.policy_documents.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.policy_documents.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
    fullscreen: ""
  }, {
    header: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " All Documents ")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("a", {
              href: "javascript:void(0);",
              class: "btn btn-download-all mt-5 mb-5",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadZip()))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("i", {
                class: "icon-svg icon-download",
                style: {"transform":"translateY(-15%)","position":"relative","-webkit-transform":"translateY(-15%)"}
              }, null, -1),
              _createTextVNode(" All ")
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_vc_document_table_data, { documents: _ctx.documents }, null, 8, ["documents"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}