import { store } from "@/store";
import { App } from "vue";
import { InvoiceActionTypes } from '@/store/enums/Invoice/Actions';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';
import { ElLoading } from 'element-plus'

class Invoice {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Invoice.vueInstance = app;
    }

    public static async makeInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.MAKE_INVOICE, payload);
    }

    public static async makeInvoiceEvent(payload: any) {
        return await store.dispatch(InvoiceActionTypes.MAKE_INVOICE_EVENT, payload);
    }

    public static async addAmendInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.ADD_AMEND_INVOICE, payload);
    }

    public static async subAmendInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.SUB_AMEND_INVOICE, payload);
    }

    public static async getInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.GET_INVOICE, payload);
    }

    public static async updateInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.UPDATE_INVOICE, payload);
    }

    public static setFilter(payload) {
        store.commit(InvoiceMutationTypes.SET_INVOICE_FILTER, payload);
    }

    public static async invoiceLists(payload) {
        const loading = ElLoading.service({
            target: '.invoice-list',
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.1)',
        })
        const data = await store.dispatch(InvoiceActionTypes.INVOICE_LISTS, payload);

        loading.close()
        return data;
    }

    public static async sendPaymentLinkEmail(payload) {
        return new Promise<void>((resolve, reject) => {
         store.dispatch(InvoiceActionTypes.SEND_PAYMENT_LINK_EMAIL, payload)
            .then((data) => {
                    
                resolve(data);
            })
            .catch((error) => { reject(error); });
        });
    }

    public static async sendInvoiceGenerationIssueEmail(payload) {
        return await store.dispatch(InvoiceActionTypes.SEND_INVOICE_GENERATION_ISSUE_EMAIL, payload);
    }

    


    public static async getInterestedPolicy(payload) {
        return await store.dispatch(InvoiceActionTypes.INTERESTED_POLICY, payload);
    }

    public static setInteredPolicyModal(payload) {
        store.commit(InvoiceMutationTypes.SET_INTERESTED_POLICY_MODAL, payload);
    }

    public static setInvoiceApprovalActionModal(payload) {
        store.commit(InvoiceMutationTypes.SET_INOVICE_APPROVAL_ACTION_MODAL, payload);
    }

    public static async uploadInvoiceDoc(payload: any) {
        return await store.dispatch(InvoiceActionTypes.UPLOAD_INVOICE_DOCUMENT, payload);
    }

    public static async getInvoiceDetails(payload: any) {
        return await store.dispatch(InvoiceActionTypes.GET_INVOICE_DETAILS, payload);
    }

    public static setInvoiceDetailsModal(payload) {
        store.commit(InvoiceMutationTypes.SET_INVOICE_DETAILS_MODAL, payload);
    }

    public static setInvoice(payload) {
        store.commit(InvoiceMutationTypes.SET_INVOICE_DETAILS, payload);
    }

    
    public static async makeGroupInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.MAKE_GROUP_INVOICE, payload);
    }
    
    public static async groupInvoiceLists(payload) {
        const loading = ElLoading.service({
            target: '.invoice-list',
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.1)',
        })
        const data = await store.dispatch(InvoiceActionTypes.GROUP_INVOICE_LISTS, payload);

        loading.close()
        return data;
    }

    public static async updateGroupInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.UPDATE_GROUP_INVOICE, payload);
    }

    public static setGroupInvoice(payload) {
        store.commit(InvoiceMutationTypes.SET_GROUP_INVOICE_DETAILS, payload);
    }

    public static async uploadGroupInvoiceDoc(payload: any) {
        return await store.dispatch(InvoiceActionTypes.UPLOAD_GROUP_INVOICE_DOCUMENT, payload);
    }

    public static async getGroupInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.GET_GROUP_INVOICE, payload);
    }

    public static async addGroupAmendInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.ADD_GROUP_AMEND_INVOICE, payload);
    }

    public static async subGroupAmendInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.SUB_GROUP_AMEND_INVOICE, payload);
    }

    public static async getGroupInvoiceLog(payload: any) {
        return await store.dispatch(InvoiceActionTypes.GET_GROUP_INVOICE_LOG, payload);
    }

    public static setGroupInvoiceLogModal(payload) {
        store.commit(InvoiceMutationTypes.SET_GROUP_INVOICE_LOG, payload);
    }

    public static async validateDuplicates(payload: any) {
        return await store.dispatch(InvoiceActionTypes.VALIDATE_DUPLICATES, payload);
    }

    public static async GroupCategoryAdditionInvoice(payload: any) {
        return await store.dispatch(InvoiceActionTypes.GROUP_CATEGORY_ADDITION_INVOICE, payload);
    }
    
}

export default Invoice;