const accordions = [
    {
        label: 'LEAD STATUS: &nbsp;',
        key: 'lead_status',
        cclass:'col-md-3',
        acn: 'd-flex col-3 first-column', 
        cn: 'badge',
        isMixin: true
    },
    {
        label: '',
        key: 'name',
        cclass:'col-md-3',
        acn: 'col-3 second-column', 
    },
    {
        label: 'Policy Expiry: ',
        key: 'policy_expiry',
        cclass:'col-md-3',
        acn: 'col-3 third-column', 
        cn: 'fw-light ms-1 fw-boldest'
    },
    {
        label: 'Task Due: ',
        key: 'task_due',
        cclass:'col-md-3',
        acn: 'col-3 fourth-column', 
        cn: 'fw-light fw-boldest'
    },
]

const timelines = [
    {
        label: 'Current Visit Date',
        value: 'current_visited_date',
        cn: 'text-primary'
    },
    {
        label: 'Last Visit Date',
        value: 'last_visited_date',
        cn: 'text-danger'
    },
    {
        label: 'Created Date',
        value: 'created_at',
        cn: 'text-warning'
    },
]

const details = [
    {
        title: `Lead ID:`,
        showTitle: true,
        subTitle: '',
        showSubTitle: true,
        inTable: true,
        interestedIn:false,
        key: 'lead',
        items: [
            {
                label: 'Insured For',
                value: 'insured_for',
                cn: '',
                cnh: 'ps-3'
            },
            {
                label: 'Lead Status',
                value: 'lead_status',
                cn: 'fw-bolder fw-6 badge',
                cnh: '',
                isMixin: true
            },
            {
                label: 'Task Status',
                value: 'task_status',
                cn: '',
                cnh: 'ps-3'
            },
            {
                label: 'Due At',
                value: 'due_at',
                cn: '',
                cnh: 'ps-3',
            },
            {
                label: 'Request Timeline',
                value: 'request_time_line',
                cn: '',
                cnh: 'ps-3',
                isTimeLine: true
            },
            {
                label: 'Lead Source',
                value: 'lead_source',
                cn: '',
                cnh: 'ps-3',
            },
        ]
    },
    {
        title: 'Sponsor/Customer Details',
        showTitle: true,
        subTitle: '',
        showSubTitle: false,
        inTable: false,
        interestedIn:false,
        key: 'customer_detail',
        items: [
            {
                label: 'Name:',
                value: 'name'
            },
            {
                label: 'Date of Birth:',
                value: 'dob'
            },
            {
                label: 'Nationality:',
                value: 'nationality'
            },
            {
                label: 'Gender:',
                value: 'gender'
            },
            {
                label: 'Salary/Month > $ 4000:',
                value: 'salary'
            },
            {
                label: 'Visa Registration Emirates:',
                value: 'emirates'
            },
            {
                label: 'Expected Policy Start Date:',
                value: 'expected_policy_start_date'
            },
        ]
    },
    {
        title: 'Details of Insured Person/s',
        showTitle: true,
        subTitle: '',
        showSubTitle: false,
        inTable: false,
        interestedIn:true,
        key: 'insured_person',
        items: [
            {
                label: 'Date of Birth:',
                value: 'dob'
            },
            {
                label: 'Nationality:',
                value: 'nationality'
            },
            {
                label: 'Gender:',
                value: 'gender'
            },
            {
                label: 'Salary/Month > $ 4000:',
                value: 'salary'
            },
            {
                label: 'Relationship:',
                value: 'relationship'
            },
            {
                label: 'Visa Registration Emirates:',
                value: 'emirates'
            },
            {
                label: 'Expected Policy Start Date:',
                value: 'expected_policy_start_date'
            },
        ]
    }
]

const customer_details = [
    {
        label: '',
        value: 'name',
        isEdit: true,
    },
    {
        label: '',
        value: 'email',
    },
    {
        label: '',
        value: 'complete_number',
    },
    {
        label: '',
        value: 'other_contact_info',
        isOtherInfo:true
    },
    {
        label: '',
        value: 'customer_status_text',
        isBadge: true
    },
    {
        label: '',
        value: 'agent_name',
    },
    {
        label: 'Tasks #:',
        value: 'open_task_count',
    },
    {
        label: 'Customer Log',
        value: 'customer_log',
        isClick: true
    },
]

const lead_tabs = [
    {
        label: 'Lead',
        value: 'lead',
        active: true,
        withCount: true
    },
    {
        label: 'View Details',
        value: 'view_details',
        active: false,
        withDisable: true

    },
    {
        label: 'Tasks',
        value: 'tasks',
        active: false
    },
    {
        label: 'Notes',
        value: 'notes',
        active: false
    },
    {
        label: 'Policies',
        value: 'policies',
        active: false,
        withDisable: true

    },
    {
        label: 'Emails',
        value: 'emails',
        active: false,
        // withDisable: true

    },
    {
        label: 'Documents',
        value: 'documents',
        active: false,
        withDisable: true

    },
    {
        label: 'View Quotes',
        value: 'view_quotes',
        active: false,
        withDisable: true

    },
    {
        label: 'SMS',
        value: 'sms',
        active: false
    },
    {
        label: 'Lead Activity',
        value: 'lead_activity',
        active: false
    },
]

const view_tasks = [
    {
        label: 'Current Data',
        value: 'current_data',
        withCondition: false
    },
    {
        label: 'Lost Lead Reason',
        value: 'lost_lead_reason',
        withCondition: true
    },
    {
        label: 'Lead Status',
        value: 'lead_status',
        withCondition: false
    },
    {
        label: 'Task Note',
        value: 'task_note',
        withCondition: false
    },
    {
        label: 'Due at',
        value: 'due_at',
        withCondition: false
    },
    {
        label: 'By',
        value: 'by',
        withCondition: false
    },
    {
        label: 'Closed at',
        value: 'closed_at',
        withCondition: false
    },
]

const leads_tasks = [
    {
        label: 'Name',
        value: 'name',
        width: '180',
        isRoute: true
    },
    {
        label: 'Email <br />Mobile Number',
        value: 'email_mobile',
        width: '220',
        isCopy: true
    },
    {
        label: 'User Type',
        value: 'customer_status_text',
        width: '300',
        isRoute: true,
        withMix: true
    },
    {
        label: 'Is Renewal',
        value: 'is_renewal',
        width: '120',
        cn: 'text-center'
    },
    {
        label: 'Task #',
        value: 'open_task_count',
        width: '80'
    },
    {
        label: 'Task Due Date',
        value: 'task_due_date',
        width: '170',
        isTimeLine: true,
        cni: 'task-due-date'
    },
    {
        label: 'Sales Agent',
        value: 'sales_agent',
        width: '180',
        isRoute: true,
        withCn: true
    },
    {
        label: 'Select to Transfer',
        value: 'customer_id',
        width: '120',
        isTransfer: true
    },
]

const emails = [
    {
        label: 'Sr. #',
        value: 'index',
        width: '120'
    },
    {
        label: 'Subject',
        value: 'subject'

    },
    {
        label: 'Sent By',
        value: 'sent_by',
        width: '200'
    },
    {
        label: 'Sent On',
        value: 'created_at',
        width: '200'
    },
    {
        label: 'Delivered',
        value: 'delivered',
        width: '120',
        isClick: true
    },
    {
        label: 'Clicked',
        value: 'clicked',
        width: '120',
        isClick: true
    },
    {
        label: 'View',
        value: 'email_body',
        width: '80',
        cn:'text-center'
    },
]

const sms = [
    {
        label: 'Sr. #',
        value: 'index',
        width: '120'
    },
    {
        label: 'SMS Content',
        value: 'sms'

    },
    
    {
        label: 'Sent On',
        value: 'created_at',
        width: '200'
    },
    {
        label: 'Status',
        value: 'status',
        width: '120',
    },
   
]

const customer_log = [
    {
        label: 'Customer Activity Log',
        value: 1
    },
    {
        label: 'Customer Status Log',
        value: 2
    },
    {
        label: 'Customer View Activity Log',
        value: 3
    },
]

const activity_logs = [
    {
        label: 'Policy Status',
        value: 'policy_status',
        isPolicy: true
    },
    {
        label: 'Current Data',
        value: 'current_data'
    },
    {
        label: 'Previous Data',
        value: 'previous_data'
    },
    {
        label: 'By',
        value: 'by'
    },
]

const status_logs = [
    
    {
        label: 'Current Status',
        value: 'current_status',
        isBold: true
    },
    {
        label: 'Previous Status',
        value: 'previous_status'
    },
    {
        label: 'By',
        value: 'by'
    },
]

const lead_activity = [
    {
        label: 'Lead Activity Log',
        value: 1
    },
    {
        label: 'Lead Status Log',
        value: 2
    }
]

const documents = [
    {
        label: 'View Documents',
        value: 1
    },
    {
        label: 'Upload Documents',
        value: 2
    },
]

const update_customer_tab = [
    {
        label: 'Customer Details',
        value: 1
    },
    {
        label: 'Individual Details',
        value: 2
    }
]

export {
    documents,
    lead_activity,
    emails,
    customer_log,
    activity_logs,
    status_logs,
    leads_tasks,
    lead_tabs,
    accordions,
    details,
    timelines,
    customer_details,
    view_tasks,
    sms,
    update_customer_tab
}