import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dashboard-section" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "card mb-5 rounded-2 full-width" }
const _hoisted_5 = { class: "row g-4 w-100 untouched-row" }
const _hoisted_6 = {
  key: 0,
  class: "card card-flush"
}
const _hoisted_7 = { class: "row w-100" }
const _hoisted_8 = { class: "col-sm-4 p-0" }
const _hoisted_9 = { class: "card-title d-flex flex-column" }
const _hoisted_10 = { class: "d-flex align-items-center digit-value" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-8" }
const _hoisted_13 = { class: "card mb-5 rounded-2 full-width" }
const _hoisted_14 = { class: "row g-4 w-100 untouched-row" }
const _hoisted_15 = {
  key: 0,
  class: "card card-flush"
}
const _hoisted_16 = { class: "row w-100" }
const _hoisted_17 = { class: "col-sm-4 p-0" }
const _hoisted_18 = { class: "card-title d-flex flex-column" }
const _hoisted_19 = { class: "d-flex align-items-center digit-value" }
const _hoisted_20 = { class: "card mb-5 rounded-2 full-width" }
const _hoisted_21 = { class: "row g-4 w-100 untouched-row" }
const _hoisted_22 = {
  key: 0,
  class: "card card-flush"
}
const _hoisted_23 = { class: "row w-100" }
const _hoisted_24 = { class: "col-sm-4 p-0" }
const _hoisted_25 = { class: "card-title d-flex flex-column" }
const _hoisted_26 = { class: "d-flex align-items-center digit-value" }
const _hoisted_27 = { class: "col-md-4 sales-dashboard-item bg-white pe-0 dashboard-pie-chart" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 1,
  class: "text-center m-20"
}
const _hoisted_30 = {
  key: 0,
  class: "card mt-5"
}
const _hoisted_31 = {
  key: 0,
  class: "card-body card-custom-spacing-tblf-10 bg-white"
}
const _hoisted_32 = { class: "pt-0 p-0" }
const _hoisted_33 = {
  class: "table-responsive table-simple-dash",
  id: "displayResponseData"
}
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_pie_chart = _resolveComponent("vc-pie-chart")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overview, (card, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass(["col-md-2 col-lg-2 col-xl-2 col-xxl-2", `${ card.hide
                            ? 'hide-card'
                            : '' }`])
              }, [
                (!card.hide)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["icon-svg", card.icon])
                          }, null, 2)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["card-header w-100", `bg-light-white`])
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", {
                            class: _normalizeClass([`${`text-gray-700`}`, "text-dark-400 pt-1 fw-bold fs-5"])
                          }, _toDisplayString(card.title), 1),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("span", {
                              style: _normalizeStyle(`color: ${card.color} !important;`),
                              class: "fw-bold"
                            }, [
                              (card.isFormated)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    style: _normalizeStyle(`font-size: ${
                                                _ctx.getFontSize(_ctx.concatData(_ctx.salesOverview[card.value], _ctx.salesOverview[card.second_value]))
                                            }`)
                                  }, _toDisplayString(_ctx.common.formatCurrency(_ctx.salesOverview[card.value])) + " " + _toDisplayString(card.second_value ?
                                                        ` ${card.in_between} ${_ctx.common.formatCurrency(_ctx.salesOverview[card.second_value]) }` : '') + " " + _toDisplayString(card.closingRatio ? '%' :''), 5))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    style: _normalizeStyle(`font-size: ${
                                                _ctx.getFontSize(_ctx.concatData())
                                            }`)
                                  }, _toDisplayString(_ctx.salesOverview[card.value]) + " " + _toDisplayString(card.second_value ?
                                                        ` | ${_ctx.salesOverview[card.second_value] }` : '') + " " + _toDisplayString(card.closingRatio ? '%' :''), 5))
                            ], 4)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overview_1, (card, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass(["col-md-2 col-lg-2 col-xl-2 col-xxl-2", `${ card.hide
                                    ? 'hide-card'
                                    : '' }`])
              }, [
                (!card.hide)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["icon-svg", card.icon])
                          }, null, 2)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["card-header w-100", `bg-light-white`])
                      }, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("span", {
                            class: _normalizeClass([`${`text-gray-700`}`, "text-dark-400 pt-1 fw-bold fs-5"])
                          }, _toDisplayString(card.title), 1),
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("span", {
                              style: _normalizeStyle(`color: ${card.color} !important; font-size: ${
                                                        _ctx.getFontSize(_ctx.salesOverview[card.value])
                                                    }`),
                              class: "fw-bold"
                            }, _toDisplayString(_ctx.common.formatNumber(_ctx.salesOverview[card.value])) + " " + _toDisplayString(card.second_value ? ` |
                                                    ${_ctx.common.formatNumber(_ctx.salesOverview[card.second_value]) }` : '') + " " + _toDisplayString(card.closingRatio ? '%' :''), 5)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overview_2, (card, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass(["col-md-2 col-lg-2 col-xl-2 col-xxl-2", `${ card.hide
                                    ? 'hide-card'
                                    : '' }`])
              }, [
                (!card.hide)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["icon-svg", card.icon])
                          }, null, 2)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["card-header w-100", `bg-light-white`])
                      }, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("span", {
                            class: _normalizeClass([`${`text-gray-700`}`, "text-dark-400 pt-1 fw-bold fs-5"])
                          }, _toDisplayString(card.title), 1),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("span", {
                              style: _normalizeStyle(`color: ${card.color} !important; font-size: ${
                                                        _ctx.getFontSize(_ctx.salesOverview[card.value])
                                                    }`),
                              class: "fw-bold"
                            }, _toDisplayString(_ctx.common.formatNumber(_ctx.salesOverview[card.value])) + " " + _toDisplayString(card.second_value ? ` |
                                                    ${_ctx.common.formatNumber(_ctx.salesOverview[card.second_value]) }` : '') + " " + _toDisplayString(card.closingRatio ? '%' :''), 5)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        (_ctx.hasData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createVNode(_component_vc_pie_chart, {
                chartOptions: _ctx.salesPerformaceChart.options,
                series: _ctx.salesPerformaceChart.series || [],
                title: "Sales Performance",
                backgroundColor: "#fff",
                fontColor: "#181C32"
              }, null, 8, ["chartOptions", "series"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_29, "Sorry, Sales Agent Dashboard Not Available"))
      ])
    ]),
    ([9, 13].includes(_ctx.userRoleId))
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _cache[0] || (_cache[0] = _createElementVNode("div", {
            class: "card-header boder-0 pt-5",
            style: `background-color: rgb(255, 255, 255) !important;`
          }, [
            _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
              _createElementVNode("span", {
                class: "card-label fw-bolder fs-3 mb-1",
                style: `color: rgb(0, 0, 0) !important;`
              }, " Team Summary ")
            ])
          ], -1)),
          (_ctx.teamSummary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, [
                    _createVNode(_component_el_table, {
                      style: {"overflow-y":"auto"},
                      data: _ctx.teamSummary,
                      class: "table table-hover table-bai-striped table-row-bordered border gy-7 gs-7 mb-0"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.team_summaries, (summary) => {
                          return (_openBlock(), _createBlock(_component_el_table_column, {
                            key: summary.label,
                            fixed: summary.isFixed,
                            width: summary.width
                          }, {
                            header: _withCtx(() => [
                              _createElementVNode("span", {
                                innerHTML: summary.label
                              }, null, 8, _hoisted_34)
                            ]),
                            default: _withCtx((scope) => [
                              (summary.isFormated)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_ctx.common.formatCurrency(scope.row[summary.value])), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_36, [
                                    _createTextVNode(_toDisplayString(scope.row[summary.value]) + " ", 1),
                                    (summary.isPercentage)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_37, " % "))
                                      : _createCommentVNode("", true)
                                  ]))
                            ]),
                            _: 2
                          }, 1032, ["fixed", "width"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["data"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}