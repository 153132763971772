// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum DashboardMutationTypes {
  SET_OVERVIEW_DATA = "SET_OVERVIEW_DATA",
  SET_DASHBOARD_OVERVIEW = "SET_DASHBOARD_OVERVIEW",
  SET_SALESPERSON_INFO = "SET_SALESPERSON_INFO",
  SET_DASHBOARD_STATISTICS = "SET_DASHBOARD_STATISTICS",
  SET_DASHBOARD_OTHERS = "SET_DASHBOARD_OTHERS",
  SET_DASHBOARD_LEADS = "SET_DASHBOARD_LEADS",
  SET_DASHBOARD_TRANSACTIONS = "SET_DASHBOARD_TRANSACTIONS",
  SET_DASHBOARD_UNDERWRITER = "SET_DASHBOARD_UNDERWRITER",
  SET_DASHBOARD_ACCOUNTS = "SET_DASHBOARD_ACCOUNTS",
  SET_DASHBOARD_MARKETING = "SET_DASHBOARD_MARKETING"
}
  