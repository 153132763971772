
import { defineComponent, computed, ref } from "vue";
import { FormInstance } from "element-plus";
import { store } from "@/store";
import { rule, notification, common } from "@/store/stateless";
import Customers from "@/core/services/Callable/Customers";
import { healthDefault, update_customer_tab } from "@/core/utils";
import Validations from "@/core/services/etc/Validations";

export default defineComponent({
  setup(_, { emit }) {
    const ruleFormRef = ref<FormInstance>();
    const open = ref<Boolean>(false);
    const loading = ref<Boolean>(false);
    const activeTab = ref<Number>(1)

    const health = computed(() => {
      return store.state.customer.health;
    });

    const submitCustomer = async (formEl: FormInstance | undefined) => {
      Validations.email_taken = false;
      Validations.phone_taken = false;
      loading.value = true;
      if (!formEl) return;

      formEl.validate(async (valid, fields: any) => {
        let payload = {};
        if(activeTab.value == 1)
        {
            payload = {
                active_tab:activeTab.value,
                id: health.value.id,
                name: health.value.name,
                phone_country_code: health.value.phone_country_code,
                phone_number: health.value.phone_number,
                email: health.value.email,
                other_contact_info: health.value.other_contact_info,
            } 
        }
        else {
            payload = {
                active_tab:activeTab.value,
                id: health.value.id,
                dob: health.value.dob,
                gender: health.value.gender,
                is_married: health.value.is_married,
                nationality_id: health.value.nationality_id,
                is_salary: health.value.is_salary,
            } 
        }
        
        if (valid) {
          const result = await Customers.updateCustomerDetails(payload);

          if (result.status === 200) {
            close(formEl);
            notification.success(
              "Customer Details",
              "You have updated the customer details successfully"
            );
            emit("update");
          } else {
            const { email_taken, phone_taken } = result.response.data.data;
            Validations.email_taken = email_taken;
            Validations.phone_taken = phone_taken;
            formEl.validate(async (valid) => {});
          }
          loading.value = false;
        } else {
          loading.value = false;
          // rule.customerDetails =  Object.keys(fields)[0]
        }
      });
    };

    const close = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.clearValidate();
      Customers.setHealth(healthDefault);
      loading.value = false;
      modal(false);
    };

    const top = ref();
    const modal = (value: Boolean) => {
      open.value = value;
      if (top.value !== undefined) top.value.setfnfocus(false);
    };

    const setFocusTofn = () => {
      if (top.value !== undefined) top.value.setfnfocus(false);
    };

    return {
      top,
      rule,
      open,
      common,
      health,
      loading,
      ruleFormRef,
      submitCustomer,
      setFocusTofn,
      modal,
      close,
      update_customer_tab,
      activeTab
    };
  },
});
