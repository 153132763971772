import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_notes_data_value = _resolveComponent("vc-customer-notes-data-value")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "card" }, [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.notes.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notes, (note, i) => {
            return (_openBlock(), _createBlock(_component_vc_customer_notes_data_value, {
              key: i,
              class: "mb-10 ms-6 mt-6",
              item: note
            }, null, 8, ["item"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}