import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container transfer-leads" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "d-flex justify-content-center mb-4 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_agents = _resolveComponent("vc-agents")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.open) = $event)),
    width: "30%",
    center: "",
    close: _ctx.closeModal,
    onCloseAutoFocus: _ctx.closeModal
  }, {
    header: _withCtx(() => _cache[5] || (_cache[5] = [
      _createElementVNode("h2", { class: "fw-bolder" }, " Transfer Lead/s To ", -1)
    ])),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: _ctx.transfer,
        "status-icon": "",
        rules: _ctx.rules,
        "scroll-to-error": "",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onSubmitTransfer(_ctx.ruleFormRef)), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label required" }, " Agent ", -1)),
                _createVNode(_component_el_form_item, { prop: "agent" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_agents, {
                      modelValue: _ctx.transfer.agent,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.transfer.agent) = $event)),
                      placeholder: "Please Select",
                      isTransfer: "",
                      options: _ctx.agents
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_vc_input, {
                  modelValue: _ctx.transfer.notes,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.transfer.notes) = $event)),
                  label: "Notes",
                  prop: "notes",
                  size: "large",
                  placeholder: "notes"
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_vc_button, {
              text: "Cancel",
              className: "btn btn-light me-3",
              onVcClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal(_ctx.ruleFormRef))),
              "is-regular": ""
            }),
            _createVNode(_component_vc_button_save, { loading: _ctx.loading }, null, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "close", "onCloseAutoFocus"]))
}