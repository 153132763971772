import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-5" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    "show-close": false,
    "close-on-click-modal": false,
    width: "500px",
    title: "Invoice already exists"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, [
          _cache[2] || (_cache[2] = _createTextVNode("Quote Reference Number ")),
          _createElementVNode("b", null, _toDisplayString(_ctx.merchant_reference), 1)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn btn-lg btn-info",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.callEvent && _ctx.callEvent(...args))),
          ref: "okRef"
        }, "Yes", 512)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}