import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-5" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "mb-10" }
const _hoisted_5 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_agents = _resolveComponent("vc-agents")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.transferSale.open,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transferSale.open) = $event)),
    onOpened: _cache[4] || (_cache[4] = ($event: any) => (_ctx.appendAgent())),
    "append-to-body": "",
    onClosed: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeModal())),
    width: "30%"
  }, {
    header: _withCtx(() => _cache[6] || (_cache[6] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Transfer Sale ")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label required" }, " Sales Agent ", -1)),
              _createVNode(_component_vc_agents, {
                modelValue: _ctx.newAgent,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAgent) = $event)),
                placeholder: "Select Policy Sales Agent",
                options: _ctx.agents,
                ref: "agent",
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isError = false))
              }, null, 8, ["modelValue", "options"]),
              (_ctx.isError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Please select sales agent "))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_vc_button_save, {
              loading: _ctx.clickLoading,
              onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitTransferSales()))
            }, null, 8, ["loading"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}