import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { AuthMutationTypes } from '@/store/enums/Auth/Mutations';
import { AuthActionTypes } from '@/store/enums/Auth/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import router from '@/router/clean';
import JwtService from '@/core/services/JwtService';
import LocalStorageServices from '@/core/services/LocalStorageServices';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AuthActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
  [AuthActionTypes.LOGOUT](
    { commit }: AugmentedActionContext
  ): Promise<any>;
  [AuthActionTypes.VERIFY_TOKEN](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
  [AuthActionTypes.SWITCH_APP](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;

  [AuthActionTypes.PASSWORD_RESET](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;

  
  [AuthActionTypes.PASSWORD_RESET_CODE](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [AuthActionTypes.LOGIN]({ commit }, payload: any) {
    commit(AuthMutationTypes.SET_AUTH_LOADING, true);
    commit(AuthMutationTypes.SET_AUTH_ERROR, null)
    try {
      const data = await ApiService.post(Links.POST_LOGIN, payload)
      const user = data.data.data.user;
      JwtService.saveToken(data.data.data.access_token)
      // window.localStorage.setItem('user', JSON.stringify(user));
      window.localStorage.setItem('refresh_token', data.data.data.refresh_token)
      window.localStorage.setItem('expires_in', data.data.data.expires_in)

      // commit(AuthMutationTypes.SET_AUTH, data.data);
      commit(AuthMutationTypes.SET_AUTH_TOKEN, data.data.data.access_token)
      commit(AuthMutationTypes.SET_AUTH_LOADING, false);
      commit(AuthMutationTypes.SET_AUTH_USER, user)
      LocalStorageServices.saveUser(JSON.stringify(user));

      // Go to page after successfully login
      // check if the currentRoute exist from the local storage
      // if currentRoute exist router will push to current state where user logged out else push to dashboard
      const localStorageRoutes = window.localStorage.getItem('currentRoute');
      let currentRoute = localStorageRoutes ? JSON.parse(localStorageRoutes) : null;
     
      const uid = window.localStorage.getItem('uid');
      window.localStorage.setItem('uid', user.id);

      // const defaultRoute = (user.role_id ==  4)  // u/w
      //                         ? '/policy/assigned' 
      //                         : ((user.role_id ==  6) // accountact
      //                                 ? '/invoice/approval' 
      //                                 : ((user.role_id == 1 || user.role_id == 14 || user.role_id == 7 || user.role_id == 11) // QCA
      //                                       ? '/dashboard' : '/customers/list'));

      const defaultRoute = '/dashboard'

      console.log(defaultRoute)
      router.push({ path: ((currentRoute && uid == user.id) 
        ? currentRoute 
        : defaultRoute) }).then(() => { router.go(0) })
      // router.push({ name: 'Dashboard' }).then(() => { return router.go(0) })
      // const href = router.resolve({ name: "dashboard" })

      // if (!currentRoute) {
      //   currentRoute = href.href
      // }

      

      // window.location.href = currentRoute
      return data;
    }
    catch (err: any) {
      localStorage.clear();
      commit(AuthMutationTypes.SET_AUTH_ERROR, err.response.data.message)
      commit(AuthMutationTypes.SET_AUTH_LOADING, false);
      return err.response;
    }
  },

  async [AuthActionTypes.PASSWORD_RESET]({}, payload) {
    try {
      const data = await ApiService.post(Links.POST_PASSWORD_RESET, payload)
      return data
    } catch (error: any) {
      return error
    }
  },

  async [AuthActionTypes.PASSWORD_RESET_CODE]({}, payload) {
    try {
      const data = await ApiService.post(Links.POST_PASSWORD_RESET_CODE, payload)
      return data
    } catch (error: any) {
      return error
    }
  },


  async [AuthActionTypes.LOGOUT]({ commit }) {
    try {
      await ApiService.post_get(Links.POST_LOGOUT);
      commit(AuthMutationTypes.SET_AUTH_TOKEN, null);
      commit(AuthMutationTypes.SET_AUTH_USER, null);
      // commit(AuthMutationTypes.CLEAR_AUTH,null);
      localStorage.clear();
      
      await router.push({ name: 'sign-in' });
      window.location.reload();
    }
    catch (err: any) {
      localStorage.clear();
      commit(AuthMutationTypes.SET_AUTH_TOKEN, null)
      router.push({ name: 'sign-in' })
    }
  },

  async [AuthActionTypes.SWITCH_ROLE]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.POST_SWITCH_ROLE);
      const user = data.data.data.user;

      // console.log(data);
      localStorage.clear();

      JwtService.saveToken(data.data.data.access_token);
      window.localStorage.setItem('refresh_token', data.data.data.refresh_token)
      window.localStorage.setItem('expires_in', data.data.data.expires_in)

      // commit(AuthMutationTypes.SET_AUTH, data.data);
      commit(AuthMutationTypes.SET_AUTH_TOKEN, data.data.data.access_token)
      commit(AuthMutationTypes.SET_AUTH_USER, user)
      LocalStorageServices.saveUser(JSON.stringify(user));
    }
    catch (err: any) {
      // localStorage.clear();
      // commit(AuthMutationTypes.SET_AUTH_TOKEN, null)
      // router.push({name: 'sign-in'})
    }
  },

  async [AuthActionTypes.VERIFY_TOKEN]({ commit }, payload: any) {
    try {
      const data = await ApiService.post(Links.VERIFY_TOKEN, payload);
      console.log('verify token data', data)

      if (data.data.access_token) {
        JwtService.saveToken(data.data.access_token);
        commit(AuthMutationTypes.SET_AUTH_TOKEN, data.data.access_token)
        // commit(AuthMutationTypes.SET_AUTH_LOADING, false);
        // commit(AuthMutationTypes.SET_AUTH_USER, data.data)
        window.localStorage.setItem('refresh_token', data.data.refresh_token)
        window.localStorage.setItem('expires_in', data.data.expires_in)
      }

      if (data.data.expires_at) {
        window.localStorage.setItem('settimeout', data.data.expires_at)
      }

      // JwtService.saveToken(payload)
      // window.localStorage.setItem('user', JSON.stringify(data.data));
      // commit(AuthMutationTypes.SET_AUTH_TOKEN, payload)
      // commit(AuthMutationTypes.SET_AUTH_LOADING, false);
      // commit(AuthMutationTypes.SET_AUTH_USER, data.data)

      // const localStorageRoutes = window.localStorage.getItem('currentRoute');
      // const currentRoute = localStorageRoutes ? JSON.parse(localStorageRoutes) : null;
      // //router.push({ name: "dashboard" })
    }
    catch (err: any) {
      localStorage.clear();
      commit(AuthMutationTypes.SET_AUTH_TOKEN, null)
      commit(AuthMutationTypes.SET_AUTH_USER, null);
      router.push({ name: 'sign-in' })
    }
  },

  async [AuthActionTypes.VERIFY_AUTH]({commit}) {
    try {
      const token = window.localStorage.getItem('id_token')
      if(token) {
        const response = await  ApiService.post("/skye/user-details", {})
        if(response.status < 299) {
          const user = response.data.data.user
          commit(AuthMutationTypes.SET_AUTH_USER, user);
          LocalStorageServices.saveUser(JSON.stringify(user));
          return user
        }
      }
      return null
    } catch (error) {
      localStorage.clear();
      commit(AuthMutationTypes.SET_AUTH_TOKEN, null)
      commit(AuthMutationTypes.SET_AUTH_USER, null);
      router.push({ name: 'sign-in' })
      return null
    }
    
  },

  async [AuthActionTypes.SWITCH_APP]({ commit }, payload: any) {
    try {
      commit(AuthMutationTypes.SET_AUTH_LOADING, true);
      const data = await ApiService.post(Links.POST_SWITCH_APP,payload);
      const user = data.data.data.user;
      JwtService.saveToken(data.data.data.access_token)
      // window.localStorage.setItem('user', JSON.stringify(user));
      window.localStorage.setItem('refresh_token', data.data.data.refresh_token)
      window.localStorage.setItem('expires_in', data.data.data.expires_in)

      // commit(AuthMutationTypes.SET_AUTH, data.data);
      commit(AuthMutationTypes.SET_AUTH_TOKEN, data.data.data.access_token)
      commit(AuthMutationTypes.SET_AUTH_LOADING, false);
      commit(AuthMutationTypes.SET_AUTH_USER, user)
      LocalStorageServices.saveUser(JSON.stringify(user));
      
      return data;
    }
    catch (err: any) {
      localStorage.clear();
      commit(AuthMutationTypes.SET_AUTH_TOKEN, null)
      router.push({name: 'sign-in'})
    }
  },

  async [AuthActionTypes.SWITCH_APP_RELOAD]({ commit }, payload: any) {
    try {
      commit(AuthMutationTypes.SET_AUTH_LOADING, true);
      const data = await ApiService.post(Links.POST_SWITCH_APP_RELOAD,payload);
      return data;
    }
    catch (err: any) {
      // localStorage.clear();
      commit(AuthMutationTypes.SET_AUTH_TOKEN, null)
      // router.push({name: 'sign-in'})
    }
  },

};
