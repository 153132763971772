import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "table-custom-invoice" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("div", { class: "card-title" }, " Email Activity ")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_table, {
              data: _ctx.email_activity,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.email_columns, (email, i) => {
                  return (_openBlock(), _createBlock(_component_el_table_column, {
                    key: i,
                    width: email.width
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(email.cn)
                      }, [
                        _createElementVNode("span", {
                          class: "d-block fs-6 fw-bolder",
                          innerHTML: email.label
                        }, null, 8, _hoisted_4)
                      ], 2)
                    ]),
                    default: _withCtx((scope) => [
                      (email.value === 'index')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(scope.$index + 1), 1))
                        : _createCommentVNode("", true),
                      (email.value === 'sent_by')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(scope.row.action_by.name), 1))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 2,
                            innerHTML: scope.row[email.value]
                          }, null, 8, _hoisted_7))
                    ]),
                    _: 2
                  }, 1032, ["width"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["data"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}