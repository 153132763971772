const toolbars = [
    // {
    //     icon : "las la-user fs-3",
    //     content: "Add Quick Lead",
    //     condition: 1,
    //     route: 1,
    //     click: 1
    // },
    {
        icon : "icon-svg icon-add-customer",
        content: "Add Individual",
        condition: 1,
        route: 1,
        click: 2
    },
    // {
    //     icon : "icon-svg icon-add-corporate",
    //     content: "Add Group",
    //     condition: 1,
    //     route: 1,
    //     click: 4
    // },
    {
        icon : "las la-file-import fs-3",
        content: "Import leads",
        condition: 4,
        route: 1,
        click: 3
    },
]

export {
    toolbars
}