import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center leads-indicator mb-2" }
const _hoisted_2 = { class: "leads nav-link alert bg-light-danger align-items-center leads-btn" }
const _hoisted_3 = { class: "leads-policy nav nav-tabs nav-line-tabs mb-5 fs-5" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "text-italic fw-boldest" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "text-italic fw-boldest" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "text-italic fw-boldest" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "text-italic fw-boldest" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = { class: "text-italic fw-boldest" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "nav-item" }
const _hoisted_20 = { class: "text-italic fw-boldest" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "nav-item" }
const _hoisted_23 = { class: "form-check form-check-sm custom-padding form-check-custom col-md-1 me-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 10 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        md: 24,
        lg: 24
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-info text-italic fw-bold d-block" }, [
              _createTextVNode(" Please Note: The below task count is for "),
              _createElementVNode("span", null, "OPEN"),
              _createTextVNode(" tasks only ")
            ], -1)),
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.customerLists ? _ctx.customerLists.total : 0) + " user/customer", 1)
          ]),
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", { 'active': _ctx.filter.task_due_dates === -1 }]),
                "data-bs-toggle": "tab",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getTaskListFor(-1)))
              }, [
                _cache[10] || (_cache[10] = _createTextVNode("All")),
                _createElementVNode("span", _hoisted_5, [
                  _createTextVNode(" (" + _toDisplayString(_ctx.taskCounts.all) + " ", 1),
                  (_ctx.filter.lead_status_id != -1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "/ " + _toDisplayString(_ctx.leadFilterCounts.all), 1))
                    : _createCommentVNode("", true),
                  _cache[9] || (_cache[9] = _createTextVNode(" )"))
                ])
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", { 'active': _ctx.filter.task_due_dates === 1, 'disabled': !_ctx.store.getters.showByRole([1, 9, 13]) && _ctx.filter.agent === 'all' }]),
                "data-bs-toggle": "tab",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getTaskListFor(1)))
              }, [
                _cache[12] || (_cache[12] = _createTextVNode("Today")),
                _createElementVNode("span", _hoisted_8, [
                  _createTextVNode(" (" + _toDisplayString(_ctx.taskCounts.today) + " ", 1),
                  (_ctx.filter.lead_status_id != -1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "/ " + _toDisplayString(_ctx.leadFilterCounts.today), 1))
                    : _createCommentVNode("", true),
                  _cache[11] || (_cache[11] = _createTextVNode(")"))
                ])
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", { 'active': _ctx.filter.task_due_dates === 2, 'disabled': !_ctx.store.getters.showByRole([1, 9, 13]) && _ctx.filter.agent === 'all' }]),
                "data-bs-toggle": "tab",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getTaskListFor(2)))
              }, [
                _cache[14] || (_cache[14] = _createTextVNode(" Yesterday ")),
                _createElementVNode("span", _hoisted_11, [
                  _createTextVNode(" (" + _toDisplayString(_ctx.taskCounts.yesterday) + " ", 1),
                  (_ctx.filter.lead_status_id != -1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, "/ " + _toDisplayString(_ctx.leadFilterCounts.yesterday), 1))
                    : _createCommentVNode("", true),
                  _cache[13] || (_cache[13] = _createTextVNode(")"))
                ])
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", { 'active': _ctx.filter.task_due_dates === 3, 'disabled': !_ctx.store.getters.showByRole([1, 9, 13]) && _ctx.filter.agent === 'all' }]),
                "data-bs-toggle": "tab",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getTaskListFor(3)))
              }, [
                _cache[16] || (_cache[16] = _createTextVNode(" Last 30 days ")),
                _createElementVNode("span", _hoisted_14, [
                  _createTextVNode(" (" + _toDisplayString(_ctx.taskCounts.last_30_days) + " ", 1),
                  (_ctx.filter.lead_status_id != -1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, "/ " + _toDisplayString(_ctx.leadFilterCounts.last_30_days), 1))
                    : _createCommentVNode("", true),
                  _cache[15] || (_cache[15] = _createTextVNode(")"))
                ])
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_16, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", { 'active': _ctx.filter.task_due_dates === 4, 'disabled': !_ctx.store.getters.showByRole([1, 9, 13]) && _ctx.filter.agent === 'all' }]),
                "data-bs-toggle": "tab",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getTaskListFor(4)))
              }, [
                _cache[18] || (_cache[18] = _createTextVNode(" Older > 30 days ")),
                _createElementVNode("span", _hoisted_17, [
                  _createTextVNode(" (" + _toDisplayString(_ctx.taskCounts.older_than_30_days) + " ", 1),
                  (_ctx.filter.lead_status_id != -1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, "/ " + _toDisplayString(_ctx.leadFilterCounts.older_than_30_days), 1))
                    : _createCommentVNode("", true),
                  _cache[17] || (_cache[17] = _createTextVNode(")"))
                ])
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_19, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", { 'active': _ctx.filter.task_due_dates === 5, 'disabled': !_ctx.store.getters.showByRole([1, 9, 13]) && _ctx.filter.agent === 'all' }]),
                "data-bs-toggle": "tab",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getTaskListFor(5)))
              }, [
                _cache[20] || (_cache[20] = _createTextVNode(" Tomorrow ")),
                _createElementVNode("span", _hoisted_20, [
                  _createTextVNode(" (" + _toDisplayString(_ctx.taskCounts.tomorrow) + " ", 1),
                  (_ctx.filter.lead_status_id != -1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, "/ " + _toDisplayString(_ctx.leadFilterCounts.tomorrow), 1))
                    : _createCommentVNode("", true),
                  _cache[19] || (_cache[19] = _createTextVNode(")"))
                ])
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input rounded-0 transfer-checkbox",
                  type: "checkbox",
                  onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onSelectAllCustomer && _ctx.onSelectAllCustomer(...args))),
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isSelectAll) = $event))
                }, null, 544), [
                  [_vModelCheckbox, _ctx.isSelectAll]
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}