import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "container py-5" }
const _hoisted_4 = { class: "col-4 mb-5" }
const _hoisted_5 = { class: "mb-5" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "container py-5" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-sm-12 text-center" }
const _hoisted_10 = ["disabled", "data-kt-indicator"]
const _hoisted_11 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_12 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dropdown, {
      id: 'invoiceActionDrpDown'+_ctx.invoice.id,
      "destroy-on-close": true
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            (_ctx.showAction && _ctx.invoice.is_show_amend_invoice && _ctx.invoice.is_active_policy  &&  (!_ctx.permissions.is_qca && !_ctx.permissions.is_accountant))
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toPages('addGroupAmendInvoice'))),
                      class: "btn-sm dropdown-item",
                      href: "javascript:void(0)"
                    }, _cache[10] || (_cache[10] = [
                      _createElementVNode("i", { class: "icon-svg icon-amend-invoice" }, null, -1),
                      _createTextVNode(" Addition Invoice ")
                    ]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showAction && _ctx.invoice.is_show_amend_invoice && _ctx.invoice.is_active_policy  &&  (!_ctx.permissions.is_qca && !_ctx.permissions.is_accountant))
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toPages('subGroupAmendInvoice'))),
                      class: "btn-sm dropdown-item",
                      href: "javascript:void(0)"
                    }, _cache[11] || (_cache[11] = [
                      _createElementVNode("i", { class: "icon-svg icon-deletion-amend-invoice" }, null, -1),
                      _createTextVNode(" Deletion Invoice ")
                    ]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showAction && _ctx.invoice.is_show_amend_invoice && _ctx.invoice.is_active_policy  &&  (!_ctx.permissions.is_qca && !_ctx.permissions.is_accountant))
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 2 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toPages('addSubGroupInvoice'))),
                      class: "btn-sm dropdown-item",
                      href: "javascript:void(0)"
                    }, _cache[12] || (_cache[12] = [
                      _createElementVNode("i", { class: "icon-svg icon-amend-invoice" }, null, -1),
                      _createTextVNode(" Category Addition ")
                    ]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  class: "btn-sm dropdown-item",
                  href: "javascript:void(0)",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.invoiceLogModal()))
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                  _createTextVNode(" Group Log")
                ]))
              ]),
              _: 1
            }),
            (_ctx.showAction && _ctx.invoice.invoice_doc)
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 3 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "btn-sm dropdown-item",
                      href: _ctx.invoice.invoice_doc.file_path,
                      target: "_blank"
                    }, _cache[14] || (_cache[14] = [
                      _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                      _createTextVNode(" View Receipt")
                    ]), 8, _hoisted_1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showAction && _ctx.invoice.cheque_doc)
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "btn-sm dropdown-item",
                      href: _ctx.invoice.cheque_doc.file_path,
                      target: "_blank"
                    }, _cache[15] || (_cache[15] = [
                      _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                      _createTextVNode(" View cheque doc")
                    ]), 8, _hoisted_2)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showAction && (_ctx.permissions.is_admin || _ctx.permissions.is_accountant))
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 5 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "btn-sm dropdown-item",
                      href: "javascript:void(0)",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.uploadReceipt(_ctx.invoice.id)))
                    }, _cache[16] || (_cache[16] = [
                      _createElementVNode("i", { class: "icon-svg icon-upload-receipt" }, null, -1),
                      _createTextVNode(" Upload Receipt")
                    ]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  class: "btn-sm dropdown-item",
                  href: "javascript:void(0)",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.invoiceDetails()))
                }, _cache[17] || (_cache[17] = [
                  _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                  _createTextVNode(" Invoice Details")
                ]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          size: "small",
          class: "el-dropdown-custom"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" ... ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["id"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.isUploadReceipt,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isUploadReceipt) = $event)),
      title: "Upload Receipt",
      width: "40%",
      onClose: _ctx.closeModal,
      "close-on-click-modal": false,
      "append-to-body": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              for: "invoiceFileUpload",
              class: "form-label required"
            }, "File/Receipt", -1)),
            _createElementVNode("form", _hoisted_5, [
              _createElementVNode("input", {
                type: "file",
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleFileUpload($event))),
                accept: ".png, .jpg, .jpeg, .pdf"
              }, null, 32)
            ])
          ]),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "mb-1 text-error",
                innerHTML: _ctx.error
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                disabled: _ctx.disableSaveButton,
                "data-kt-indicator": _ctx.disableSaveButton ? 'on' : null,
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.uploadInvoiceDocument && _ctx.uploadInvoiceDocument(...args))),
                class: "btn btn-lg btn-info",
                type: "submit"
              }, [
                (!_ctx.disableSaveButton)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Upload "))
                  : _createCommentVNode("", true),
                (_ctx.disableSaveButton)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[19] || (_cache[19] = [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_10)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}