
import { defineComponent, computed, ref, onMounted, getCurrentInstance, watch } from 'vue';
import { store } from "@/store";
import { rule, common } from '@/store/stateless';
import Quote from '@/core/services/Callable/Quote';
import router from '@/router';
import { useRoute } from 'vue-router';
import { insurance_type } from '@/core/utils'
import { ElLoading } from 'element-plus'

interface policyData {
    id: any;
    key: any;
    value: string | null;
}

export default defineComponent({
    props:{
        showInsurancePlanError:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    setup(_,context) {

        const route = useRoute()
        const health = computed(() => {
            return store.state.customer.health;
        })

        const activeTab = ref<Number>(0)

        const extraBenefit = ref('')
        const extraBenefitDisabled = ref(false)

        const insuranceType = computed(() => {
            return insurance_type.filter(x => x.value !=3 );
        })

        const quotes = computed(() => {
            return store.getters.getGenerateQuotePolicies;
        })

        const policyData = computed(() => {
            return store.state.quote.quote_policies;
        })
        // console.log(policyData.value)
        const providers = ref<any>([]);
        const policies = ref<any>([]);
        const benefits = ref<any>([]);
        const insuranceProviders = ref<any>([]);

        
        onMounted(async () => {
            // await changeInsuranceType();
            // console.log(quotes.value);
            quote_insurance_type.value = 0
            providers.value = [];
            await Quote.setQuotePolicies([]);
            await Quote.setGenerateQuotePolicies([]);
            changeInsuranceType()

        })

        watch(() => health.value, async (newValue) => {
            console.log('inside watchs')
            if(newValue) {
                // if(quote_insurance_type.value != 0)
                //     quote_insurance_type.value = newValue.insurance_type
                quote_insurance_type.value = 0
                providers.value = []
                await Quote.setQuotePolicies([]);
                await Quote.setGenerateQuotePolicies([]);
                changeInsuranceType()
            }
        }, { deep: true })

        const changeInsuranceType = async () => {
            const payload = {
                health: health.value,
                insurance_type: quote_insurance_type.value,
                // generate_quote:1
            };
            console.log('payload',payload)
            // payload.health.insurance_type = quote_insurance_type.value;

            const loading = ElLoading.service({
                // target: '.policies-list',
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
            await Quote.getGenerateQuotes(payload);

            loading.close()
            providers.value = [];

            context.emit('quoteInsuranceType',quote_insurance_type.value)
        }

        

        // const setPolicies = (id) => {
        //     activeTab.value = id
        //     policies.value = insuranceProviders.value.filter((item) => {
        //         return item['id'] == id;
        //     })
        //     // console.log(policies.value);

        // }

        const selectProvider = async () => {
            // policyData.value = [];
            let insurance_providers = quotes.value.filter((item) => {
                // console.log(item);
                return providers.value.includes(item['id'])
            })
            await Quote.setQuotePolicies([]);
            benefits.value = ['Premium Override'];
            insuranceProviders.value = insurance_providers;
            console.log('insuranceProviders' , insuranceProviders.value)
            insuranceProviders.value.forEach((value, index) => {
                policyData.value[index] = 
                    {
                        id: value['id'],
                        policy_id: value['policy_id'],
                        key: value['premium'],
                        value: '',
                        extras:[{key:'premium',value:''}]
                    }
                
            });
            // setPolicies(providers.value[0]);

        }

        const changeExtraBenefit = () => {
            // if(extraBenefit)
            // {
            //     extraBenefitDisabled.value = true;
            // }
            // else 
            //     extraBenefitDisabled.value = false;

            context.emit('updateExtraBenefit',benefits.value)
        }


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
                toFocus.focus()
                window.scrollTo(0, common.getY('last'))
            }
        }

        const quote_insurance_type = ref(0)

        const changeQuoteInsuranceType = () => {
            console.log(quote_insurance_type.value);
            
            // health.value.insurance_type = quote_insurance_type.value;
            changeInsuranceType()
        }

        const addBenefit = () => {
            insuranceProviders.value.forEach((value, index) => {
                policyData.value[index].extras.push( 
                    {
                        key: '',
                        value: '',
                    }
                )
            });
        }

        const removeBenefit = (key) => {
            // e.preventDefault();
            benefits.value.splice(key,1)
            insuranceProviders.value.forEach((value, index) => {
                policyData.value[index].extras.splice(key,1)
            });
        }

        

        return {
            rule,
            focus,
            health,
            providers,
            quotes,
            selectProvider,
            activeTab,
            common,
            insuranceProviders,
            policies,
            // setPolicies,
            policyData,
            extraBenefit,
            extraBenefitDisabled,
            changeExtraBenefit,
            changeInsuranceType,
            changeQuoteInsuranceType,
            quote_insurance_type,
            insurance_type,
            addBenefit,
            removeBenefit,
            benefits,
            insuranceType
        }
    },
})
