import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "align-self-center text-gray-700" }
const _hoisted_5 = { class: "ms-3" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_9 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_10 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_11 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_12 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_13 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_14 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_15 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_16 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_17 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_18 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_19 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_20 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_21 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_22 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_23 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_24 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_25 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}
const _hoisted_26 = { class: "col-sm-3" }
const _hoisted_27 = { class: "mb-10" }
const _hoisted_28 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-3`)
}
const _hoisted_29 = {
  class: /*@__PURE__*/_normalizeClass(`mb-10`)
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_input_emirate_id = _resolveComponent("vc-input-emirate-id")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_insurance_payment = _resolveComponent("vc-insurance-payment")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[14] || (_cache[14] = _createTextVNode(" Update Policy Details ")),
            (_ctx.isGroup)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "mx-2 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.invoiceLogModal && _ctx.invoiceLogModal(...args)))
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "fw-bolder" }, "Expected Policy Start Date:", -1)),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.policy?.lead?.expected_insurance_start_date_format), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_vc_input, {
                  modelValue: _ctx.policy.insured_person,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.policy.insured_person) = $event)),
                  label: "Client Name",
                  placeholder: "Client Name",
                  size: "large",
                  prop: "insured_person",
                  ref: "insured_person",
                  maxlength: 180,
                  isRequired: "",
                  keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)",
                  disabled: _ctx.isDisableAllFields
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_vc_input, {
                  modelValue: _ctx.policy.emirates_id,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.policy.emirates_id) = $event)),
                  label: "Lebanese Id",
                  placeholder: "Lebanese Id",
                  size: "large",
                  prop: "emirates_id",
                  ref: "emirates_id",
                  maxlength: 50,
                  isRequired: "",
                  disabled: _ctx.isDisableAllFields
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            (false && _ctx.policy.lead.insured_type_id != _ctx.constant.insuredMyselfValue)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_vc_input, {
                        modelValue: _ctx.policy.policy_holder,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.policy.policy_holder) = $event)),
                        label: "Policy Holder Name",
                        placeholder: "Policy Holder Name",
                        size: "large",
                        isRequired: "",
                        prop: "policy_holder",
                        keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)",
                        disabled: _ctx.isDisableAllFields
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_vc_input_emirate_id, {
                        modelValue: _ctx.policy.policy_holder_emirates_id,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.policy.policy_holder_emirates_id) = $event)),
                        prop: "policy_holder_emirates_id",
                        label: "Policy Holder Emirates ID",
                        placeholder: "Policy Holder Emirates ID",
                        size: "large",
                        isRequired: "",
                        disabled: _ctx.isDisableAllFields
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_vc_input, {
                  disabled: "",
                  modelValue: _ctx.policy.policy_name,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.policy.policy_name) = $event)),
                  prop: "policy_name",
                  ref: "policy_name",
                  label: "Policy Plan",
                  placeholder: "Policy Plan",
                  size: "large",
                  isRequired: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_vc_input, {
                  modelValue: _ctx.policy.policy_number,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.policy.policy_number) = $event)),
                  prop: "policy_number",
                  ref: "policy_number",
                  label: "Policy No.",
                  placeholder: "Policy No.",
                  size: "large",
                  isRequired: "",
                  maxlength: 150,
                  disabled: _ctx.isDisableAllFields
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label required" }, " Policy Start Date ", -1)),
                _createVNode(_component_vc_date_picker, {
                  modelValue: _ctx.policy.policy_start_date,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.policy.policy_start_date) = $event)),
                  prop: "policy_start_date",
                  min: `${_ctx.minStartDate}`,
                  max: `${_ctx.maxStartDate}`,
                  ref: "policy_start_date",
                  disabled: (_ctx.policy.status == 4 && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields,
                  onBlurOut: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updatePolicyStartDate()))
                }, null, 8, ["modelValue", "min", "max", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label required" }, " Policy End Date ", -1)),
                _createVNode(_component_vc_date_picker, {
                  disabled: !_ctx.isEnableAllFields,
                  modelValue: _ctx.policy.policy_end_date,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.policy.policy_end_date) = $event)),
                  prop: "policy_end_date",
                  min: _ctx.policy.policy_start_date,
                  ref: "policy_end_date"
                }, null, 8, ["disabled", "modelValue", "min"])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label required" }, " Insurance Payment ", -1)),
                _createVNode(_component_el_form_item, { prop: "insurance_payment" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_insurance_payment, {
                      modelValue: _ctx.policy.insurance_payment,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.policy.insurance_payment) = $event)),
                      ref: "insurance_payment",
                      disabled: _ctx.isDisableAllFields
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_component_vc_input, {
                  isInput: "radio",
                  modelValue: _ctx.policy.entity_type,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.policy.entity_type) = $event)),
                  prop: "entity_type",
                  ref: "entity_type",
                  label: "Entity Type",
                  placeholder: "Entity Type",
                  size: "large",
                  isRequired: "",
                  options: _ctx.entities,
                  disabled: _ctx.isDisableAllFields
                }, null, 8, ["modelValue", "options", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_vc_input, {
                  modelValue: _ctx.policy.passport,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.policy.passport) = $event)),
                  prop: "passport",
                  ref: "passport",
                  label: "Passport",
                  placeholder: "Passport",
                  size: "large",
                  isRequired: _ctx.policy.emirates_id == '111-1111-1111111-1',
                  disabled: !_ctx.policy.emirates_id || _ctx.isDisableAllFields,
                  maxlength: 150
                }, null, 8, ["modelValue", "isRequired", "disabled"])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}