import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "leads-policy nav nav-tabs nav-line-tabs mb-5 fs-5" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "text-italic fw-boldest" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "text-italic fw-boldest" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "text-italic fw-boldest" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 10 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        md: 24,
        lg: 24
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            _createElementVNode("li", _hoisted_2, [
              _createElementVNode("a", {
                class: "nav-link cursor-pointer active",
                "data-bs-toggle": "tab",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getCodDirectIntrasact()))
              }, [
                _cache[3] || (_cache[3] = _createTextVNode("COD/OMT/Direct/Cheque/Bank Transfer")),
                _createElementVNode("span", _hoisted_3, " (" + _toDisplayString(_ctx.invoiceCount.cod_direct_count) + ")", 1)
              ])
            ]),
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("a", {
                class: "nav-link cursor-pointer",
                "data-bs-toggle": "tab",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getOnline()))
              }, [
                _cache[4] || (_cache[4] = _createTextVNode(" Online ")),
                _createElementVNode("span", _hoisted_5, " (" + _toDisplayString(_ctx.invoiceCount.online_count) + ")", 1)
              ])
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("a", {
                class: "nav-link cursor-pointer",
                "data-bs-toggle": "tab",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getFailed()))
              }, [
                _cache[5] || (_cache[5] = _createTextVNode(" Unsuccessful Payments ")),
                _createElementVNode("span", _hoisted_7, " (" + _toDisplayString(_ctx.invoiceCount.failed_payments_count) + ")", 1)
              ])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("li", { class: "nav-item" }, [
              _createElementVNode("a", {
                class: "nav-link cursor-pointer",
                "data-bs-toggle": "tab"
              })
            ], -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}