import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "align-self-center text-gray-700" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  key: 0,
  class: "col-sm-12 mb-10"
}
const _hoisted_7 = { class: "col-sm-12 mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_document_upload = _resolveComponent("vc-document-upload")!
  const _component_vc_document_table_data = _resolveComponent("vc-document-table-data")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-title" }, " Update Documents ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", {
              href: "javascript:void(0);",
              class: "btn btn-download-all mt-5 mb-5",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadZip()))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", {
                class: "icon-svg icon-download",
                style: {"transform":"translateY(-15%)","position":"relative","-webkit-transform":"translateY(-15%)"}
              }, null, -1),
              _createTextVNode(" All ")
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.isDisableAllFields)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_vc_document_upload, {
                    lead_id: _ctx.policy.lead_id,
                    policy_id: _ctx.policy.policy_purchase_id,
                    lead_employee_category_id: _ctx.leadEmployeeCategoryId
                  }, null, 8, ["lead_id", "policy_id", "lead_employee_category_id"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_vc_document_table_data, {
                "is-edit": "",
                documents: _ctx.documents,
                policy_id: _ctx.policy.policy_purchase_id,
                showDelete: true,
                lead_employee_category_id: _ctx.leadEmployeeCategoryId,
                onRefreshData: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchDocument())),
                onDeleteEmirates: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteEmirates())),
                isDisableAllFields: _ctx.isDisableAllFields
              }, null, 8, ["documents", "policy_id", "lead_employee_category_id", "isDisableAllFields"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}