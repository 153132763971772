import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "table-responsive"
}
const _hoisted_3 = {
  class: "table table-hover table-bai-striped table-row-bordered border gy-7 gs-7",
  id: "leadTaskListing"
}
const _hoisted_4 = { class: "fw-bold text-gray-600" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "bi bi-flag-fill text-danger me-1"
}
const _hoisted_7 = {
  key: 1,
  class: "bi bi-flag-fill text-warning me-1"
}
const _hoisted_8 = { class: "text-bai-table fs-6" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "text-center cursor-default" }
const _hoisted_13 = { class: "text-bai-table text-center cursor-default" }
const _hoisted_14 = { class: "text-bai-table" }
const _hoisted_15 = {
  style: {"margin-right":"16px"},
  plain: ""
}
const _hoisted_16 = { class: "card" }
const _hoisted_17 = { class: "card-body p-2" }
const _hoisted_18 = { class: "text-gray-600" }
const _hoisted_19 = {
  key: 0,
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_20 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_21 = {
  key: 1,
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_22 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_23 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_24 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "form-check form-check-sm form-check-custom" }
const _hoisted_27 = ["value"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (Object.keys(_ctx.lists).length > 0 && _ctx.lists.data && _ctx.lists.data.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("table", _hoisted_3, [
                  _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", { class: "bg-bai-table-header text-gray-700 text-start fw-bolder fs-6 gs-0 cursor-pointer" }, [
                      _createElementVNode("th", {
                        width: "200px",
                        class: "fs-6"
                      }, "Name"),
                      _createElementVNode("th", { width: "250px" }, [
                        _createElementVNode("span", { class: "fw-bolder text-hover-primary d-block fs-6" }, "Email"),
                        _createElementVNode("span", { class: "d-block fs-6" }, "Mobile Number")
                      ]),
                      _createElementVNode("th", { class: "fs-6" }, "User Type"),
                      _createElementVNode("th", { class: "fs-6" }, "Is Renewal"),
                      _createElementVNode("th", { class: "fs-6" }, "Task #"),
                      _createElementVNode("th", { class: "fs-6" }, "Task Due Date"),
                      _createElementVNode("th", { class: "fs-6" }, "Sales Agent"),
                      _createElementVNode("th", { class: "fs-6" }, "Select Transfer")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists.data, (user, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        class: "odd bg-hover-light text-bai-table",
                        key: index,
                        style: {"border-top-color":"azure"}
                      }, [
                        _createElementVNode("td", {
                          onClick: ($event: any) => (_ctx.toDetailed(user))
                        }, [
                          (user.customer.hot_lead == 1)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                            : _createCommentVNode("", true),
                          (user.customer.hot_renewal_lead == 1)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(user.customer.name), 1)
                        ], 8, _hoisted_5),
                        _createElementVNode("td", _hoisted_8, [
                          _createElementVNode("span", {
                            class: "cursor-copy",
                            onClick: ($event: any) => (_ctx.copyText.copyLink(user.customer.email))
                          }, _toDisplayString(user.customer.email), 9, _hoisted_9),
                          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("span", {
                            class: "text-muted fw-bold text-muted d-block fs-6 cursor-copy",
                            onClick: ($event: any) => (_ctx.copyText.copyLink(user.customer.phone_number))
                          }, _toDisplayString(user.customer.phone_number), 9, _hoisted_10)
                        ]),
                        _createElementVNode("td", {
                          onClick: ($event: any) => (_ctx.toDetailed(user))
                        }, [
                          _createElementVNode("span", {
                            class: "badge fs-7 fw-bold min-w-100 p-1",
                            style: _normalizeStyle(_ctx.UserTypesMixin(user.health_customer.customer_status_id))
                          }, _toDisplayString(user.health_customer.customer_status_text), 5)
                        ], 8, _hoisted_11),
                        _createElementVNode("td", _hoisted_12, _toDisplayString(user.is_renewal == 0 || user.open_task_count == 0 ? "-" : "Renew"), 1),
                        _createElementVNode("td", _hoisted_13, _toDisplayString(user.open_task_count), 1),
                        _createElementVNode("td", _hoisted_14, [
                          _createVNode(_component_el_popover, {
                            placement: "left",
                            width: 320,
                            "popper-style": "box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                          }, {
                            reference: _withCtx(() => [
                              _createElementVNode("span", _hoisted_15, _toDisplayString(user.due_date_format), 1)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("table", _hoisted_18, [
                                    _createElementVNode("tbody", null, [
                                      _cache[6] || (_cache[6] = _createElementVNode("tr", {
                                        class: "text-gray-600",
                                        style: {"background-color":"#fff"}
                                      }, [
                                        _createElementVNode("th", {
                                          scope: "col",
                                          nowrap: "",
                                          colspan: "2",
                                          class: "text-center p-2"
                                        }, " Request Timeline ")
                                      ], -1)),
                                      (user.health_customer.current_visited_date_format)
                                        ? (_openBlock(), _createElementBlock("tr", _hoisted_19, [
                                            _cache[3] || (_cache[3] = _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2",
                                              nowrap: ""
                                            }, [
                                              _createElementVNode("i", { class: "fa fa-genderless text-primary icon-xl" }),
                                              _createTextVNode(" Current Visit Date ")
                                            ], -1)),
                                            _createElementVNode("td", _hoisted_20, _toDisplayString(user.health_customer.current_visited_date_format), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (user.health_customer.hot_enquiry)
                                        ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                                            _cache[4] || (_cache[4] = _createElementVNode("th", {
                                              scope: "col",
                                              nowrap: "",
                                              class: "p-2"
                                            }, [
                                              _createElementVNode("i", { class: "fa fa-genderless text-danger icon-xl" }),
                                              _createTextVNode(" Last Visit Date ")
                                            ], -1)),
                                            _createElementVNode("td", _hoisted_22, _toDisplayString(user.health_customer.last_visited_date_format), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("tr", _hoisted_23, [
                                        _cache[5] || (_cache[5] = _createElementVNode("th", {
                                          scope: "col",
                                          class: "p-2",
                                          nowrap: ""
                                        }, [
                                          _createElementVNode("i", { class: "fa fa-genderless text-warning icon-xl" }),
                                          _createTextVNode(" Created Date ")
                                        ], -1)),
                                        _createElementVNode("td", _hoisted_24, _toDisplayString(user.health_customer.created_at), 1)
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("td", {
                          onClick: ($event: any) => (_ctx.toDetailed(user)),
                          class: "text-bai-table cursor-default"
                        }, _toDisplayString(user.health_customer.agent_details
                                            ? user.health_customer.agent_details.name
                                            : "Not Assigned"), 9, _hoisted_25),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_26, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input rounded-0 transfer-checkbox",
                              type: "checkbox",
                              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checked && _ctx.checked(...args))),
                              value: user.customer_id,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.checkedTransfer) = $event))
                            }, null, 40, _hoisted_27), [
                              [_vModelCheckbox, _ctx.customer.checkedTransfer]
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createVNode(_component_el_empty, { description: "No Records Available" })
              ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_29, _cache[8] || (_cache[8] = [
          _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}