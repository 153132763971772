
import { defineComponent, ref, onMounted } from 'vue'
import { payment_type, pending_payment_type } from '@/core/utils'


export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select Payment Type'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        pendingPaymentType:{
            type:Boolean,
            required:false,
            default:false
        },
        invoiceFilter:{
            type:Boolean,
            required:false,
            default:false
        }
    },

    setup(props, context) {
        
        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("payment_type", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        if(props.invoiceFilter)
        {
            payment_type.shift()
            payment_type.unshift({
                value: -1,
                label: 'All Transaction Type'
            })
        }

        const paymentType = ref([])

        onMounted(() => {
            paymentType.value = payment_type.filter(x => x.value != 7)

        });

        return {
            focus,
            payment_type,
            inputRef,
            updateValue,
            pending_payment_type,
            paymentType
        }
    },
})
