import { MutationTree } from 'vuex';

import { User, Reset } from '@/core/types/Auth';

import { State } from './state';
import { AuthMutationTypes } from '@/store/enums/Auth/Mutations';

export type Mutations<S = State> = {
  [AuthMutationTypes.SET_AUTH_ERROR](state: S, payload: string | null): void;
  [AuthMutationTypes.SET_AUTH_TOKEN](state: S, payload: string | null): void;
  [AuthMutationTypes.SET_AUTH_LOADING](state: S, payload: boolean): void;
  [AuthMutationTypes.SET_AUTH_USER](state: S, payload: any): void;
  [AuthMutationTypes.CLEAR_AUTH](state: S, payload: any): void;
  [AuthMutationTypes.SET_OPEN_PASSWORD_RESET](state: S, payload: any): void;
  [AuthMutationTypes.SET_PASSWORD_RESET_POPULATED](state: S, payload: any): void;
  [AuthMutationTypes.SET_PASSWORD_RESET](state: S, payload: any): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [AuthMutationTypes.SET_AUTH_ERROR](state: State, payload: string | null) {
    state.error = payload;
  },

  [AuthMutationTypes.SET_AUTH_TOKEN](state: State, payload: string | null) {
    state.token = payload;
  },

  [AuthMutationTypes.SET_AUTH_LOADING](state: State, payload: boolean) {
    state.loading = payload;
  },

  
  [AuthMutationTypes.SET_OPEN_PASSWORD_RESET](state: State, payload: boolean) {
    state.open_reset = payload;
  },

  [AuthMutationTypes.SET_PASSWORD_RESET_POPULATED](state: State, payload: boolean) {
    state.reset_populated = payload;
  },

  [AuthMutationTypes.SET_PASSWORD_RESET](state: State, payload: Reset) {
    state.reset = payload;
  },

  [AuthMutationTypes.SET_AUTH_USER](state: State, payload: any) {
    state.user = payload;
  },

  [AuthMutationTypes.CLEAR_AUTH](state: State,payload: any) {
    window.localStorage.removeItem("id_token");
    window.localStorage.removeItem("myRoute");
    // window.localStorage.removeItem("currentRoute");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("expires_in");
  },

};
