
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Invoice from '@/core/services/Callable/Invoice'
import { registration, common } from '@/store/stateless';

export default defineComponent({
    props: {
        // isError: {
        //     type: Boolean,
        //     : true,
        //     default: false,
        // }
    },

    setup(props) {
        const store = useStore();
        const currency = registration.currency
        const invoiceDetails = computed(() => {
            return store.state.invoice.invoiceDetails;
        });

        const invoice = computed(()=>{
            console.log(invoiceDetails.value.invoice)

            if(invoiceDetails.value.invoice){
            invoiceDetails.value.invoice.is_split_payment = invoiceDetails.value.invoice.is_split_payment == 1 ? true : false; 
            return invoiceDetails.value.invoice;
            }
            return null;
        })

        const closeModal = () => {
            const data = {
                show: false,
                invoice: null
            }
            Invoice.setInvoiceDetailsModal(data);
        }

        const icp_total = computed(() => {
            if(invoice.value) return parseFloat(invoice.value.icp) + parseFloat(invoice.value.icp_vat) 

            return 0
        })

        const policySalesAgent = computed(() => {
            return invoice.value.policy_sales_agent?.name
        })

        const policyName = computed(() => {
            return invoice.value.policy?.policy_name
        })

        const invoiceDoc = computed(() => {
            return invoice.value.invoice_doc?.file_path
        })

        const chequeDoc = computed(() => {
            return invoice.value.cheque_doc?.file_path
        })


        return {
            icp_total,
            chequeDoc,
            invoiceDoc,
            policyName,
            policySalesAgent,
            common,
            invoiceDetails,
            invoice,
            closeModal,
            currency,
            store
        };
    },
});
