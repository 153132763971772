import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "w-100 h-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_add_notes = _resolveComponent("vc-customer-add-notes")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_vc_customer_notes_data = _resolveComponent("vc-customer-notes-data")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    width: "35%",
    onClosed: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modal(false))),
    fullscreen: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Notes List ", -1)),
        _createVNode(_component_vc_customer_add_notes, {
          lead_id: _ctx.activeTab,
          ref: "add_notes"
        }, null, 8, ["lead_id"]),
        _createVNode(_component_vc_button, {
          className: "me-12 btn btn-md btn-info",
          text: "Add Notes",
          "is-regular": "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.add_notes.modal(true)))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "mx-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  filterable: "",
                  modelValue: _ctx.activeValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeValue) = $event)),
                  onChange: _ctx.changeValue,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: i,
                        label: lead.name,
                        value: lead.lead_id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
            ])))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.notes.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_vc_customer_notes_data)
                  ]))
                : (_openBlock(), _createBlock(_component_el_empty, {
                    key: 1,
                    description: "No Records Available"
                  }))
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}