import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    class: "upload-demo",
    drag: "",
    headers: { Authorization: `Bearer ${_ctx.bearer}` },
    action: `${_ctx.url}skye/health/documents/add-document`,
    multiple: "",
    data: _ctx.dataValue,
    accept: ".jpeg,.jpg,.png,.pdf,.zip",
    "before-upload": _ctx.beforeUpload,
    "on-success": _ctx.onSuccess,
    "on-error": _ctx.onError,
    "on-progress": _ctx.onProgress,
    "show-file-list": false,
    "list-type": "text",
    style: {"background-color":"#F1FAFF !important"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
        default: _withCtx(() => [
          _createVNode(_component_upload_filled)
        ]),
        _: 1
      }),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "el-upload__text" }, [
        _createTextVNode(" Click to Attach files or drop files here. Valid document types that can be uploaded are "),
        _createElementVNode("em", null, "pdf, jpeg, jpg and png only")
      ], -1))
    ]),
    _: 1
  }, 8, ["headers", "action", "data", "before-upload", "on-success", "on-error", "on-progress"]))
}