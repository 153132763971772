import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container ps-2 pe-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "mb-6 p-1 col-sm-6" }
const _hoisted_5 = { class: "mb-6 p-1 col-sm-6" }
const _hoisted_6 = { class: "mb-6 p-1 col-sm-6" }
const _hoisted_7 = { class: "mb-6 p-1 col-sm-6" }
const _hoisted_8 = { class: "mb-6 p-1 col-sm-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_table_stats = _resolveComponent("vc-table-stats")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table_others, (card, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["mb-6 p-1", card.div_class])
        }, [
          (_openBlock(), _createBlock(_component_vc_table_stats, {
            items: _ctx.getOtherData(card.value),
            columns: card.columns,
            showCount: card.showCount,
            title: card.label,
            backgroundColor: card.backgroundColor,
            fontColor: card.fontColor,
            onPagination: ($event: any) => (_ctx.paginationClick(card.value,$event)),
            lists: _ctx.getPagination(card.value),
            key: card.value
          }, null, 8, ["items", "columns", "showCount", "title", "backgroundColor", "fontColor", "onPagination", "lists"]))
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_vc_table_stats, {
          title: 'Medical Class First',
          backgroundColor: '#34bfa3',
          fontColor: '#FFF',
          isBodyFields: "",
          BodyFields: _ctx.firstMedical
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_vc_table_stats, {
          title: 'Medical Class Second',
          backgroundColor: '#34bfa3',
          fontColor: '#FFF',
          isBodyFields: "",
          BodyFields: _ctx.secondClass
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_vc_table_stats, {
          title: 'Medical Class Third',
          backgroundColor: '#34bfa3',
          fontColor: '#FFF',
          isBodyFields: "",
          BodyFields: _ctx.thirdClass
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_vc_table_stats, {
          title: 'Medical Class Luxury',
          backgroundColor: '#34bfa3',
          fontColor: '#FFF',
          isBodyFields: "",
          BodyFields: _ctx.luxClass
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_vc_table_stats, {
          title: 'Closing Ratio',
          twoAndDown: "",
          hfont: "blue",
          lcolor: "red",
          backgroundColor: '#36a3f7',
          fontColor: '#FFF',
          isBodyFields: "",
          BodyFields: _ctx.closingRatio
        }, null, 8, ["BodyFields"])
      ])
    ])
  ]))
}