import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "demo-collapse" }
const _hoisted_2 = { class: "col-sm-8" }
const _hoisted_3 = { class: "col-sm-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_collapse, { accordion: "" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadInformation, (lead, i) => {
          return (_openBlock(), _createBlock(_component_el_collapse_item, {
            key: i,
            name: lead.id
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(lead.member_name), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.declaration_answers, (declaration, j) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row",
                    key: j
                  }, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(declaration.question), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_el_radio_group, {
                        modelValue: declaration.answer,
                        "onUpdate:modelValue": ($event: any) => ((declaration.answer) = $event)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_radio, { label: "YES" }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Yes")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_radio, { label: "NO" }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode("No")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue"])
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}