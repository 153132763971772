import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-5"
}
const _hoisted_2 = { class: "text-danger" }
const _hoisted_3 = {
  key: 1,
  class: "p-5"
}
const _hoisted_4 = { class: "text-danger" }
const _hoisted_5 = {
  key: 2,
  class: "p-5"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "document-icons adjust-icons generate-pdf-icons text-center invoice-generated"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.openModal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openModal) = $event)),
    "show-close": false,
    "close-on-click-modal": false,
    width: "500px",
    title: _ctx.openModalValue.isError || _ctx.openModalValue.isDocError  ? 'Invoice Error' : 'Invoice Generated'
  }, {
    default: _withCtx(() => [
      (_ctx.openModalValue.isError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.openModalValue.message), 1)
          ]))
        : (_ctx.openModalValue.isDocError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.openModalValue.msg), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", null, [
                _cache[5] || (_cache[5] = _createTextVNode(" Invoice - ")),
                _createElementVNode("span", null, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.openModalValue.merchant_reference), 1)
                ]),
                _cache[6] || (_cache[6] = _createTextVNode(" generated Sucessfully.")),
                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                (_ctx.openModalValue.payment_type == 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "This is the your payment url : " + _toDisplayString(_ctx.openModalValue.payment_link), 1))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.openModalValue.payment_type == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendEmailPaymentCustomer && _ctx.sendEmailPaymentCustomer(...args))),
                      class: "btn-outline-brand email-button me-5",
                      ref: "okRef"
                    }, [
                      (_ctx.loading)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _cache[8] || (_cache[8] = [
                            _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                          ])))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[9] || (_cache[9] = [
                            _createElementVNode("i", { class: "icon-svg icon-inbox" }, null, -1)
                          ])))
                    ], 512),
                    _createElementVNode("a", {
                      href: "javascript:void(0);",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redirectToWhatsapp && _ctx.redirectToWhatsapp(...args))),
                      class: "btn-outline-brand whatsapp-button"
                    }, _cache[10] || (_cache[10] = [
                      _createElementVNode("i", { class: "la la-whatsapp" }, null, -1)
                    ]))
                  ]))
                : _createCommentVNode("", true)
            ]))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        (_ctx.openModalValue.isError)
          ? (_openBlock(), _createBlock(_component_vc_button, {
              key: 0,
              type: "button",
              loading: _ctx.loading,
              text: 'Send Email',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendEmailItTeam()))
            }, null, 8, ["loading"]))
          : _createCommentVNode("", true),
        (!_ctx.openModalValue.isError && _ctx.openModalValue.payment_type != 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-lg btn-info",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.redirectToLeadDetails && _ctx.redirectToLeadDetails(...args))),
              ref: "okRef"
            }, "Ok", 512))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}