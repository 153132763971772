
import { computed, defineComponent, onMounted, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

interface IPayload {
    isDropdown: boolean;
}

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        },
        members:{
            type:Object,
            required:false,
            default:[]
        }
    },

    setup(props, context) {

        let bodyParam = ref<IPayload>({
            isDropdown: true,
        });
        const health = computed(() => {
            return store.state.customer.health;
        })


        const relationships = computed(() => {
            return store.state.etc.relationships
        })

        onMounted(() => {
            Etcs.getRelationships(bodyParam.value);
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("relationship", event)
        }

        const disableSelf = (relation) => {
            const self = health.value.members.filter(x => x.relationship == 'Self');

            if(self.length >= 1 && relation == 'Self'){
                return true
            }
            return false

        }

        

        return {
            relationships,
            updateValue,
            disableSelf
        }
    },
})
