import { GetterTree } from 'vuex';
import router from "@/router/clean";

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';



import { State } from './state';
import { common } from "@/store/stateless";

export type Getters = {
    // authorized_headers(state: LoginState): boolean
}


export const getters: GetterTree<State, RootState> & Getters = {
    getDashboardDetails: (state) => {
        if(state.dashboard_details && state.dashboard_details.data) {
            return state.dashboard_details.data.map(x => {
                let policy_price = x.latest_policy_purchase ? x.latest_policy_purchase.policy_price : 0
                if(x.insured_type_id == 4) {
                    policy_price = x.latest_group_policy_purchase ? x.latest_group_policy_purchase.policy_price : 0
                }
                return {
                    customer_id: x.customer_id,
                    name: x.customer.name,
                    email: x.customer.email,
                    mobile: x.customer.phone_number,
                    lead_source: x.lead_source ? x.lead_source : '-',
                    lead_status_text: x.status_text,
                    lead_status: x.status,
                    sales_agent: x.agent ? x.agent.name : '-',
                    other_lead_sources: x.other_sources_count_by,
                    utm_source: x.utm_source ? x.utm_source : '',
                    utm_medium: x.utm_medium ? x.utm_medium : '',
                    other_utm_medium: x.other_utm_medium_count_by ? x.other_utm_medium_count_by : '',
                    utm_campaign: x.utm_campaign ? x.utm_campaign : '',
                    other_utm_campaign: x.other_utm_campaign_count_by ? x.other_utm_campaign_count_by : '',
                    utm_content: x.utm_content ? x.utm_content : '',
                    other_utm_content: x.other_utm_content_count_by ? x.other_utm_content_count_by : '',
                    lead_id: x.id,
                    policy_type: x.insured_type_id == 4 ? 'Group' : 'Individual',
                    insured_for: x.insured_type_text,
                    insurance_type: x.insurance_type_text,
                    relationship: x.insured_type_id == 4 ? '' : (x.lead_information ? x.lead_information.relationship : ''),
                    // relationship: x.lead_information ? x.lead_information.relationship : '',
                    policy_price: common.formatCurrency(policy_price),
                    created_on: x.created_at,
                    updated_on: x.updated_at,
                }
            })
        }
        return []
    },

    getAgentSales: (state) => {
        if(state.agent_sales && state.agent_sales.data) {
            return state.agent_sales.data.map(x => {
                // return x;
                return {
                    created_at: x.created_at,
                    merchant_reference: x.merchant_reference,
                    created_by: x.created_by.name,
                    policy_created_at: x.policy_purchase.created_at,
                    issue_date: x.policy_purchase.issue_date ,
                    policy_price: common.formatCurrency(x.policy_price),
                    policy_price_vat: common.formatCurrency(x.policy_price_vat),
                    basmah:  common.formatCurrency(x.basmah),
                    basmah_vat:  common.formatCurrency(x.basmah_vat),
                    icp:  common.formatCurrency(Number(x.icp) + Number(x.icp_vat)),
                    icp_vat:  common.formatCurrency(x.icp_vat),
                    non_taxable_fee:  common.formatCurrency(x.non_taxable_fee),
                    sales_price:  common.formatCurrency(x.sales_price),
                    sales_price_vat:  common.formatCurrency(x.sales_price_vat),
                    payment_type_text: x.payment_type_text,
                    policy_sales_agent: x.policy_sales_agent.name,
                    policy_status: x.policy_purchase.status_text,
                    debit_amount: common.formatCurrency(x.debit_amount),
                    debit_amount_vat: common.formatCurrency(x.debit_amount_vat),
                    policy_name: x.policy.insurance_provider_name +' - '+ x.policy.policy_name,
                    insurance_type: x.policy.insurance_type_text,
                    policy_number: x.policy_purchase.policy_number,
                    policy_start_date_format: x.policy_purchase.policy_start_date_format,
                    policy_end_date_format: x.policy_purchase.policy_end_date_format,
                    lead_details: x.health_lead.lead_name,
                    underwriter: x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '',
                    transaction_type_text:x.policy_purchase.transaction_type_text
                }
            })
        }
        return []
    },

    getAgentGroupSales: (state) => {
        if(state.agent_group_sales && state.agent_group_sales.data) {
            return state.agent_group_sales.data.map(x => {
                // return x;
                return {
                    created_at: x.invoice.created_at,
                    merchant_reference: x.invoice.merchant_reference + ' / '+ x.merchant_reference,
                    created_by: x.invoice.created_by.name,
                    policy_created_at: x.invoice.policy_purchase.created_at,
                    issue_date: x.invoice.policy_purchase.issue_date,
                    policy_price: common.formatCurrency(x.amount_with_vat),
                    policy_price_vat: common.formatCurrency(x.amount_vat),
                    basmah: common.formatCurrency(x.basmah),
                    basmah_vat: common.formatCurrency(x.basmah_vat),
                    icp: common.formatCurrency(Number(x.icp) + Number(x.icp_vat)),
                    icp_vat: common.formatCurrency(x.icp_vat),
                    non_taxable_fee: common.formatCurrency(x.non_taxable_fee),
                    sales_price: common.formatCurrency(x.total_amount),
                    sales_price_vat: common.formatCurrency(x.total_amount_vat),
                    payment_type_text: x.invoice.payment_type_text,
                    policy_sales_agent: x.invoice.policy_sales_agent.name,
                    policy_status: x.invoice.policy_purchase.status_text,
                    debit_amount: common.formatCurrency(x.debit_amount),
                    debit_amount_vat: common.formatCurrency(x.debit_amount_vat),
                    policy_name: x.insurance_plan,
                    insurance_type: 'Enhanced',
                    policy_number: x.policy_number,
                    policy_start_date_format: [1,2].includes(x.invoice.amend_type) ? x.amendment_start_date_format : x.policy_start_date_format,
                    policy_end_date_format: [1,2].includes(x.invoice.amend_type) ? x.amendment_end_date_format : x.policy_end_date_format,
                    lead_details: x.invoice.health_lead.lead_name,
                    underwriter: x.invoice.policy_purchase.policy_underwriter ? x.invoice.policy_purchase.policy_underwriter.name : '',
                    transaction_type_text:x.invoice.policy_purchase.transaction_type_text

                }
            })
        }
        return []
    },

    getPoliciesAssignedVsCompleted: (state) => {
        if(state.policies && state.policies.data) {
            return state.policies.data.map(x => {
                // console.log('x',x)
                // return x;
                return {
                    created_at: x.created_at,
                    merchant_reference: x.main_invoice.merchant_reference,
                    status_text: x.status_text,
                    underwriter: x.policy_underwriter ? x.policy_underwriter.name : '',
                    sales_agent: x.policy_sales_agent.name,
                    transaction_type: x.transaction_type_text,
                    policy_name: x.policy.insurance_provider_name +' - '+x.policy.policy_name,
                    insurance_type: x.policy.insurance_type_text,
                    policy_number: x.policy_number,
                    policy_price: common.formatCurrency(x.policy_price),
                    sales_price_vat:  common.formatCurrency(x.main_invoice.sales_price_vat),
                    policy_start_date_format: x.policy_start_date_format,
                    policy_end_date_format: x.policy_end_date_format,
                    lead_details: x.lead.lead_name
                }
                
            })
        }
        return []
    },

    getPoliciesGroupAssignedVsCompleted: (state) => {
        if(state.policies_group && state.policies_group.data) {
            return state.policies_group.data.map(x => {
                // return x;
                return {
                    created_at: x.created_at,
                    merchant_reference: x.main_invoice.merchant_reference,
                    status_text: x.status_text,
                    underwriter: x.policy_underwriter ? x.policy_underwriter.name : '',
                    sales_agent: x.policy_sales_agent.name,
                    transaction_type: x.transaction_type_text,
                    policy_name: '',
                    insurance_type: '',
                    policy_number: x.policy_number,
                    policy_price: common.formatCurrency(x.policy_price),
                    sales_price_vat:  common.formatCurrency(x.main_invoice.sales_price_vat),
                    policy_start_date_format: x.policy_start_date_format,
                    policy_end_date_format: x.policy_end_date_format,
                    lead_details: x.lead.lead_name
                }
            })
        }
        return []
    },

    getDeletedLeads: (state) => {
        if(state.deleted_leads && state.deleted_leads.data) {
            return state.deleted_leads.data.map(x => {
                let deleted_reason = x.latest_task ? x.latest_task.lost_lead_reason_text : ''

                if(x.latest_task && x.latest_task.lost_lead_reason_id == -1)
                    deleted_reason = x.latest_task.disposition_text

                return {
                    customer_id: x.customer_id,
                    name: x.customer.name,
                    lead_created_on: x.created_at,
                    lead_deleted_on: x.deleted_at,
                    lead_deleted_by: x.deleted_by ? x.deleted_by.name : '',
                    lead_assigned_to: x.agent ? x.agent.name : '',
                    email: x.customer.email,
                    mobile: x.customer.phone_number,
                    deleted_reason,
                    lead_source: x.lead_source ? x.lead_source : '-',
                    lead_status_text: x.status_text,
                    lead_status: x.status,
                    sales_agent: x.agent ? x.agent.name : '-',
                    other_lead_sources: x.other_sources_count_by,
                    utm_source: x.utm_source ? x.utm_source : '',
                    utm_medium: x.utm_medium ? x.utm_medium : '',
                    other_utm_medium: x.other_utm_medium_count_by ? x.other_utm_medium_count_by : '',
                    utm_campaign: x.utm_campaign ? x.utm_campaign : '',
                    other_utm_campaign: x.other_utm_campaign_count_by ? x.other_utm_campaign_count_by : '',
                    utm_content: x.utm_content ? x.utm_content : '',
                    other_utm_content: x.other_utm_content_count_by ? x.other_utm_content_count_by : '',
                    lead_id: x.id,
                    policy_type: x.insured_type_id == 4 ? 'Group' : 'Individual',
                    insured_for: x.insured_type_text,
                    insurance_type: x.insurance_type_text,
                    relationship: x.insured_type_id == 4 ? '' : (x.lead_information ? x.lead_information.relationship : ''),
                    // relationship: x.lead_information ? x.lead_information.relationship : '',
                }
            })
        }
    },

    getAdminReport: (state) => {
        if(state.admin_report) {
            return state.admin_report.map(x => {
                
                return x;
            })
        }

        return [];
    },

    getApprovedInvoices: (state) => {
        if(state.approved_invoices && state.approved_invoices.data) {
            return state.approved_invoices.data.map(x => {
                // return x;
                return {
                    created_at: x.created_at,
                    merchant_reference: x.merchant_reference,
                    invoice_type: x.invoice_type,
                    created_by: x.created_by.name,
                    policy_created_at: x.policy_purchase.created_at,
                    issue_date: x.policy_purchase.issue_date,
                    policy_price: common.formatCurrency(x.policy_price),
                    policy_price_vat: common.formatCurrency(x.policy_price_vat),
                    basmah:  common.formatCurrency(x.basmah),
                    basmah_vat:  common.formatCurrency(x.basmah_vat),
                    icp:  common.formatCurrency(Number(x.icp) + Number(x.icp_vat)),
                    icp_vat:  common.formatCurrency(x.icp_vat),
                    non_taxable_fee:  common.formatCurrency(x.non_taxable_fee),
                    sales_price:  common.formatCurrency(x.sales_price),
                    sales_price_vat:  common.formatCurrency(x.sales_price_vat),
                    status_text: x.status_text,
                    payment_type_text: x.payment_type_text,
                    policy_sales_agent: x.policy_sales_agent.name,
                    policy_status: x.policy_purchase.status_text,
                    debit_amount: common.formatCurrency(x.debit_amount),
                    debit_amount_vat: common.formatCurrency(x.debit_amount_vat),
                    policy_name: x.policy.insurance_provider_name +' - '+ x.policy.policy_name,
                    invoice_approved_at: x.invoice_approved_at,
                    order_description: x.order_description,
                    insurance_type: x.policy.insurance_type_text,
                    policy_start_date_format: x.policy_purchase.policy_start_date_format,
                    policy_end_date_format: x.policy_purchase.policy_end_date_format,
                    underwriter: x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '',
                }
            })
        }
        return []
    },

    getApprovedGroupInvoices: (state) => {
        if(state.approved_group_invoices && state.approved_group_invoices.data) {
            return state.approved_group_invoices.data.map(x => {
                // return x;
                return {
                    created_at: x.created_at,
                    merchant_reference: x.merchant_reference,
                    invoice_type: x.invoice_type,
                    created_by: x.created_by.name,
                    policy_created_at: x.policy_purchase.created_at,
                    issue_date: x.policy_purchase.issue_date,
                    policy_price: common.formatCurrency(x.policy_price),
                    policy_price_vat: common.formatCurrency(x.policy_price_vat),
                    basmah:  common.formatCurrency(x.basmah),
                    basmah_vat:  common.formatCurrency(x.basmah_vat),
                    icp:  common.formatCurrency(Number(x.icp) + Number(x.icp_vat)),
                    icp_vat:  common.formatCurrency(x.icp_vat),
                    non_taxable_fee:  common.formatCurrency(x.non_taxable_fee),
                    sales_price:  common.formatCurrency(x.sales_price),
                    sales_price_vat:  common.formatCurrency(x.sales_price_vat),
                    status_text: x.status_text,
                    payment_type_text: x.payment_type_text,
                    policy_sales_agent: x.policy_sales_agent.name,
                    policy_status: x.policy_purchase.status_text,
                    debit_amount: common.formatCurrency(x.debit_amount),
                    debit_amount_vat: common.formatCurrency(x.debit_amount_vat),
                    policy_name: '',
                    invoice_approved_at: x.invoice_approved_at,
                    order_description: x.order_description,
                    insurance_type: 'Enhanced',
                    policy_start_date_format: x.policy_purchase.policy_start_date_format,
                    policy_end_date_format: x.policy_purchase.policy_end_date_format,
                    underwriter: x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '',
                }
            })
        }
        return []
    },


    getClosedLeadsReport: (state) => {
        if(state.closed_leads && state.closed_leads.data) {
            return state.closed_leads.data.map(x => {

                x.lead_assigned_to = x.healthlead_new_status_log ? x.healthlead_new_status_log.agent_details.name : ''
                x.closed_on = x.healthlead_closed_status_log ? x.healthlead_closed_status_log.created_at : ''
                x.closed_by = x.healthlead_closed_status_log ? x.healthlead_closed_status_log.action_by.name : ''
                x.name = x.customer.name
                x.email = x.customer.email
                x.mobile = x.customer.phone_number
                return x;
            })
        }
    },

    getConvertedLeadsReport: (state) => {
        if(state.converted_leads && state.converted_leads.data) {
            return state.converted_leads.data.map(x => {

                x.lead_assigned_to = x.healthlead_new_status_log ? x.healthlead_new_status_log.agent_details.name : ''
                x.converted_on = x.healthlead_converted_status_log ? x.healthlead_converted_status_log.created_at : ''
                x.converted_by = x.healthlead_converted_status_log ? x.healthlead_converted_status_log.action_by.name : ''
                x.name = x.customer.name
                x.email = x.customer.email
                x.mobile = x.customer.phone_number
                return x;
            })
        }
    },

    getLeadBySourceReport: (state) => {
        if(state.lead_by_source && state.lead_by_source.data) {
            return state.lead_by_source.data.map(x => {
                let policy_price = x.latest_policy_purchase ? x.latest_policy_purchase.policy_price : 0
                if(x.insured_type_id == 4) {
                    policy_price = x.latest_group_policy_purchase ? x.latest_group_policy_purchase.policy_price : 0
                }
                return {
                    customer_id: x.customer_id,
                    name: x.customer.name,
                    email: x.customer.email,
                    mobile: x.customer.phone_number,
                    lead_source: x.lead_source ? x.lead_source : '-',
                    lead_status_text: x.status_text,
                    lead_status: x.status,
                    sales_agent: x.agent ? x.agent.name : '-',
                    other_lead_sources: x.other_sources_count_by,
                    utm_source: x.utm_source ? x.utm_source : '',
                    utm_medium: x.utm_medium ? x.utm_medium : '',
                    other_utm_medium: x.other_utm_medium_count_by ? x.other_utm_medium_count_by : '',
                    utm_campaign: x.utm_campaign ? x.utm_campaign : '',
                    other_utm_campaign: x.other_utm_campaign_count_by ? x.other_utm_campaign_count_by : '',
                    utm_content: x.utm_content ? x.utm_content : '',
                    other_utm_content: x.other_utm_content_count_by ? x.other_utm_content_count_by : '',
                    lead_id: x.id,
                    policy_type: x.insured_type_id == 4 ? 'Group' : 'Individual',
                    insured_for: x.insured_type_text,
                    insurance_type: x.insurance_type_text,
                    relationship: x.insured_type_id == 4 ? '' : (x.lead_information ? x.lead_information.relationship : ''),
                    policy_price: common.formatCurrency(policy_price),
                    created_on: x.created_at,
                    updated_on: x.updated_at,
                }
            })
        }
        return []
    },

    getProductionReport: (state) => {
        if(state.production_report) {
            return state.production_report.map(x => {
                x.requested_by = x.user.name
                return x;
            })
        }

        return [];
    },

    getAccountMasterReport: (state) => {
        if(state.account_master && state.account_master.data) {
            return state.account_master.data.map(invoice => {

                // invoice.invoice_no = invoice.merchant_reference
                // invoice.customer_name = invoice.customer.name
                // invoice.policy_holder = invoice.policy_purchase.insured_person
                // invoice.insurance_type_text = invoice.policy.insurance_type_text
                // invoice.insured_for = invoice.health_lead.insured_type_text
                // invoice.relationship = 'demo'
                // invoice.insured_name = 'demo'

                // invoice.id =  invoice.policy_purchase.emirates_id
                // invoice.line_of_business =  'MEDICAL-INDIVIDUAL'
                // invoice.emirates = invoice.health_lead.insured_city_text
                // invoice.policy_created_on =  invoice.policy_purchase.created_at
                // invoice.policy_completed_on = invoice.policy_purchase.issue_date
                // invoice.policy_status =  invoice.policy_purchase.status_text
                // invoice.policy_start_date =  invoice.policy_purchase.policy_start_date_format
                // invoice.policy_end_date =  invoice.policy_purchase.policy_end_date_format
                // invoice.underwriter =  invoice.policy_purchase.policy_underwriter.name
                // invoice.policy_number =  invoice.policy_purchase.policy_number

                // invoice.policy_name =  invoice.policy.policy_name
                // invoice.company_name =  invoice.policy.insurance_provider_name
                // invoice.policy_type =  'Individual'
                // invoice.policy_price =  invoice.policy_purchase.status_text
                // invoice.endorsement_no =  invoice.policy_purchase.status_text


                // // Calculations:
                // invoice.tp = Number(invoice.debit_amount); // "Tot.Premium(TP)"
                // invoice.vat = Number(invoice.debit_amount_vat);
                // invoice.tax_fees = Number(0); // "TaxFees (Admin Fees)"
                // invoice.ntf = Number(invoice.non_taxable_fee); // NonTaxFees (NTF)
                // invoice.bsf = Number(invoice.basmah)  // Iss/BsmaFees (BSF)
                // invoice.icp = Number( invoice.icp) + Number(invoice.icp_vat); // Iss/BsmaFees (BSF)
                // invoice.tpf = Number( invoice.tax_fees + invoice.ntf + invoice.bsf + invoice.icp); // Total Policy fees (TPF)
                // invoice.np = Number(invoice.tp - invoice.vat -  invoice.tpf); //Net Premium (NP)
                // invoice.ntp = Number(0); // Non Taxable Premium (NTP)
                // invoice.taxable_premium = Number(invoice.np -  invoice.ntp); // Taxable Premium (TP)
                // invoice.prem_total_fee = Number(invoice.np + invoice.tpf); // Prem+Tot.Fees

                // console.log(invoice)
                return invoice;
            })
        }

        return [];
    },

    getCampaignAmountSpend: (state) => {
        // console.log(state.amount_spend)
        return state.amount_spend
    },

    getMarketingReport: (state) => {
        // console.log(state.marketing_report)
        return state.marketing_report
    },

    authorized_headers: (state, getter, rootState): boolean => {
        return [1].includes(rootState.auth.user.role_id)
    }

};

