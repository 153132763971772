import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/warning.svg'


const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_3 = ["innerHTML"]

import { ref } from "vue";
import { common } from "@/store/stateless";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'vcPaymentWarning',
  props: {
    title: {
        type: String,
        required: true
    },
    message: {
        type: String,
        required: true
    },
  },
  setup(__props, { expose: __expose }) {

const props = __props

  const isOpen = ref(false)

  function open(value) {
    isOpen.value = value
  }

  __expose({
    open
  })
    

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event)),
    title: __props.title,
    width: _unref(common).isMobile() ? '90%' :'25%',
    "append-to-body": "",
    "custom-class": "confirmation-popups",
    "close-on-click-modal": false,
    "show-close": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.disableSaveButton)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "warning"
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          innerHTML: __props.message,
          style: {"color":"orange","font-size":"18px","margin-top":"8px"}
        }, null, 8, _hoisted_3)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "width"]))
}
}

})