import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-end customer-details-tab mb-5" }
const _hoisted_2 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-5" }
const _hoisted_3 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_6 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_9 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_12 = { class: "navbar-right" }
const _hoisted_13 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_vc_transfer = _resolveComponent("vc-transfer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lead_tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (tab.value == 'lead_activity')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.permissions.is_admin || _ctx.permissions.is_manager || _ctx.permissions.is_qca)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                      _createElementVNode("a", {
                        class: _normalizeClass(["nav-link pb-2", { active: tab.active, disabled: ((tab.withDisable && _ctx.disabled) || _ctx.leads.length == 0) }]),
                        href: "javascript:void(0);",
                        onClick: ($event: any) => (_ctx.$emit('tab', tab.value))
                      }, [
                        _createTextVNode(_toDisplayString(tab.label) + " ", 1),
                        (tab.withCount)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_5, " (" + _toDisplayString(_ctx.lead_count) + ") ", 1))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_4)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : (tab.value == 'documents')
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link pb-2", { active: tab.active, disabled: ((tab.withDisable && _ctx.disabled) || _ctx.leads.length == 0 || _ctx.onlyGroup) }]),
                    href: "javascript:void(0);",
                    onClick: ($event: any) => (_ctx.$emit('tab', tab.value))
                  }, [
                    _createTextVNode(_toDisplayString(tab.label) + " ", 1),
                    (tab.withCount)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_8, " (" + _toDisplayString(_ctx.lead_count) + ") ", 1))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_7)
                ]))
              : (_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link pb-2", { active: tab.active, disabled: ((tab.withDisable && _ctx.disabled) || _ctx.leads.length == 0) }]),
                    href: "javascript:void(0);",
                    onClick: ($event: any) => (_ctx.$emit('tab', tab.value))
                  }, [
                    _createTextVNode(_toDisplayString(tab.label) + " ", 1),
                    (tab.withCount)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_11, " (" + _toDisplayString(_ctx.lead_count) + ") ", 1))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_10)
                ]))
        ], 64))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_vc_button_navigator, {
        className: "btn btn-info mb-0 me-3",
        icon: "las la-plus fs-3 text-white ",
        "is-icon": "",
        text: "New Lead",
        value: "NewDetail",
        params: { customer_id: _ctx.customer_id }
      }, null, 8, ["params"]),
      (_ctx.cd.is_show_transfer)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            class: "box-item",
            effect: "light",
            content: "Transfer lead",
            placement: "bottom-start"
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                style: {"border-radius":"0"},
                class: "btn btn-light-info btn-light-lead-filter ms-3",
                disabled: _ctx.customer.checkedTransfer.length > 0 ? false : true,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.transfer.modal(true)))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "far fa-paper-plane icon-size" }, null, -1)
              ]), 8, _hoisted_13)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_vc_transfer, { ref: "transfer" }, null, 512)
    ])
  ]))
}