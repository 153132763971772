import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "row justify-content-center" }
const _hoisted_7 = { class: "col-sm-12" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-sm-3" }
const _hoisted_10 = { class: "mb-10" }
const _hoisted_11 = { class: "col-sm-3" }
const _hoisted_12 = { class: "mb-10" }
const _hoisted_13 = { class: "col-sm-3" }
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = { class: "col-sm-3" }
const _hoisted_16 = { class: "mb-10" }
const _hoisted_17 = { class: "col-sm-3" }
const _hoisted_18 = { class: "mb-10" }
const _hoisted_19 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_20 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_countries = _resolveComponent("vc-countries")!
  const _component_vc_relationships = _resolveComponent("vc-relationships")!
  const _component_vc_gender = _resolveComponent("vc-gender")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!
  const _component_vc_dividers = _resolveComponent("vc-dividers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.health.members, (member, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-sm-12",
          key: i
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", null, " Member " + _toDisplayString(_ctx.health.members.length - i), 1),
            (!_ctx.isAmend || !member.id || _ctx.isSubAmend)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (!_ctx.isDisabled)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.health.members.length > 1)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: "ms-4 cursor-pointer icon-bg-common",
                              onClick: ($event: any) => (_ctx.deleteMember(i, member.id))
                            }, [
                              _createVNode(_component_el_tooltip, {
                                class: "box-item",
                                content: "Delete",
                                effect: "light",
                                placement: "bottom",
                                "show-after": 200,
                                "hide-after": 0
                              }, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createElementVNode("i", { class: "icon-svg icon-delete" }, null, -1)
                                ])),
                                _: 1
                              })
                            ], 8, _hoisted_5))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _cache[1] || (_cache[1] = _createElementVNode("label", { class: "form-label required" }, " Full Name ", -1)),
                    _createVNode(_component_el_form_item, {
                      prop: `members.` + i + `.member_name`,
                      rules: _ctx.isDisabled || (_ctx.isAmend && member.id) ? '' : { required: true, message: 'Please enter name', trigger: ['blur', 'change'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          ref_for: true,
                          ref: `members.` + i + `.member_name`,
                          placeholder: "Enter Full Name",
                          size: "large",
                          style: {"width":"100%"},
                          disabled: (_ctx.health.is_self === true && member.relationship == 'Sponsor') || _ctx.isDisabled || (_ctx.isAmend && member.id),
                          modelValue: member.member_name,
                          "onUpdate:modelValue": ($event: any) => ((member.member_name) = $event),
                          onkeydown: "return /[-a-zAZ ]/i.test(event.key)"
                        }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label required" }, " DOB ", -1)),
                    _createVNode(_component_el_form_item, {
                      prop: `members.` + i + `.dob`,
                      class: "el-form-item-custom-date-picker",
                      rules: _ctx.isDisabled || (_ctx.isAmend && member.id) ? '' : { required: true, message: 'Please enter date of birth', trigger: ['blur', 'change'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vc_date_picker, {
                          ref_for: true,
                          ref: `members.` + i + `.dob`,
                          modelValue: member.dob,
                          "onUpdate:modelValue": ($event: any) => ((member.dob) = $event),
                          "with-prop": false,
                          min: "1900-01-01",
                          max: _ctx.common.exceptFutureDates(),
                          disabled: (_ctx.health.is_self === true && member.relationship == 'Sponsor') || _ctx.isDisabled || (_ctx.isAmend && member.id)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "max", "disabled"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label required" }, " Nationality ", -1)),
                    _createVNode(_component_el_form_item, {
                      prop: `members.` + i + `.nationality_id`,
                      rules: _ctx.isDisabled || (_ctx.isAmend && member.id) ? '' : { required: true, message: 'Please select the nationality', trigger: ['blur', 'change'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vc_countries, {
                          ref_for: true,
                          ref: `members.` + i + `.nationality_id`,
                          modelValue: member.nationality_id,
                          "onUpdate:modelValue": ($event: any) => ((member.nationality_id) = $event),
                          style: {"width":"100%"},
                          disabled: (_ctx.health.is_self === true && member.relationship == 'Sponsor') || _ctx.isDisabled || (_ctx.isAmend && member.id),
                          label: "Please select your nationality"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label required" }, " Relationship ", -1)),
                    _createVNode(_component_el_form_item, {
                      prop: `members.` + i + `.relationship`,
                      rules: _ctx.isDisabled || (_ctx.isAmend && member.id) ? '' : { required: true, message: 'Select relationship', trigger: ['blur', 'change'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vc_relationships, {
                          ref_for: true,
                          ref: `members.` + i + `.relationship`,
                          style: {"width":"100%"},
                          modelValue: member.relationship,
                          "onUpdate:modelValue": ($event: any) => ((member.relationship) = $event),
                          disabled: (_ctx.health.is_self === true && member.relationship == 'Sponsor') || _ctx.isDisabled || (_ctx.isAmend && member.id),
                          onRelationship: ($event: any) => (_ctx.updateGender(member))
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onRelationship"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label required" }, " Gender ", -1)),
                    _createVNode(_component_el_form_item, {
                      prop: `members.` + i + `.gender`,
                      class: "el-form-item-custom-radio",
                      rules: _ctx.isDisabled || (_ctx.isAmend && member.id) ? '' : { required: true, message: 'Please select the gender', trigger: ['blur', 'change'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vc_gender, {
                          ref_for: true,
                          ref: `members.` + i + `.gender`,
                          genderName: `members.` + i + `.gender`,
                          modelValue: member.gender,
                          "onUpdate:modelValue": ($event: any) => ((member.gender) = $event),
                          onGender: ($event: any) => (_ctx.UpdateIsMarried(member)),
                          disabled: (_ctx.health.is_self === true && member.relationship == 'Sponsor') || _ctx.isDisabled || (_ctx.isAmend && member.id)
                        }, null, 8, ["genderName", "modelValue", "onUpdate:modelValue", "onGender", "disabled"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"])
                  ])
                ]),
                (member.gender === 'FEMALE')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label required" }, " Is married? ", -1)),
                        _createVNode(_component_el_form_item, {
                          prop: `members.` + i + `.is_married`,
                          class: "el-form-item-custom-radio"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_vc_yes_no, {
                              ref_for: true,
                              ref: `members.` + i + `.is_married`,
                              yesNoName: `members.` + i + `.is_married`,
                              modelValue: member.is_married,
                              "onUpdate:modelValue": ($event: any) => ((member.is_married) = $event),
                              disabled: (_ctx.health.is_self === true && member.relationship == 'Sponsor') || _ctx.isDisabled || (_ctx.isAmend && member.id)
                            }, null, 8, ["yesNoName", "modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 2
                        }, 1032, ["prop"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createVNode(_component_vc_dividers, {
            index: i,
            items: _ctx.health.members
          }, null, 8, ["index", "items"])
        ]))
      }), 128))
    ])
  ]))
}