import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-bordered mb-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-3" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = { class: "col-sm-3" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "col-sm-3" }
const _hoisted_11 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_health = _resolveComponent("vc-health")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_input = _resolveComponent("vc-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", {
      class: "card-header ribbon mb-5 mt-5",
      id: "middle"
    }, [
      _createElementVNode("div", { class: "card-title mb-5" }, [
        _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Insured Type ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label required" }, " Insurance Class ", -1)),
            _createVNode(_component_el_form_item, { prop: "insured_type_id" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_health, {
                  modelValue: _ctx.health.insured_type_id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.insured_type_id) = $event)),
                  onHealth: _ctx.selectedInsurance,
                  ref: "insured_type_id",
                  disabled: _ctx.disabledInsuranceForValue(),
                  ql: _ctx.ql,
                  onChange: _ctx.checkQlMember
                }, null, 8, ["modelValue", "onHealth", "disabled", "ql", "onChange"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, " Expected Insurance Start Date ", -1)),
            _createVNode(_component_vc_date_picker, {
              prop: "expected_insurance_start_date",
              modelValue: _ctx.health.expected_insurance_start_date,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.health.expected_insurance_start_date) = $event)),
              min: `${_ctx.minStartDate}`,
              ref: "expected_insurance_start_date"
            }, null, 8, ["modelValue", "min"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_vc_input, {
              label: "Expected Premium",
              placeholder: "Expected Premium",
              ref: "company_name",
              size: "large",
              modelValue: _ctx.health.expected_premium,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.health.expected_premium) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_vc_input, {
              label: "Expected Plan",
              placeholder: "Expected Plan",
              ref: "company_name",
              size: "large",
              modelValue: _ctx.health.interested_policy_name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.health.interested_policy_name) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ])
  ]))
}