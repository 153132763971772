import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "nav nav-tabs nav-line-tabs mb-5 fs-5",
  id: "group-details-header"
}
const _hoisted_4 = ["id", "href", "onClick"]
const _hoisted_5 = {
  class: "tab-content",
  id: "group-details-content"
}
const _hoisted_6 = ["id", "aria-labelledby"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("div", { class: "card-title" }, " Group Details ")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy.main_invoice.categories, (category, i) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "nav-item",
                key: i
              }, [
                _createElementVNode("a", {
                  id: `group-details-${i}`,
                  class: _normalizeClass(["nav-link cursor-pointer", {'active': _ctx.activeTab == i}]),
                  "data-bs-toggle": "tab",
                  href: `#group-details-content-${i}`,
                  onClick: ($event: any) => (_ctx.updateTab(category.lead_employee_category_id, i))
                }, _toDisplayString(category.category_name) + " (" + _toDisplayString(category.visa_emirates_text) + ") ", 11, _hoisted_4)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy.main_invoice.categories, (category, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass(["tab-pane fade", {'show active': _ctx.activeTab == i}]),
                id: `group-details-content-${i}`,
                role: "tabpanel",
                "aria-labelledby": `group-details-content-${i}`
              }, [
                _createVNode(_component_el_table, {
                  data: [category],
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.edit_policy_group_columns, (group, x) => {
                      return (_openBlock(), _createBlock(_component_el_table_column, {
                        key: x,
                        width: group.width,
                        "header-align": "top"
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "d-block fs-6 fw-bolder",
                            innerHTML: group.label
                          }, null, 8, _hoisted_7)
                        ]),
                        default: _withCtx((scope) => [
                          (group.value == 'policy_number')
                            ? (_openBlock(), _createBlock(_component_vc_input, {
                                key: 0,
                                "is-show-label": false,
                                type: 'text',
                                id: `main_invoice.categories.${i}.policy_number`,
                                ref_for: true,
                                ref: `main_invoice.categories.${i}.policy_number`,
                                prop: `main_invoice.categories.${i}.policy_number`,
                                rules: { validator: _ctx.Validations.policy_number, trigger: ['blur', 'change'] },
                                placeholder: "Policy No",
                                size: "large",
                                style: {"width":"100%"},
                                modelValue: scope.row['policy_number'],
                                "onUpdate:modelValue": ($event: any) => ((scope.row['policy_number']) = $event),
                                maxlength: 100,
                                disabled: _ctx.isDisableAllFields
                              }, null, 8, ["id", "prop", "rules", "modelValue", "onUpdate:modelValue", "disabled"]))
                            : (group.value == 'policy_start_date')
                              ? (_openBlock(), _createBlock(_component_vc_date_picker, {
                                  key: 1,
                                  modelValue: scope.row['policy_start_date'],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row['policy_start_date']) = $event),
                                  id: `main_invoice.categories.${i}.policy_start_date`,
                                  ref_for: true,
                                  ref: `main_invoice.categories.${i}.policy_start_date`,
                                  prop: `main_invoice.categories.${i}.policy_start_date`,
                                  min: _ctx.policy.policy_start_date,
                                  max: _ctx.common.subDays(_ctx.policy.policy_end_date,1),
                                  rules: _ctx.policy.main_invoice.id == scope.row.group_invoice_id ? '' : { validator: _ctx.Validations.edit_policy_start_date, trigger: ['blur', 'change'] },
                                  disabled: (_ctx.policy.main_invoice.id == scope.row.group_invoice_id && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "id", "prop", "min", "max", "rules", "disabled"]))
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 2,
                                  innerHTML: scope.row[group.value]
                                }, null, 8, _hoisted_8))
                        ]),
                        _: 2
                      }, 1032, ["width"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["data"])
              ], 10, _hoisted_6))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}