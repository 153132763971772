import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  class: /*@__PURE__*/_normalizeClass(`col-sm-6`)
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-items-center policy-details-actions justify-content-end"
}
const _hoisted_6 = { class: "form-group form-check" }
const _hoisted_7 = { class: "form-group form-check" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_save = _resolveComponent("vc-button-save")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("div", {
              class: _normalizeClass(`col-sm-6`)
            }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.policy.status == 4)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "form-check-input",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sendEmailTo.email_customer) = $event)),
                        id: "customer"
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.sendEmailTo.email_customer]
                      ]),
                      _cache[3] || (_cache[3] = _createElementVNode("label", { for: "customer" }, " Send Email To Customer", -1))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "form-check-input",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sendEmailTo.email_broker) = $event)),
                        id: "almanara"
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.sendEmailTo.email_broker]
                      ]),
                      _cache[4] || (_cache[4] = _createElementVNode("label", { for: "almanara" }, "Send Email To Broker", -1))
                    ]),
                    _createVNode(_component_vc_button_save, {
                      type: "button",
                      loading: _ctx.emailLoading,
                      class: _normalizeClass('btn-danger'),
                      text: `Send Email`,
                      icon: 'far fa-paper-plane',
                      disabled: _ctx.emailLoading || (!_ctx.sendEmailTo.email_customer && !_ctx.sendEmailTo.email_broker) ,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendEmail()))
                    }, null, 8, ["loading", "disabled"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}