import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "fv-row py-2 px-lg-5" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "mb-5" }
const _hoisted_6 = { class: "mb-5" }
const _hoisted_7 = { class: "dialog-footer" }
const _hoisted_8 = { class: "d-flex justify-content-center" }
const _hoisted_9 = ["data-kt-indicator", "disabled"]
const _hoisted_10 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_11 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_phone = _resolveComponent("vc-phone")!
  const _component_vc_agents = _resolveComponent("vc-agents")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_checkNumberExist = _resolveComponent("checkNumberExist")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      ref: "formQuickLead",
      model: _ctx.constant.quicklead,
      "status-icon": "",
      rules: _ctx.rule.quicklead,
      onSubmit: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.customer.quicklead(_ctx.formQuickLead)), ["prevent"])),
      "scroll-to-error": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_dialog, {
          modelValue: _ctx.modal.quicklead,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modal.quicklead) = $event)),
          width: "650px",
          onOpenAutoFocus: _ctx.getSetup,
          id: "Status"
        }, {
          header: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("h2", { class: "fw-bolder" }, " Add a Quick Lead ", -1)
          ])),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_vc_input, {
                  label: "Name",
                  placeholder: "Full Name",
                  prop: "name",
                  size: "large",
                  keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)",
                  modelValue: _ctx.constant.quicklead.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.constant.quicklead.name) = $event)),
                  isRequired: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_vc_input, {
                  isInput: "email",
                  label: "Email",
                  placeholder: "Email",
                  prop: "email",
                  size: "large",
                  tooltip: "Email address must be active",
                  keydown: "return /[-a-z._@0-9]/i.test(event.key)",
                  modelValue: _ctx.constant.quicklead.email,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.constant.quicklead.email) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_vc_phone, {
                  label: "Phone Number",
                  placeholder: "Enter Phone Number",
                  prop: "phone_number",
                  ref: "phone_number",
                  isInput: "text",
                  maxlength: "10",
                  class: "phone country-code-dropdown",
                  keydown: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')",
                  modelValue: _ctx.constant.quicklead.phone_number,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.constant.quicklead.phone_number) = $event)),
                  code: _ctx.constant.quicklead.phone_country_code,
                  "onUpdate:code": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.constant.quicklead.phone_country_code) = $event)),
                  disabled: _ctx.isDisbaled,
                  isRequired: ""
                }, null, 8, ["modelValue", "code", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_vc_input, {
                  isInput: "select",
                  label: "Lead Source",
                  prop: "lead_source",
                  size: "large",
                  options: _ctx.leadSources,
                  keydown: "return /[-a-z 0-9]/i.test(event.key)",
                  modelValue: _ctx.constant.quicklead.lead_source,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.constant.quicklead.lead_source) = $event))
                }, null, 8, ["options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, " Sales Agent ", -1)),
                _createVNode(_component_el_form_item, { prop: "agent" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_agents, {
                      modelValue: _ctx.constant.quicklead.salesAgents,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.constant.quicklead.salesAgents) = $event)),
                      placeholder: "Please Select",
                      label: "Select Sales Agent",
                      options: _ctx.agents
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _: 1
                })
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-warning" }, " It will be assigned by RoundRobin if not selected ", -1))
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_add_customer_cancel",
                  class: "btn btn-light me-3",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.customer.closeQuicklead(false, _ctx.formQuickLead)))
                }, " Cancel "),
                _createElementVNode("button", {
                  "data-kt-indicator": _ctx.loading.quicklead ? 'on' : null,
                  disabled: _ctx.disable.quicklead,
                  class: "btn btn-info",
                  type: "submit",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
                }, [
                  (!_ctx.loading.quicklead)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, "   Save   "))
                    : _createCommentVNode("", true),
                  (_ctx.loading.quicklead)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[13] || (_cache[13] = [
                        _createTextVNode(" Please wait... "),
                        _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_9)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "onOpenAutoFocus"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_checkNumberExist, {
      isOpen: _ctx.isOpen,
      onRedirectToCustomerDetail: _ctx.toCustomerDetail,
      onClearRedirect: _ctx.clearIt
    }, null, 8, ["isOpen", "onRedirectToCustomerDetail", "onClearRedirect"])
  ], 64))
}