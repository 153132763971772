import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card-body m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_vc_roles = _resolveComponent("vc-roles")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_underwriters = _resolveComponent("vc-underwriters")!
  const _component_vc_team_leads = _resolveComponent("vc-team-leads")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!
  const _component_vc_agent_type = _resolveComponent("vc-agent-type")!
  const _component_vc_renewal_deal = _resolveComponent("vc-renewal-deal")!
  const _component_vc_assign_lead = _resolveComponent("vc-assign-lead")!
  const _component_vc_assign_language = _resolveComponent("vc-assign-language")!
  const _component_vc_assign_customer_status = _resolveComponent("vc-assign-customer-status")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_vc_transfer_agent = _resolveComponent("vc-transfer-agent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { gutter: 10 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_vc_input, {
              label: "Name",
              placeholder: "Enter Name",
              prop: "name",
              ref: "name",
              size: "large",
              modelValue: _ctx.user.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event)),
              focus: true,
              "is-required": "",
              keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_vc_input, {
              label: "Email",
              placeholder: "Enter Email Address",
              prop: "email",
              ref: "email",
              size: "large",
              modelValue: _ctx.user.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event)),
              "is-required": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_vc_input, {
              label: "Office Mobile Number",
              placeholder: "05xxxxxx",
              prop: "office_mobile_number",
              ref: "office_mobile_number",
              size: "large",
              modelValue: _ctx.user.office_mobile_number,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.office_mobile_number) = $event)),
              maxlength: "10",
              keydown: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_vc_input, {
              label: "Other Mobile Number",
              placeholder: "05xxxxxx",
              prop: "other_mobile_number",
              ref: "other_mobile_number",
              size: "large",
              maxlength: "10",
              modelValue: _ctx.user.other_mobile_number,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.other_mobile_number) = $event)),
              keydown: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_vc_input, {
              "is-input": "password",
              label: "Password",
              placeholder: "",
              prop: "password",
              ref: "password",
              size: "large",
              modelValue: _ctx.user.password,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.password) = $event)),
              "is-required": "",
              "is-append": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_vc_input, {
              "is-input": "password",
              label: "Confirm Password",
              placeholder: "",
              prop: "password_confirmation",
              ref: "password_confirmation",
              size: "large",
              modelValue: _ctx.user.password_confirmation,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.password_confirmation) = $event)),
              "is-required": "",
              "is-append": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _cache[18] || (_cache[18] = _createElementVNode("label", { class: "required" }, " Role ", -1)),
            _createVNode(_component_el_form_item, { prop: "role_id" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_roles, {
                  modelValue: _ctx.user.roles,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.roles) = $event)),
                  onRoles: _ctx.resetByRole,
                  "is-multiple": "",
                  ref: "role_id"
                }, null, 8, ["modelValue", "onRoles"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.showByRole)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _cache[19] || (_cache[19] = _createElementVNode("label", { class: "required" }, " Assign Underwriter ", -1)),
                  _createVNode(_component_el_form_item, { prop: "policy_agent_id" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_vc_underwriters, {
                        modelValue: _ctx.user.policy_agent_id,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.policy_agent_id) = $event)),
                        ref: "policy_agent_id"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.user.role_id === 2)
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    span: 12
                  }, {
                    default: _withCtx(() => [
                      _cache[20] || (_cache[20] = _createElementVNode("label", { class: "required" }, " Assign Team Leader ", -1)),
                      _createVNode(_component_el_form_item, { prop: "team_leader" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_vc_team_leads, {
                            modelValue: _ctx.user.team_leader,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.team_leader) = $event)),
                            ref: "team_leader"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 10 }, {
      default: _withCtx(() => [
        (_ctx.showByRole || _ctx.user.role_id === 4)
          ? (_openBlock(), _createBlock(_component_el_col, {
              key: 0,
              span: 12
            }, {
              default: _withCtx(() => [
                _cache[21] || (_cache[21] = _createElementVNode("label", { class: "required" }, " In Round Robin ", -1)),
                _createVNode(_component_el_form_item, { prop: "is_round_robin" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_yes_no, {
                      modelValue: _ctx.user.is_round_robin,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.is_round_robin) = $event)),
                      ref: "is_round_robin"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showByRole)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  (_ctx.user.is_round_robin)
                    ? (_openBlock(), _createBlock(_component_vc_input, {
                        key: 0,
                        "is-type": "number",
                        label: "Skip Round",
                        placeholder: "",
                        prop: "skip_round",
                        ref: "skip_round",
                        size: "large",
                        modelValue: _ctx.user.skip_round,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.skip_round) = $event)),
                        "is-required": ""
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  (_ctx.user.is_round_robin)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "required" }, " Agent Type ", -1)),
                        _createVNode(_component_el_form_item, { prop: "agent_type" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_vc_agent_type, {
                              modelValue: _ctx.user.agent_type,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.agent_type) = $event)),
                              onAgent: _ctx.selectedAgent,
                              ref: "agent_type"
                            }, null, 8, ["modelValue", "onAgent"])
                          ]),
                          _: 1
                        })
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  (_ctx.user.is_round_robin)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "required" }, " Renewal Deals ", -1)),
                        _createVNode(_component_el_form_item, { prop: "renewal_deal" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_vc_renewal_deal, {
                              modelValue: _ctx.user.renewal_deal,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.renewal_deal) = $event)),
                              onAgent: _ctx.selectedDeals,
                              disabled: _ctx.user.agent_type === 1,
                              ref: "renewal_deal"
                            }, null, 8, ["modelValue", "onAgent", "disabled"])
                          ]),
                          _: 1
                        })
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 10 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            (_ctx.user.is_round_robin || _ctx.user.role_id === 4)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[24] || (_cache[24] = _createElementVNode("label", { class: "required" }, " Assign Leads Of ", -1)),
                  _createVNode(_component_el_form_item, { prop: "assign" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_vc_assign_lead, {
                        modelValue: _ctx.user.assign,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.user.assign) = $event)),
                        onLead: _ctx.selectedAssign,
                        ref: "assign"
                      }, null, 8, ["modelValue", "onLead"])
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            (_ctx.user.is_round_robin)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[25] || (_cache[25] = _createElementVNode("label", { class: "required" }, " Language ", -1)),
                  _createVNode(_component_el_form_item, { prop: "assign_lang" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_vc_assign_language, {
                        modelValue: _ctx.user.assign_lang,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.user.assign_lang) = $event)),
                        ref: "assign_lang"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.user.id > 0)
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          gutter: 10
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _cache[26] || (_cache[26] = _createElementVNode("label", { class: "required" }, " Status ", -1)),
                _createVNode(_component_el_form_item, { prop: "status" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_assign_customer_status, {
                      modelValue: _ctx.user.status,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.user.status) = $event)),
                      ref: "status"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.user.status === 0)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    (_ctx.user.is_edit)
                      ? (_openBlock(), _createBlock(_component_el_checkbox, {
                          key: 0,
                          modelValue: _ctx.user.is_archived,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.user.is_archived) = $event)),
                          label: 1,
                          size: "large"
                        }, {
                          default: _withCtx(() => _cache[27] || (_cache[27] = [
                            _createTextVNode(" Archived ")
                          ])),
                          _: 1
                        }, 8, ["modelValue"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _cache[28] || (_cache[28] = _createElementVNode("label", { class: "required" }, " Select Transfer Agent/s ", -1)),
                          _createVNode(_component_el_form_item, { prop: "agents" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_vc_transfer_agent, {
                                modelValue: _ctx.user.agents,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.user.agents) = $event)),
                                ref: "agents"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ], 64))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}