import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "m-4" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-6 mb-5" }
const _hoisted_6 = { class: "col-sm-6 mb-5" }
const _hoisted_7 = { class: "col-sm-6 mb-5" }
const _hoisted_8 = { class: "col-sm-6 mb-5" }
const _hoisted_9 = { ref: "uploadInvoice" }
const _hoisted_10 = { class: "container py-5" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 text-center" }
const _hoisted_13 = ["disabled", "data-kt-indicator"]
const _hoisted_14 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_15 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_date_picker_v2 = _resolveComponent("vc-date-picker-v2")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dropdown, {
      id: 'invoiceActionDrpDown'+_ctx.invoice.id,
      "destroy-on-close": true
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            (_ctx.showAction && _ctx.invoice.invoice_doc)
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "btn-sm dropdown-item",
                      href: _ctx.invoice.invoice_doc.file_path,
                      target: "_blank"
                    }, _cache[10] || (_cache[10] = [
                      _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                      _createTextVNode(" View Receipt")
                    ]), 8, _hoisted_1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showAction && _ctx.invoice.cheque_doc)
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "btn-sm dropdown-item",
                      href: _ctx.invoice.cheque_doc.file_path,
                      target: "_blank"
                    }, _cache[11] || (_cache[11] = [
                      _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                      _createTextVNode(" View cheque doc")
                    ]), 8, _hoisted_2)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showAction && (_ctx.permissions.is_admin || _ctx.permissions.is_accountant))
              ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 2 }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      class: "btn-sm dropdown-item",
                      href: "javascript:void(0)",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uploadReceipt(_ctx.invoice.id)))
                    }, _cache[12] || (_cache[12] = [
                      _createElementVNode("i", { class: "icon-svg icon-upload-receipt" }, null, -1),
                      _createTextVNode(" Upload Receipt")
                    ]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  class: "btn-sm dropdown-item",
                  href: "javascript:void(0)",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.invoiceDetails()))
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                  _createTextVNode(" Invoice Details")
                ]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          size: "small",
          class: "el-dropdown-custom"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" ... ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["id"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.isUploadReceipt,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isUploadReceipt) = $event)),
      title: "Upload Receipt",
      width: "40%",
      onClose: _ctx.closeModal,
      "close-on-click-modal": false,
      "append-to-body": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            ref: "ruleFormNameRef",
            model: _ctx.receipt,
            rules: _ctx.rules,
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_form_item, { prop: "order_description" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", {
                        for: "receipt-order_description",
                        class: _normalizeClass({'required': _ctx.order_description_required})
                      }, " Order description ", 2),
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.receipt.order_description,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.receipt.order_description) = $event)),
                        placeholder: "Order Description",
                        ref: "order_description",
                        id: "receipt-order_description",
                        maxlength: "100"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_form_item, { prop: "reference_no" }, {
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createElementVNode("label", {
                        for: "receipt-reference_no",
                        class: "required"
                      }, " Reference No. ", -1)),
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.receipt.reference_no,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.receipt.reference_no) = $event)),
                        placeholder: "Reference No.",
                        ref: "reference_no",
                        id: "receipt-reference_no",
                        maxlength: "50",
                        onInput: _ctx.resetError
                      }, null, 8, ["modelValue", "onInput"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_el_form_item, { prop: "payment_date" }, {
                    default: _withCtx(() => [
                      _cache[15] || (_cache[15] = _createElementVNode("label", {
                        for: "receipt-payment_date",
                        class: "required"
                      }, " Payment Date ", -1)),
                      _createVNode(_component_vc_date_picker_v2, {
                        modelValue: _ctx.receipt.payment_date,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.receipt.payment_date) = $event)),
                        ref: "payment_date",
                        id: "receipt-payment_date",
                        max: _ctx.moment().format('YYYY-MM-DD')
                      }, null, 8, ["modelValue", "max"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_el_form_item, { prop: "invoice_doc" }, {
                    default: _withCtx(() => [
                      _cache[16] || (_cache[16] = _createElementVNode("label", {
                        for: "receipt-invoice-doc",
                        class: "required"
                      }, " File/Receipt ", -1)),
                      _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("form", _hoisted_9, [
                        _createElementVNode("input", {
                          type: "file",
                          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleFileUpload($event))),
                          ref: "uploadInvoiceDocumentModalRef",
                          accept: ".png, .jpg, .jpeg, .pdf"
                        }, null, 544)
                      ], 512),
                      _createVNode(_component_el_input, {
                        type: "hidden",
                        modelValue: _ctx.receipt.invoice_doc,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.receipt.invoice_doc) = $event)),
                        ref: "invoice_doc",
                        style: {"display":"none"}
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("button", {
                disabled: _ctx.disableSaveButton,
                "data-kt-indicator": _ctx.disableSaveButton ? 'on' : null,
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.uploadInvoiceDocument(_ctx.ruleFormNameRef))),
                class: "btn btn-lg btn-info",
                type: "submit"
              }, [
                (!_ctx.disableSaveButton)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, " Upload "))
                  : _createCommentVNode("", true),
                (_ctx.disableSaveButton)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _cache[18] || (_cache[18] = [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_13)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}