
import Customers from '@/core/services/Callable/Customers'
import router from '@/router/clean'
import { store } from '@/store'
import { defineComponent, ref, computed, onMounted, getCurrentInstance, watchEffect } from 'vue'
import { common, constant } from '@/store/stateless'
import Policy from '@/core/services/Callable/Policy'
import { PolicyMutationTypes } from '@/store/enums/Policy/Mutations';

export default defineComponent({
    setup() {
        const open = ref<Boolean>(false)
        const loading = ref<Boolean>(false)
        const compose_emails = ref()
        const customer_id: any = router.currentRoute.value.params.customer_id?.toString()

        const { view_policies } = constant

        const leads = computed(() => {
            const data = store.getters.getLeads(true)
            if (view_policies.lead_id > 0)
                return data.filter(x => x.lead_id === view_policies.lead_id)

            return data
        })

        const permissions = computed(() => {
            return store.getters.getUserPermissions
        })
        
        const logs = computed(() => {
            return store.getters.getPolicyActivityLog
        })
        const lists = computed(() => {
            return store.state.policy.policyLists
        })


        const activeTab = ref<Number>(0)
        const activeSecondTab = ref<Number>(1)
        const selectedPolicy = ref(0)


        const modal = async (value: Boolean) => {
            console.log('call sd')
            open.value = value
            if (value) {
                let c_lead_id = (leads.value.find(x => !x.tab_disabled)).lead_id
                if (view_policies.lead_id > 0)
                    c_lead_id = view_policies.lead_id

                if (activeTab.value > 0)
                    c_lead_id = activeTab.value

                activeValue.value = c_lead_id
                changeValue()

                // await Policy.policyLists({ customer_id })
            }
        }

        const lead_id = ref(0)

        const activeValue = ref(0);

        const changeValue = () => {
            fetchData(activeValue.value);
        };

        const fetchData = async (value: number) => {
            activeTab.value = value
            lead_id.value = value
            // activeSecondTab.value = 1
            selectedPolicy.value = 0
            // getPolicyLog()
            await getPolicyList()
            // await Customers.getAllTaskByLeadAndCustomerId({
            //     customer_id: view_task.customer_id,
            //     lead_id: lead_id.value
            // })
        }

        const getPolicyList = async () => {
            console.log('lead_id', lead_id.value)
            console.log('clickLead', clickLead.value)
            loading.value = true
            const data ={
                    customer_id: customer_id,
                    lead_id: lead_id.value
                }
            // if(clickLead.value && clickLead.value.insured_type_id == 4) {
            //     await Policy.groupLeadPolicyDetails(data)
            // } else {
            //     await Policy.policyLists(data)
            // }
            await Policy.policyLists(data)
            
            loading.value = false

            // console.log('lists',lists.value)
        }

        const inst = getCurrentInstance()
        watchEffect(() => {
            // console.log('view_policies',view_policies)
            if (view_policies.open) {
                let c_lead_id = (leads.value.find(x => !x.tab_disabled)).lead_id
                if (view_policies.lead_id > 0)
                    c_lead_id = view_policies.lead_id

                if (activeTab.value > 0)
                    c_lead_id = activeTab.value

                activeValue.value = c_lead_id
                setTimeout(() => {
                // changeValue()

                    // const listRef: any = inst?.refs[`lead_${c_lead_id}`]
                    // if (activeTab.value === 0) {
                    //     // listRef.click()
                    // }
                }, 500);
            }
        })


        const close = () => {
            view_policies.open = false
            view_policies.lead_id = 0
            view_policies.is_fullscreen = false

            activeTab.value = 0
            modal(false)
            // Customers.clearTaskDetails()
        }

        const getPolicyLog = () => {
            loading.value = true
            if (selectedPolicy.value > 0) {
                
                Policy.getPoliciesLog({
                    policy_purchase_id: selectedPolicy.value,
                    is_group:  0
                });

            } else {
                store.commit(PolicyMutationTypes.SET_POLICY_LOG, []);
            }
            loading.value = false

        };

        const clickLead = computed(() => {
            return leads.value.find(x => x.lead_id == activeTab.value)
        })

        return {
            clickLead,
            compose_emails,
            customer_id,
            open,
            modal,
            leads,
            activeTab,
            fetchData,
            common,
            activeSecondTab,
            getPolicyList,
            close,
            view_policies,
            lists,
            selectedPolicy,
            getPolicyLog,
            logs,
            activeValue,
            changeValue,
            loading,
            permissions
        }
    },
})
