import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5",
  id: "last"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-3" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "col-sm-3" }
const _hoisted_11 = { class: "mb-10" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm-3" }
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = { class: "col-sm-3" }
const _hoisted_16 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_vc_payment_type = _resolveComponent("vc-payment-type")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_ctx.invoiceData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "card-header ribbon mb-5 mt-5" }, [
          _createElementVNode("div", { class: "card-title mb-5" }, [
            _createElementVNode("h6", { class: "card-subtitle text-muted" }, "Invoice Information")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_vc_input, {
                  label: "Quote Ref. No.",
                  modelValue: _ctx.invoiceData.merchant_reference,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.invoiceData.merchant_reference) = $event)),
                  placeholder: "Quote Ref. No.",
                  size: "large",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_vc_amount_v2, {
                  label: "Policy Price",
                  subLabel: '(incl. VAT) ('+_ctx.currency+')',
                  modelValue: _ctx.invoiceData.policy_price,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.invoiceData.policy_price) = $event)),
                  placeholder: "Policy Price",
                  ref: "policy_price",
                  size: "large",
                  isRequired: "",
                  disabled: ""
                }, null, 8, ["subLabel", "modelValue"])
              ])
            ]),
            (_ctx.invoiceData.basmah > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_vc_amount_v2, {
                      label: "Basmah Amount",
                      subLabel: '(incl. VAT)('+_ctx.currency+')',
                      modelValue: _ctx.invoiceData.basmah,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.invoiceData.basmah) = $event)),
                      placeholder: "Basmah Amount",
                      ref: "basmah_amount",
                      size: "large",
                      disabled: ""
                    }, null, 8, ["subLabel", "modelValue"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_vc_amount_v2, {
                  label: "Total Payable / Invoice Amount",
                  subLabel: '('+_ctx.currency+')',
                  modelValue: _ctx.invoiceData.invoice_amount,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.invoiceData.invoice_amount) = $event)),
                  placeholder: "Total Payable / Invoice Amount",
                  ref: "invoice_amount",
                  size: "large",
                  isRequired: "",
                  disabled: ""
                }, null, 8, ["subLabel", "modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label required" }, "Payment Type", -1)),
                _createVNode(_component_el_form_item, { prop: "payment_type" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_payment_type, {
                      modelValue: _ctx.invoice.payment_type,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoice.payment_type) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_vc_amount_v2, {
                  label: "Refund Amount",
                  subLabel: '(incl. VAT)('+_ctx.currency+')',
                  modelValue: _ctx.invoice.policy_price,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoice.policy_price) = $event)),
                  placeholder: "Refund Amount",
                  prop: "policy_price",
                  ref: "policy_price",
                  size: "large",
                  isRequired: "",
                  isInput: 'number',
                  disabled: ""
                }, null, 8, ["subLabel", "modelValue"])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}