
    import {
        defineComponent,
        computed,
    } from "vue";
    import { store } from "@/store";
    import { common } from "@/store/stateless";
    import { useRouter } from 'vue-router'

    export default defineComponent({
        name: "dashboard-overview",
        setup() {

            const data = computed(() => {
                return store.state.dashboard.dashboard_overview;
            })

            const router = useRouter()


            const getTextWidth = (text) => {
                const canvas = document.createElement("canvas");
                const context: any = canvas.getContext("2d");

                context.font = getComputedStyle(document.body).font;

                return context.measureText(text).width;
            };

            const getFontSize = (value) => {
                const width = getTextWidth(value);
                return width > 92 ? '22px' : width > 82 ? '24px' : width > 70 ? '26px' : width > 60 ? '28px' : '34px'
            }

            const toClick = (row) => {
                if(row.isClick) {
                    const link = router.resolve({ name: 'ReportDashboardDetails', query: { start_date: store.state.dashboard.filter.start_date, end_date: store.state.dashboard.filter.end_date, [row.key]: row.item  } })
                    window.open(link.href)
                }
            }



            return {
                toClick,
                getTextWidth,
                getFontSize,
                data,
                common
            };
        },
    });
