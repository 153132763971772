import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isModalOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalOpen) = $event)),
    title: "Customer Already Exist",
    width: "30%",
    center: "",
    "show-close": false,
    "close-on-click-modal": false,
    onClose: _ctx.clearIt
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: _ctx.clearIt }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("No")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.toCustomerDetail
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Proceed ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fv-row py-5 px-lg-8" }, [
        _createElementVNode("span", null, "This Customer already exists. Any updates need to be done via the Lead Details screen. Do you want to proceed to Lead Details?")
      ], -1))
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}