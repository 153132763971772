import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      type: "file",
      name: "document",
      id: "document",
      accept: "image/*, .pdf",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args))),
      style: {"display":"none"},
      ref: "fileUpload",
      disabled: _ctx.disableUpload
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: "amend-upload ms-4 cursor-pointer icon-bg-common",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openFile && _ctx.openFile(...args)))
    }, [
      _createVNode(_component_el_tooltip, {
        class: "box-item",
        content: 'upload',
        effect: "light",
        placement: "bottom",
        "show-after": 200,
        "hide-after": 0
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", {
            class: _normalizeClass('icon-svg icon-upload')
          }, null, -1)
        ])),
        _: 1
      })
    ])
  ], 64))
}