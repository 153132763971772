import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card card-bordered mb-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = { class: "col-sm-3" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "col-sm-3" }
const _hoisted_11 = { class: "mb-10" }
const _hoisted_12 = {
  key: 1,
  class: "col-sm-3"
}
const _hoisted_13 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_health = _resolveComponent("vc-health")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_emirates = _resolveComponent("vc-emirates")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_insurance_type = _resolveComponent("vc-insurance-type")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "card-header ribbon mb-5 mt-5" }, [
      _createElementVNode("div", { class: "card-title mb-5" }, [
        _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Insured Type ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label required" }, " Insurance For ", -1)),
                _createVNode(_component_el_form_item, { prop: "insured_type_id" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_health, {
                      modelValue: _ctx.health.insured_type_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.insured_type_id) = $event)),
                      type: 'employee',
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label required" }, " Visa Issued Emirate ", -1)),
            _createVNode(_component_el_form_item, { prop: "insured_city" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_emirates, {
                  modelValue: _ctx.health.insured_city,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.health.insured_city) = $event)),
                  ref: "insured_city"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label required" }, " Expected Insurance Start Date ", -1)),
            _createVNode(_component_vc_date_picker, {
              prop: "expected_insurance_start_date",
              modelValue: _ctx.health.expected_insurance_start_date,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.health.expected_insurance_start_date) = $event)),
              min: `${_ctx.minStartDate}`,
              ref: "expected_insurance_start_date"
            }, null, 8, ["modelValue", "min"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label required" }, " Insurance Type ", -1)),
            _createVNode(_component_el_form_item, { prop: "insurance_type" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_insurance_type, {
                  modelValue: _ctx.health.insurance_type,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.health.insurance_type) = $event)),
                  min: `${_ctx.minStartDate}`,
                  ref: "insurance_type",
                  disabled: true
                }, null, 8, ["modelValue", "min"])
              ]),
              _: 1
            })
          ])
        ]),
        (_ctx.health.insured_type_id === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label required" }, " Do you need insurance for yourself? ", -1)),
                _createVNode(_component_el_form_item, {
                  prop: "is_self",
                  class: "el-form-item-custom-radio"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_yes_no, {
                      modelValue: _ctx.health.is_self,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.health.is_self) = $event)),
                      onYesNo: _ctx.addMeToSponsor,
                      ref: "is_self",
                      disabled: _ctx.disabledInsuranceForself
                    }, null, 8, ["modelValue", "onYesNo", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}