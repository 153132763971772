import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-header ribbon mb-5 mt-5"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-3" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = { class: "col-sm-3" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "col-sm-3" }
const _hoisted_11 = { class: "mb-10" }
const _hoisted_12 = { class: "col-sm-3" }
const _hoisted_13 = { class: "mb-10" }
const _hoisted_14 = { class: "col-sm-3" }
const _hoisted_15 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_phone = _resolveComponent("vc-phone")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_group_size = _resolveComponent("vc-group-size")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card mb-5", `${_ctx.isEdit ? '' : 'card-bordered'}`])
  }, [
    (_ctx.isShowHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[8] || (_cache[8] = [
          _createElementVNode("div", { class: "card-title mb-5" }, [
            _createElementVNode("h6", { class: "card-subtitle text-muted" }, "Customer Details ")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_vc_phone, {
              label: "Contact Phone Number",
              placeholder: "Enter Contact Phone Number",
              prop: "phone_number",
              ref: "phone_number",
              isInput: "text",
              maxlength: "10",
              class: "phone country-code-dropdown",
              keydown: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')",
              modelValue: _ctx.health.phone_number,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.phone_number) = $event)),
              code: _ctx.health.phone_country_code,
              "onUpdate:code": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.health.phone_country_code) = $event)),
              isRequired: "",
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('checkPhoneExist'))),
              disabled: _ctx.isDisbaled || _ctx.isDisabledMain
            }, null, 8, ["modelValue", "code", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_vc_input, {
              label: "Company Name",
              placeholder: "Enter Company Name",
              prop: "name",
              ref: "name",
              size: "large",
              modelValue: _ctx.health.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.health.name) = $event)),
              disabled: _ctx.isDisbaled || _ctx.isDisabledMain,
              isRequired: "",
              keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)"
            }, null, 8, ["modelValue", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_vc_input, {
              label: "Company Email",
              isInput: "email",
              placeholder: "Enter Company Email",
              prop: "email",
              ref: "email",
              size: "large",
              onInput: _ctx.emailEvent,
              modelValue: _ctx.health.email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.health.email) = $event)),
              isPrefix: "",
              isRequired: "",
              disabled: _ctx.health.dummy_email && (_ctx.isDisbaled || _ctx.isDisabledMain),
              keydown: "return /[-a-zA-Z0-9_@.]/i.test(event.key)"
            }, null, 8, ["onInput", "modelValue", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_vc_input, {
              label: "Contact Person",
              placeholder: "Enter Contact Person",
              prop: "contact_person",
              ref: "contact_person",
              size: "large",
              modelValue: _ctx.health.contact_person,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.health.contact_person) = $event)),
              disabled: _ctx.isDisbaled,
              isRequired: "",
              keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)"
            }, null, 8, ["modelValue", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label required" }, " Group size ", -1)),
            _createVNode(_component_el_form_item, { prop: "group_size" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_group_size, {
                  modelValue: _ctx.health.group_size,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.health.group_size) = $event)),
                  ref: "group_size",
                  disabled: _ctx.isDisbaled
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label required" }, " Expected Insurance Start Date ", -1)),
            _createVNode(_component_vc_date_picker, {
              prop: "expected_insurance_start_date",
              modelValue: _ctx.health.expected_insurance_start_date,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.health.expected_insurance_start_date) = $event)),
              min: `${_ctx.minStartDate}`,
              ref: "expected_insurance_start_date",
              disabled: _ctx.isExpectedStartDateDisabled
            }, null, 8, ["modelValue", "min", "disabled"])
          ])
        ])
      ])
    ])
  ], 2))
}