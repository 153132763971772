import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-bordered mb-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-3" }
const _hoisted_5 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_agents = _resolveComponent("vc-agents")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", {
      class: "card-header ribbon mb-5 mt-5",
      id: "last"
    }, [
      _createElementVNode("div", { class: "card-title mb-5" }, [
        _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Sales Agent Details ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "form-label" }, " Sales Agent ", -1)),
            _createVNode(_component_vc_agents, {
              modelValue: _ctx.health.agent,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.agent) = $event)),
              placeholder: "Select agent",
              options: _ctx.agents,
              ref: "agent"
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ])
    ])
  ]))
}