import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-3" }
const _hoisted_3 = { class: "col-sm-9" }
const _hoisted_4 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-4 ms-4 fs-5 w-100" }
const _hoisted_5 = { class: "nav-item cursor-pointer policy" }
const _hoisted_6 = {
  key: 0,
  class: "nav-item cursor-pointer policy"
}
const _hoisted_7 = {
  key: 0,
  class: "m-4"
}
const _hoisted_8 = {
  key: 0,
  class: "align-self-center text-gray-700",
  style: {"text-align":"right"}
}
const _hoisted_9 = {
  key: 1,
  class: "m-10"
}
const _hoisted_10 = { class: "d-flex mt-2" }
const _hoisted_11 = { class: "col-sm-6" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 0,
  class: "col-sm-6 align-self-center text-gray-700",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_vc_document_table_data = _resolveComponent("vc-document-table-data")!
  const _component_vc_document_upload = _resolveComponent("vc-document-upload")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[7] || (_cache[7] = ($event: any) => (_ctx.modal(false))),
    fullscreen: "",
    class: "view-documents-dialog"
  }, {
    header: _withCtx(() => _cache[8] || (_cache[8] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Lead Document Details ")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_select, {
            disabled: _ctx.upload_documents.disabled,
            modelValue: _ctx.activeValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeValue) = $event)),
            onChange: _ctx.changeValue,
            size: "large",
            style: {"width":"100%","margin-left":"20px","margin-top":"5px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: i,
                  label: lead.label,
                  value: lead.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled", "modelValue", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link trunc text-truncate", {'active': _ctx.activeTab === 1}]),
                id: `document_1`,
                ref: `document_1`,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchTab(1)))
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("span", null, " View Documents ", -1)
              ]), 2)
            ]),
            (_ctx.showUpload)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link trunc text-truncate", {'active': _ctx.activeTab === 2}]),
                    id: `document_2`,
                    ref: `document_2`,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchTab(2)))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("span", null, " Upload Documents ", -1)
                  ]), 2)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.activeTab === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.documents.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("a", {
                    href: "javascript:void(0);",
                    class: "btn btn-download-all mt-5 mb-5",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadZip()))
                  }, _cache[11] || (_cache[11] = [
                    _createElementVNode("i", {
                      class: "icon-svg icon-download",
                      style: {"transform":"translateY(-15%)","position":"relative","-webkit-transform":"translateY(-15%)"}
                    }, null, -1),
                    _createTextVNode(" All ")
                  ]))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_vc_document_table_data, {
              documents: _ctx.documents,
              showLog: ""
            }, null, 8, ["documents"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeTab === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_vc_document_upload, {
              lead_id: _ctx.activeValue,
              lead_employee_category_id: _ctx.leadEmployeeCategoryId,
              policy_id: _ctx.upload_documents.policy_id
            }, null, 8, ["lead_id", "lead_employee_category_id", "policy_id"]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_ctx.upload_documents.group_name)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.upload_documents.group_name), 1))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.documents.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("a", {
                      href: "javascript:void(0);",
                      class: "btn btn-download-all",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.downloadZip()))
                    }, _cache[12] || (_cache[12] = [
                      _createElementVNode("i", {
                        class: "icon-svg icon-download",
                        style: {"transform":"translateY(-15%)","position":"relative","-webkit-transform":"translateY(-15%)"}
                      }, null, -1),
                      _createTextVNode(" All ")
                    ]))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_vc_document_table_data, {
              documents: _ctx.documents,
              "is-edit": "",
              lead_id: _ctx.activeValue,
              lead_employee_category_id: _ctx.leadEmployeeCategoryId,
              onRefreshData: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchDocument(_ctx.activeValue))),
              ref: "employeeTable",
              showDelete: "",
              hideDownload: "",
              verificationTypes: ""
            }, null, 8, ["documents", "lead_id", "lead_employee_category_id"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}