import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card card-bordered mb-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_7 = {
  key: 0,
  class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x mt-n5 fs-5 w-100"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_11 = ["id"]
const _hoisted_12 = { class: "row mt-3" }
const _hoisted_13 = { class: "col-sm-4" }
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = { class: "col-sm-4" }
const _hoisted_16 = { class: "mb-10" }
const _hoisted_17 = {
  key: 0,
  class: "col-sm-4 d-flex pt-10"
}
const _hoisted_18 = {
  key: 2,
  class: "py-5"
}
const _hoisted_19 = { class: "w-25" }
const _hoisted_20 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "card-header ribbon mb-5" }, [
      _createElementVNode("div", { class: "card-title mb-5 mt-5" }, [
        _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Policy Details")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label required" }, " Insurance Plans ", -1)),
            _createVNode(_component_el_form_item, { prop: "providers" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.providers,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.providers) = $event)),
                  filterable: "",
                  multiple: "",
                  "multiple-limit": 6,
                  placeholder: "Select",
                  size: "large",
                  "collapse-tags": "",
                  "tag-type": 'success',
                  "max-collapse-tags": 3,
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectProvider()))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quotes, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.id,
                        label: item.name,
                        value: item.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_ctx.providers.length == 0 && _ctx.showInsurancePlanError)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Please select insurance plans"))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        (_ctx.providers.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.insuranceProviders, (policy, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "nav-item mb-3"
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["m-0 me-5", index == 0 ? `nav-link active` : `nav-link`]),
                    "data-bs-toggle": "tab",
                    href: `#kt_tab_pane_${index}`
                  }, [
                    _createVNode(_component_el_tooltip, {
                      content: policy.name,
                      placement: "bottom",
                      effect: "light"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          class: _normalizeClass({'wrap-content d-block': _ctx.common.getTextWidth(policy.name) > 120 ? true : false }),
                          style: {"width":"240px"},
                          innerHTML: policy.name
                        }, null, 10, _hoisted_9)
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ], 10, _hoisted_8)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.providers.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policyData, (data, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  role: "tabpanel",
                  key: index,
                  id: `kt_tab_pane_${index}`,
                  class: _normalizeClass(index == 0 ? `tab-pane  active` : `tab-pane fade`)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.extras, (extra, key) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_vc_input, {
                            label: "Key",
                            placeholder: "Key",
                            prop: "key",
                            ref_for: true,
                            ref: 'key_' + key,
                            size: "large",
                            modelValue: _ctx.benefits[key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.benefits[key]) = $event),
                            focus: true,
                            disabled: index != 0 || key == 0,
                            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeExtraBenefit()))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_vc_input, {
                            isInput: key == 0 ? 'number' : 'text',
                            label: "value",
                            placeholder: "value",
                            prop: "value",
                            ref_for: true,
                            ref: 'value_' + key,
                            size: "large",
                            modelValue: extra.value,
                            "onUpdate:modelValue": ($event: any) => ((extra.value) = $event),
                            focus: true
                          }, null, 8, ["isInput", "modelValue", "onUpdate:modelValue"])
                        ])
                      ]),
                      (index == 0 && key == data.extras.length - 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createVNode(_component_vc_button, {
                              text: "Add",
                              className: "p-1 btn-info cursor-pointer px-4 border-0 me-3",
                              onVcClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addBenefit()))
                            }),
                            (key != 0 )
                              ? (_openBlock(), _createBlock(_component_vc_button, {
                                  key: 0,
                                  color: "danger",
                                  text: "Remove",
                                  className: "p-1 cursor-pointer px-4 border-0 me-3",
                                  onClick: _withModifiers(($event: any) => (_ctx.removeBenefit(key)), ["prevent"])
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 256))
                ], 10, _hoisted_11))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createVNode(_component_el_table, {
                data: _ctx.insuranceProviders,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, { width: 100 }, {
                    header: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("span", null, "Sr. #", -1)
                    ])),
                    default: _withCtx((scope) => [
                      _createElementVNode("span", null, _toDisplayString(scope.$index + 1), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, null, {
                    header: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("span", null, "Plan", -1)
                    ])),
                    default: _withCtx((scope) => [
                      _createElementVNode("span", null, _toDisplayString(scope.row.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, null, {
                    header: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("span", null, "Premium", -1)
                    ])),
                    default: _withCtx((scope) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.memberPremiums, (copay, i) => {
                        return (_openBlock(), _createElementBlock("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(copay.members, (member, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "d-flex"
                            }, [
                              _createElementVNode("span", _hoisted_19, _toDisplayString(member.relationship), 1),
                              _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(member.premium)), 1)
                            ]))
                          }), 128)),
                          _createElementVNode("div", _hoisted_20, [
                            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "w-25" }, [
                              _createElementVNode("b", null, "Total Premium:")
                            ], -1)),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(copay.totalPremium)), 1)
                          ])
                        ]))
                      }), 256))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}