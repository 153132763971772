
import { defineComponent, onMounted, computed, getCurrentInstance, ref } from 'vue'
import { store } from "@/store";
import { customer, rule, common } from '@/store/stateless';
import Etcs from '@/core/services/Callable/Etcs';
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    props: {
        isDisbaled: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabledMain: {
            type: Boolean,
            required: false,
            default: false
        },
        isEnableName: {
            type: Boolean,
            required: false,
            default: false
        },
        
        isShowHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        updateCustomer: {
            type: Boolean,
            required: false,
            default: false
        }

    },

    setup(_, context) {

        const fnfocus = ref(false)
        const ql = ref(false)
        onMounted(() => {
            Etcs.getCountryCode();
            ql.value = health.value.insured_type_id != 0
        })

        const health = computed(() => {
            return store.state.customer.health;
        })

        const countryCodes = computed(() => {
            return store.state.etc.countryCodes;
        })

        const disabledDate = (time: any) => {
            return time.getTime() > new Date()
        }

        const inst = getCurrentInstance()
        const focus = () => {
            // console.log('top focus')
            const toFocus: any = inst?.refs[rule.customerDetails]
            // console.log(toFocus)
            if (toFocus !== undefined) {
            // console.log('in focus')

                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        const sa = () => {
            // 
        }

        const phoneEvent = () => {
            Validations.phone_taken = false
        }

        const emailEvent = () => {
            Validations.email_taken = false
        }

        const setfnfocus = (value) => {
            setTimeout(() => {
                // const name: any = inst?.refs.name
                // if (name !== undefined)
                //     name.focus()
            }, 100);
        }


        const updateGender = () => {
            console.log('updateGender')
            context.emit('clearValidate' ,'gender')
        }

        return {
            sa,
            rule,
            focus,
            health,
            fnfocus,
            countryCodes,
            disabledDate,
            phoneEvent,
            emailEvent,
            setfnfocus,
            customer,
            common,
            ql,
            updateGender
        }
    },
})
