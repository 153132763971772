import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/icons/policy-for-you.png'
import _imports_1 from '@/assets/images/icons/Date.png'


const _hoisted_1 = { class: "online-checkout-popup" }
const _hoisted_2 = { class: "checkout-block" }
const _hoisted_3 = { class: "checkout-top" }
const _hoisted_4 = { class: "row g-0" }
const _hoisted_5 = { class: "col-lg-10 col-md-12 left-col" }
const _hoisted_6 = { class: "checkout-top__block" }
const _hoisted_7 = { class: "result-listing__item__block_top_row flex-wrap d-flex align-items-center" }
const _hoisted_8 = { class: "partner-logo" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "partner-content" }
const _hoisted_11 = { class: "partner-content_title" }
const _hoisted_12 = { class: "mb-0" }
const _hoisted_13 = { class: "col-lg-2 col-md-12 right-col" }
const _hoisted_14 = { class: "right-buy-now text-center h-100" }
const _hoisted_15 = { class: "right-buy-now-top" }
const _hoisted_16 = { class: "price-title" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { class: "checkout-middle" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-md-10 col-sm-12" }
const _hoisted_21 = { class: "partner-content_bottom row mb-5" }
const _hoisted_22 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_23 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_26 = ["href"]
const _hoisted_27 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_28 = { class: "partner-content_bottom row" }
const _hoisted_29 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_30 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_31 = { class: "partner-content_bottom_item col-md-3 me-0" }
const _hoisted_32 = {
  key: 0,
  class: "col-md-2 col-sm-12"
}
const _hoisted_33 = { class: "co-pay-block" }
const _hoisted_34 = { class: "radio-list" }
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "checkout-bottom checkout-bottom-container" }
const _hoisted_37 = { class: "checkout-bottom_block" }
const _hoisted_38 = { class: "row" }
const _hoisted_39 = { class: "col-md-8 left-col" }
const _hoisted_40 = { class: "checkout-middle-footer border-0" }
const _hoisted_41 = { class: "row justify-content-lg-between p-5" }
const _hoisted_42 = { class: "listing-ul" }
const _hoisted_43 = { class: "col-md-4 right-col" }
const _hoisted_44 = { class: "summary" }
const _hoisted_45 = { class: "inner-title" }
const _hoisted_46 = { class: "mb-0" }
const _hoisted_47 = { class: "tooltip-content" }
const _hoisted_48 = { class: "summary-block" }
const _hoisted_49 = { class: "summary-block__table" }
const _hoisted_50 = { class: "summary-block__table--item" }
const _hoisted_51 = { class: "right" }
const _hoisted_52 = {
  key: 0,
  class: "summary-block__table--item"
}
const _hoisted_53 = { class: "left" }
const _hoisted_54 = { class: "right" }
const _hoisted_55 = {
  key: 1,
  class: "summary-block__table--item"
}
const _hoisted_56 = { class: "right" }
const _hoisted_57 = {
  key: 2,
  class: "summary-block__table--item"
}
const _hoisted_58 = { class: "left" }
const _hoisted_59 = { class: "right" }
const _hoisted_60 = { class: "summary-block__table--item" }
const _hoisted_61 = { class: "right big-bold" }
const _hoisted_62 = { class: "dialog-footer" }
const _hoisted_63 = { class: "container" }
const _hoisted_64 = { class: "row" }
const _hoisted_65 = { class: "col-sm-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "modal-class": "online-payment",
    modelValue: _ctx.interestedPolicyModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.interestedPolicyModal) = $event)),
    "close-on-click-modal": true,
    "destroy-on-close": "",
    onBeforeClose: _ctx.close,
    onClose: _ctx.close,
    title: 'Interested Policy',
    width: "80%",
    "show-close": true,
    "append-to-body": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("img", {
                        src: `${_ctx.domain}${_ctx.url}/img/${_ctx.policy.insurance_provider.image}`,
                        class: "img-fluid"
                      }, null, 8, _hoisted_9)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.policy.insurance_provider.provider_name) + " - " + _toDisplayString(_ctx.policy.policy_name), 1)
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "partner-content_bottom d-flex flex-wrap" }, [
                        _createElementVNode("div", { class: "partner-content_bottom_item" }, [
                          _createElementVNode("div", { class: "icon" }, [
                            _createElementVNode("img", {
                              src: _imports_0,
                              class: "img-fluid"
                            })
                          ]),
                          _createElementVNode("div", { class: "title" }, [
                            _createElementVNode("div", { class: "text" }, " for you ")
                          ])
                        ])
                      ], -1))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "premium-title" }, [
                      _createElementVNode("p", null, "Total Premium")
                    ], -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("h4", _hoisted_17, _toDisplayString(_ctx.currency(_ctx.totalPremium)), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("p", null, [
                      _cache[4] || (_cache[4] = _createTextVNode(" Annual Limit ")),
                      _createElementVNode("span", null, "$ " + _toDisplayString(_ctx.policy.annual_limit), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("p", null, [
                      _cache[5] || (_cache[5] = _createTextVNode(" Scope of coverage ")),
                      _createElementVNode("span", {
                        innerHTML: _ctx.policy.medical_cover
                      }, null, 8, _hoisted_24)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("p", null, [
                      _cache[7] || (_cache[7] = _createTextVNode(" Network list ")),
                      _createElementVNode("span", null, [
                        _createElementVNode("a", {
                          href: `${_ctx.domain}${_ctx.folder}/${_ctx.policy.network_list}`,
                          target: "_blank",
                          title: "download"
                        }, _cache[6] || (_cache[6] = [
                          _createElementVNode("i", { class: "icon-svg download-icon" }, null, -1),
                          _createTextVNode(" Download ")
                        ]), 8, _hoisted_26)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("p", null, [
                      _cache[8] || (_cache[8] = _createTextVNode(" Dental ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.policy?.top_section[3]?.value), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("p", null, [
                      _cache[9] || (_cache[9] = _createTextVNode("Maternity Coverage ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.policy?.top_section[1]?.value), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("p", null, [
                      _cache[10] || (_cache[10] = _createTextVNode(" Deductibles ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.policy?.top_section[2]?.value), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("p", null, [
                      _cache[11] || (_cache[11] = _createTextVNode(" Optical ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.policy?.top_section[4]?.value), 1)
                    ])
                  ])
                ])
              ]),
              (_ctx.premiums.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _cache[12] || (_cache[12] = _createElementVNode("h6", null, "Copay", -1)),
                        _createElementVNode("span", {
                          innerHTML: _ctx.selectedPremium?.name
                        }, null, 8, _hoisted_35)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createVNode(_component_el_scrollbar, { height: "300px" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_41, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy.more_details, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "col-lg-4 col-md-4 special-features"
                            }, [
                              _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("ul", null, [
                                  _createElementVNode("li", {
                                    class: _normalizeClass(item.status ? 'check' : 'cross'),
                                    style: {"margin-bottom":"0.5rem"}
                                  }, _toDisplayString(item.name), 3)
                                ])
                              ])
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("h5", _hoisted_46, [
                        _cache[14] || (_cache[14] = _createTextVNode(" Summary ")),
                        _createVNode(_component_el_tooltip, {
                          class: "box-item",
                          effect: "light"
                        }, {
                          content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_47, [
                              _createElementVNode("table", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedPremium.members, (member, index) => {
                                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                    _createElementVNode("td", null, _toDisplayString(member.relationship) + ": ", 1),
                                    _createElementVNode("td", null, _toDisplayString(_ctx.currency(member.premium)), 1)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]),
                          default: _withCtx(() => [
                            _cache[13] || (_cache[13] = _createElementVNode("a", {
                              class: "popper",
                              "data-bs-trigger": "hover",
                              placement: "right",
                              "data-bs-toggle": "popover"
                            }, [
                              _createElementVNode("img", {
                                src: _imports_1,
                                class: "img-fluid"
                              })
                            ], -1))
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("div", _hoisted_49, [
                        _createElementVNode("div", _hoisted_50, [
                          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "left" }, " Premium ", -1)),
                          _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.currency(_ctx.selectedPremium.totalPremium, 2)), 1)
                        ]),
                        (_ctx.vat > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                              _createElementVNode("div", _hoisted_53, " Vat " + _toDisplayString(_ctx.vatper) + "% ", 1),
                              _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.currency(_ctx.vat || 0, 2)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.basmah > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "left" }, " Basmah ", -1)),
                              _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.currency(_ctx.basmah, 2)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.basmah > 0 && _ctx.vat > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                              _createElementVNode("div", _hoisted_58, " Basmah Vat " + _toDisplayString(_ctx.vatper) + "%", 1),
                              _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.currency(_ctx.basmah_vat, 2)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_60, [
                          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "left" }, " Payable Total ", -1)),
                          _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.currency(_ctx.totalPremium)), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_62, [
        _createElementVNode("div", _hoisted_63, [
          _createElementVNode("div", _hoisted_64, [
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "col-sm-4" }, null, -1)),
            _createElementVNode("div", _hoisted_65, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-light",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, " Cancel ")
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onBeforeClose", "onClose"]))
}