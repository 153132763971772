import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "card card-bordered mb-5",
  id: "last"
}
const _hoisted_2 = { class: "card-header ribbon mb-5 d-flex flex-nowrap align-items-center mt-5 mb-5 pb-5" }
const _hoisted_3 = { class: "card-tools d-flex align-items-center" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = { class: "col-sm-3" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-3" }
const _hoisted_12 = { class: "mb-10 d-flex" }
const _hoisted_13 = { class: "col-sm-3" }
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = { class: "col-sm-3" }
const _hoisted_16 = { class: "mb-10 d-flex" }
const _hoisted_17 = { class: "col-sm-3" }
const _hoisted_18 = { class: "mb-10" }
const _hoisted_19 = { class: "col-sm-3" }
const _hoisted_20 = { class: "mb-10" }
const _hoisted_21 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_22 = { class: "mb-10" }
const _hoisted_23 = {
  key: 1,
  class: "col-sm-3"
}
const _hoisted_24 = { class: "mb-10" }
const _hoisted_25 = {
  key: 2,
  class: "col-sm-3"
}
const _hoisted_26 = { class: "mb-10" }
const _hoisted_27 = { class: "col-sm-3" }
const _hoisted_28 = { class: "mb-10" }
const _hoisted_29 = {
  key: 3,
  class: "col-sm-3"
}
const _hoisted_30 = { class: "mb-10" }
const _hoisted_31 = { class: "col-sm-3" }
const _hoisted_32 = { class: "mb-10" }
const _hoisted_33 = { class: "form-label required" }
const _hoisted_34 = {
  key: 4,
  class: "col-sm-3"
}
const _hoisted_35 = { class: "mb-10" }
const _hoisted_36 = {
  key: 5,
  class: "col-sm-3"
}
const _hoisted_37 = { class: "mb-10" }
const _hoisted_38 = { class: "col-sm-3" }
const _hoisted_39 = { class: "mb-10" }
const _hoisted_40 = {
  key: 6,
  class: "col-sm-3"
}
const _hoisted_41 = { class: "mb-10" }
const _hoisted_42 = {
  key: 7,
  class: "col-sm-3"
}
const _hoisted_43 = { class: "mb-10" }
const _hoisted_44 = {
  key: 8,
  class: "col-sm-3"
}
const _hoisted_45 = { class: "mb-10" }
const _hoisted_46 = {
  key: 9,
  class: "col-sm-3"
}
const _hoisted_47 = { class: "mb-10" }
const _hoisted_48 = {
  key: 10,
  class: "col-sm-3"
}
const _hoisted_49 = { class: "mb-10" }
const _hoisted_50 = {
  key: 11,
  class: "col-sm-3"
}
const _hoisted_51 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_agents = _resolveComponent("vc-agents")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_client_classification = _resolveComponent("vc-client-classification")!
  const _component_vc_payment_type = _resolveComponent("vc-payment-type")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h6", { class: "card-subtitle text-muted" }, "Invoice Information")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[30] || (_cache[30] = _createElementVNode("label", {
          for: "exampleFormControlInput1",
          class: "form-label required text-warning me-3 d-block"
        }, "Please Select Agent for this Policy", -1)),
        _createVNode(_component_vc_agents, {
          modelValue: _ctx.health.agent,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.agent) = $event)),
          disabled: _ctx.isAmend,
          placeholder: "Select agent",
          options: _ctx.agents,
          ref: "agent",
          class: "w-auto"
        }, null, 8, ["modelValue", "disabled", "options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isAmend)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_vc_input, {
                  label: "Quote Ref. No.",
                  modelValue: _ctx.invoice.quote_ref_no,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.invoice.quote_ref_no) = $event)),
                  placeholder: "Quote Ref. No.",
                  size: "large",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_vc_input, {
                  label: "Quote Ref. No. for Amendment",
                  modelValue: _ctx.amend_quote_ref_no,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.amend_quote_ref_no) = $event)),
                  placeholder: "Quote Ref. No. for Amendment",
                  size: "large",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_vc_amount_v2, {
              label: _ctx.isAmend ? 'Amend Price' :'Policy Price',
              subLabel: '(w/o VAT)('+_ctx.currency+')',
              modelValue: _ctx.invoice.orginal_policy_price,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.invoice.orginal_policy_price) = $event)),
              placeholder: "Policy Price",
              prop: "policy_price",
              ref: "policy_price",
              size: "large",
              isRequired: "",
              style: {"width":"67%"},
              disabled: ""
            }, null, 8, ["label", "subLabel", "modelValue"]),
            _createVNode(_component_vc_amount_v2, {
              disabled: "",
              label: 'VAT',
              subLabel: '('+_ctx.currency+')',
              modelValue: _ctx.invoice.orginal_policy_price_vat,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoice.orginal_policy_price_vat) = $event)),
              style: {"width":"33%"},
              placeholder: "VAT",
              prop: "policy_price",
              size: "large",
              isRequired: ""
            }, null, 8, ["subLabel", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_vc_amount_v2, {
              label: "Basmah Amount",
              subLabel: '(incl. VAT)('+_ctx.currency+')',
              modelValue: _ctx.invoice.basmah,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoice.basmah) = $event)),
              placeholder: "Basmah Amount",
              prop: "basmah_amount",
              ref: "basmah_amount",
              size: "large",
              disabled: ""
            }, null, 8, ["subLabel", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_vc_amount_v2, {
              label: 'ICP',
              subLabel: '(w/o VAT)('+_ctx.currency+')',
              modelValue: _ctx.invoice.icp,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.invoice.icp) = $event)),
              placeholder: "ICP/Other fee",
              prop: "icp",
              ref: "icp",
              size: "large",
              style: {"width":"67%"},
              disabled: ""
            }, null, 8, ["subLabel", "modelValue"]),
            _createVNode(_component_vc_amount_v2, {
              disabled: "",
              label: 'VAT',
              subLabel: '('+_ctx.currency+')',
              modelValue: _ctx.invoice.icp_vat,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.invoice.icp_vat) = $event)),
              placeholder: "VAT",
              size: "large",
              style: {"width":"33%"}
            }, null, 8, ["subLabel", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_vc_amount_v2, {
              label: "Non Taxable Fee",
              disabled: "",
              subLabel: '('+_ctx.currency+')',
              modelValue: _ctx.invoice.non_taxable_fee,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.invoice.non_taxable_fee) = $event)),
              placeholder: "Non Taxable Fee",
              prop: "non_taxable_fee",
              ref: "non_taxable_fee",
              size: "large"
            }, null, 8, ["subLabel", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_vc_amount_v2, {
              class: "label-highlight",
              label: "Total Payable / Invoice Amount",
              subLabel: '('+_ctx.currency+')',
              modelValue: _ctx.invoice.invoice_amount,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.invoice.invoice_amount) = $event)),
              placeholder: "Total Payable / Invoice Amount",
              prop: "invoice_amount",
              ref: "invoice_amount",
              size: "large",
              isRequired: "",
              disabled: ""
            }, null, 8, ["subLabel", "modelValue"])
          ])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_vc_amount_v2, {
                  label: "Sales Price",
                  subLabel: '('+_ctx.currency+')',
                  modelValue: _ctx.invoice.sales_price,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.invoice.sales_price) = $event)),
                  placeholder: "Sales Price",
                  prop: "sales_price",
                  ref: "sales_price",
                  size: "large",
                  isRequired: "",
                  onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.calculateDiscountAmount()))
                }, null, 8, ["subLabel", "modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _cache[32] || (_cache[32] = _createElementVNode("label", { class: "form-label required" }, " Discount Given ", -1)),
                _createVNode(_component_el_form_item, { prop: "is_discount_given" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_yes_no, {
                      modelValue: _ctx.invoice.is_discount_given,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.invoice.is_discount_given) = $event)),
                      yesNoName: `is_discount_given`,
                      disabled: {is_discount_given_disabled: _ctx.is_discount_given_disabled}
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.invoice.is_discount_given)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_vc_input, {
                  label: "Discount Amount",
                  subLabel: '('+_ctx.currency+')',
                  modelValue: _ctx.invoice.discount_amount,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.invoice.discount_amount) = $event)),
                  placeholder: "Discount Amount",
                  prop: "discount_amount",
                  ref: "discount_amount",
                  size: "large",
                  isRequired: "",
                  onKeypress: _cache[14] || (_cache[14] = ($event: any) => (_ctx.isNumber($event))),
                  disabled: ""
                }, null, 8, ["subLabel", "modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label required" }, " Client Classification ", -1)),
            _createVNode(_component_el_form_item, { prop: "client_classification" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_client_classification, {
                  ref: "client_classification",
                  modelValue: _ctx.invoice.client_classification,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.invoice.client_classification) = $event)),
                  disabled: _ctx.isAmend
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            })
          ])
        ]),
        (!_ctx.isAmend)
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _cache[34] || (_cache[34] = _createElementVNode("label", { class: "form-label required" }, " Is Split Payment ? ", -1)),
                _createVNode(_component_el_form_item, { prop: "is_split_payment" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_yes_no, {
                      ref: "is_split_payment",
                      modelValue: _ctx.invoice.is_split_payment,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.invoice.is_split_payment) = $event)),
                      yesNoName: `is_split_payment`,
                      onChange: _ctx.updateSplitPayment
                    }, null, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.invoice.is_split_payment ? '1st' : '') + " Payment Type", 1),
            _createVNode(_component_el_form_item, { prop: "payment_type" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_payment_type, {
                  ref: "payment_type",
                  modelValue: _ctx.invoice.payment_type,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.invoice.payment_type) = $event)),
                  onChange: _ctx.chgPaymentType
                }, null, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ])
        ]),
        (_ctx.invoice.is_split_payment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _cache[35] || (_cache[35] = _createElementVNode("label", { class: "form-label required" }, " Pending Payment Type", -1)),
                _createVNode(_component_el_form_item, { prop: "pending_payment_type" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_payment_type, {
                      ref: "pending_payment_type",
                      disabled: "",
                      modelValue: _ctx.invoice.pending_payment_type,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.invoice.pending_payment_type) = $event)),
                      pendingPaymentType: true
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.invoice.is_split_payment && _ctx.invoice.pending_payment_type == 4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_vc_input, {
                  label: "Number of Installments",
                  subLabel: "(Max 6)",
                  modelValue: _ctx.invoice.installments,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.invoice.installments) = $event)),
                  placeholder: "Number of Installments",
                  prop: "installments",
                  ref: "installments",
                  size: "large",
                  isRequired: "",
                  onKeypress: _cache[20] || (_cache[20] = ($event: any) => (_ctx.isNumber($event)))
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createVNode(_component_vc_input, {
              label: "Order Description",
              modelValue: _ctx.invoice.order_description,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.invoice.order_description) = $event)),
              placeholder: "Order Description",
              prop: "order_description",
              ref: "order_description",
              size: "large",
              maxLength: "100",
              isRequired: "",
              rules: { validator:(rule, value, callback) => {
                                        _ctx.Validations.custom_order_description(rule, value, callback,_ctx.invoice.payment_type); // Pass 18 as a custom parameter
                                    }, trigger: ['blur', 'change'] },
              disabled: _ctx.invoice.payment_type == 3
            }, null, 8, ["modelValue", "rules", "disabled"])
          ])
        ]),
        (_ctx.invoice.payment_type == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, [
                _cache[37] || (_cache[37] = _createElementVNode("label", { class: "form-label required" }, " Link Expiry date ", -1)),
                _createVNode(_component_el_form_item, { prop: "expiry_date_time" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_date_picker, {
                      modelValue: _ctx.invoice.expiry_date_time,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.invoice.expiry_date_time) = $event)),
                      min: "1900-01-01",
                      ref: "expiry_date_time",
                      label: "Link Expiry date",
                      isRequired: ""
                    }, null, 8, ["modelValue"]),
                    _cache[36] || (_cache[36] = _createElementVNode("label", {
                      for: "payment_holder",
                      class: "form-label text-warning"
                    }, "Only for online payment methods", -1))
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.invoice.payment_type == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
              _createElementVNode("div", _hoisted_43, [
                _cache[38] || (_cache[38] = _createElementVNode("label", { class: "form-label required" }, " Language ", -1)),
                _createVNode(_component_el_form_item, { prop: "language" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.invoice.language,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.invoice.language) = $event)),
                      size: "large"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          label: "English",
                          value: "en"
                        }),
                        _createVNode(_component_el_option, {
                          label: "Arabic",
                          value: "ar"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (true || [2, 3, 4, 5].includes(_ctx.invoice.payment_type))
          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _createVNode(_component_vc_input, {
                  label: "Reference No.",
                  modelValue: _ctx.invoice.reference_no,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.invoice.reference_no) = $event)),
                  placeholder: "Reference No.",
                  prop: "reference_no",
                  ref: "reference_no",
                  size: "large",
                  isRequired: "",
                  maxLength: "30",
                  onPress: _cache[25] || (_cache[25] = ($event: any) => (_ctx.Validations.is_reference_no_exist = false))
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        (true || [2, 3, 4, 5].includes(_ctx.invoice.payment_type))
          ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
              _createElementVNode("div", _hoisted_47, [
                _cache[39] || (_cache[39] = _createElementVNode("label", { class: "form-label required" }, " Payment date ", -1)),
                _createVNode(_component_vc_date_picker, {
                  modelValue: _ctx.invoice.payment_date,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.invoice.payment_date) = $event)),
                  min: "1900-01-01",
                  ref: "payment_date",
                  label: "Payment Date",
                  prop: "payment_date",
                  isRequired: ""
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        (true || [2, 3, 4, 5].includes(_ctx.invoice.payment_type))
          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, [
                _cache[40] || (_cache[40] = _createElementVNode("label", { class: "form-label required" }, " Receipt (Payment Proof) ", -1)),
                _createVNode(_component_el_form_item, { prop: "file_receipt" }, {
                  default: _withCtx(() => [
                    _createElementVNode("input", {
                      type: "file",
                      onChange: _cache[27] || (_cache[27] = ($event: any) => (_ctx.uploadFile($event))),
                      accept: ".png, .jpg, .jpeg, .pdf"
                    }, null, 32),
                    _createVNode(_component_el_input, {
                      type: "hidden",
                      modelValue: _ctx.invoice.file_receipt,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.invoice.file_receipt) = $event)),
                      ref: "file_receipt",
                      style: {"display":"none"}
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.invoice.is_split_payment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _cache[41] || (_cache[41] = _createElementVNode("label", { class: "form-label" }, " Cheque ", -1)),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("input", {
                      id: "file-cheque",
                      type: "file",
                      onChange: _cache[29] || (_cache[29] = ($event: any) => (_ctx.uploadChequeFile($event))),
                      accept: ".png, .jpg, .jpeg, .pdf"
                    }, null, 32)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}