import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center mb-4 ms-4"
}
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center text-gray-800 text-hover-primary fw-bolder fs-6" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted fw-bold d-flex customer-log-container"
}
const _hoisted_5 = { class: "customer-log-label" }
const _hoisted_6 = { class: "customer-log-value" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "text-muted fw-bold d-flex customer-log-container"
}
const _hoisted_9 = { class: "customer-log-label" }
const _hoisted_10 = { class: "customer-log-value" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 2,
  class: "text-muted fw-bold d-flex customer-log-container"
}
const _hoisted_13 = { class: "customer-log-label" }
const _hoisted_14 = { class: "customer-log-value" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "d-flex align-items-center mb-4 ms-4"
}
const _hoisted_17 = { class: "flex-grow-1" }
const _hoisted_18 = { class: "customer-log-label" }
const _hoisted_19 = { class: "customer-log-value" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 2,
  class: "d-flex align-items-center mb-4 ms-4"
}
const _hoisted_22 = { class: "flex-grow-1" }
const _hoisted_23 = { class: "text-muted fw-bold d-flex customer-log-container" }
const _hoisted_24 = { class: "customer-view-log-label text-gray-800 text-hover-primary fw-bolder fs-6" }
const _hoisted_25 = { class: "customer-log-value" }
const _hoisted_26 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.property === 'activity_log' && _ctx.activeTab === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(["bullet bullet-vertical h-75px", `bg-${_ctx.color[Math.floor(Math.random() * _ctx.color.length)]}`])
          }, null, 2),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(_ctx.value.title), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activity_logs, (activity, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                ((activity.value == 'policy_status' || activity.value == 'by') && _ctx.isPolicy && _ctx.value[activity.value].length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(activity.label), 1),
                      _createElementVNode("span", _hoisted_6, [
                        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "customer-log-colon me-3" }, ": ", -1)),
                        _createElementVNode("span", {
                          innerHTML: _ctx.value[activity.value]
                        }, null, 8, _hoisted_7)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isPolicy && (activity.value == 'current_data' || activity.value == 'previous_data') && (_ctx.value['current_data'].length > 0 || _ctx.value['previous_data'].length > 0))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(activity.label), 1),
                      _createElementVNode("span", _hoisted_10, [
                        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "customer-log-colon me-3" }, ": ", -1)),
                        _createElementVNode("span", {
                          innerHTML: _ctx.value[activity.value]
                        }, null, 8, _hoisted_11)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isPolicy && activity.value != 'policy_status')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(activity.label), 1),
                      _createElementVNode("span", _hoisted_14, [
                        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "customer-log-colon me-3" }, ": ", -1)),
                        _createElementVNode("span", {
                          innerHTML: _ctx.value[activity.value]
                        }, null, 8, _hoisted_15)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.property === 'status_log' && _ctx.activeTab === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("span", {
            class: _normalizeClass(["bullet bullet-vertical h-75px", `bg-${_ctx.color[Math.floor(Math.random() * _ctx.color.length)]}`])
          }, null, 2),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
          _createElementVNode("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.status_logs, (activity, i) => {
              return (_openBlock(), _createElementBlock("span", {
                key: i,
                class: _normalizeClass(["text-muted fw-bold d-flex customer-log-container", { 'text-gray-800 text-hover-primary fw-bolder fs-6': activity.isBold }])
              }, [
                _createElementVNode("span", _hoisted_18, _toDisplayString(activity.label), 1),
                _createElementVNode("span", _hoisted_19, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "customer-log-colon me-3" }, ": ", -1)),
                  _createElementVNode("span", {
                    innerHTML: _ctx.value[activity.value]
                  }, null, 8, _hoisted_20)
                ])
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.property === 'view_activity_log' && _ctx.activeTab === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("span", {
            class: _normalizeClass(["bullet bullet-vertical h-40px", `bg-${_ctx.color[Math.floor(Math.random() * _ctx.color.length)]}`])
          }, null, 2),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("span", _hoisted_23, [
              _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.value.description), 1),
              _createElementVNode("span", _hoisted_25, [
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "customer-log-colon mx-2" }, " - ", -1)),
                _createElementVNode("span", {
                  innerHTML: _ctx.value.by
                }, null, 8, _hoisted_26)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}