import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container marketing-dashboard" }
const _hoisted_2 = { class: "row marketing-dash-row" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  key: 0,
  class: "col-sm-8 pull-right"
}
const _hoisted_7 = {
  class: "fw-light fs-2",
  title: "Deleted"
}
const _hoisted_8 = { class: "title text-danger" }
const _hoisted_9 = { class: "row mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_pie_chart = _resolveComponent("vc-pie-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.marketingCounts, (count) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-md-6 marketing-dash-item",
          key: count.label
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-4" }, [
                  _createElementVNode("i", { class: "icon-svg icon-total-sales" })
                ], -1)),
                (count.deleted)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, "(" + _toDisplayString(_ctx.common.formatNumber(_ctx.counts[count.deleted])) + ")", 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.common.formatNumber(_ctx.counts[count.value])), 1),
              _createElementVNode("h3", null, _toDisplayString(count.label), 1)
            ])
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.marketing, (card, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["mb-6 p-1", card.div_class])
        }, [
          _createVNode(_component_vc_pie_chart, {
            chartOptions: _ctx.graph[card.value][card.type].options,
            series: _ctx.graph[card.value][card.type].series || [],
            title: card.label
          }, null, 8, ["chartOptions", "series", "title"])
        ], 2))
      }), 128))
    ])
  ]))
}