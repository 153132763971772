import { GetterTree } from 'vuex';
import { common } from '@/store/stateless'
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';


import { State } from './state';


export type Getters = {
    getInvoiceList(state: State): any ;
}

export const getters: GetterTree<State, RootState> & Getters = {

    getInvoiceList: (state) => {
        const data = state.invoiceLists
        
        if (data) {
            console.log('invoice',data?.data)
            return data.data.map((x) => {
                const custom = { ... x};
                custom.agent_details =  `${x?.policy_purchase?.policy_underwriter?.name ?? "<span class='text-danger'>Not Assigned</span>"} <br />
                ${x?.policy_sales_agent?.name ?? '-'} `;

                custom.created_details =  `${x?.created_by?.name ?? ""} <br />
                ${x?.created_at} `;

                custom.medical_class_text = x.policy ? x.policy?.medical_class_text : ''

                custom.policy_name = x.policy?.policy_name
                custom.payment_date = x.payment_date ? `${common.setDateOnly(x.payment_date)}` : ''
                
                custom.is_active_policy = (x.policy_purchase && x.policy_purchase.is_active == 1 ) ? true : false
                
                // console.log(custom)

                custom.child_invoices = custom.child_invoices.map(y => {
                y.payment_date = y.payment_date ? `${common.setDateOnly(y.payment_date)}` : ''
                    return {
                        ...y,
                        agent_details: `${x?.policy_purchase?.policy_underwriter?.name ?? "<span class='text-danger'>Not Assigned</span>"} <br />
                        ${x?.policy_sales_agent?.name ?? '-'} `,
                        created_details: `${y?.created_by?.name ?? ""} <br />
                        ${y?.created_at} `,
                        policy_name: x.policy?.policy_name,
                        medical_class_text: x.policy.medical_class_text,
                        
                    }
                })
                
                return custom;
            });

        }
        return [] ;
    },

    getGroupInvoiceList: (state) => {
        const data = state.groupInvoiceLists
        
        if (data) {
            // console.log('invoice',data?.data)
            return data.data.map((x) => {
                const custom = { ... x};
                custom.agent_details =  `${x?.policy_purchase?.policy_underwriter?.name ?? "<span class='text-danger'>Not Assigned</span>"} <br />
                ${x?.policy_sales_agent?.name ?? '-'} `;

                custom.created_details =  `${x?.created_by?.name ?? ""} <br />
                ${x?.created_at} `;

                custom.insurance_type_text = ''

                // console.log('policy active',x.policy_purchase.is_active )

                custom.is_active_policy = (x.policy_purchase && x.policy_purchase.is_active == 1 ) ? true : false

                custom.policy_name = '';
                custom.payment_date = x.payment_date ? `${common.setDateOnly(x.payment_date)}` : '-'
                custom.categories = custom.categories.map(y => {
                    return {
                        ...y,
                        counts: `${y.group_count} (${y.members_above_60})`
                    }
                })

                custom.child_invoices = custom.child_invoices.map(y => {
                    y.payment_date = y.payment_date ? `${common.setDateOnly(y.payment_date)}` : ''
                    y.categories = y.categories.map(y => {
                        return {
                            ...y,
                            counts: `${y.group_count} (${y.members_above_60})`
                        }
                    })
                    return {
                        ...y,
                        agent_details: `${x?.policy_purchase?.policy_underwriter?.name ?? "<span class='text-danger'>Not Assigned</span>"} <br />
                        ${x?.policy_sales_agent?.name ?? '-'} `,
                        created_details: `${y?.created_by?.name ?? ""} <br />
                        ${y?.created_at} `
                    }
                })
                
                return custom;
            });

        }
        return [] ;
    },
    
}