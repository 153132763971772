// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum 
InvoiceActionTypes {
  MAKE_INVOICE = "MAKE_INVOICE",
  MAKE_INVOICE_EVENT = "MAKE_INVOICE_EVENT",
  INVOICE_LISTS = "INVOICE_LISTS",
  UPDATE_INVOICE = "UPDATE_INVOICE",   
  SEND_PAYMENT_LINK_EMAIL = 'SEND_PAYMENT_LINK_EMAIL',
  INTERESTED_POLICY = "INTERESTED_POLICY",
  UPLOAD_INVOICE_DOCUMENT = 'UPLOAD_INVOICE_DOCUMENT',
  GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS',
  GET_INVOICE = "GET_INVOICE",
  ADD_AMEND_INVOICE = "ADD_AMEND_INVOICE",
  SEND_INVOICE_GENERATION_ISSUE_EMAIL = "SEND_INVOICE_GENERATION_ISSUE_EMAIL",
  SUB_AMEND_INVOICE = "SUB_AMEND_INVOICE",
  MAKE_GROUP_INVOICE = "MAKE_GROUP_INVOICE",
  GROUP_INVOICE_LISTS = "GROUP_INVOICE_LISTS",
  UPDATE_GROUP_INVOICE = "UPDATE_GROUP_INVOICE",
  UPLOAD_GROUP_INVOICE_DOCUMENT = "UPLOAD_GROUP_INVOICE_DOCUMENT",
  GET_GROUP_INVOICE = "GET_GROUP_INVOICE",
  ADD_GROUP_AMEND_INVOICE = "ADD_GROUP_AMEND_INVOICE",
  SUB_GROUP_AMEND_INVOICE = "SUB_GROUP_AMEND_INVOICE",
  GET_GROUP_INVOICE_LOG = "GET_GROUP_INVOICE_LOG",
  VALIDATE_DUPLICATES = 'VALIDATE_DUPLICATES',
  GROUP_CATEGORY_ADDITION_INVOICE = 'GROUP_CATEGORY_ADDITION_INVOICE'
  
}
  