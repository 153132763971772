
const total_sales = [
    
    {
        title: 'Total Sales',
        value: 'total_deals',
        secondary_value: 'total_customer_deals',
        icon: 'icon-total-sales',
        isClick: true,
        key: 'sales',
        item: 'total-sales',
        color: '#00c5dc'
    },

    {
        title: 'Total Leads',
        value: 'total_leads',
        secondary_value: 'total_customers_leads',
        icon: 'icon-total-leads',
        isClick: true,
        key: 'leads',
        item: 'total-leads',
        color: '#2739c1'
    },

    {
        title: 'Closing Ratio',
        value: 'closing_ratio',
        icon: 'icon-closing-ratio',
        color: '#f4516c'
    },

    {
        title: 'Basic / Enhance / Group',
        value: 'basic_enhance',
        icon: 'icon-third-party',
        color: '#34bfa3'
    },

    {
        title: 'Total Revenue',
        value: 'total_revenue',
        icon: 'icon-total-revenue',
        color: '#34bfa3'
    },
    
]

const new_sales = [
    
    {
        title: 'New Sales',
        value: 'total_deals',
        secondary_value: 'total_customer_deals',
        icon: 'icon-new-sales',
        isClick: true,
        key: 'sales',
        item: 'total-new-sales',
        color: '#2739c1'
    },

    {
        title: 'New Leads',
        value: 'total_leads',
        secondary_value: 'total_customers_leads',
        icon: 'icon-new-leads',
        isClick: true,
        key: 'leads',
        item: 'total-new-leads',
        color: '#2739c1'
    },

    {
        title: 'Closing Ratio',
        value: 'closing_ratio',
        icon: 'icon-closing-ratio',
        color: '#f4516c'
    },

    {
        title: 'Basic / Enhance / Group',
        value: 'basic_enhance',
        icon: 'icon-third-party',
        color: '#34bfa3'
    },

    {
        title: 'Revenue',
        value: 'total_revenue',
        icon: 'icon-new-revenue',
        color: '#34bfa3'
    },
    
]

const wip_sales = [
    
    {
        title: 'WIP Sales',
        value: 'total_deals',
        icon: 'icon-new-sales',
        isClick: true,
        key: 'sales',
        item: 'total-wip-sales',
        color: '#2739c1'
    },
    {
        title: 'WIP Sales',
        value: 'total_customer_deals',
        icon: 'icon-new-sales',
        color: '#00c5dc',
        hide: true
    },
    {
        title: 'WIP Sales',
        value: 'total_customer_deals',
        icon: 'icon-new-sales',
        color: '#00c5dc',
        hide: true

    },

    {
        title: 'Basic / Enhance / Group',
        value: 'basic_enhance',
        icon: 'icon-third-party',
        color: '#34bfa3'
    },

    {
        title: 'Revenue',
        value: 'total_revenue',
        icon: 'icon-new-revenue',
        color: '#34bfa3'
    },
    
]

const renewal_sales = [
    
    {
        title: 'Renewal Sales',
        value: 'total_deals',
        secondary_value: 'customer_renewal_deals',
        icon: 'icon-renewal-sales',
        isClick: true,
        key: 'sales',
        item: 'total-renewal-sales',
        color: '#2739c1'
    },

    {
        title: 'Renewal Leads',
        value: 'total_leads',
        secondary_value: 'customer_renewal_leads',
        icon: 'icon-renewal-leads',
        isClick: true,
        key: 'leads',
        item: 'total-renewal-leads',
        color: '#2739c1'
    },

    {
        title: 'Closing Ratio',
        value: 'closing_ratio',
        icon: 'icon-closing-ratio',
        color: '#f4516c'
    },

    {
        title: 'Basic / Enhance / Group',
        value: 'basic_enhance',
        icon: 'icon-third-party',
        color: '#34bfa3'
    },

    {
        title: 'Revenue',
        value: 'total_revenue',
        icon: 'icon-new-revenue',
        color: '#34bfa3'
    },
    
]

const llr_sales = [
    
    {
        title: 'Lost Lead Renewal Sales',
        value: 'total_deals',
        secondary_value: 'customer_llr_deals',
        icon: 'icon-llr-sales',
        isClick: true,
        key: 'sales',
        item: 'total-llr-sales',
        color: '#2739c1'
    },

    {
        title: 'Lost Lead Renewal',
        value: 'total_leads',
        secondary_value: 'customer_llr_leads',
        icon: 'icon-llr-leads',
        isClick: true,
        key: 'leads',
        item: 'total-llr-leads',
        color: '#2739c1'
    },

    {
        title: 'Closing Ratio',
        value: 'closing_ratio',
        icon: 'icon-closing-ratio',
        color: '#f4516c'
    },

    {
        title: 'Basic / Enhance / Group',
        value: 'basic_enhance',
        icon: 'icon-third-party',
        color: '#34bfa3'
    },

    {
        title: 'Revenue',
        value: 'total_revenue',
        icon: 'icon-new-revenue',
        color: '#34bfa3'
    },
    
]

const lrl_sales = [
    
    {
        title: 'Lost Leads',
        value: 'total_deals',
        icon: 'icon-lrl-sales',
        isClick: true,
        key: 'leads',
        item: 'total-lost-leads',
        color: '#2739c1'
    },

    {
        title: 'Lead Renewal Lost',
        value: 'total_leads',
        icon: 'icon-lrl-leads',
        isClick: true,
        key: 'leads',
        item: 'total-lrl-leads',
        color: '#2739c1'
    },

    {
        title: 'Hot Enquiry',
        value: 'untouched_hot_enquiry',
        icon: 'icon-u-rhl',
        color: '#34bfa3'
    },

    {
        title: 'WIP',
        value: 'wip_leads',
        icon: 'icon-u-wip',
        color: '#2739c1'
    },

    
]



const lr_sales = [
    
    {
        title: 'Current Year Renewal Sales',
        value: 'total_deals',
        icon: 'icon-lr-sales',
        isClick: true,
        key: 'sales',
        item: 'lifetime-renewal-leads',
        color: '#2739c1'
    },

    {
        title: 'Previous Year Renewal Leads',
        value: 'total_leads',
        icon: 'icon-lr-leads',
        isClick: true,
        key: 'leads',
        item: 'lifetime-renewal-leads',
        color: '#2739c1'
    },

    {
        title: 'Closing Ratio',
        value: 'closing_ratio',
        icon: 'icon-closing-ratio',
        color: '#f4516c'
    },

    {
        title: 'Basic / Enhance / Group',
        value: 'basic_enhance',
        icon: 'icon-third-party',
        color: '#34bfa3'
    },

    {
        title: 'Revenue',
        value: 'total_revenue',
        icon: 'icon-new-revenue',
        color: '#34bfa3'
    },
    
]


const untouched_sales = [
    
    {
        title: 'Untouched Customers',
        value: 'untouched_customer',
        icon: 'icon-u-customers',
        color: '#2739c1'
    },

    {
        title: 'Untouched Renewal Leads',
        value: 'renewal_leads',
        icon: 'icon-u-renewal-leads',
        color: '#2739c1'
    },

    {
        title: 'Untouched New Leads',
        value: 'new_leads',
        icon: 'icon-u-new-leads',
        color: '#2739c1'
    },
    
    {
        title: 'Untouched LLR',
        value: 'llr_leads',
        icon: 'icon-u-llr',
        color: '#2739c1'
    },

]

const refunds = [
    
    {
        title: 'Total Refund',
        value: 'total_refund',
        icon: 'icon-refund',
        color: '#2739c1'
    },

    {
        title: 'Partial Refund',
        value: 'partial_refund',
        icon: 'icon-partial-refund',
        color: '#2739c1'
    },

    {
        title: 'Full Refund',
        value: 'full_refund',
        icon: 'icon-full-refund',
        color: '#2739c1'
    },
    
    {
        title: 'Total Refund Amount',
        value: 'total_refund_amount',
        icon: 'icon-total-revenue',
        color: '#2739c1'
    },

    {
        title: 'Partial Refund Amount',
        value: 'partial_refund_amount',
        icon: 'icon-total-revenue',
        color: '#2739c1'
    },

    {
        title: 'Full Refund Amount',
        value: 'full_refund_amount',
        icon: 'icon-total-revenue',
        color: '#2739c1'
    },

]


const cancellation = [
    
    {
        title: 'Total Cancellation',
        value: 'total_cancellation',
        icon: 'icon-policies-cancelled',
        color: '#2739c1'
    },

    {
        title: 'Partial Cancellation',
        value: 'partial_cancellation',
        icon: 'icon-partial-refund',
        color: '#2739c1'
    },

    {
        title: 'Full Cancellation',
        value: 'full_cancellation',
        icon: 'icon-full-refund',
        color: '#2739c1'
    },
    
    {
        title: 'Total Cancellation Amount',
        value: 'total_cancellation_amount',
        icon: 'icon-total-revenue',
        color: '#2739c1'
    },

    {
        title: 'Partial Cancellation Amount',
        value: 'partial_cancellation_amount',
        icon: 'icon-total-revenue',
        color: '#2739c1'
    },

    {
        title: 'Full Cancellation Amount',
        value: 'full_cancellation_amount',
        icon: 'icon-total-revenue',
        color: '#2739c1'
    },

]

const total_revenue = [
    {
        title: 'Total Revenue (Inclusive Addition)',
        value: 'total_revenue_inc_amend',
        icon: 'icon-total-revenue',
        color: '#716aca'
    },

    {
        title: 'Addition',
        value: 'total_amend_revenue',
        icon: 'icon-addson',
        color: '#f4516c'
    },

    {
        title: 'Deletion',
        value: 'total_deletion_revenue',
        icon: 'icon-addson',
        color: '#f4516c'
    },
]

const top_sales_person = [
    {
        label: 'Name',
        value: 'agent_name',
        width: '150',
    },
    {
        label: 'New Leads',
        value: 'new_leads',
        width: '120',
    },
    {
        label: 'New Deals',
        value: 'new_deals',
        width: '120',
    },
    {
        label: 'CR (New)',
        value: 'closing_ratio_new',
        width: '120',
    },
    {
        label: 'WIP Deals',
        value: 'wip_deals',
        width: '120',
    },

    {
        label: 'Renewal Leads',
        value: 'renewal_leads',
        width: '150',
    },

    {
        label: 'Renewal Deals',
        value: 'renewal_deals',
        width: '150',
    },

    {
        label: 'CR (Renewal)',
        value: 'closing_ratio_renewal',
        width: '150',
    },

    {
        label: 'LLR Leads',
        value: 'llr_leads',
        width: '120',
    },

    {
        label: 'LLR Deals',
        value: 'llr_deals',
        width: '120',
    },

    {
        label: 'CR (LLR)',
        value: 'closing_ratio_llr',
        width: '120',
    },

    {
        label: 'Total Leads',
        value: 'total_leads',
        width: '120',
    },

    {
        label: 'Total Deals',
        value: 'total_deals',
        width: '120',
    },

    {
        label: 'CR (Total)',
        value: 'closing_ratio_total',
        width: '120',
    },

    {
        label: 'Total Revenue',
        value: 'total_revenue',
        width: '150',
    },

    {
        label: 'LR Lost',
        value: 'lrl_leads',
        width: '120',
    },

    {
        label: 'Lost leads',
        value: 'lost_leads',
        width: '120',
    },


    {
        label: 'Untouched leads',
        value: 'untouched_leads',
        width: '180',
    },
    
    {
        label: 'Untouched Customers',
        value: 'untouched_customers',
        width: '200',
    },

    {
        label: 'Over Due Tasks',
        value: 'over_due_tasks',
        width: '150',
    },

    {
        label: 'Pending Leads',
        value: 'pending_leads',
        width: '150',
    },

    {
        label: 'Deals Cancelled',
        value: 'total_deals_cancelled',
        width: '180',
    },

    {
        label: 'Cancellation Amount',
        value: 'deals_cancellation_payable_amount',
        width: '200',
    },

]

const sales_target_summary = [
    {
        label: 'Name',
        value: 'name',
        width: '150',
    },

    {
        label: 'Total Deals',
        value: 'total_deals',
        width: '120',
    },

    {
        label: 'Sales Target',
        value: 'sales_target',
        width: '150',
    },
    {
        label: 'Refund Amount',
        value: 'refund',
        width: '150',
    },
    {
        label: 'Revenue Made',
        value: 'revenue_made',
        width: '150',
    },
    {
        label: 'Revenue Target',
        value: 'revenue_target',
        width: '150',
    },
]


const leads_target_summary = [
    {
        label: 'Marketing Agent',
        value: 'name',
        width: '150',
    },

    {
        label: 'Number Of Leads',
        value: 'no_of_leads',
        width: '120',
    },

    {
        label: 'Achieved',
        value: 'achieved',
        width: '120',
    },
    {
        label: 'Marketing Spend',
        value: 'sales_targetmarketing_spend',
        width: '120',
    },
    
]

const utm_stats = [
    {
        label:'Paid vs Organic New Leads',
        div_class:'col-sm-4',
        value:'paid_vs_organic_leads',
        type:'new'
    },
    {
        label:'Paid New Leads By UTM Source',
        div_class:'col-sm-4',
        value:'paid_utm_source_leads',
        type:'new'
    },
    {
        label:"Organic New Leads By UTM Source",
        div_class:'col-sm-4',
        value:'organic_utm_source_leads',
        type:'new'
    },
    {
        label:"Paid New Leads By UTM Campaign",
        div_class:'col-sm-6',
        value:'paid_utm_campaign_leads',
        type:'new'
    },
    {
        label:"Paid vs Organic WIP Deals",
        div_class:'col-sm-6',
        value:'paid_vs_organic_deals',
        type:'wip'
    },
    {
        label:"Paid vs Organic New Deals",
        div_class:'col-sm-6',
        value:'paid_vs_organic_deals',
        type:'new'
    },
    {
        label:"Paid New Deals By UTM Campaign",
        div_class:'col-sm-6',
        value:'paid_utm_campaign_deals',
        type:'new'
    },
    {
        label:"Paid New Deals By UTM Source",
        div_class:'col-sm-6',
        value:'paid_utm_source_deals',
        type:'new'
    },
    {
        label:"Organic New Deals By UTM Source",
        div_class:'col-sm-6',
        value:'organic_utm_source_deals',
        type:'new'
    },
    {
        label:"New Leads By Lead Source",
        div_class:'col-sm-6',
        value:'lead_source_leads',
        type:'new'
    },
    {
        label:"New Deals By Lead Source",
        div_class:'col-sm-6',
        value:'lead_source_deals',
        type:'new'
    },
];

const top_provider = [
    {
        label: 'Provider Name',
        value: 'providers',
        width: '150',
    },

    {
        label: 'Total Policies Sold',
        value: 'sold',
        width: '120',
    },

    {
        label: 'Total Revenue',
        value: 'revenue',
        width: '120',
    }
]

const top_emirate = [
    {
        label: 'Emirate',
        value: 'emirate',
        width: '150',
    },

    {
        label: 'Sold Policies',
        value: 'sold',
        width: '120',
    },
]



const top_insured = [
    {
        label: 'Insured For',
        value: 'insured',
        width: '150',
    },

    {
        label: 'Sold Policies',
        value: 'sold',
        width: '120',
    },
]

const table_stats = [
    {
        // Individual - 
        label:'Top Providers',
        div_class:'col-sm-6',
        columns: top_provider,
        value: 'top_provider',
        showCount: true,
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },

    // {
    //     label:'Group - Top Providers',
    //     div_class:'col-sm-6',
    //     columns: top_provider,
    //     value: 'top_group_provider',
    //     showCount: true,
    //     backgroundColor:'#34bfa3',
    //     fontColor:'#FFF'
    // },
    
    // {
    //     label:'Individual - Top Emirate',
    //     div_class:'col-sm-6',
    //     columns: top_emirate,
    //     value: 'top_emirate',
    //     showCount: true,
    //     backgroundColor:'#34bfa3',
    //     fontColor:'#FFF'
    // },

    // {
    //     label:'Group - Top Emirate',
    //     div_class:'col-sm-6',
    //     columns: top_emirate,
    //     value: 'top_group_emirate',
    //     showCount: true,
    //     backgroundColor:'#34bfa3',
    //     fontColor:'#FFF'
    // },


    // {
    //     label:'Top Insured For Sold',
    //     div_class:'col-sm-6',
    //     columns: top_insured,
    //     value: 'top_insured',
    //     showCount: true,
    //     backgroundColor:'#34bfa3',
    //     fontColor:'#FFF'
    // },
    

]

const lead_info = [
    {
        label: 'Name',
        value: 'name',
        // width: '150',
    },

    {
        label: 'Email',
        value: 'email',
        // width: '120',
    },

    {
        label: 'Mobile',
        value: 'phone_number',
        // width: '120',
    },

    {
        label: 'Joined on',
        value: 'created_at',
        // width: '120',
    },
]

const transactions = [
    {
        label: 'Quote Ref. No.',
        value: 'merchant_reference',
        // width: '150',
    },

    {
        label: 'Policy',
        value: 'policy_name',
        // width: '120',
    },

    {
        label: 'Policy Price',
        value: 'sales_price',
        isAmount:true
        // width: '120',
    },
]

const table_others = [
    {
        label:'New Leads',
        div_class:'col-sm-12',
        columns: lead_info,
        value: 'leadsNew',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    
    {
        label:'Pending Leads',
        div_class:'col-sm-12',
        columns: lead_info,
        value: 'leadsPending',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'Lost Leads',
        div_class:'col-sm-12',
        columns: lead_info,
        value: 'leadsLost',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'Online Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'online_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'Suyool Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'suyool_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'COD Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'cod_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'Direct Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'direct_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'OMT Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'omt_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'Cash Deposit Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'cash_deposit_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    },
    {
        label:'Cheque Transactions',
        div_class:'col-sm-6',
        columns: transactions,
        value: 'cheque_transactions',
        backgroundColor:'#34bfa3',
        fontColor:'#FFF'
    }
]

const team_summaries = [
    {
        label: 'Name',
        value: 'agent_name',
        isFixed: true,
        width: '150'
    },
    {
        label: 'Revenue',
        value: 'total_revenue',
        isFormated: true,
        width: '150'
    },
    {
        label: 'Deals',
        value: 'total_deals',
        width: '150'
    },
    {
        label: 'Sales Target',
        value: 'sales_target',
        width: '150'
    },
    {
        label: 'Variance',
        value: 'variance',
        isFormated: true,
        width: '150'
    },
    {
        label: 'Closing Ratio',
        value: 'closing_ratio_total',
        isPercentage: true,
        width: '150'
    },
    {
        label: 'New Leads',
        value: 'new_leads',
        width: '150'
    },
    {
        label: 'Quick Leads',
        value: 'quick_leads',
        width: '150'
    },
    {
        label: 'Renewals',
        value: 'renewal_leads',
        width: '150'
    },
    {
        label: 'LL Renewals',
        value: 'llr_leads',
        width: '150'
    },
    {
        label: 'LR Lost',
        value: 'lrl_leads',
        width: '150'
    },
    {
        label: 'Lost Leads',
        value: 'lost_leads',
        width: '150'
    },
    {
        label: 'Pending',
        value: 'pending_leads',
        width: '150'
    },
    {
        label: 'Refunds',
        value: 'total_refund',
        isFormated: true,
        width: '150'
    },
    {
        label: 'Cancellation',
        value: 'cancellation_amount',
        isFormated: true,
        width: '150'
    },
    {
        label: 'Over Due Tasks',
        value: 'over_due_tasks',
        width: '180'
    },
    {
        label: 'Tasks Today',
        value: 'todays_task_count',
        width: '180'
    },
    {
        label: 'Open Tasks in this Month',
        value: 'current_month_tasks',
        width: '250'
    },
    {
        label: 'Open Tasks in previous Months',
        value: 'previous_month_tasks',
        width: '280'
    },
]

const underwriter = [
    {
        title: '',
        items: [
            {
                title: 'Policy Assigned',
                value: 'assigned',
                icon: 'icon-svg icon-policy-assigned',
                color: '#f1416c'
            },
        
            {
                title: 'Policy Completed',
                value: 'completed',
                icon: 'icon-svg icon-policy-completed',
                color: '#f1416c'
            },
        
            {
                title: 'Assigned Status in Previous Months',
                value: 'assigned_prev_months',
                icon: 'icon-svg icon-policy-assigned',
                color: '#f1416c'
            },
        
            
        ]
    },
    {
        title: '',
        items: [
            {
                title: 'Cancellation Amount',
                value: 'cancellation_amount',
                icon: 'icon-svg icon-revenue',
                color: '#f1416c'
            },
        
            {
                title: 'Full Cancellation Amount',
                value: 'full_cancellation_amount',
                icon: 'icon-svg icon-revenue',
                color: '#f1416c'
            },
        
            {
                title: 'Partial Cancellation Amount',
                value: 'partial_cancellation_amount',
                icon: 'icon-svg icon-revenue',
                color: '#f1416c'
            }
        ]
    },
    {
        title: '',
        items: [
            {
                title: 'Total Cancellations',
                value: 'cancellation',
                icon: 'icon-svg icon-policies-cancelled',
                color: '#f1416c'
            },
        
            {
                title: 'Full Cancellations Total',
                value: 'full_cancellation',
                icon: 'icon-svg icon-total-cancellation',
                color: '#f1416c'
            },
        
            {
                title: 'Partial Cancellations Total',
                value: 'partial_cancellation',
                icon: 'icon-svg icon-partial-cancellation',
                color: '#f1416c'
            }
        ]
    },
]

const accountants = [
    {
        title: 'Approval For',
        isLoop: false,
        items: [
            {
                title: 'Online / Suyool / COD / Direct / Cash Deposit / OMT / Cheque Invoices',
                value: 'cod_direct_cheque',
                icon: 'icon-svg icon-invoices',
                color: '#f1416c'
            },
           
            {
                title: 'Cancellations',
                value: 'cancellation_approval',
                icon: 'icon-svg icon-cancelled',
                color: '#f1416c'
            },
            {
                title: 'Refunds',
                value: 'refunds_pending',
                icon: 'icon-svg icon-refund',
                color: '#9800dc'
            },
        ]
    },
    {
        title: 'Policy Status',
        isLoop: false,
        items: [
            {
                title: 'Policy Assigned',
                value: 'assigned',
                icon: 'icon-svg icon-policy-assigned',
                color: '#3ad8f9'
            },
            {
                title: 'Policy Completed',
                value: 'policies_completed',
                icon: 'icon-svg icon-policy-completed',
                color: '#3ad8f9'
            },
        ]
    },
    {
        title: 'Deals Status',
        isLoop: false,
        items: [
            {
                title: 'Total Deals',
                value: 'total_deals',
                icon: 'icon-svg icon-total-sales',
                color: '#f1416c'
            },
            {
                title: 'Total Revenue',
                value: 'total_deals_revenue',
                icon: 'icon-svg icon-total-revenue',
                color: '#f1416c'
            },
            {
                title: 'Total Cancelled',
                value: 'total_deals_cancelled',
                icon: 'icon-svg icon-policies-cancelled',
                color: '#f1416c'
            },
            {
                title: 'Total Cancelled Amount',
                value: 'total_deals_cancelled_revenue',
                icon: 'icon-svg icon-total-revenue',
                color: '#f1416c'
            },
        ]
    },
    {
        title: 'Cancellation Refund Info',
        isLoop: true,
        items: [
            {
                children: [
                    {
                        title: 'Total No. of Cancellations',
                        value: 'cancelled',
                        icon: 'icon-svg icon-policies-cancelled',
                        color: '#f4516c'
                    },
                    {
                        title: 'Cancellations Amount',
                        value: 'cancelled_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    },
                    {
                        title: 'Policies Partially Cancelled',
                        value: 'cancelled_partial',
                        icon: 'icon-svg icon-partial-cancellation',
                        color: '#f4516c'
                    },
                    {
                        title: 'Partial Cancellations Amount',
                        value: 'cancelled_partial_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    },
                    {
                        title: 'Policies Fully Cancelled',
                        value: 'cancelled_full',
                        icon: 'icon-svg icon-total-cancellation',
                        color: '#f4516c'
                    },
                    {
                        title: 'Full Cancellations Amount',
                        value: 'cancelled_full_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    }
                ]
            },
            {
                children: [
                    {
                        title: 'Total No. of Refunds',
                        value: 'cod_direct_intransact',
                        icon: 'icon-svg icon-refund',
                        color: '#9800dc'
                    },
                    {
                        title: 'Refunds Amount',
                        value: 'completed_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    },
                    {
                        title: 'Partial Refunds',
                        value: 'completed_partial',
                        icon: 'icon-svg icon-partial-cancellation',
                        color: '#9800dc'
                    },
                    {
                        title: 'Partial Refunds Amount',
                        value: 'completed_partial_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    },
                    {
                        title: 'Full Refunds',
                        value: 'completed_full',
                        icon: 'icon-svg icon-total-cancellation',
                        color: '#9800dc'
                    },
                    {
                        title: 'Full Refunds Amount',
                        value: 'completed_full_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    }
                ]
            },
            {
                children: [
                    {
                        title: 'Refund Closed',
                        value: 'completed_closed',
                        icon: 'icon-svg icon-refund-closed',
                        color: '#9800dc'
                    },
                    {
                        title: 'Refund Closed Amount',
                        value: 'completed_closed_amount',
                        icon: 'icon-svg icon-revenue',
                        color: '#34bfa3'
                    }
                ]
            }
        ]
    },
]

const marketing = [
    {
        label:'Paid vs Organic Leads',
        div_class:'col-sm-4',
        value:'paid_vs_organic_leads',
        type:'new'
    },
    {
        label:'Paid vs Organic Deals',
        div_class:'col-sm-4',
        value:'paid_vs_organic_deals',
        type:'new'
    },
    {
        label:'Paid vs Organic WIP Deals',
        div_class:'col-sm-4',
        value:'paid_vs_organic_deals',
        type:'wip'
    },
    {
        label:'Paid Leads By UTM Source',
        div_class:'col-sm-6',
        value:'paid_utm_source_leads',
        type:'new'
    },
    {
        label:'Paid Deals By UTM Source',
        div_class:'col-sm-6',
        value:'paid_utm_source_deals',
        type:'new'
    },
    {
        label:'Organic Leads By UTM Source',
        div_class:'col-sm-6',
        value:'organic_utm_source_leads',
        type:'new'
    },
    {
        label:'Organic Deals By UTM Source',
        div_class:'col-sm-6',
        value:'organic_utm_source_deals',
        type:'new'
    },
    {
        label:'Leads By Lead Source',
        div_class:'col-sm-6',
        value:'lead_source_leads',
        type:'new'
    },
    {
        label:'Deals By Lead Source',
        div_class:'col-sm-6',
        value:'lead_source_deals',
        type:'new'
    },
    {
        label:'Paid Leads By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_leads',
        type:'new'
    },
    {
        label:'Paid Deals By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_deals',
        type:'new'
    },
    {
        label:'Paid Leads By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_leads',
        type:'new'
    },
    {
        label:'Paid Deals By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_deals',
        type:'new'
    },
    {
        label:'Paid WIP Deals By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_deals',
        type:'wip'
    },
    {
        label:'Organic WIP Deals By UTM Campaign',
        div_class:'col-sm-6',
        value:'organic_utm_campaign_deals',
        type:'wip'
    },
    {
        label:'Paid WIP Deals By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_deals',
        type:'wip'
    },
    {
        label:'Organic WIP Deals By UTM Content',
        div_class:'col-sm-6',
        value:'organic_utm_content_deals',
        type:'wip'
    },
    {
        label:'Paid Renewal Leads By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_leads',
        type:'renewal'
    },
    {
        label:'Paid Renewal Deals By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_deals',
        type:'renewal'
    },
    {
        label:'Paid LLR Leads By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_leads',
        type:'llr'
    },
    {
        label:'Paid LLR Deals By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_deals',
        type:'llr'
    },
    {
        label:'Paid Renewal Leads By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_leads',
        type:'renewal'
    },
    {
        label:'Paid Renewal Deals By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_deals',
        type:'renewal'
    },
    {
        label:'Paid LLR Leads By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_leads',
        type:'llr'
    },
    {
        label:'Paid LLR Deals By UTM Content',
        div_class:'col-sm-6',
        value:'paid_utm_content_deals',
        type:'llr'
    },
    {
        label:'Deleted Paid Leads By UTM Campaign',
        div_class:'col-sm-6',
        value:'paid_utm_campaign_leads',
        type:'deleted'
    }
]

const marketingCounts = [
    {
        label: 'Leads By Source',
        value: 'lead_by_source',
        deleted: 'lead_by_source_deleted'
    },
    {
        label: 'Deals By Source',
        value: 'deal_by_source',
    }
]

export {
    marketingCounts,
    marketing,
    accountants,
    underwriter,
    team_summaries,
    total_sales,
    new_sales,
    wip_sales,
    renewal_sales,
    llr_sales,
    lrl_sales,
    lr_sales,
    untouched_sales,
    refunds,
    cancellation,
    total_revenue,
    top_sales_person,
    sales_target_summary,
    leads_target_summary,
    utm_stats,
    table_stats,
    table_others
}