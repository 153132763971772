import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dashboard-section" }
const _hoisted_2 = { class: "container ps-2 pe-2" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 p-1" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = {
  key: 0,
  class: "card-body card-custom-spacing-tblf-10 bg-white w-100"
}
const _hoisted_7 = { class: "table-custom-invoice" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "col-sm-12 mt-6 p-1"
}
const _hoisted_10 = { class: "card mt-5" }
const _hoisted_11 = {
  key: 0,
  class: "card-body card-custom-spacing-tblf-10 bg-white w-100"
}
const _hoisted_12 = { class: "pt-0 p-0" }
const _hoisted_13 = { class: "table-custom-invoice" }
const _hoisted_14 = {
  key: 1,
  class: "col-sm-6 mt-6 p-1"
}
const _hoisted_15 = { class: "card mt-5" }
const _hoisted_16 = {
  key: 0,
  class: "card-body card-custom-spacing-tblf-10 bg-white w-100"
}
const _hoisted_17 = { class: "pt-0 p-0" }
const _hoisted_18 = {
  class: "table-responsive table-simple-dash",
  id: "displayResponseData"
}
const _hoisted_19 = { class: "table-custom-invoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "card-header boder-0 pt-5 w-100",
              style: `background-color: #36a3f7 !important;`
            }, [
              _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
                _createElementVNode("span", {
                  class: "card-label fw-bolder fs-3 mb-1",
                  style: `color: #ffffff !important;`
                }, " Top Sales Person ")
              ])
            ], -1)),
            (_ctx.topSalesAgent)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_el_table, { data: _ctx.topSalesAgent }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.top_sales_person, (row, i) => {
                          return (_openBlock(), _createBlock(_component_el_table_column, {
                            key: i,
                            width: row.width
                          }, {
                            header: _withCtx(() => [
                              _createElementVNode("span", {
                                class: "d-block fs-6 fw-bolder",
                                innerHTML: row.label
                              }, null, 8, _hoisted_8)
                            ]),
                            default: _withCtx((scope) => [
                              _createElementVNode("span", null, _toDisplayString(scope.row[row.value]), 1)
                            ]),
                            _: 2
                          }, 1032, ["width"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["data"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.user.role_id != 7)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[1] || (_cache[1] = _createElementVNode("div", {
                  class: "card-header boder-0 pt-5 w-100",
                  style: `background-color: #36a3f7 !important;`
                }, [
                  _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
                    _createElementVNode("span", {
                      class: "card-label fw-bolder fs-3 mb-1",
                      style: `color: #ffffff !important;`
                    }, " Sales Target Summary ")
                  ])
                ], -1)),
                (_ctx.salesTarget)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_el_table, { data: _ctx.salesTarget }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sales_target_summary, (row, i) => {
                                return (_openBlock(), _createBlock(_component_el_table_column, {
                                  key: i,
                                  label: row.label,
                                  width: row.width
                                }, {
                                  default: _withCtx((scope) => [
                                    _createTextVNode(_toDisplayString(scope.row[row.value]), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label", "width"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["data"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.user.role_id != 7 && false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[2] || (_cache[2] = _createElementVNode("div", {
                  class: "card-header boder-0 pt-5 w-100",
                  style: `background-color: #36a3f7 !important;`
                }, [
                  _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
                    _createElementVNode("span", {
                      class: "card-label fw-bolder fs-3 mb-1",
                      style: `color: #ffffff !important;`
                    }, " Leads Target Summary ")
                  ])
                ], -1)),
                (_ctx.leadsTarget)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_el_table, { data: _ctx.leadsTarget }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads_target_summary, (row, i) => {
                                  return (_openBlock(), _createBlock(_component_el_table_column, {
                                    key: i,
                                    label: row.label,
                                    width: row.width
                                  }, {
                                    default: _withCtx((scope) => [
                                      _createElementVNode("span", null, _toDisplayString(scope.row[row.value]), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "width"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["data"])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}