import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "flags-block" }
const _hoisted_2 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        md: 16,
        sm: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { gutter: 10 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                md: 8,
                sm: 16
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.filter.customer_status_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.customer_status_id) = $event)),
                    onChange: _ctx.updateValue,
                    label: "Please select user/Customer Type",
                    filterable: "",
                    size: "large",
                    class: "el-h-44",
                    "no-match-text": "No customer type found"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTypes, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                md: 4,
                sm: 4
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_tooltip, {
                      class: "box-item",
                      effect: "light",
                      content: "Hot Enquiry",
                      placement: "bottom-start"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", {
                          type: "button",
                          style: {"border-radius":"0"},
                          class: _normalizeClass(["btn btn-light-info btn-light-lead-filter", _ctx.filter.hot_enquiry > 0 ? 'active' : '']),
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangeHotEnquiry && _ctx.onChangeHotEnquiry(...args)))
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("i", { class: "bi bi-flag-fill text-danger icon-size" }, null, -1)
                        ]), 2)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        md: 8,
        sm: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 10,
            style: {"float":"right"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { md: 24 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.filter.keyword,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.keyword) = $event)),
                      placeholder: "Search User/Customer",
                      "prefix-icon": _ctx.Search,
                      size: "large",
                      onKeydown: _withKeys(_withModifiers(_ctx.searchCustomer, ["prevent"]), ["enter"])
                    }, null, 8, ["modelValue", "prefix-icon", "onKeydown"]),
                    _createElementVNode("button", {
                      type: "button",
                      style: {"border-radius":"0"},
                      class: "btn btn-light-info cursor-pointer ms-3",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchCustomer && _ctx.searchCustomer(...args)))
                    }, " Search "),
                    _createElementVNode("button", {
                      type: "button",
                      style: {"border-radius":"0"},
                      class: "btn btn-light-info ms-3",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.resetFilter && _ctx.resetFilter(...args)))
                    }, " Reset ")
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}