import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["data-kt-indicator", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn btn-info",
    "data-kt-indicator": _ctx.loading ? 'on' : null,
    type: "submit",
    disabled: _ctx.loading || _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit')))
  }, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass(_ctx.icon)
              }, null, 2))
            : _createCommentVNode("", true),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[2] || (_cache[2] = [
          _createTextVNode(" Please wait... "),
          _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}