import { store } from "@/store";
import { App } from "vue";
import { EtcActionTypes } from '@/store/enums/Etc/Actions';
import { EtcMutationTypes } from '@/store/enums/Etc/Mutations';

class Etcs {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Etcs.vueInstance = app;
    }

    public static async getRoutes() {
        return await store.dispatch(EtcActionTypes.ROUTES, {
            type: "health"
        });
    }

    public static async getCountryCode() {
        return await store.dispatch(EtcActionTypes.COUNTRY_CODE);
    }

    public static async getAgentLists(aType = 'dom') {
        return await store.dispatch(EtcActionTypes.AGENT_LISTS, {
            type: 'Health',
            aType:aType
        });
    }

    public static async getUnderwriterLists() {
        return await store.dispatch(EtcActionTypes.UNDERWRITERS_LISTS);
    }

    public static async getCountries(payload) {
        return await store.dispatch(EtcActionTypes.COUNTRIES, payload);
    }

    public static async getRelationships(payload) {
        return await store.dispatch(EtcActionTypes.RELATIONSHIPS, payload)
    }

    public static async getEmirates(payload) {
        return await store.dispatch(EtcActionTypes.EMIRATES, payload);
    }

    public static async getInsuredTypes(payload) {
        return await store.dispatch(EtcActionTypes.INSURED_TYPES, payload);
    }

    public static async getInsuranceProviders(payload) {
        return await store.dispatch(EtcActionTypes.INSURANCE_PROVIDERS, payload);
    }

    public static async getInsurancePolicies(payload) {
        return await store.dispatch(EtcActionTypes.INSURANCE_PLANS, payload);
    }

    public static async getPlans() {
        return await store.dispatch(EtcActionTypes.PLANS);
    }

    public static async getApps() {
        return await store.dispatch(EtcActionTypes.APPS);
    }

    public static async getUserType() {
        return await store.dispatch(EtcActionTypes.USER_TYPES);
    }

    public static setRelationships(value) {
        if(value) {
            store.state.etc.relationships.push({
                value: 3, label: "Sponsor"
            });
        } else {
            // store.state.etc.relationships.splice(2, 1);
        }
    }

    public static async getLeadSources() {
        return await store.dispatch(EtcActionTypes.LEAD_SOURCES);
    }

    public static async checkExistNumber(payload) {
        return await store.dispatch(EtcActionTypes.CUSTOMER_ALREADY_EXIST, payload);
    }

    public static setExistCustomer(payload) {
        store.commit(EtcMutationTypes.SET_CUSTOMER_ALREADY_EXIST, payload);
    }

    public static async getEmployeeCategories() {
        return await store.dispatch(EtcActionTypes.EMPLOYEE_CATEGORIES);
    }

    public static async getAllTasks() {
        return await store.dispatch(EtcActionTypes.TASK_LISTS);
    }

    public static async getAllLeadReasons() {
        return await store.dispatch(EtcActionTypes.LEAD_REASONS);
    }

    public static async getAllRoles() {
        return await store.dispatch(EtcActionTypes.ROLES);
    }

    public static async getBasmah(payload) {
        return await store.dispatch(EtcActionTypes.BASMAH, payload);
    }

    public static async getVat() {
        return await store.dispatch(EtcActionTypes.GET_VAT);
    }

    public static async getServiceFee() {
        return await store.dispatch(EtcActionTypes.GET_SERVICE_FEE);
    }


    public static async globalSearch(payload) {
        return await store.dispatch(EtcActionTypes.GLOBAL_SEARCH, payload);
    }

    public static async getRecentNotifications(payload) {
        payload['type'] = 'health'
        return await store.dispatch(EtcActionTypes.GET_RECENT_NOTIFICATIONS, payload);
    }

    public static async updateNotificationClick(payload) {
        payload['type'] = 'health'
        return await store.dispatch(EtcActionTypes.UPDATE_NOTIFICATIONS_CLICK, payload);
    }

    public static async readNotification() {
        const payload = {
            type:'health'
        }
        return await store.dispatch(EtcActionTypes.READ_NOTIFICATION,payload);
    }

    public static async getUnreadNotificationCount() {
        const payload = {
            type:'health'
        }
        return await store.dispatch(EtcActionTypes.GET_NOTIFICATION_COUNT, payload);
    }

    public static async getAllNotifications(payload) {
        return await store.dispatch(EtcActionTypes.GET_ALL_NOTIFICATIONS, payload)
    }

    public static async getGroupSize(payload) {
        return await store.dispatch(EtcActionTypes.GET_GROUP_SIZE, payload);
    }

    public static async getSettingVal(payload) {
        return await store.dispatch(EtcActionTypes.GET_HEALTH_SETTING,payload);
    }


    public static async autoSuggestQuote(payload) {
        return await store.dispatch(EtcActionTypes.AUTO_SUGGEST_QUOTE,payload);
    }
}

export default Etcs;