import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "leads-policy nav nav-tabs nav-line-tabs mb-5 fs-5" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "text-italic fw-boldest" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "text-italic fw-boldest" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "text-italic fw-boldest" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "text-italic fw-boldest" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "text-italic fw-boldest" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { class: "leads nav-link alert bg-light-danger align-items-center leads-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    gutter: 10,
    class: "filters-second-row"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        md: 24,
        lg: 24
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            _createElementVNode("li", _hoisted_2, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", {'active' : _ctx.filter.task_due_dates == 'all'}]),
                "data-bs-toggle": "tab",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabChange('all')))
              }, [
                _cache[5] || (_cache[5] = _createTextVNode("All")),
                _createElementVNode("span", _hoisted_3, " (" + _toDisplayString(_ctx.policyCount.all_count) + ")", 1)
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", {'active' : _ctx.filter.task_due_dates == 'today'}]),
                "data-bs-toggle": "tab",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabChange('today')))
              }, [
                _cache[6] || (_cache[6] = _createTextVNode(" Today ")),
                _createElementVNode("span", _hoisted_5, " (" + _toDisplayString(_ctx.policyCount.today_count) + ")", 1)
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", {'active' : _ctx.filter.task_due_dates == 'yesterday'}]),
                "data-bs-toggle": "tab",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.tabChange('yesterday')))
              }, [
                _cache[7] || (_cache[7] = _createTextVNode(" Yesterday ")),
                _createElementVNode("span", _hoisted_7, " (" + _toDisplayString(_ctx.policyCount.yesterday_count) + ")", 1)
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", {'active' : _ctx.filter.task_due_dates == 'last_30_days'}]),
                "data-bs-toggle": "tab",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.tabChange('last_30_days')))
              }, [
                _cache[8] || (_cache[8] = _createTextVNode(" Last 30 days ")),
                _createElementVNode("span", _hoisted_9, " (" + _toDisplayString(_ctx.policyCount.last30_count) + ")", 1)
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link cursor-pointer", {'active' : _ctx.filter.task_due_dates == 'older_than_30_days'}]),
                "data-bs-toggle": "tab",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.tabChange('older_than_30_days')))
              }, [
                _cache[9] || (_cache[9] = _createTextVNode(" Older > 30 days ")),
                _createElementVNode("span", _hoisted_11, " (" + _toDisplayString(_ctx.policyCount.older30_count) + ")", 1)
              ], 2)
            ]),
            _createElementVNode("li", _hoisted_12, [
              _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.lists ? _ctx.lists.total : 0) + " records ", 1)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}