import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "m-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_compose_emails = _resolveComponent("vc-customer-compose-emails")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_customer_email_data_value = _resolveComponent("vc-customer-email-data-value")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modal(false))),
    fullscreen: "",
    class: "emails-dialog"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Emails ", -1)),
        _createVNode(_component_vc_customer_compose_emails, {
          customer_id: _ctx.customer_id,
          from: _ctx.from,
          to: _ctx.to,
          ref: "compose_emails"
        }, null, 8, ["customer_id", "from", "to"]),
        (_ctx.to)
          ? (_openBlock(), _createBlock(_component_vc_button, {
              key: 0,
              className: "me-12 btn btn-md btn-info",
              text: "New Email",
              "is-regular": "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.compose_emails.modal(true)))
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[4] || (_cache[4] = [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.emails.length > 0)
              ? (_openBlock(), _createBlock(_component_vc_customer_email_data_value, { key: 0 }))
              : (_openBlock(), _createBlock(_component_el_empty, {
                  key: 1,
                  description: "No Records Available"
                }))
          ]))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}