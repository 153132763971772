import { store } from "@/store";
const vatPresent = store.getters.getIsVatSet
export const salesOptions = [
    {
      value: 'total-sales', 
      label: 'Total Sales'
    },
    {
      value: 'total-new-sales', 
      label: 'New Sales'
    },
    {
      value: 'total-renewal-sales', 
      label: 'Renewal Sales'
    },
    {
      value: 'total-llr-sales', 
      label: 'Lost Lead Renewal Sales'
    },
    {
      value: 'lifetime-renewal-leads', 
      label: 'Current Year Renewal Sales'
    },
]

export const leadsOptions = [
    {
      value: 'total-leads', 
      label: 'Total Leads'
    },
    {
      value: 'total-new-leads', 
      label: 'New Leads'
    },
    {
      value: 'total-renewal-leads', 
      label: 'Renewal Leads'
    },
    {
      value: 'total-llr-leads', 
      label: 'Lost Lead Renewals'
    },
    {
      value: 'total-lost-leads', 
      label: 'Lost Leads'
    },
    {
      value: 'total-lrl-leads', 
      label: 'Lead Renewal Lost'
    },
    {
      value: 'lifetime-renewal-leads', 
      label: 'Previous Year Renewal Leads'
    },
];

export const dashboard_details_table = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''
  },
  
  {
    label: 'Customer ID',
    value: 'customer_id',
    isFixed: true,
    width: "140",
    cn: ''
  },
  {
    label: 'Name',
    value: 'name',
    isFixed: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Email',
    value: 'email',
    isFixed: true,
    width: "200",
    cn: ''
  },
  {
    label: 'Mobile',
    value: 'mobile',
    width: "160",
    cn: ''
  },
  {
    label: 'Lead Source',
    value: 'lead_source',
    width: "180",
    cn: ''
  },
  // {
  //   label: 'Lead Score',
  //   value: 'lead_score'
  // },
  {
    label: 'Lead Status',
    value: 'lead_status_text',
    width: "180",
    cn: 'fw-bolder fw-6 badge',
    isMixin: true
  },
  {
    label: 'Sales Agent',
    value: 'sales_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Other Lead Sources',
    value: 'other_lead_sources',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Source',
    value: 'utm_source',
    width: "180",
    cn: ''
  },
  {
    label: 'UTM Medium',
    value: 'utm_medium',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Medium',
    value: 'other_utm_medium',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Campaign',
    value: 'utm_campaign',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Campaign',
    value: 'other_utm_campaign',
    width: "200",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Content',
    value: 'utm_content',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Content',
    value: 'other_utm_content',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'Lead ID',
    value: 'lead_id',
    width: "180",
    cn: ''
  },
  // {
  //   label: 'Policy for', // individual or group
  //   value: 'policy_type',
  //   width: "180",
  //   cn: ''
  // },
  {
    label: 'Insured Class',
    value: 'insured_for',
    width: "180",
    cn: ''
  },
  // {
  //   label: 'Insurance type', // enhance basic
  //   value: 'insurance_type',
  //   width: "180",
  //   cn: ''
  // },
  
  {
    label: 'Relationship',
    value: 'relationship',
    width: "180",
    cn: ''
  },
  {
    label: 'Policy Price ($)',
    value: 'policy_price',
    width: "180",
    cn: ''
  },
  {
    label: 'Created On',
    value: 'created_on',
    width: "180",
    isFormat: true,
    cn: ''
  },
  {
    label: 'Updated On',
    value: 'updated_on',
    width: "180",
    isFormat: true,
    cn: ''
  },
]


export const agent_sales_table = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''  
  },
  {
    label: 'Created On',
    value: 'created_at',
    isFixed: true,
    width: "140",
    cn: ''  
  },
  {
    label: 'Quote Reference No',
    value: 'merchant_reference',
    isFixed: true,
    width: "200",
    cn: ''  
  },
  {
    label: 'Invoice <br/> Created By',
    value: 'created_by',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy <br/> Created On',
    value: 'policy_created_at',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy <br/> Completed Date',
    value: 'issue_date',
    isFormat:true,
    width: "180",
    cn: ''  
  },
  {
    label: 'Policy  Price  ($)',
    value: 'policy_price',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy <br/> Price (VAT)  ($)',
    value: 'policy_price_vat',
    width: "140",
    cn: '',
    notShow:  !vatPresent  
  },
    {
    label: 'Sale Price / <br/> Invoice Amount ($) ',
    value: 'sales_price',
    width: "180",
    cn: ''  
  },
  {
    label: 'Sale Price (VAT) ($)',
    value: 'sales_price_vat',
    width: "140",
    cn: '' ,
    notShow:  !vatPresent 
  },
  {
    label: 'Transaction Type',
    value: 'transaction_type_text',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Sales Agent',
    value: 'policy_sales_agent',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Status',
    value: 'policy_status',
    width: "140",
    cn: ''  
  },
  {
    label: 'Debit Note  ($)',
    value: 'debit_amount',
    width: "140",
    cn: ''  
  },
  {
    label: 'Debit Note (VAT) ($)',
    value: 'debit_amount_vat',
    width: "140",
    cn: '',
    notShow:  !vatPresent  
  },
  {
    label: 'Policy Name',
    value: 'policy_name',
    width: "140",
    cn: ''  
  },
  // {
  //   label: 'Policy Type',
  //   value: 'insurance_type',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Policy No',
    value: 'policy_number',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Start Date',
    value: 'policy_start_date_format',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy End Date',
    value: 'policy_end_date_format',
    width: "140",
    cn: ''  
  },
  {
    label: 'Lead Details',
    value: 'lead_details',
    width: "340",
    cn: ''  
  },
  {
    label: 'Underwriter',
    value: 'underwriter',
    width: "140",
    cn: ''  
  },
]
  
export const deleted_leads_table = [
  {
      label: 'Sr. #',
      value: 'index',
      isIndex: true,
      isFixed: true,
    cn: ''
  },
  {
    label: 'Lead Created On',
    value: 'lead_created_on',
    isFixed: true,
    isFormat: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Assigned To',
    value: 'lead_assigned_to',
    width: "200",
    cn: ''
  },
  {
    label: 'Lead Status',
    value: 'lead_status_text',
    width: "180",
    cn: ''
  },
  {
    label: 'Deleted Reason',
    value: 'deleted_reason',
    width: "200",
    cn: ''
  },
  {
    label: 'Lead Deleted On',
    value: 'lead_deleted_on',
    isFormat: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Deleted By',
    value: 'lead_deleted_by',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Source',
    value: 'lead_source',
    width: "180",
    cn: ''
  },
  {
    label: 'Customer Name',
    value: 'name',
    width: "180",
    cn: ''
  },
  {
    label: 'Email',
    value: 'email',
    width: "200",
    cn: ''
  },
  {
    label: 'Phone Number',
    value: 'mobile',
    width: "160",
    cn: ''
  },
  // {
  //   label: 'Policy for', // individual or group
  //   value: 'policy_type',
  //   width: "180",
  //   cn: ''
  // },
  {
    label: 'Insured Class',
    value: 'insured_for',
    width: "180",
    cn: ''
  },
  // {
  //   label: 'Insurance type', // enhance basic
  //   value: 'insurance_type',
  //   width: "180",
  //   cn: ''
  // },
  
  {
    label: 'Relationship',
    value: 'relationship',
    width: "180",
    cn: ''
  },
  {
    label: 'Other Lead Sources',
    value: 'other_lead_sources',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Source',
    value: 'utm_source',
    width: "180",
    cn: ''
  },
  {
    label: 'UTM Medium',
    value: 'utm_medium',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Medium',
    value: 'other_utm_medium',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Campaign',
    value: 'utm_campaign',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Campaign',
    value: 'other_utm_campaign',
    width: "200",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Content',
    value: 'utm_content',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Content',
    value: 'other_utm_content',
    width: "180",
    isCount: true,
    cn: ''
  }

]


export const policies_report = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''
  },
  {
    label: 'Created On',
    value: 'created_at',
    isFixed: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Quote Reference No',
    value: 'merchant_reference',
    width: "200",
    cn: ''
  },
  {
    label: 'Policy Status',
    value: 'status_text',
    width: "180",
    cn: ''
  },
  {
    label: 'Underwriter',
    value: 'underwriter',
    width: "200",
    cn: ''
  },
  {
    label: 'Sales Agent',
    value: 'sales_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Transaction Type',
    value: 'transaction_type',
    width: "180",
    cn: ''
  },
  {
    label: 'Policy Name',
    value: 'policy_name',
    width: "140",
    cn: ''  
  },
  // {
  //   label: 'Policy Type',
  //   value: 'insurance_type',
  //   width: "140",
  //   cn: '' ,
  // },
  {
    label: 'Policy No',
    value: 'policy_number',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price ($)',
    value: 'policy_price',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price (VAT) ($)',
    value: 'sales_price_vat',
    width: "140",
    cn: '' , 
    notShow:  !vatPresent
  },
  {
    label: 'Policy Start Date',
    value: 'policy_start_date_format',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Expiry Date',
    value: 'policy_end_date_format',
    width: "140",
    cn: ''  
  },
  {
    label: 'Lead Details',
    value: 'lead_details',
    width: "340",
    cn: ''  
  },
]

export const policies_group_report = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''
  },
  {
    label: 'Created On',
    value: 'created_at',
    isFixed: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Quote Reference No',
    value: 'merchant_reference',
    width: "200",
    cn: ''
  },
  {
    label: 'Policy Status',
    value: 'status_text',
    width: "180",
    cn: ''
  },
  {
    label: 'Underwriter',
    value: 'underwriter',
    width: "200",
    cn: ''
  },
  {
    label: 'Sales Agent',
    value: 'sales_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Transaction Type',
    value: 'transaction_type',
    width: "180",
    cn: ''
  },
  {
    label: 'Policy Price ($)',
    value: 'policy_price',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price (VAT) ($)',
    value: 'sales_price_vat',
    width: "140",
    cn: '' ,
    notShow:  !vatPresent 
  },
  {
    label: 'Policy Start Date',
    value: 'policy_start_date_format',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Expiry Date',
    value: 'policy_end_date_format',
    width: "140",
    cn: ''  
  },
  {
    label: 'Lead Details',
    value: 'lead_details',
    width: "340",
    cn: ''  
  },
]

export const admin_report = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''
  },
  {
    label: 'Months-Year',
    value: 'name',
    width: "140",
    isFixed: true,
    cn: 'text-bold'
  },
  {
    label: 'Total Sales',
    value: 'total_sale',
    width: "140",
    cn: ''
  },
  {
    label: 'Total Leads',
    value: 'total_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Closing Ratio%',
    value: 'total_closing_ratio',
    width: "140",
    cn: ''
  },
  {
    label: 'Basic',
    value: 'total_basic',
    width: "140",
    cn: ''
  },
  {
    label: 'Enhanced',
    value: 'total_enhance',
    width: "140",
    cn: ''
  },
  {
    label: 'Group',
    value: 'total_group',
    width: "140",
    cn: ''
  },
  {
    label: 'Total Revenue ($)',
    value: 'total_revenue',
    width: "140",
    isNumber: true,
    cn: ''
  },
  {
    label: 'New Sales',
    value: 'new_sale',
    width: "140",
    cn: ''
  },
  {
    label: 'New Leads',
    value: 'new_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Closing Ratio%',
    value: 'new_closing_ratio',
    width: "140",
    cn: ''
  },
  {
    label: 'Basic',
    value: 'new_basic',
    width: "140",
    cn: ''
  },
  {
    label: 'Enhanced',
    value: 'new_enhance',
    width: "140",
    cn: ''
  },
  {
    label: 'Group',
    value: 'new_group',
    width: "140",
    cn: ''
  },
  {
    label: 'Revenue ($)',
    value: 'new_revenue',
    width: "140",
    isNumber: true,
    cn: ''
  },

  {
    label: 'WIP Sales',
    value: 'wip_sale',
    width: "140",
    cn: ''
  },
  
  {
    label: 'Basic',
    value: 'wip_basic',
    width: "140",
    cn: ''
  },
  {
    label: 'Enhanced',
    value: 'wip_enhance',
    width: "140",
    cn: ''
  },
  {
    label: 'Group',
    value: 'wip_group',
    width: "140",
    cn: ''
  },
  {
    label: 'Revenue ($)',
    value: 'wip_revenue',
    width: "140",
    isNumber: true,
    cn: ''
  },
  {
    label: 'Renewal Sales',
    value: 'renewal_sale',
    width: "140",
    cn: ''
  },
  {
    label: 'Renewal Leads',
    value: 'renewal_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Closing Ratio%',
    value: 'renewal_closing_ratio',
    width: "140",
    cn: ''
  },
  {
    label: 'Basic',
    value: 'renewal_basic',
    width: "140",
    cn: ''
  },
  {
    label: 'Enhanced',
    value: 'renewal_enhance',
    width: "140",
    cn: ''
  },
  {
    label: 'Group',
    value: 'renewal_group',
    width: "140",
    cn: ''
  },
  {
    label: 'Revenue ($)',
    value: 'renewal_revenue',
    width: "140",
    isNumber: true,
    cn: ''
  },

  {
    label: 'Lost Lead Renewal Sales',
    value: 'llr_sale',
    width: "140",
    cn: ''
  },
  {
    label: 'Lost Lead Renewal Leads',
    value: 'llr_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Closing Ratio%',
    value: 'llr_closing_ratio',
    width: "140",
    cn: ''
  },
  {
    label: 'Basic',
    value: 'llr_basic',
    width: "140",
    cn: ''
  },
  {
    label: 'Enhanced',
    value: 'llr_enhance',
    width: "140",
    cn: ''
  },
  {
    label: 'Group',
    value: 'llr_group',
    width: "140",
    cn: ''
  },
  {
    label: 'Revenue ($)',
    value: 'llr_revenue',
    width: "140",
    isNumber: true,
    cn: ''
  },

  {
    label: 'Lost Leads',
    value: 'lost_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Lead Renewal Lost',
    value: 'lrl_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'HE',
    value: 'hot_enquiry',
    width: "140",
    cn: ''
  },
  {
    label: 'WIP',
    value: 'work_in_progress',
    width: "140",
    cn: ''
  },
  {
    label: 'Current Year Renewal Sales',
    value: 'ltr_sale',
    width: "140",
    cn: ''
  },
  {
    label: 'Previous Year Renewal Leads',
    value: 'ltr_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Closing Ratio%',
    value: 'ltr_closing_ratio',
    width: "140",
    cn: ''
  },
  {
    label: 'Basic',
    value: 'ltr_basic',
    width: "140",
    cn: ''
  },
  {
    label: 'Enhanced',
    value: 'ltr_enhance',
    width: "140",
    cn: ''
  },
  {
    label: 'Group',
    value: 'ltr_group',
    width: "140",
    cn: ''
  },
  {
    label: 'Revenue ($)',
    value: 'ltr_revenue',
    width: "140",
    isNumber: true,
    cn: ''
  },
  {
    label: 'Untouched Customers',
    value: 'untouched_customers',
    width: "140",
    cn: ''
  },
  {
    label: 'Untouched Renewal Leads',
    value: 'untouched_renewal_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Untouched New Leads',
    value: 'untouched_new_leads',
    width: "140",
    cn: ''
  },
  {
    label: 'Untouched LLR',
    value: 'untouched_llr',
    width: "140",
    cn: ''
  },
  {
    label: 'Total Revenue (incl. Amendments) ($)',
    value: 'total_revenue_inc_addons',
    width: "140",
    isNumber: true,
    cn: ''
  },
  {
    label: 'Amendments ($)',
    value: 'addons',
    width: "140",
    isNumber: true,
    cn: ''
  },

]

export const approved_invoices_table = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''  
  },
  {
    label: 'Quote Ref No',
    value: 'merchant_reference',
    isFixed: true,
    width: "200",
    cn: ''  
  },
  
  {
    label: 'Invoice Type',
    value: 'invoice_type',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price ($)',
    value: 'policy_price',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price (VAT)  ($)',
    value: 'policy_price_vat',
    width: "140",
    cn: '',
    notShow:  !vatPresent  
  },
  {
    label: 'Sale Price / <br/> Invoice Amount ($) ',
    value: 'sales_price',
    width: "180",
    cn: ''  
  },
  {
    label: 'Sale Price (VAT) ($)',
    value: 'sales_price_vat',
    width: "140",
    cn: '' ,
    notShow:  !vatPresent 
  },
  {
    label: 'Invoice Status',
    value: 'status_text',
    width: "140",
    cn: ''  
  },
  {
    label: 'Transaction Type',
    value: 'payment_type_text',
    width: "140",
    cn: ''  
  },
  
  {
    label: 'Underwriter',
    value: 'underwriter',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Sales Agent',
    value: 'policy_sales_agent',
    width: "140",
    cn: ''  
  },
  {
    label: 'Created On',
    value: 'created_at',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice By',
    value: 'created_by',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice Approved On',
    value: 'invoice_approved_at',
    width: "140",
    cn: ''  
  },
  
  // {
  //   label: 'Policy Type',
  //   value: 'insurance_type',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Policy Name',
    value: 'policy_name',
    width: "140",
    cn: ''  
  },
  {
    label: 'Order Description',
    value: 'order_description',
    width: "140",
    cn: ''  
  },
  
]

export const approved_group_invoices_table = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''  
  },
  {
    label: 'Quote Ref No',
    value: 'merchant_reference',
    isFixed: true,
    width: "200",
    cn: ''  
  },
  
  {
    label: 'Invoice Type',
    value: 'invoice_type',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price ($) ',
    value: 'policy_price',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Price (VAT) ($)',
    value: 'policy_price_vat',
    width: "140",
    cn: '' ,
    notShow:  !vatPresent 
  },
  
  {
    label: 'Sale Price / <br/> Invoice Amount ($) ',
    value: 'sales_price',
    width: "180",
    cn: ''  
  },
  {
    label: 'Sale Price (VAT) ($)',
    value: 'sales_price_vat',
    width: "140",
    cn: '',
    notShow:  !vatPresent  
  },
  {
    label: 'Invoice Status',
    value: 'status_text',
    width: "140",
    cn: ''  
  },
  {
    label: 'Transaction Type',
    value: 'payment_type_text',
    width: "140",
    cn: ''  
  },
  
  {
    label: 'Underwriter',
    value: 'underwriter',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Sales Agent',
    value: 'policy_sales_agent',
    width: "140",
    cn: ''  
  },
  {
    label: 'Created On',
    value: 'created_at',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice By',
    value: 'created_by',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice Approved On',
    value: 'invoice_approved_at',
    width: "140",
    cn: ''  
  },
  
  // {
  //   label: 'Policy Type',
  //   value: 'insurance_type',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Order Description',
    value: 'order_description',
    width: "140",
    cn: ''  
  },
  
]

export const closed_leads_table = [
  {
      label: 'Sr. #',
      value: 'index',
      isIndex: true,
      isFixed: true,
    cn: ''
  },
  {
    label: 'Lead Created On',
    value: 'created_at',
    isFixed: true,
    isFormat: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Id',
    value: 'id',
    isFixed: true,
    width: "140",
    cn: ''
  },
  {
    label: 'Lead Details',
    value: 'lead_name',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Assigned To',
    value: 'lead_assigned_to',
    width: "200",
    cn: ''
  },
  {
    label: 'Lead Status <br/> 1st Year',
    value: 'lead_first_year_status',
    width: "180",
    cn: ''
  },
  {
    label: 'Year 1 <br/> Sales Agent',
    value: 'lead_first_year_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Status <br/> 2nd Year',
    value: 'lead_second_year_status',
    width: "180",
    cn: ''
  },
  {
    label: 'Year 2 <br/> Sales Agent',
    value: 'lead_second_year_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Closed On',
    value: 'closed_on',
    isFormat: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Closed By',
    value: 'closed_by',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Source',
    value: 'lead_source',
    width: "180",
    cn: ''
  },
  {
    label: 'Customer Name',
    value: 'name',
    width: "180",
    cn: ''
  },
  {
    label: 'Email',
    value: 'email',
    width: "200",
    cn: ''
  },
  {
    label: 'Phone Number',
    value: 'mobile',
    width: "160",
    cn: ''
  },
  

]

export const converted_leads_table = [
  {
      label: 'Sr. #',
      value: 'index',
      isIndex: true,
      isFixed: true,
    cn: ''
  },
  {
    label: 'Lead Created On',
    value: 'created_at',
    isFixed: true,
    isFormat: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Id',
    value: 'id',
    isFixed: true,
    width: "140",
    cn: ''
  },
  {
    label: 'Lead Details',
    value: 'lead_name',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Assigned To',
    value: 'lead_assigned_to',
    width: "200",
    cn: ''
  },
  {
    label: 'Lead Status <br/> Previous Year',
    value: 'lead_first_year_status',
    width: "180",
    cn: ''
  },
  {
    label: 'Previous Year <br/> Sales Agent',
    value: 'lead_first_year_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Converted For',
    value: 'converted_leads',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Converted On',
    value: 'converted_on',
    isFormat: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Converted By',
    value: 'converted_by',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Source',
    value: 'lead_source',
    width: "180",
    cn: ''
  },
  {
    label: 'Customer Name',
    value: 'name',
    width: "180",
    cn: ''
  },
  {
    label: 'Email',
    value: 'email',
    width: "200",
    cn: ''
  },
  {
    label: 'Phone Number',
    value: 'mobile',
    width: "160",
    cn: ''
  },
  

]

export const lead_source_table = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''
  },
  
  {
    label: 'Customer ID',
    value: 'customer_id',
    isFixed: true,
    width: "140",
    cn: ''
  },
  {
    label: 'Name',
    value: 'name',
    isFixed: true,
    width: "180",
    cn: ''
  },
  {
    label: 'Email',
    value: 'email',
    isFixed: true,
    width: "200",
    cn: ''
  },
  {
    label: 'Mobile',
    value: 'mobile',
    width: "160",
    cn: ''
  },
  
  {
    label: 'Lead ID',
    value: 'lead_id',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Source',
    value: 'lead_source',
    width: "180",
    cn: ''
  },
  {
    label: 'Lead Score',
    value: 'lead_score'
  },
  {
    label: 'Lead Status',
    value: 'lead_status_text',
    width: "180",
    cn: 'fw-bolder fw-6 badge',
    isMixin: true
  },
  {
    label: 'Sales Agent',
    value: 'sales_agent',
    width: "180",
    cn: ''
  },
  {
    label: 'Other Lead Sources',
    value: 'other_lead_sources',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Source',
    value: 'utm_source',
    width: "180",
    cn: ''
  },
  {
    label: 'UTM Medium',
    value: 'utm_medium',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Medium',
    value: 'other_utm_medium',
    width: "180",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Campaign',
    value: 'utm_campaign',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Campaign',
    value: 'other_utm_campaign',
    width: "200",
    isCount: true,
    cn: ''
  },
  {
    label: 'UTM Content',
    value: 'utm_content',
    width: "180",
    cn: ''
  },
  {
    label: 'Other UTM Content',
    value: 'other_utm_content',
    width: "180",
    isCount: true,
    cn: ''
  },
  // {
  //   label: 'Policy for', // individual or group
  //   value: 'policy_type',
  //   width: "180",
  //   cn: ''
  // },
  {
    label: 'Insured Class',
    value: 'insured_for',
    width: "180",
    cn: ''
  },
  // {
  //   label: 'Insurance type', // enhance basic
  //   value: 'insurance_type',
  //   width: "180",
  //   cn: ''
  // },
  
  {
    label: 'Relationship',
    value: 'relationship',
    width: "180",
    cn: ''
  },
  {
    label: 'Policy Price ($)',
    value: 'policy_price',
    width: "180",
    cn: ''
  },
  {
    label: 'Created On',
    value: 'created_on',
    width: "180",
    isFormat: true,
    cn: ''
  },
  {
    label: 'Updated On',
    value: 'updated_on',
    width: "180",
    isFormat: true,
    cn: ''
  },
]


export const production_report = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    width: "100",
    cn: ''
  },
  {
    label: 'Created On',
    value: 'created_at',
    isFormat: true,
  },

  {
    label: 'Requested Date',
    value: 'requested_date',
    isFormat: true,
  },

  {
    label: 'Requested By',
    value: 'requested_by',
  },
  {
    label: 'Download',
    value: 'download',
    isDownload: true,
  },

]


export const account_master = [
  {
    label: 'Sr. #',
    value: 'index',
    isIndex: true,
    isFixed: true,
    cn: ''  
  },
  {
    label: 'Quote Ref No',
    value: 'merchant_reference',
    isFixed: true,
    width: "200",
    cn: ''  
  },
  {
    label: 'Invoice No.',
    value: 'invoice_no',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice Type',
    value: 'invoice_type',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice Approved On',
    value: 'invoice_approved_at',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice Created On',
    value: 'created_at',
    width: "140",
    cn: ''  
  },
  {
    label: 'Invoice Created By',
    value: 'invoice_created_by',
    width: "140",
    cn: ''  
  },
  // {
  //   label: 'Customer Category',
  //   value: 'category_name',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Customer Classification',
    value: 'client_classification_text',
    width: "140",
    cn: ''  
  },
  {
    label: 'Transaction Type',
    value: 'transaction_type_text',
    width: "140",
    cn: ''  
  },
  {
    label: 'Reference Number',
    value: 'reference_no',
    width: "140",
    cn: ''  
  },
  {
    label: 'Payment Date',
    value: 'payment_date',
    width: "140",
    isFormat:true,
    cn: ''  
  },
  {
    label: 'Customer Name',
    value: 'customer_name',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy holder',
    value: 'policy_holder',
    width: "140",
    cn: ''  
  },
  // {
  //   label: 'Insurance Type',
  //   value: 'insurance_type_text',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Insured Class',
    value: 'insured_for',
    width: "140",
    cn: ''  
  },
  {
    label: 'Relationship',
    value: 'relationship',
    width: "140",
    cn: ''  
  },
  {
    label: 'Insured Name',
    value: 'insured_name',
    width: "140",
    cn: ''  
  },
  {
    label: 'ID Number',
    value: 'emirates_id',
    width: "140",
    cn: ''  
  },
  // {
  //   label: 'Trade License No.',
  //   value: 'trade_license',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Line of Business',
    value: 'line_of_business',
    width: "140",
    cn: ''  
  },
  {
    label: 'Emirates',
    value: 'emirates',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Created on',
    value: 'policy_created_on',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Completed on',
    value: 'policy_completed_on',
    isFormat:true,
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Completed By',
    value: 'policy_completed_by',
    width: "140",
    cn: ''  
  },
  
  {
    label: 'Policy Status',
    value: 'policy_status',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Start Date',
    value: 'p_start_date',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy End Date',
    value: 'p_end_date',
    width: "140",
    cn: ''  
  },
  
  {
    label: 'Underwriter',
    value: 'underwriter',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy Number ',
    value: 'policy_number',
    width: "140",
    cn: ''  
  },
  {
    label: 'Endorsement No ',
    value: 'endorsement_no',
    width: "140",
    cn: ''  
  },
  
  {
    label: 'Policy Name',
    value: 'policy_name',
    width: "140",
    cn: ''  
  },
  
  {
    label: 'Company Name',
    value: 'company_name',
    width: "140",
    cn: ''  
  },
  // {
  //   label: 'Policy Type',
  //   value: 'policy_type',
  //   width: "140",
  //   cn: ''  
  // },
  {
    label: 'Policy Price ($)',
    value: 'policy_price',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Policy Price (VAT)($)',
    value: 'policy_price_vat',
    width: "140",
    isNumber: true,
    cn: '',
    notShow:  !vatPresent  
  },
 
  {
    label: 'Invoice Amount  ($)',
    value: 'sales_price',
    width: "180",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Invoice Amount (VAT) ($)',
    value: 'sales_price_vat',
    width: "140",
    isNumber: true,
    cn: '',
    notShow:  !vatPresent  
  },
  {
    label: 'Credit Note  ($)',
    value: 'credit_amount',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Credit Note VAT  ($)',
    value: 'credit_amount_vat',
    width: "140",
    isNumber: true,
    cn: '',
    notShow:  !vatPresent  
  },
  {
    label: 'Taxable Premium (TP) ($)',
    value: 'taxable_premium',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Non Taxable Premium (NTP) ($)',
    value: 'ntp',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Net Premium (NP) ($)',
    value: 'np',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'TaxFees (Admin Fees) ($)',
    value: 'tax_fees',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'NonTaxFees (NTF) ($)',
    value: 'ntf',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'BsmaFees (BSF) ($)',
    value: 'bsf',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'HCV/ICP  ($)',
    value: 'icp',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Total Policy fees (TPF) ($)',
    value: 'tpf',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'Prem+Tot.Fees  ($)',
    value: 'prem_total_fee',
    width: "140",
    isNumber: true,
    cn: ''  
  },
  {
    label: 'VAT ($)',
    value: 'debit_amount_vat',
    width: "140",
    isNumber: true,
    cn: '' ,
    notShow:  !vatPresent 
  },
  {
    label: 'Debit Note ($)',
    value: 'debit_amount',
    width: "140",
    isNumber: true,
    cn: ''  
  },

  {
    label: 'Lead Source',
    value: 'lead_source',
    width: "140",
    cn: ''  
  },
  {
    label: 'Agent',
    value: 'agent',
    width: "140",
    cn: ''  
  },
  {
    label: 'Policy SA',
    value: 'policy_sa',
    width: "140",
    cn: ''  
  },
  
]