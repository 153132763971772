import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "m-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_sms_data_value = _resolveComponent("vc-customer-sms-data-value")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal(false))),
    fullscreen: "",
    class: "sms-modal"
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("div", { class: "d-flex justify-content-between" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " SMS ")
      ], -1)
    ])),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.sms.length > 0)
              ? (_openBlock(), _createBlock(_component_vc_customer_sms_data_value, { key: 0 }))
              : (_openBlock(), _createBlock(_component_el_empty, {
                  key: 1,
                  description: "No Records Available"
                }))
          ]))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}