import { MutationTree } from 'vuex';

import { State } from './state';
import { EtcMutationTypes } from '@/store/enums/Etc/Mutations';

export type Mutations<S = State> = {
  [EtcMutationTypes.SET_ROUTES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_COUNTRY_CODE](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_AGENT_LISTS](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_LIST_USER_TYPES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_CAR_TASK_DUE_DATE_COUNT](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_COUNTRIES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_EMIRATES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_INSURED_TYPES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_INSURANCE_PROVIDERS](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_INSURANCE_PLANS](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_PLANS](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_APPS](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_RELATIONSHIPS](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_OTHER_TYPE](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_ROLES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_WORK_TYPE](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_PARENT_TYPE](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_EMPLOYEE_CATEGORIES](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_BASMAH](state: S, payload: any | null): void;
  [EtcMutationTypes.SET_UNDERWRITERS_LISTS](state: S, payload: any | null): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [EtcMutationTypes.SET_ROUTES](state: State, payload: any | null) {
    state.menus = payload;
  },

  [EtcMutationTypes.SET_COUNTRY_CODE](state: State, payload: any | null) {
    state.countryCodes = payload;
  },

  [EtcMutationTypes.SET_AGENT_LISTS](state: State, payload: any | null) {
    state.agents = payload;
  },

  [EtcMutationTypes.SET_UNDERWRITERS_LISTS](state: State, payload: any | null) {
    state.underwriters = payload;
  },

  [EtcMutationTypes.SET_LIST_USER_TYPES](state: State, payload: any | null) {
    state.user_types = payload;
  },

  [EtcMutationTypes.SET_CAR_TASK_DUE_DATE_COUNT](state: State, payload: any | null) {
    state.taskCount = payload;
  },

  [EtcMutationTypes.SET_COUNTRIES](state: State, payload: any | null) {
    state.countries = payload;
  },

  [EtcMutationTypes.SET_RELATIONSHIPS](state: State, payload: any | null) {
    state.relationships = payload;
  },

  [EtcMutationTypes.SET_OTHER_TYPE](state: State, payload: any | null) {
    state.others = payload;
  },

  [EtcMutationTypes.SET_ROLES](state: State, payload: any | null) {
    state.roles = payload;
  },

  [EtcMutationTypes.SET_WORK_TYPE](state: State, payload: any | null) {
    state.workType = payload;
  },

  [EtcMutationTypes.SET_PARENT_TYPE](state: State, payload: any | null) {
    state.parentType = payload;
  },

  [EtcMutationTypes.SET_EMIRATES](state: State, payload: any | null) {
    state.emirates = payload;
  },

  [EtcMutationTypes.SET_INSURED_TYPES](state: State, payload: any | null) {
    state.insuredTypes = payload;
  },

  [EtcMutationTypes.SET_INSURANCE_PROVIDERS](state: State, payload: any | null) {
    state.providers = payload;
  },

  [EtcMutationTypes.SET_INSURANCE_PLANS](state: State, payload: any | null) {
    state.policies = payload;
  },

  [EtcMutationTypes.SET_PLANS](state: State, payload: any | null) {
    state.plans = payload;
  },

  [EtcMutationTypes.SET_APPS](state: State, payload: any | null) {
    state.apps = payload;
  },

  [EtcMutationTypes.SET_LEAD_SOURCES](state: State, payload: any | null) {
    state.leadSources = payload;
  },

  [EtcMutationTypes.SET_CUSTOMER_ALREADY_EXIST](state: State, payload: any | null) {
    state.customerAlreadyExist = payload;
  },

  [EtcMutationTypes.SET_EMPLOYEE_CATEGORIES](state: State, payload: any | null) {
    state.employeeCategories = payload;
  },

  [EtcMutationTypes.SET_TASK_LISTS](state: State, payload: any | null) {
    state.task_lists = payload;
  },

  [EtcMutationTypes.SET_LEAD_REASONS](state: State, payload: any | null) {
    state.lead_reasons = payload;
  },

  [EtcMutationTypes.SET_NOTIFICATIONS](state: State, payload: any | null) {
    state.notifications = payload;
  },

  [EtcMutationTypes.SET_BASMAH](state: State, payload: any | null) {
    state.basmah = parseFloat(payload);
  },

  [EtcMutationTypes.SET_VAT](state: State, payload: any | null) {
    state.vat = parseFloat(payload);
  },

  [EtcMutationTypes.SET_SERVICE_FEE](state: State, payload: any | null) {
    state.cod_service_fee = parseFloat(payload);
  },

  [EtcMutationTypes.SET_NOTIFICATION_LIST](state: State, payload: any | null) {
    state.notificationList = payload;
  },

  [EtcMutationTypes.SET_ALL_NOTIFICATIONS](state: State, payload: any | null) {
    state.allNotificationList = payload;
  },

  [EtcMutationTypes.SET_NOTIFICATION_COUNT](state: State, payload: any | null) {
    state.notificationCount = payload;
  },


  [EtcMutationTypes.SET_GROUP_SIZE](state: State, payload: any | null) {
    state.groupSize = payload;
  },


  [EtcMutationTypes.SET_HEALTH_SETTING](state: State, payload: any) {
    // if(payload.type == 'hl_renewal_policy_days')
      state.settings[payload.type] = payload.value;
  },

  [EtcMutationTypes.SET_EMPLOYEE_TYPE](state: State, payload: any | null) {
    state.employeeType = payload;
  },
};
