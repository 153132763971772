import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "align-self-center text-gray-700" }
const _hoisted_4 = { class: "ms-3" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row mt-1" }
const _hoisted_7 = { class: "col-sm-22" }
const _hoisted_8 = { class: "form-label" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  key: 0,
  class: "col-sm-2"
}
const _hoisted_11 = { class: "mb-10" }
const _hoisted_12 = {
  key: 1,
  class: "col-sm-2"
}
const _hoisted_13 = { class: "mb-10" }
const _hoisted_14 = { class: "col-sm-2" }
const _hoisted_15 = { class: "mb-10" }
const _hoisted_16 = { class: "col-sm-2" }
const _hoisted_17 = { class: "mb-10" }
const _hoisted_18 = { class: "col-sm-2" }
const _hoisted_19 = { class: "mb-10" }
const _hoisted_20 = { class: "col-sm-2" }
const _hoisted_21 = { class: "mb-10" }
const _hoisted_22 = { class: "col-sm-2" }
const _hoisted_23 = { class: "mb-10" }
const _hoisted_24 = {
  key: 2,
  class: "col-sm-2"
}
const _hoisted_25 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_vc_input = _resolveComponent("vc-input")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, " Update Credit & Debit Note Information ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fw-bolder" }, "Policy Price:", -1)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.common.formatCurrency(_ctx.policy.main_invoice.sales_price)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy.credit_debit_details, (invoice, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(invoice.merchant_reference) + " (" + _toDisplayString(invoice.amend_type == 2 ? 'Refund Amount' : 'Invoice Amount') + ": " + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.common.formatCurrency(invoice.sales_price)) + ")", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (invoice.is_amend == 1 && invoice.amend_type == 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label required" }, " Start Date ", -1)),
                        _createVNode(_component_vc_date_picker, {
                          modelValue: invoice.amendment_start_date,
                          "onUpdate:modelValue": ($event: any) => ((invoice.amendment_start_date) = $event),
                          prop: `credit_debit_details.` + i + `.amendment_start_date`,
                          ref_for: true,
                          ref: `credit_debit_details.` + i + `.amendment_start_date`,
                          min: _ctx.policy.policy_start_date,
                          max: _ctx.policy.policy_end_date,
                          rules: { validator: _ctx.Validations.amendment_start_date, trigger: ['blur', 'change'] },
                          disabled: _ctx.isDisableAllFields
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "min", "max", "rules", "disabled"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (invoice.is_amend == 1 && invoice.amend_type == 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label required" }, " End Date ", -1)),
                        _createVNode(_component_vc_date_picker, {
                          modelValue: invoice.amendment_end_date,
                          "onUpdate:modelValue": ($event: any) => ((invoice.amendment_end_date) = $event),
                          prop: `credit_debit_details.` + i + `.amendment_end_date`,
                          ref_for: true,
                          ref: `credit_debit_details.` + i + `.amendment_end_date`,
                          min: _ctx.policy.policy_start_date,
                          max: _ctx.policy.policy_end_date,
                          disabled: (invoice.is_invoice_completed && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields ,
                          rules: { validator:(rule, value, callback) => {
                                        _ctx.Validations.amendment_end_date(rule, value, callback, invoice.amendment_start_date, invoice.is_invoice_completed);
                                    }, trigger: ['blur', 'change'] }
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "min", "max", "disabled", "rules"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_vc_amount_v2, {
                      modelValue: invoice.credit_amount,
                      "onUpdate:modelValue": ($event: any) => ((invoice.credit_amount) = $event),
                      label: "CN",
                      subLabel: '('+_ctx.currency+')',
                      placeholder: "CN",
                      size: "large",
                      isRequired: "",
                      disabled: (invoice.is_invoice_completed && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields,
                      prop: `credit_debit_details.` + i + `.credit_amount`,
                      ref_for: true,
                      ref: `credit_debit_details.` + i + `.credit_amount`,
                      rules: invoice.is_invoice_completed ? '' : { validator: _ctx.Validations.credit_amount, trigger: ['blur', 'change'] }
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "subLabel", "disabled", "prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_vc_input, {
                      modelValue: invoice.credit_notes_no,
                      "onUpdate:modelValue": ($event: any) => ((invoice.credit_notes_no) = $event),
                      label: "CN No.",
                      placeholder: "CN No.",
                      size: "large",
                      isRequired: "",
                      disabled: _ctx.isDisableAllFields,
                      prop: `credit_debit_details.` + i + `.credit_notes_no`,
                      ref_for: true,
                      ref: `credit_debit_details.` + i + `.credit_notes_no`,
                      rules: { validator: _ctx.Validations.credit_notes_no, trigger: ['blur', 'change'] },
                      maxlength: 100
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_vc_amount_v2, {
                      modelValue: invoice.debit_amount,
                      "onUpdate:modelValue": ($event: any) => ((invoice.debit_amount) = $event),
                      label: "DN",
                      subLabel: '('+_ctx.currency+')',
                      placeholder: "DN",
                      size: "large",
                      isRequired: "",
                      disabled: (invoice.is_invoice_completed && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields,
                      prop: `credit_debit_details.` + i + `.debit_amount`,
                      ref_for: true,
                      ref: `credit_debit_details.` + i + `.debit_amount`,
                      rules: invoice.is_invoice_completed ? '' : {
                                    validator: (rule, value, callback) => {
                                        _ctx.Validations.debit_amount(rule, value, callback,_ctx.minDebitAmount(invoice.sales_price ), invoice.sales_price , invoice.amend_type); // Pass 18 as a custom parameter
                                    }, trigger: ['blur', 'change']
                                }
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "subLabel", "disabled", "prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_vc_input, {
                      modelValue: invoice.debit_notes_no,
                      "onUpdate:modelValue": ($event: any) => ((invoice.debit_notes_no) = $event),
                      label: "DN No.",
                      placeholder: "DN No.",
                      size: "large",
                      isRequired: "",
                      disabled: _ctx.isDisableAllFields,
                      prop: `credit_debit_details.` + i + `.debit_notes_no`,
                      ref_for: true,
                      ref: `credit_debit_details.` + i + `.debit_notes_no`,
                      rules: { validator: _ctx.Validations.debit_notes_no, trigger: ['blur', 'change'] },
                      maxlength: 100
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "prop", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label required" }, " Tax Invoice Date ", -1)),
                    _createVNode(_component_vc_date_picker, {
                      modelValue: invoice.tax_invoice_date,
                      "onUpdate:modelValue": ($event: any) => ((invoice.tax_invoice_date) = $event),
                      disabled: _ctx.isDisableAllFields,
                      prop: `credit_debit_details.` + i + `.tax_invoice_date`,
                      ref_for: true,
                      ref: `credit_debit_details.` + i + `.tax_invoice_date`,
                      rules: { validator: _ctx.Validations.tax_invoice_date, trigger: ['blur', 'change'] }
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "prop", "rules"])
                  ])
                ]),
                (invoice.is_amend == 1 && invoice.amend_type != 3)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_vc_input, {
                          modelValue: invoice.endorsement_no,
                          "onUpdate:modelValue": ($event: any) => ((invoice.endorsement_no) = $event),
                          label: "Endorsement No",
                          placeholder: "Endorsement No.",
                          size: "large",
                          isRequired: "",
                          disabled: _ctx.isDisableAllFields,
                          prop: `credit_debit_details.` + i + `.endorsement_no`,
                          ref_for: true,
                          ref: `credit_debit_details.` + i + `.endorsement_no`,
                          rules: invoice.is_policy_completed ? '' : { validator: _ctx.Validations.endorsement_no, trigger: ['blur', 'change'] }
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "prop", "rules"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 64))
          }), 128)),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "col-sm-12" }, " ** All prices are inclusive of VAT. ")
          ], -1))
        ])
      ]))
    : _createCommentVNode("", true)
}