import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 h-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_vc_customer_details = _resolveComponent("vc-customer-details")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modal(false))),
    fullscreen: ""
  }, {
    header: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, "View Details")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "m-10"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  filterable: "",
                  modelValue: _ctx.activeValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeValue) = $event)),
                  onChange: _ctx.changeValue,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: i,
                        label: lead.name,
                        value: lead.lead_id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (typeof _ctx.selectedlead === 'object')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_el_row, {
                gutter: 10,
                class: "justify-content-center mx-10"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getters.getCustomerLeadDetails(
                  _ctx.selectedlead
                ), (detail, i) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                          (_ctx.selectedlead[detail.key].show && !detail.notShow)
                            ? (_openBlock(), _createBlock(_component_vc_customer_details, {
                                key: 0,
                                "is-show-title": detail.showTitle,
                                "is-show-subt-title": detail.showSubTitle,
                                title: detail.title,
                                "is-table": detail.inTable,
                                items: detail.items,
                                section: detail.key,
                                values: _ctx.selectedlead[detail.key],
                                "interested-in": detail.interestedIn,
                                "interested-policy": _ctx.selectedlead.interested_policy,
                                lead_id: _ctx.selectedlead.lead_id,
                                lead: _ctx.selectedlead,
                                "hide-data": "",
                                "hide-actions": ""
                              }, null, 8, ["is-show-title", "is-show-subt-title", "title", "is-table", "items", "section", "values", "interested-in", "interested-policy", "lead_id", "lead"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}