import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_vc_payment_type = _resolveComponent("vc-payment-type")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_el_row, { class: "filters-block-row" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { md: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { md: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.filters.customerDateRangeLT,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.customerDateRangeLT) = $event)),
                      type: "daterange",
                      "unlink-panels": "",
                      "range-separator": "-",
                      "start-placeholder": "Start date",
                      "end-placeholder": "End date",
                      shortcuts: _ctx.shortcuts,
                      onChange: _ctx.getDates,
                      format: "DD/MM/YYYY",
                      "value-format": "YYYY-MM-DD",
                      class: _normalizeClass(["el-h-41", { 'mb-2': _ctx.common.isMobile() }]),
                      style: {"width":"100%"},
                      size: "large",
                      clearable: false
                    }, null, 8, ["modelValue", "shortcuts", "onChange", "class"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { md: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_payment_type, {
                      modelValue: _ctx.filter.payment_type,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.payment_type) = $event)),
                      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchFilter())),
                      label: 'All Transaction Type',
                      "invoice-filter": true
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { md: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_input, {
                modelValue: _ctx.filter.keyword,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.keyword) = $event)),
                placeholder: "Search Ref/Email/Mobile/Name",
                "prefix-icon": _ctx.Search,
                size: "large"
              }, {
                prefix: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createElementVNode("i", { class: "el-input__icon el-icon-search search-field-icon" }, null, -1)
                ])),
                _: 1
              }, 8, ["modelValue", "prefix-icon"]),
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchFilter())),
                style: {"border-radius":"0"},
                class: "btn btn-light-info cursor-pointer ms-3"
              }, " Search "),
              _createElementVNode("button", {
                type: "reset",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetFilter())),
                style: {"border-radius":"0"},
                class: "btn btn-light-info ms-3"
              }, " Reset ")
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}