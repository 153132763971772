import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-5 w-100 mt-4" }
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "py-5 table-custom-invoice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.view_declarations.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.view_declarations.open) = $event)),
    "with-header": false,
    "append-to-body": "",
    onClose: _ctx.close,
    size: _ctx.view_declarations.is_fullscreen ? '100%' : '30%',
    class: "declaration-drawer"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fw-bolder text-gray-700 mb-10" }, "Declarations", -1)),
        _createVNode(_component_el_button, {
          link: "",
          icon: _ctx.Close,
          onClick: _ctx.close
        }, null, 8, ["icon", "onClick"])
      ]),
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadInformations, (information, i) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "nav-item cursor-pointer policy",
            key: i
          }, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link trunc text-truncate", {
                        'active': _ctx.activeTab === information.id,
                        'disabled': information.tab_disabled
                    }]),
              id: `Dlead_${i}`,
              ref_for: true,
              ref: `Dlead_${information.id}`,
              onClick: ($event: any) => (_ctx.fetchData(information.id))
            }, [
              (_ctx.common.getTextWidth(information.member_name) > 270)
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 0,
                    class: "box-item",
                    effect: "light",
                    content: information.member_name,
                    placement: "bottom",
                    "show-after": 500,
                    "hide-after": 0
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(information.member_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(information.member_name), 1))
            ], 10, _hoisted_3)
          ]))
        }), 128))
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "card" }, [
              _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
            ], -1)
          ])))
        : (_ctx.declarations.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_el_table, {
                data: _ctx.declarations,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, { width: 80 }, {
                    header: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("span", null, "Sr. #", -1)
                    ])),
                    default: _withCtx((scope) => [
                      _createElementVNode("span", null, _toDisplayString(scope.$index + 1), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, null, {
                    header: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("span", null, "Question", -1)
                    ])),
                    default: _withCtx((scope) => [
                      _createElementVNode("span", null, _toDisplayString(scope.row.question), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, { width: 200 }, {
                    header: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("span", null, "Action", -1)
                    ])),
                    default: _withCtx((scope) => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_el_radio_group, {
                          disabled: _ctx.view_declarations.disabled,
                          modelValue: scope.row.answer,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.answer) = $event),
                          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveDeclarations()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "YES" }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("Yes")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_el_radio, { label: "NO" }, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode("No")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["disabled", "modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "size"]))
}