import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center mb-10 mt-10" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center text-gray-800 text-hover-primary fw-bolder fs-6" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "text-muted fw-bold d-flex customer-log-container"
}
const _hoisted_6 = { class: "customer-log-label" }
const _hoisted_7 = { class: "customer-log-value" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "text-muted fw-bold d-flex customer-log-container"
}
const _hoisted_10 = { class: "customer-log-label" }
const _hoisted_11 = { class: "customer-log-value" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_view_tasks_drawer_close_task = _resolveComponent("vc-customer-view-tasks-drawer-close-task")!
  const _component_vc_button = _resolveComponent("vc-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["bullet bullet-vertical h-125px", `bg-${_ctx.value.class_text}`])
    }, null, 2),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "Task Info", -1)),
        (_ctx.value.show_close_task)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_vc_customer_view_tasks_drawer_close_task, { ref: "close_task" }, null, 512),
              _createVNode(_component_vc_button, {
                text: "Close Task",
                className: "btn btn-sm btn-info",
                "is-regular": "",
                onVcClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openTask(_ctx.value)))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Close Task ")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view_tasks, (task, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (!task.withCondition)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(task.label), 1),
                _createElementVNode("span", _hoisted_7, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "customer-log-colon" }, ": ", -1)),
                  _createElementVNode("span", {
                    innerHTML: _ctx.value[task.value]
                  }, null, 8, _hoisted_8)
                ])
              ]))
            : _createCommentVNode("", true),
          (task.withCondition && _ctx.value.show)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(task.label), 1),
                _createElementVNode("span", _hoisted_11, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "customer-log-colon" }, ": ", -1)),
                  _createElementVNode("span", {
                    innerHTML: _ctx.value[task.value]
                  }, null, 8, _hoisted_12)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}