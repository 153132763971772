import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 mb-10" }
const _hoisted_5 = {
  key: 0,
  class: "table-custom-invoice"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "d-flex"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 3 }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_update_amend_documents = _resolveComponent("vc-update-amend-documents")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_ctx.policy && _ctx.amend_documents.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("div", { class: "card-title" }, "Update Endorsement Documents")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.amend_documents.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_el_table, {
                      data: _ctx.amend_documents,
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amend_doc_columns, (document, i) => {
                          return (_openBlock(), _createBlock(_component_el_table_column, {
                            key: i,
                            width: document.width,
                            align: document.align
                          }, {
                            header: _withCtx(() => [
                              _createElementVNode("span", {
                                innerHTML: document.label
                              }, null, 8, _hoisted_6)
                            ]),
                            default: _withCtx((scope) => [
                              (document.value === 'index')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                    _createElementVNode("span", null, _toDisplayString(scope.$index + 1), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (document.value === 'upload')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                    _createVNode(_component_vc_update_amend_documents, {
                                      document: scope.row
                                    }, null, 8, ["document"])
                                  ]))
                                : (scope.row.filename && document.value === 'action')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amend_doc_actions, (action, k) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                                          (action.show)
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                class: "ms-4 cursor-pointer icon-bg-common",
                                                onClick: ($event: any) => (_ctx.makeAction(scope.row, action.value))
                                              }, [
                                                _createVNode(_component_el_tooltip, {
                                                  class: "box-item",
                                                  content: action.tip,
                                                  effect: "light",
                                                  placement: "bottom",
                                                  "show-after": 200,
                                                  "hide-after": 0
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("i", {
                                                      class: _normalizeClass(action.icon)
                                                    }, null, 2)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["content"])
                                              ], 8, _hoisted_10))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                              (document.value === 'invoice_created_details')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                    _createTextVNode(_toDisplayString(scope.row.created_by_skye_user?.name) + " ", 1),
                                    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" " + _toDisplayString(scope.row.created_at), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 4,
                                    innerHTML: scope.row[document.value]
                                  }, null, 8, _hoisted_12))
                            ]),
                            _: 2
                          }, 1032, ["width", "align"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["data"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}