import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-4 ms-4 fs-5 w-100" }
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_customer_log_data_value = _resolveComponent("vc-customer-customer-log-data-value")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal(false))),
    fullscreen: ""
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Customer Log Details ")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer_log, (log, i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item cursor-pointer policy",
              key: i
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link", {'active': _ctx.activeTab === log.value}]),
                id: `log_${i}`,
                ref_for: true,
                ref: `log_${log.value}`,
                onClick: ($event: any) => (_ctx.fetchData(log.value))
              }, [
                _createElementVNode("span", null, _toDisplayString(log.label), 1)
              ], 10, _hoisted_2)
            ]))
          }), 128))
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.store.getters.getCustomerLogs), (logs, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logs[1], (activity, i) => {
                    return (_openBlock(), _createBlock(_component_vc_customer_customer_log_data_value, {
                      key: i,
                      value: activity,
                      property: logs[0],
                      "active-tab": _ctx.activeTab
                    }, null, 8, ["value", "property", "active-tab"]))
                  }), 128))
                ], 64))
              }), 128))
            ]))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}