import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container corporate-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "row mb-10" }
const _hoisted_11 = { class: "col-sm-3" }
const _hoisted_12 = { class: "col-sm-3" }
const _hoisted_13 = { class: "col-sm-3" }
const _hoisted_14 = { class: "col-sm-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_view_documents = _resolveComponent("vc-customer-view-documents")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_vc_employee_categories = _resolveComponent("vc-employee-categories")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vc_emirates = _resolveComponent("vc-emirates")!
  const _component_vc_policies = _resolveComponent("vc-policies")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_vc_dividers = _resolveComponent("vc-dividers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vc_customer_view_documents, {
        ref: "documents",
        "is-view": ""
      }, null, 512),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.health.categories, (category, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-sm-12",
          key: i
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", null, " Category " + _toDisplayString(i + 1), 1),
            (_ctx.health.categories.length > 1 && !_ctx.isDisabled)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "ms-4 cursor-pointer icon-bg-common",
                  onClick: ($event: any) => (_ctx.deleteCategory(i))
                }, [
                  _createVNode(_component_el_tooltip, {
                    class: "box-item",
                    content: "Delete",
                    effect: "light",
                    placement: "bottom",
                    "show-after": 200,
                    "hide-after": 0
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("i", { class: "icon-svg icon-delete" }, null, -1)
                    ])),
                    _: 1
                  })
                ], 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_el_form_item, {
                  prop: `categories.` + i + `.name`,
                  rules: { required: true, message: 'Please select category', trigger: ['blur', 'change','input'] }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_employee_categories, {
                      ref_for: true,
                      ref: `categories.` + i + `.name`,
                      placeholder: "Select Category Group",
                      size: "large",
                      style: {"width":"100%"},
                      modelValue: category.name,
                      "onUpdate:modelValue": ($event: any) => ((category.name) = $event),
                      disabled: _ctx.isDisabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["prop"])
              ])
            ]),
            (category.lead_id > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_el_button, {
                      size: "large",
                      onClick: ($event: any) => (_ctx.uploadDocument(category.id, category.lead_id, category.category_name, null))
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Upload Documents")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label" }, " Visa Issued Emirate ", -1)),
              _createVNode(_component_el_form_item, {
                prop: `categories.` + i + `.visa_emirates`,
                rules: { required: true, message: 'Please select visa emirates', trigger: ['blur', 'change','input'] }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_emirates, {
                    ref_for: true,
                    ref: `categories.` + i + `.visa_emirates`,
                    modelValue: category.visa_emirates,
                    "onUpdate:modelValue": ($event: any) => ((category.visa_emirates) = $event),
                    disabled: _ctx.isDisabled
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                ]),
                _: 2
              }, 1032, ["prop"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, " Insurance Plan ", -1)),
              _createVNode(_component_el_form_item, {
                prop: `categories.` + i +`.insurance_plan_id`,
                rules: { required: true, message: 'Please select the insurance plan', trigger: ['blur', 'change','input'] }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_policies, {
                    ref_for: true,
                    ref: `categories.` + i +`.insurance_plan_id`,
                    modelValue: category.insurance_plan_id,
                    "onUpdate:modelValue": ($event: any) => ((category.insurance_plan_id) = $event),
                    options: _ctx.plans,
                    disabled: _ctx.isDisabled
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled"])
                ]),
                _: 2
              }, 1032, ["prop"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, " Members Count ", -1)),
              _createVNode(_component_el_form_item, {
                prop: `categories.` + i +`.group_count`,
                rules: 
                            { validator: _ctx.Validations.members_count, trigger: ['blur', 'change'] }
                            
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    ref_for: true,
                    ref: `categories.` + i +`.group_count`,
                    modelValue: category.group_count,
                    "onUpdate:modelValue": ($event: any) => ((category.group_count) = $event),
                    min: 1,
                    size: "large",
                    width: "100%",
                    disabled: _ctx.isDisabled
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                ]),
                _: 2
              }, 1032, ["prop", "rules"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, " Members > 60 ", -1)),
              _createVNode(_component_el_form_item, {
                prop: `categories.` + i +`.members_above_60`,
                rules: { validator:(rule, value, callback) => {
                                        _ctx.Validations.members_above_60(rule, value, callback,category.group_count); // Pass 18 as a custom parameter
                                    }, trigger: ['blur', 'change','input','keydown'] }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    ref_for: true,
                    ref: `categories.` + i +`.members_above_60`,
                    modelValue: category.members_above_60,
                    "onUpdate:modelValue": ($event: any) => ((category.members_above_60) = $event),
                    min: 0,
                    size: "large",
                    disabled: _ctx.isDisabled
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                ]),
                _: 2
              }, 1032, ["prop", "rules"])
            ])
          ]),
          _createVNode(_component_vc_dividers, {
            index: i,
            items: _ctx.health.categories
          }, null, 8, ["index", "items"])
        ]))
      }), 128))
    ])
  ]))
}