import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "additional-details border border-gray-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_task_form = _resolveComponent("vc-customer-task-form")!

  return (![14,16].includes(_ctx.lead_status_id))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text ps-3 me-5 text-color-blue" }, " Enter Task Details ", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_vc_customer_task_form, {
            customer_id: _ctx.customer_id,
            agent: _ctx.agent,
            lead_id: _ctx.lead_id,
            lead_status_id: _ctx.lead_status_id,
            "disable-button": _ctx.disableButton,
            ref: "task_form"
          }, null, 8, ["customer_id", "agent", "lead_id", "lead_status_id", "disable-button"])
        ])
      ]))
    : _createCommentVNode("", true)
}