import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Teleport as _Teleport, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  "element-loading-background": "white",
  "element-loading-text": "Please wait",
  style: {"z-index":"1"}
}
const _hoisted_2 = {
  key: 1,
  class: "accordion accordion-custom",
  id: "kt_accordion"
}
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id", "data-bs-target", "aria-controls", "onClick"]
const _hoisted_5 = ["id", "aria-labelledby"]
const _hoisted_6 = { class: "accordion-body customer-detatils" }
const _hoisted_7 = ["id"]
const _hoisted_8 = {
  key: 0,
  class: "d-block align-content-center",
  style: {"height":"150px","display":"inline-block"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_details = _resolveComponent("vc-customer-details")!
  const _component_vc_customer_task_details = _resolveComponent("vc-customer-task-details")!
  const _component_vc_customer_accordion_data = _resolveComponent("vc-customer-accordion-data")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (Object.keys(_ctx.openLead).length > 0)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: `#accordion-datas-${_ctx.selectedLead}`
        }, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getters.getCustomerLeadDetails(_ctx.openLead), (detail, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                (_ctx.openLead[detail.key].show && !detail.notShow)
                  ? (_openBlock(), _createBlock(_component_vc_customer_details, {
                      key: 0,
                      "is-show-title": detail.showTitle,
                      "is-show-subt-title": detail.showSubTitle,
                      title: detail.title,
                      "is-table": detail.inTable,
                      items: detail.items,
                      section: detail.key,
                      values: _ctx.openLead[detail.key],
                      "interested-in": detail.interestedIn,
                      "interested-policy": _ctx.openLead.interested_policy,
                      lead_id: _ctx.openLead.lead_id,
                      lead: _ctx.openLead,
                      onAction: _ctx.tabAction
                    }, null, 8, ["is-show-title", "is-show-subt-title", "title", "is-table", "items", "section", "values", "interested-in", "interested-policy", "lead_id", "lead", "onAction"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createVNode(_component_vc_customer_task_details, {
              customer_id: _ctx.openLead.customer_id,
              agent: _ctx.openLead.agent,
              lead_id: _ctx.openLead.lead_id,
              lead_status_id: _ctx.openLead.lead_status_id,
              "disable-button": _ctx.openLead.disable_task_button,
              ref: "task_details"
            }, null, 8, ["customer_id", "agent", "lead_id", "lead_status_id", "disable-button"])
          ])), [
            [_directive_loading, _ctx.openLeadLoading]
          ])
        ], 8, ["to"]))
      : _createCommentVNode("", true),
    (_ctx.leads.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "accordion-item mb-5",
              key: index
            }, [
              _createElementVNode("h2", {
                class: "accordion-header",
                id: `kt_accordion_header_${index}`
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(["accordion-button fs-4 fw-bold collapsed", {'accordion-button-blue': lead.highlight}]),
                  id: `accordian_${index}`,
                  type: "button",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": `#kt_accordion_body_${index}`,
                  "aria-expanded": "false",
                  "aria-controls": `kt_accordion_body_${index}`,
                  ref_for: true,
                  ref: "listRef",
                  onClick: ($event: any) => (_ctx.fetchOpenLead(lead,index))
                }, [
                  _createVNode(_component_vc_customer_accordion_data, { data: lead }, null, 8, ["data"])
                ], 10, _hoisted_4)
              ], 8, _hoisted_3),
              _createElementVNode("div", {
                id: `kt_accordion_body_${index}`,
                class: "accordion-collapse collapse",
                "aria-labelledby": `kt_accordion_header_${index}`,
                "data-bs-parent": "#kt_accordion"
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    id: `accordion-datas-${index}`
                  }, null, 8, _hoisted_7),
                  (!(Object.keys(_ctx.openLead).length > 0))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
                        _createElementVNode("p", { class: "text-center fw-bold" }, "Please wait", -1)
                      ])))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_5)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}