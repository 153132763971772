import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text ps-3 me-5 text-color-blue"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "fw-light text-gray-700 fs-6"
}
const _hoisted_4 = {
  key: 2,
  class: "fw-light text-gray-700 fs-6"
}
const _hoisted_5 = {
  key: 0,
  class: "bg-light-danger d-flex align-items-center p-5 mb-10 me-12"
}
const _hoisted_6 = { class: "svg-icon svg-icon-2hx svg-icon-danger me-4" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex fr"
}
const _hoisted_8 = {
  key: 0,
  class: "border border-gray-300 pt-5 mb-5 lead-details"
}
const _hoisted_9 = { class: "table lists" }
const _hoisted_10 = { class: "text-gray-700 fs-6 fw-bold" }
const _hoisted_11 = { class: "border-top fs-6 text-gray-700" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "text-gray-600" }
const _hoisted_14 = {
  key: 0,
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_15 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_16 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 1,
  class: "border border-gray-300 mb-5 additional-car-details"
}
const _hoisted_19 = {
  key: 0,
  class: "fs-6"
}
const _hoisted_20 = {
  key: 0,
  class: "additional-details"
}
const _hoisted_21 = { class: "col-sm-12" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-sm-12" }
const _hoisted_24 = { class: "d-flex fw-bolder text-gray-700" }
const _hoisted_25 = { class: "customer-first-column" }
const _hoisted_26 = { class: "m-3" }
const _hoisted_27 = { class: "fw-regular text-gray-700" }
const _hoisted_28 = { class: "fw-regular text-gray-700" }
const _hoisted_29 = { class: "col-sm-12 border-top" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = {
  key: 0,
  class: "col-sm-4 border-bottom"
}
const _hoisted_32 = {
  key: 0,
  class: "row"
}
const _hoisted_33 = { class: "d-flex" }
const _hoisted_34 = { class: "fw-bold text-gray-700 m-3" }
const _hoisted_35 = { class: "fw-regular text-gray-700" }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = {
  key: 1,
  class: "additional-details"
}
const _hoisted_39 = { class: "row m-0" }
const _hoisted_40 = {
  key: 0,
  class: "col-sm-4 lead-row"
}
const _hoisted_41 = { class: "row" }
const _hoisted_42 = { class: "d-flex" }
const _hoisted_43 = { class: "fw-bold text-gray-700 m-3" }
const _hoisted_44 = { class: "fw-regular text-gray-700 d-flex align-items-center" }
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_dropdown = _resolveComponent("vc-dropdown")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, {
      justify: "space-between",
      class: "m-0 align-items-center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          md: 8,
          sm: 24
        }, {
          default: _withCtx(() => [
            (_ctx.isShowTitle)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [
                  _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                  (_ctx.isTable)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _createTextVNode(_toDisplayString(_ctx.values.lead_id) + " ", 1),
                        (_ctx.lead.check_pending_invoice)
                          ? (_openBlock(), _createBlock(_component_el_tooltip, {
                              key: 0,
                              class: "box-item",
                              effect: "light",
                              content: _ctx.lead.check_pending_invoice_main ? 'Invoice pending for approval' : 'Amend invoice pending for approval',
                              placement: "bottom",
                              "show-after": 500,
                              "hide-after": 0
                            }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createElementVNode("span", { class: "fw-light text-gray-700 cursor-pointer" }, [
                                  _createElementVNode("i", { class: "icon-svg icon-invoices-hourly-glass" })
                                ], -1)
                              ])),
                              _: 1
                            }, 8, ["content"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isShowSubTitle)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (Status : " + _toDisplayString(_ctx.subTitle) + ") ", 1))
                    : _createCommentVNode("", true),
                  (_ctx.section == 'latest_policy_purchase')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (Status : " + _toDisplayString(_ctx.lead.latest_policy_purchase.status) + " | " + _toDisplayString(_ctx.lead.latest_policy_purchase.is_active == 1 ? 'Active' : 'Inactive') + ") ", 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.isTable)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_el_col, {
                md: 10,
                sm: 24
              }, {
                default: _withCtx(() => [
                  (_ctx.openMerge)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen048.svg" })
                        ]),
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-flex flex-column" }, [
                          _createElementVNode("h4", { class: "mb-1 text-danger" }, "Please Note"),
                          _createElementVNode("span", { style: {"font-size":"14px"} }, "A similar lead exists for the same customer. Do you want to merge.?")
                        ], -1))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                md: 6,
                sm: 24
              }, {
                default: _withCtx(() => [
                  (!_ctx.hideActions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_vc_button, {
                          text: _ctx.lead.lead_status_id == 3 ? `View Tasks (${_ctx.lead.followup_count})` : 'View Tasks',
                          "is-regular": "",
                          className: "me-4 btn btn-info",
                          onVcClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openViewTasks(_ctx.values)))
                        }, null, 8, ["text"]),
                        (_ctx.openMerge)
                          ? (_openBlock(), _createBlock(_component_vc_button, {
                              key: 0,
                              text: "",
                              "is-regular": "",
                              "is-icon": "",
                              "is-icon-local": "",
                              icon: "media/background/merge-icon.svg",
                              className: "me-4 btn btn-secondary",
                              onVcClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openMergeLead(_ctx.values)))
                            }))
                          : _createCommentVNode("", true),
                        ((!([6,8,14,16].includes(_ctx.lead.lead_status_id))))
                          ? (_openBlock(), _createBlock(_component_vc_dropdown, {
                              key: 1,
                              items: _ctx.store.getters.getLeadActions(_ctx.lead),
                              onAction: _ctx.clickEvent
                            }, null, 8, ["items", "onAction"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isTable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("table", _hoisted_9, [
            _createElementVNode("tr", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: _normalizeClass(["pb-2", item.cnh]),
                  key: index
                }, _toDisplayString(item.label), 3))
              }), 128))
            ]),
            _createElementVNode("tr", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: item.value
                }, [
                  (item.value == 'lead_source')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.values.duplicate_source)
                          ? (_openBlock(), _createBlock(_component_el_tooltip, {
                              key: 0,
                              class: "box-item",
                              effect: "light",
                              content: _ctx.values.duplicate_source
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("label", {
                                  class: _normalizeClass(item.cn),
                                  style: {"cursor":"pointer"}
                                }, _toDisplayString(_ctx.values.lead_source), 3)
                              ]),
                              _: 2
                            }, 1032, ["content"]))
                          : _createCommentVNode("", true),
                        (!_ctx.values.duplicate_source)
                          ? (_openBlock(), _createElementBlock("label", {
                              key: 1,
                              class: _normalizeClass(item.cn)
                            }, _toDisplayString(_ctx.values.lead_source), 3))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (!item.isTimeLine)
                      ? (_openBlock(), _createElementBlock("label", {
                          key: 1,
                          class: _normalizeClass(item.cn),
                          style: _normalizeStyle(`${item.isMixin ? _ctx.common.leadStatus(_ctx.values.lead_status_id) : ''}`),
                          innerHTML: _ctx.values[item.value]
                        }, null, 14, _hoisted_12))
                      : (_openBlock(), _createBlock(_component_el_popover, {
                          key: 2,
                          placement: "left-start",
                          trigger: "hover"
                        }, {
                          reference: _withCtx(() => [
                            _createElementVNode("label", {
                              class: _normalizeClass(item.cn),
                              style: {"cursor":"pointer"},
                              innerHTML: _ctx.values[item.value]
                            }, null, 10, _hoisted_17)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("table", _hoisted_13, [
                              _createElementVNode("tbody", null, [
                                _cache[5] || (_cache[5] = _createElementVNode("tr", {
                                  class: "text-gray-600 fonts-align",
                                  style: {"background-color":"#fff"}
                                }, [
                                  _createElementVNode("th", {
                                    scope: "col",
                                    nowrap: "",
                                    colspan: "2",
                                    class: "text-center p-2"
                                  }, " Request Timeline ")
                                ], -1)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timelines, (timeline, k) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                                    (_ctx.values.time_lines[timeline.value])
                                      ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                                          _createElementVNode("th", _hoisted_15, [
                                            _createElementVNode("i", {
                                              class: _normalizeClass(["fa fa-genderless icon-xl", timeline.cn])
                                            }, null, 2),
                                            _createTextVNode(" " + _toDisplayString(timeline.label), 1)
                                          ]),
                                          _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.values.time_lines[timeline.value]), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 128))
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024))
                ]))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isTable && _ctx.section != 'employee_categories')
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          true
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                (_ctx.values.members && _ctx.values.members.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values.members, (value, l) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "row lead-row m-0 pt-5",
                          key: l
                        }, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("h4", _hoisted_24, [
                                  _createElementVNode("div", _hoisted_25, [
                                    _createElementVNode("p", _hoisted_26, "Member " + _toDisplayString(l + 1), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_27, [
                                    _createElementVNode("span", _hoisted_28, _toDisplayString(value.is_temp === 1 ? 'Not Entered' : value.name), 1)
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("div", _hoisted_30, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                  (!item?.notShow)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                        ((item.value == 'is_married' && value['gender'] != 'Male') || (item.value != 'is_married'))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                              _createElementVNode("div", _hoisted_33, [
                                                _createElementVNode("div", {
                                                  class: _normalizeClass(item.cnd)
                                                }, [
                                                  _createElementVNode("p", _hoisted_34, _toDisplayString(item.label), 1)
                                                ], 2),
                                                _createElementVNode("p", _hoisted_35, [
                                                  (_ctx.common.getTextWidth(_ctx.values[item.value]) > _ctx.sizeWrap)
                                                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                                        key: 0,
                                                        content: _ctx.values[item.value],
                                                        placement: "bottom",
                                                        effect: "light"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createElementVNode("span", {
                                                            class: _normalizeClass({'wrap-content d-block': _ctx.common.getTextWidth(_ctx.values[item.value]) > _ctx.sizeWrap ? true : false }),
                                                            style: _normalizeStyle(`max-width: ${_ctx.sizeWrapBind}`),
                                                            innerHTML: value.is_temp === 1 ? '-' : value[item.value]
                                                          }, null, 14, _hoisted_36)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["content"]))
                                                    : (_openBlock(), _createElementBlock("span", {
                                                        key: 1,
                                                        innerHTML: value.is_temp === 1 ? '-' : value[item.value]
                                                      }, null, 8, _hoisted_37))
                                                ])
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (!item?.notShow)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                  _createElementVNode("div", _hoisted_41, [
                                    _createElementVNode("div", _hoisted_42, [
                                      _createElementVNode("div", {
                                        class: _normalizeClass(item.cnd)
                                      }, [
                                        _createElementVNode("p", _hoisted_43, _toDisplayString(item.label), 1)
                                      ], 2),
                                      _createElementVNode("p", _hoisted_44, [
                                        (_ctx.common.getTextWidth(_ctx.values[item.value]) > _ctx.sizeWrap)
                                          ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                              key: 0,
                                              content: _ctx.values[item.value],
                                              placement: "bottom",
                                              effect: "light"
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("span", {
                                                  class: _normalizeClass({'wrap-content d-block': _ctx.common.getTextWidth(_ctx.values[item.value]) > _ctx.sizeWrap ? true : false }),
                                                  style: _normalizeStyle(`max-width: ${_ctx.sizeWrapBind}`),
                                                  innerHTML: _ctx.values[item.value]
                                                }, null, 14, _hoisted_45)
                                              ]),
                                              _: 2
                                            }, 1032, ["content"]))
                                          : (_openBlock(), _createElementBlock("span", {
                                              key: 1,
                                              innerHTML: _ctx.values[item.value]
                                            }, null, 8, _hoisted_46)),
                                        (item.isViewDocument)
                                          ? (_openBlock(), _createBlock(_component_el_button, {
                                              key: 2,
                                              type: "text",
                                              size: "small",
                                              class: "ms-2 icon-width-42 icon-bg-common",
                                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchDocument()))
                                            }, {
                                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                _createElementVNode("i", { class: "icon-svg icon-view-all-documents" }, null, -1)
                                              ])),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ])
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ])
                    ]))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}