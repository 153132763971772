import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 4,
  class: "d-flex"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "m-5 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_vc_employee_categories = _resolveComponent("vc-employee-categories")!
  const _component_vc_emirates = _resolveComponent("vc-emirates")!
  const _component_vc_policies = _resolveComponent("vc-policies")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.invoice.categories,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (group, i) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: i,
            width: group.width,
            "header-align": "top",
            align: group.align
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", {
                class: "d-block fs-6 fw-bolder",
                innerHTML: group.label
              }, null, 8, _hoisted_2)
            ]),
            default: _withCtx((scope) => [
              (group.value == 'group_count')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    prop: `categories.` + scope.$index +`.group_count`,
                    rules: {
                                validator: (rule, value, callback) => {
                                    _ctx.Validations.amendGroupCount(rule, value, callback, scope.row.amount)
                                }, trigger: ['blur', 'change']
                            }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        type: "number",
                        ref_for: true,
                        ref: `categories.` + scope.$index + `.group_count`,
                        placeholder: "Members Count",
                        min: "0",
                        size: "large",
                        style: {"width":"100%"},
                        modelValue: scope.row['group_count'],
                        "onUpdate:modelValue": ($event: any) => ((scope.row['group_count']) = $event),
                        onChange: ($event: any) => (_ctx.updateValue(scope.row, 'group_count'))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]))
                : (group.value == 'members_above_60')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      prop: `categories.` + scope.$index +`.members_above_60`,
                      rules: { validator:(rule, value, callback) => {
                                        _ctx.Validations.members_above_60(rule, value, callback,scope.row.group_count, 'M > 60'); // Pass 18 as a custom parameter
                                    }, trigger: ['blur', 'change','input','keydown'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: 'number',
                          ref_for: true,
                          ref: `categories.` + scope.$index + `.members_above_60`,
                          placeholder: "Members > 60",
                          min: 0,
                          size: "large",
                          style: {"width":"100%"},
                          modelValue: scope.row['members_above_60'],
                          "onUpdate:modelValue": ($event: any) => ((scope.row['members_above_60']) = $event),
                          onChange: ($event: any) => (_ctx.updateValue(scope.row, 'members_above_60'))
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"]))
                  : (group.value == 'basmah')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        prop: `categories.` + scope.$index +`.basmah`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_vc_amount_v2, {
                            "with-prop": false,
                            type: 'number',
                            ref_for: true,
                            ref: `categories.` + scope.$index + `.basmah`,
                            placeholder: "Basmah",
                            min: "1",
                            size: "large",
                            style: {"width":"100%"},
                            modelValue: scope.row['basmah'],
                            "onUpdate:modelValue": ($event: any) => ((scope.row['basmah']) = $event),
                            disabled: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop"]))
                    : (group.value == 'icp')
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 3,
                          prop: `categories.` + scope.$index +`.icp`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_vc_amount_v2, {
                              "with-prop": false,
                              ref_for: true,
                              ref: `categories.` + scope.$index + `.icp`,
                              placeholder: "ICP",
                              min: "0",
                              size: "large",
                              style: {"width":"100%"},
                              modelValue: scope.row['icp'],
                              "onUpdate:modelValue": ($event: any) => ((scope.row['icp']) = $event),
                              disabled: scope.row['icp_disabled'] || _ctx.allDisabled,
                              onPress: _ctx.updateIcp
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onPress"])
                          ]),
                          _: 2
                        }, 1032, ["prop"]))
                      : (group.value == 'amount')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_el_form_item, {
                              prop: `categories.` + scope.$index +`.amount`,
                              rules: {
                                validator: (rule, value, callback) => {
                                    _ctx.Validations.amendAmount(rule, value, callback, scope.row.group_count)
                                }, trigger: ['blur', 'change']
                            },
                              style: {"width":"60%"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_vc_amount_v2, {
                                  "with-prop": false,
                                  ref_for: true,
                                  ref: `categories.` + scope.$index + `.amount`,
                                  placeholder: "amount",
                                  size: "large",
                                  style: {"width":"100%"},
                                  modelValue: scope.row['amount'],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row['amount']) = $event),
                                  onPress: ($event: any) => (_ctx.updatePolicyPrice(scope.row)),
                                  disabled: _ctx.allDisabled
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onPress", "disabled"])
                              ]),
                              _: 2
                            }, 1032, ["prop", "rules"]),
                            _createVNode(_component_vc_amount_v2, {
                              "with-prop": false,
                              disabled: "",
                              label: 'VAT',
                              modelValue: scope.row['amount_vat'],
                              "onUpdate:modelValue": ($event: any) => ((scope.row['amount_vat']) = $event),
                              style: {"width":"40%"},
                              placeholder: "VAT",
                              size: "large",
                              isRequired: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]))
                        : (group.value == 'non_taxable_fee')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 5,
                              prop: `categories.` + scope.$index +`.non_taxable_fee`
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_vc_amount_v2, {
                                  "with-prop": false,
                                  ref_for: true,
                                  ref: `categories.` + scope.$index + `.non_taxable_fee`,
                                  placeholder: "Non Taxable Fee",
                                  min: "1",
                                  size: "large",
                                  style: {"width":"100%"},
                                  modelValue: scope.row['non_taxable_fee'],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row['non_taxable_fee']) = $event),
                                  onPress: _ctx.updateNonTaxableFee,
                                  disabled: _ctx.allDisabled
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onPress", "disabled"])
                              ]),
                              _: 2
                            }, 1032, ["prop"]))
                          : (group.value == 'name')
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 6,
                                prop: `categories.` + scope.$index +`.name`,
                                rules: {
                                validator: (rule, value, callback) => {
                                    _ctx.Validations.category_name(rule, value, callback)
                                }, trigger: ['blur', 'change']
                            }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_vc_employee_categories, {
                                    ref_for: true,
                                    ref: `categories.` + scope.$index + `.name`,
                                    modelValue: scope.row['name'],
                                    "onUpdate:modelValue": ($event: any) => ((scope.row['name']) = $event),
                                    disabled: _ctx.isDisabled
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                                ]),
                                _: 2
                              }, 1032, ["prop", "rules"]))
                            : (group.value == 'visa_emirates')
                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                  key: 7,
                                  prop: `categories.` + scope.$index +`.visa_emirates`,
                                  rules: {
                                validator: (rule, value, callback) => {
                                    _ctx.Validations.category_visa_issued(rule, value, callback)
                                }, trigger: ['blur', 'change']
                            }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_vc_emirates, {
                                      ref_for: true,
                                      ref: `categories.` + scope.$index + `.visa_emirates`,
                                      modelValue: scope.row['visa_emirates'],
                                      "onUpdate:modelValue": ($event: any) => ((scope.row['visa_emirates']) = $event),
                                      disabled: _ctx.isDisabled
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                                  ]),
                                  _: 2
                                }, 1032, ["prop", "rules"]))
                              : (group.value == 'insurance_plan_id')
                                ? (_openBlock(), _createBlock(_component_el_form_item, {
                                    key: 8,
                                    prop: `categories.` + scope.$index +`.insurance_plan_id`,
                                    rules: {
                                validator: (rule, value, callback) => {
                                    _ctx.Validations.category_insurance_plan(rule, value, callback)
                                }, trigger: ['blur', 'change']
                            }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_vc_policies, {
                                        ref_for: true,
                                        ref: `categories.` + scope.$index +`.insurance_plan_id`,
                                        modelValue: scope.row['insurance_plan_id'],
                                        "onUpdate:modelValue": ($event: any) => ((scope.row['insurance_plan_id']) = $event),
                                        options: _ctx.plans,
                                        disabled: _ctx.isDisabled
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled"])
                                    ]),
                                    _: 2
                                  }, 1032, ["prop", "rules"]))
                                : (group.value == 'remove')
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 9,
                                      class: "cursor-pointer icon-bg-common",
                                      style: {"margin-bottom":"18px"},
                                      onClick: ($event: any) => (_ctx.deleteCategory(scope.$index))
                                    }, [
                                      _createVNode(_component_el_tooltip, {
                                        class: "box-item",
                                        content: "Delete",
                                        effect: "light",
                                        placement: "bottom",
                                        "show-after": 200,
                                        "hide-after": 0
                                      }, {
                                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                                          _createElementVNode("i", { class: "icon-svg icon-delete" }, null, -1)
                                        ])),
                                        _: 1
                                      })
                                    ], 8, _hoisted_4))
                                  : (_openBlock(), _createElementBlock("span", {
                                      key: 10,
                                      innerHTML: scope.row[group.value]
                                    }, null, 8, _hoisted_5))
            ]),
            _: 2
          }, 1032, ["width", "align"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.invoice.categories.length > 0)
        ? (_openBlock(), _createBlock(_component_vc_button_save, {
            key: 0,
            loading: _ctx.validateLoading,
            text: 'Validate duplicates',
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.validateDuplicates()), ["prevent"]))
          }, null, 8, ["loading"]))
        : _createCommentVNode("", true)
    ])
  ]))
}