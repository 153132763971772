import { Filter, Invoice,GroupInvoice, Listing, Counts } from '@/core/types/Invoice';
import moment from 'moment';

export type State = {
  invoice: Invoice;
  groupInvoice: GroupInvoice;
  filter: Filter;
  loading: boolean;
  invoiceLists: any;
  invoiceListCounts: Array<Counts>;
  openInvoiceModal:Boolean;
  openInvoiceModalValue:any;
  openInterestedPolicyModal:Boolean;
  interestedPolicy:any;
  invoiceApprovalActionModal:any;
  invoiceDetails:any;
  invoiceData:any;
  groupInvoiceLists: any;
  groupInvoiceListCounts: Array<Counts>;
  groupInvoiceLog:any;
}

export const state: State = {
  invoice: {
    invoice_id: null,
    quote_ref_no: null,
    customer_id: null,
    lead_id: null,
    insurance_plan: null,
    copay: null,
    orginal_policy_price: 0,
    orginal_policy_price_vat: 0,
    orginal_policy_price_vat_display: '0',
    policy_price: 0,
    invoice_amount:0,
    invoice_amount_display: '0',
    sales_price: 0,
    basmah: 0,
    admin_extra_charges: 0,
    basmah_vat: 0,
    is_discount_given: false,
    discount_amount: 0,
    is_split_payment: false,
    payment_type: null,
    pending_payment_type: null,
    installments: null,
    order_description: null,
    reference_no: null,
    payment_date: null,
    file_receipt: null,
    language: "en",
    expiry_date_time: null,
    client_classification: null,
    members: null,

    icp: 0,
    icp_vat: 0,
    icp_vat_display: '0',
    non_taxable_fee:0,
  },
  filter: {
    agent: "all",
    end_date: "",
    index: 1,
    keyword: "",
    page: 1,
    per_page: 50,
    start_date: "",
    payment_type: -1,
    is_amend: -1,
    status: 1,
    is_manual_order: 0,
    is_discrepancy: 0,
    tab: 'all'
  },
  loading: false,
  invoiceLists: null,
  invoiceListCounts: [],
  openInvoiceModal: false,
  openInvoiceModalValue: {
    isError: false,
    idDocError:false,
    msg: ''
  },
  openInterestedPolicyModal: false,
  interestedPolicy: null,
  invoiceApprovalActionModal: {
    show: false,
    loading: false,
    invoice_id: 0,
    status: 0,
    item: null
  },
  invoiceDetails: {
    show: false,
    invoice: null
  },
  invoiceData:null,
  groupInvoice: {
    invoice_id: null,
    quote_ref_no:null,
    customer_id: null,
    lead_id: null,
    insurance_plan: null,
    copay: null,
    orginal_policy_price: 0,
    orginal_policy_price_vat: 0,
    policy_price: 0,
    invoice_amount:0,
    sales_price: 0,
    basmah:0,
    basmah_vat:0,
    is_discount_given: false,
    discount_amount: 0,
    is_split_payment: false,
    payment_type: null,
    pending_payment_type: null,
    installments: null,
    order_description: null,
    reference_no: null,
    payment_date: null,
    file_receipt: null,
    language: "en",
    expiry_date_time: null,
    client_classification:null,
    members: null,
    categories:[],
    icp: 0,
    icp_vat: 0,
    non_taxable_fee:0,
    all_invoices: []

  },
  groupInvoiceLists: null,
  groupInvoiceListCounts : [],
  groupInvoiceLog: {
    show:false,
    log:null
  }
};
