import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-header ribbon mb-5 mt-5"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_phone = _resolveComponent("vc-phone")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_countries = _resolveComponent("vc-countries")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_gender = _resolveComponent("vc-gender")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card mb-5", `${_ctx.isEdit ? '' : 'card-bordered'}`])
  }, [
    (_ctx.isShowHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "card-title mb-5" }, [
            _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Personal Details ")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
          }, [
            _createVNode(_component_vc_phone, {
              label: "Phone Number",
              placeholder: "Enter Phone Number",
              prop: "phone_number",
              ref: "phone_number",
              isInput: "text",
              maxlength: _ctx.health.phone_country_code == '+961' ? '8' : '10',
              class: "phone country-code-dropdown",
              keydown: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')",
              modelValue: _ctx.health.phone_number,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.phone_number) = $event)),
              code: _ctx.health.phone_country_code,
              "onUpdate:code": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.health.phone_country_code) = $event)),
              disabled: _ctx.isDisbaled || _ctx.isDisabledMain,
              isRequired: "",
              onKeydown: _withKeys(_withModifiers(_ctx.sa, ["prevent"]), ["enter"]),
              onInput: _ctx.phoneEvent,
              onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('checkPhoneExist')))
            }, null, 8, ["maxlength", "modelValue", "code", "disabled", "onKeydown", "onInput"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
          }, [
            _createVNode(_component_vc_input, {
              label: "Full Name",
              placeholder: "Enter Full Name",
              prop: "name",
              ref: "name",
              size: "large",
              modelValue: _ctx.health.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.health.name) = $event)),
              disabled: (_ctx.isDisbaled || _ctx.isDisabledMain) && !_ctx.isEnableName,
              onKeydown: _withKeys(_withModifiers(_ctx.sa, ["prevent"]), ["enter"]),
              focus: true,
              isRequired: "",
              keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)"
            }, null, 8, ["modelValue", "disabled", "onKeydown"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
          }, [
            _createVNode(_component_vc_input, {
              label: "Email",
              isInput: "email",
              placeholder: "Enter Email",
              prop: "email",
              ref: "email",
              size: "large",
              modelValue: _ctx.health.email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.health.email) = $event)),
              isPrefix: "",
              isRequired: "",
              disabled: (_ctx.isDisbaled && _ctx.ql || _ctx.isDisabledMain),
              onKeydown: _withKeys(_withModifiers(_ctx.sa, ["prevent"]), ["enter"]),
              onInput: _ctx.emailEvent,
              keydown: "return /[-a-zA-Z0-9_@.]/i.test(event.key)"
            }, null, 8, ["modelValue", "disabled", "onKeydown", "onInput"])
          ], 2)
        ], 2),
        (_ctx.updateCustomer)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
              }, [
                _createVNode(_component_vc_input, {
                  modelValue: _ctx.health.other_contact_info,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.health.other_contact_info) = $event)),
                  prop: "other_contact_info",
                  label: "Other Contact Information",
                  placeholder: "Enter contact information",
                  size: "large"
                }, null, 8, ["modelValue"])
              ], 2)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label required" }, " DOB ", -1)),
            _createVNode(_component_vc_date_picker, {
              modelValue: _ctx.health.dob,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.health.dob) = $event)),
              min: "1900-01-01",
              prop: "dob",
              ref: "dob",
              label: "DOB",
              disabled: _ctx.isDisbaled && _ctx.ql,
              isRequired: "",
              max: _ctx.common.minDate(),
              defaultValue: _ctx.common.minDate()
            }, null, 8, ["modelValue", "disabled", "max", "defaultValue"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
          }, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label required" }, " Nationality ", -1)),
            _createVNode(_component_el_form_item, { prop: "nationality_id" }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_countries, {
                  modelValue: _ctx.health.nationality_id,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.health.nationality_id) = $event)),
                  ref: "nationality_id",
                  label: "Select your nationality",
                  disabled: _ctx.isDisbaled && _ctx.ql
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            })
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
          }, [
            _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label required" }, " Gender ", -1)),
            _createVNode(_component_el_form_item, {
              prop: "gender",
              class: "el-form-item-custom-radio"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_gender, {
                  genderName: "gender",
                  modelValue: _ctx.health.gender,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.health.gender) = $event)),
                  ref: "gender",
                  disabled: _ctx.isDisbaled,
                  onGender: _ctx.updateGender
                }, null, 8, ["modelValue", "disabled", "onGender"])
              ]),
              _: 1
            })
          ], 2)
        ], 2),
        (_ctx.health.gender === 'FEMALE')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(`col-sm-${_ctx.isEdit ? 6 : 3}`)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(`mb-${_ctx.isEdit ? 0 : 10}`)
              }, [
                _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label required" }, " Is married? ", -1)),
                _createVNode(_component_el_form_item, {
                  yesNoName: "yesNoIsMarried",
                  prop: "is_married",
                  class: "el-form-item-custom-radio"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_yes_no, {
                      modelValue: _ctx.health.is_married,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.health.is_married) = $event)),
                      disabled: _ctx.isDisbaled
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}