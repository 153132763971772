import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    id: 'policyActionDrpDown'+_ctx.policy.id,
    "destroy-on-close": true
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          (!_ctx.policy.isChild && !_ctx.isView && (_ctx.permissions.is_admin || _ctx.permissions.is_underwritter || _ctx.permissions.is_qca))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: "javascript:void(0);",
                    title: "Edit Policy",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirect(_ctx.policy.id))),
                    class: "btn-active-color-primary btn-sm dropdown-item me-1"
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("i", { class: "icon-svg icon-edit" }, null, -1),
                    _createTextVNode(" Edit Policy Details ")
                  ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.policy.isChild && (_ctx.permissions.is_sales_agent || _ctx.permissions.is_team_leader || _ctx.permissions.is_accountant || _ctx.permissions.is_manager))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: "javascript:void(0);",
                    title: "View Policy",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirect(_ctx.policy.id , true))),
                    class: "btn-active-color-primary btn-sm dropdown-item me-1"
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("i", { class: "icon-svg icon-view-policy" }, null, -1),
                    _createTextVNode(" View Policy Details ")
                  ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ((!_ctx.policy.isChild && _ctx.policy.policy_document))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 2 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: "javascript:void(0);",
                    title: "View Policy",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.viewPolicyDocument(_ctx.policy.policy_document))),
                    class: "btn-active-color-primary btn-sm dropdown-item me-1"
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("i", { class: "icon-svg icon-view-policy" }, null, -1),
                    _createTextVNode(" View Policy")
                  ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ((!_ctx.policy.isChild && _ctx.policy.health_lead_documents.length > 0))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 3 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "btn-active-color-primary btn-sm dropdown-item",
                    href: "javascript:void(0)",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchDocument()))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("i", { class: "icon-svg icon-view-all-documents" }, null, -1),
                    _createTextVNode(" View All Documents")
                  ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.policy.isChild && !_ctx.isView && (_ctx.permissions.is_admin || _ctx.permissions.is_manager || _ctx.permissions.is_qca))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 4 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "btn-active-color-primary btn-sm dropdown-item",
                    href: "javascript:void(0)",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.transferSaleAgent()))
                  }, _cache[11] || (_cache[11] = [
                    _createElementVNode("i", { class: "icon-svg icon-transfer-sale" }, null, -1),
                    _createTextVNode(" Transfer Sale")
                  ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.policy.isChild && !_ctx.isView && (_ctx.permissions.is_admin || _ctx.permissions.is_underwritter || _ctx.permissions.is_manager || _ctx.permissions.is_qca))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 5 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "btn-active-color-primary btn-sm dropdown-item",
                    href: "javascript:void(0)",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.transferUnderwriter()))
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("i", { class: "icon-svg icon-change-underwriter" }, null, -1),
                    _createTextVNode(" Change Underwriter")
                  ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.policy.isChild && !_ctx.isView && _ctx.policy.main_invoice && _ctx.policy.main_invoice.invoice_doc)
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 6 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "btn-sm dropdown-item",
                    href: _ctx.policy.main_invoice.invoice_doc.file_path,
                    target: "_blank"
                  }, _cache[13] || (_cache[13] = [
                    _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                    _createTextVNode(" View Receipt")
                  ]), 8, _hoisted_1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.policy.isChild && !_ctx.isView && _ctx.policy && _ctx.policy.invoice_doc)
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 7 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "btn-sm dropdown-item",
                    href: _ctx.policy.invoice_doc.file_path,
                    target: "_blank"
                  }, _cache[14] || (_cache[14] = [
                    _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                    _createTextVNode(" View Receipt")
                  ]), 8, _hoisted_2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        size: "small",
        class: "el-dropdown-custom"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" ... ")
        ])),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id"]))
}