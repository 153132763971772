import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.statusModal.isNotValidBrowser,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statusModal.isNotValidBrowser) = $event)),
    title: "Skye Information",
    width: "30%",
    "custom-class": "confirmation-popups "
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.statusModal.isNotValidBrowser = false))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Close")
          ])),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "m-5" }, "To enhance your experience kindly browse SKYE on Chrome or Firefox", -1))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}