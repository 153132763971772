
import { computed, defineComponent, onMounted, ref } from 'vue'
import { tDocuments, logs } from '@/core/utils'
import { common, notification } from '@/store/stateless';
import Documents from '@/core/services/Callable/Documents';
import { store } from '@/store'
import { docType } from '@/core/types/Document'

interface dataValue {
    lead_id: number,
    policy_id: number,
    categories: string,
    lead_employee_category_id?: number,
    document_type:string,
}

export default defineComponent({
    props: {
        documents: {
            type: Array,
            required: true,
            default: [] as Array<docType>
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDownload: {
            type: Boolean,
            required: false,
            default: false
        },
        policy_id: {
            type: Number,
            required: false,
            default: null
        },

        lead_id: {
            type: Number,
            required: false,
            default: null
        },
        lead_employee_category_id: {
            type: Number,
            required: false,
            default: 0
        },
        showLog: {
            type:Boolean,
            required: false,
            default: false
        },
        isDisableAllFields: {
            type: Boolean,
            required: false,
            default: false
        },
        verificationTypes: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(_, { emit }) {

        const open = ref<Boolean>(false)
        const openDelete = ref<Boolean>(false)
        const openLog = ref<Boolean>(false)
        const link = ref<String>('')
        const document_id = ref<Number>(0)
        const agent_documents = [1,2,3,4,8,9,11,12,19]

        onMounted(async () => {
            await callDocumentTypes()
            console.log(_.documents)
            console.log(document_types.value)
        })

        const getDocumentType = (value) => {
            const type = document_types.value.find(x => x.value == value)
            if(type)
                return type.label;
        }



        const disabledDocumentType = (value) => {
            if(agent_documents.includes(value)){
                // console.log(props.data)
                const exist = _.documents.find((x:any) => x.type == value)
                if(exist)
                    return true
            }
            return false
        }

        const document_types = computed(() => {
            return store.getters.getDocumentTypes
        })

        const document_actions = computed(() => {
            let data = store.getters.getDocumentActions(store.getters.myRoles, _.showDelete, _.hideDownload, _.showLog)
            if(_.isDisableAllFields){
                data = data.filter(x => !x.isDelete)
            }
            return data
        })

        const modal = (value) => {
            open.value = value
        }

        const openFile = (value) => {
            if (value.file_extension.toLowerCase() !== 'pdf') {
                link.value = value.link
                modal(true)
            }
        }

        const updateDocumentType = async (value) => {
            if (value.type !== 0) {
                value.document_type = document_types.value.find(x => x.value == value.type).document_type
                await Documents.updateDocumentType({
                    document_id: value.document_id,
                    type: value.type
                })
            }
        }

        const makeAction = (value, action) => {
            switch (action) {
                case 1:
                    document_id.value = value.document_id
                    modalDelete(true)
                    break;
                case 2:
                    window.open(value.encrypted_file_path); break;
                case 3:
                    window.open(value.file_path); break;
                case 4:
                    viewLogs(value.document_id); break;
                default:
                    return '';
            }
        }

        const viewLogs = async (document_id) => {
            openLog.value = true
            await Documents.getDocumentLogById({
                reference_id: document_id,
                module: 'lead'
            })
        }

        const modalDelete = (value) => {
            openDelete.value = value
            if (!value) document_id.value = 0
        }

        const loading = ref(false)
        const confirmDelete = async (value: number) => {
            loading.value = true;
            
            await Documents.deleteDocumentType({ document_id: value })

            if( _.documents.length > 0){
                const doc:any = _.documents.find((x:any) => x.document_id == value)
                if(doc && doc.type == 3)
                    emit('delete-emirates')
            }

            notification.success('Document', 'You have successfully deleted a document')
            // _.documents.filter((x:any) => x.document_id === value)
            modalDelete(false)
            emit('refresh-data')

            

            loading.value = false;

        }

        async function callDocumentTypes() {
            if (_.isEdit) {
                const condition = <dataValue>{ categories: 'standard' , policy_id:_.policy_id, lead_id:_.lead_id }
                if(_.lead_employee_category_id > 0) condition.lead_employee_category_id = _.lead_employee_category_id

                if(_.verificationTypes) condition.document_type = 'verification'

                await Documents.getDocumentTypes(condition)
            }
        }

        return {
            callDocumentTypes,
            document_actions,
            document_types,
            document_id,
            tDocuments,
            openDelete,
            openLog,
            common,
            store,
            logs,
            open,
            link,
            modal,
            viewLogs,
            openFile,
            makeAction,
            modalDelete,
            confirmDelete,
            updateDocumentType,
            loading,
            getDocumentType,
            disabledDocumentType,
            agent_documents
        }
    },
})
