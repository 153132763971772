import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "p-5" }
const _hoisted_2 = {
  class: "card card-bordered mb-5",
  ref: "invoice_information"
}
const _hoisted_3 = { class: "m-2" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  class: "card card-bordered mb-5",
  id: "last"
}
const _hoisted_8 = { class: "card-header ribbon mb-5 d-flex flex-nowrap align-items-center" }
const _hoisted_9 = { class: "card-tools d-flex align-items-center" }
const _hoisted_10 = { class: "m-2" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-3" }
const _hoisted_13 = { class: "mb-10" }
const _hoisted_14 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_15 = { class: "mb-10" }
const _hoisted_16 = { class: "col-sm-3" }
const _hoisted_17 = { class: "mb-10" }
const _hoisted_18 = { class: "col-sm-3" }
const _hoisted_19 = { class: "mb-10" }
const _hoisted_20 = { class: "form-label" }
const _hoisted_21 = { class: "col-sm-3" }
const _hoisted_22 = { class: "mb-10" }
const _hoisted_23 = { class: "col-sm-3" }
const _hoisted_24 = { class: "mb-10 d-flex" }
const _hoisted_25 = {
  key: 0,
  class: "col-sm-3"
}
const _hoisted_26 = { class: "mb-10" }
const _hoisted_27 = { class: "col-sm-3" }
const _hoisted_28 = { class: "mb-10 d-flex" }
const _hoisted_29 = { class: "col-sm-3" }
const _hoisted_30 = { class: "mb-10" }
const _hoisted_31 = { class: "col-sm-3" }
const _hoisted_32 = { class: "mb-10" }
const _hoisted_33 = {
  key: 1,
  class: "col-sm-3"
}
const _hoisted_34 = { class: "mb-10" }
const _hoisted_35 = {
  key: 2,
  class: "col-sm-3"
}
const _hoisted_36 = { class: "mb-10" }
const _hoisted_37 = {
  key: 3,
  class: "col-sm-3"
}
const _hoisted_38 = { class: "mb-10" }
const _hoisted_39 = { class: "col-sm-3" }
const _hoisted_40 = { class: "mb-10" }
const _hoisted_41 = {
  key: 4,
  class: "col-sm-3"
}
const _hoisted_42 = { class: "mb-10" }
const _hoisted_43 = { class: "col-sm-3" }
const _hoisted_44 = { class: "mb-10" }
const _hoisted_45 = { class: "form-label" }
const _hoisted_46 = {
  key: 5,
  class: "col-sm-3"
}
const _hoisted_47 = { class: "mb-10" }
const _hoisted_48 = {
  key: 6,
  class: "col-sm-3"
}
const _hoisted_49 = { class: "mb-10" }
const _hoisted_50 = { class: "col-sm-3" }
const _hoisted_51 = { class: "mb-10" }
const _hoisted_52 = {
  key: 7,
  class: "col-sm-3"
}
const _hoisted_53 = { class: "mb-10" }
const _hoisted_54 = {
  key: 8,
  class: "col-sm-3"
}
const _hoisted_55 = { class: "mb-10" }
const _hoisted_56 = {
  key: 9,
  class: "col-sm-3"
}
const _hoisted_57 = { class: "mb-10" }
const _hoisted_58 = {
  key: 10,
  class: "col-sm-3"
}
const _hoisted_59 = { class: "mb-10" }
const _hoisted_60 = {
  key: 11,
  class: "col-sm-3"
}
const _hoisted_61 = { class: "mb-10" }
const _hoisted_62 = ["href"]
const _hoisted_63 = {
  key: 12,
  class: "col-sm-3"
}
const _hoisted_64 = { class: "mb-10" }
const _hoisted_65 = ["href"]
const _hoisted_66 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_vc_payment_type = _resolveComponent("vc-payment-type")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!
  const _component_vc_client_classification = _resolveComponent("vc-client-classification")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.invoice)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _ctx.invoiceDetails.show,
        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.invoiceDetails.show) = $event)),
        width: "90%",
        "append-to-body": "",
        onClosed: _cache[28] || (_cache[28] = ($event: any) => (_ctx.closeModal()))
      }, {
        header: _withCtx(() => _cache[29] || (_cache[29] = [
          _createElementVNode("div", { class: "d-flex" }, [
            _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Invoice Details ")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[30] || (_cache[30] = _createElementVNode("div", { class: "card-header ribbon mb-5" }, [
                _createElementVNode("div", { class: "card-title mb-5 mt-5" }, [
                  _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Group Details ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_el_table, {
                  data: _ctx.invoice.categories,
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoice_details_group_columns, (group, i) => {
                      return (_openBlock(), _createBlock(_component_el_table_column, {
                        key: i,
                        width: group.width,
                        "header-align": "top"
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "d-block fs-6 fw-bolder",
                            innerHTML: group.label
                          }, null, 8, _hoisted_4)
                        ]),
                        default: _withCtx((scope) => [
                          (group.isFormat)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.common.formatCurrency(scope.row[group.value])), 1))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                innerHTML: scope.row[group.value]
                              }, null, 8, _hoisted_6))
                        ]),
                        _: 2
                      }, 1032, ["width"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ], 512),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "card-title" }, [
                  _createElementVNode("h6", { class: "card-subtitle text-muted" }, "Invoice Information")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _cache[31] || (_cache[31] = _createElementVNode("label", {
                    for: "exampleFormControlInput1",
                    class: "form-label required text-warning me-3 d-block"
                  }, "Please Select Agent for this Policy", -1)),
                  _createVNode(_component_vc_input, {
                    disabled: "",
                    modelValue: _ctx.policySalesAgent,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.policySalesAgent) = $event)),
                    size: "large"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (_ctx.invoice.amend_type == 2)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.invoice.parent_invoice[0].policy_price)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  _createVNode(_component_vc_amount_v2, {
                                    disabled: "",
                                    label: "Policy Price",
                                    subLabel: '(incl. VAT) ('+_ctx.currency+')',
                                    modelValue: _ctx.invoice.parent_invoice[0].policy_price,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.invoice.parent_invoice[0].policy_price) = $event)),
                                    placeholder: "Policy Price",
                                    prop: "policy_price",
                                    ref: "policy_price",
                                    size: "large"
                                  }, null, 8, ["subLabel", "modelValue"])
                                ])
                              ]),
                              (_ctx.invoice.parent_invoice[0].basmah > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    _createElementVNode("div", _hoisted_15, [
                                      _createVNode(_component_vc_amount_v2, {
                                        disabled: "",
                                        label: "Basmah Amount",
                                        subLabel: '(incl. VAT)('+_ctx.currency+')',
                                        modelValue: _ctx.invoice.parent_invoice[0].basmah,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.invoice.parent_invoice[0].basmah) = $event)),
                                        placeholder: "Basmah Amount",
                                        prop: "basmah_amount",
                                        ref: "basmah_amount",
                                        size: "large"
                                      }, null, 8, ["subLabel", "modelValue"])
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_vc_amount_v2, {
                                    disabled: "",
                                    label: "Total Payable / Invoice Amount",
                                    subLabel: '('+_ctx.currency+')',
                                    modelValue: _ctx.invoice.parent_invoice[0].invoice_amount,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.invoice.parent_invoice[0].invoice_amount) = $event)),
                                    placeholder: "Total Payable / Invoice Amount",
                                    prop: "invoice_amount",
                                    ref: "invoice_amount",
                                    size: "large"
                                  }, null, 8, ["subLabel", "modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.invoice.parent_invoice[0].is_split_payment ? '1st' : '') + " Payment Type", 1),
                                  _createVNode(_component_el_form_item, { prop: "payment_type" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_vc_payment_type, {
                                        modelValue: _ctx.invoice.parent_invoice[0].payment_type,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoice.parent_invoice[0].payment_type) = $event)),
                                        disabled: ""
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ])
                              ])
                            ], 64))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_vc_amount_v2, {
                              disabled: "",
                              label: "Refund Amount",
                              subLabel: '('+_ctx.currency+')',
                              modelValue: _ctx.invoice.invoice_amount,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoice.invoice_amount) = $event)),
                              placeholder: "Refund Amount",
                              prop: "refund_amount",
                              ref: "refund_amount",
                              size: "large"
                            }, null, 8, ["subLabel", "modelValue"])
                          ])
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_vc_amount_v2, {
                              label: _ctx.isAmend ? 'Amend Price' :'Policy Price',
                              subLabel: '(w/o. VAT)('+_ctx.currency+')',
                              modelValue: _ctx.invoice.orginal_policy_price,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.invoice.orginal_policy_price) = $event)),
                              placeholder: "Policy Price",
                              prop: "policy_price",
                              ref: "policy_price",
                              size: "large",
                              style: {"width":"100%"},
                              isRequired: "",
                              disabled: ""
                            }, null, 8, ["label", "subLabel", "modelValue"]),
                            _createVNode(_component_vc_amount_v2, {
                              disabled: "",
                              label: 'Vat',
                              subLabel: '('+_ctx.currency+')',
                              modelValue: _ctx.invoice.policy_price_vat,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.invoice.policy_price_vat) = $event)),
                              placeholder: "Vat",
                              prop: "policy_price",
                              size: "large",
                              style: {"width":"50%"},
                              isRequired: ""
                            }, null, 8, ["subLabel", "modelValue"])
                          ])
                        ]),
                        (_ctx.invoice.basmah > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createVNode(_component_vc_amount_v2, {
                                  disabled: "",
                                  label: "Basmah Amount",
                                  subLabel: '(incl. VAT)('+_ctx.currency+')',
                                  modelValue: _ctx.invoice.basmah,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.invoice.basmah) = $event)),
                                  placeholder: "Basmah Amount",
                                  prop: "basmah_amount",
                                  ref: "basmah_amount",
                                  size: "large"
                                }, null, 8, ["subLabel", "modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createVNode(_component_vc_amount_v2, {
                              label: 'ICP',
                              subLabel: '(w/o. VAT)('+_ctx.currency+')',
                              modelValue: _ctx.invoice.icp,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.invoice.icp) = $event)),
                              placeholder: "ICP/Other fee",
                              prop: "icp",
                              ref: "icp",
                              size: "large",
                              style: {"width":"100%"},
                              isRequired: "",
                              disabled: ""
                            }, null, 8, ["subLabel", "modelValue"]),
                            _createVNode(_component_vc_amount_v2, {
                              disabled: "",
                              label: 'Vat',
                              subLabel: '('+_ctx.currency+')',
                              modelValue: _ctx.invoice.icp_vat,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.invoice.icp_vat) = $event)),
                              placeholder: "Vat",
                              size: "large",
                              isRequired: "",
                              style: {"width":"50%"}
                            }, null, 8, ["subLabel", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_vc_amount_v2, {
                              label: "Non Taxable Fee",
                              subLabel: '('+_ctx.currency+')',
                              modelValue: _ctx.invoice.non_taxable_fee,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.invoice.non_taxable_fee) = $event)),
                              placeholder: "Non Taxable Fee",
                              prop: "non_taxable_fee",
                              ref: "non_taxable_fee",
                              size: "large",
                              isRequired: "",
                              disabled: ""
                            }, null, 8, ["subLabel", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_component_vc_amount_v2, {
                              disabled: "",
                              label: "Total Payable / Invoice Amount",
                              subLabel: '('+_ctx.currency+')',
                              modelValue: _ctx.invoice.invoice_amount,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.invoice.invoice_amount) = $event)),
                              placeholder: "Total Payable / Invoice Amount",
                              prop: "invoice_amount",
                              ref: "invoice_amount",
                              size: "large"
                            }, null, 8, ["subLabel", "modelValue"])
                          ])
                        ]),
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                              _createElementVNode("div", _hoisted_34, [
                                _createVNode(_component_vc_input, {
                                  disabled: "",
                                  label: "Sales Price",
                                  subLabel: '('+_ctx.currency+')',
                                  modelValue: _ctx.invoice.sales_price,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.invoice.sales_price) = $event)),
                                  placeholder: "Sales Price",
                                  prop: "sales_price",
                                  ref: "sales_price",
                                  size: "large"
                                }, null, 8, ["subLabel", "modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                              _createElementVNode("div", _hoisted_36, [
                                _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label" }, " Discount Given ", -1)),
                                _createVNode(_component_el_form_item, { prop: "is_discount_given" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_vc_yes_no, {
                                      modelValue: _ctx.invoice.is_discount_given,
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.invoice.is_discount_given) = $event)),
                                      yesNoName: `is_discount_given`
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.invoice.is_discount_given)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                              _createElementVNode("div", _hoisted_38, [
                                _createVNode(_component_vc_input, {
                                  disabled: "",
                                  label: "Discount Amount",
                                  subLabel: '('+_ctx.currency+')',
                                  modelValue: _ctx.invoice.discount_amount,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.invoice.discount_amount) = $event)),
                                  placeholder: "Discount Amount",
                                  prop: "discount_amount",
                                  ref: "discount_amount",
                                  size: "large"
                                }, null, 8, ["subLabel", "modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _cache[34] || (_cache[34] = _createElementVNode("label", { class: "form-label" }, " Client Classification ", -1)),
                            _createVNode(_component_el_form_item, { prop: "client_classification" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_vc_client_classification, {
                                  disabled: "",
                                  modelValue: _ctx.invoice.client_classification,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.invoice.client_classification) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        (_ctx.invoice.amend_type == '0')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                              _createElementVNode("div", _hoisted_42, [
                                _cache[35] || (_cache[35] = _createElementVNode("label", { class: "form-label" }, " Is Split Payment ? ", -1)),
                                _createVNode(_component_el_form_item, { prop: "is_split_payment" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_vc_yes_no, {
                                      modelValue: _ctx.invoice.is_split_payment,
                                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.invoice.is_split_payment) = $event)),
                                      yesNoName: `is_split_payment`,
                                      disabled: ""
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_43, [
                          _createElementVNode("div", _hoisted_44, [
                            _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.invoice.is_split_payment ? '1st' : '') + " Payment Type", 1),
                            _createVNode(_component_el_form_item, { prop: "payment_type" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_vc_payment_type, {
                                  modelValue: _ctx.invoice.payment_type,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.invoice.payment_type) = $event)),
                                  disabled: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        (_ctx.invoice.is_split_payment)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                              _createElementVNode("div", _hoisted_47, [
                                _cache[36] || (_cache[36] = _createElementVNode("label", { class: "form-label" }, " Pending Payment Type", -1)),
                                _createVNode(_component_el_form_item, { prop: "pending_payment_type" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_vc_payment_type, {
                                      modelValue: _ctx.invoice.pending_payment_type,
                                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.invoice.pending_payment_type) = $event)),
                                      pendingPaymentType: true,
                                      disabled: ""
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.invoice.is_split_payment && _ctx.invoice.pending_payment_type == 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                              _createElementVNode("div", _hoisted_49, [
                                _createVNode(_component_vc_input, {
                                  disabled: "",
                                  label: "Number of Installments",
                                  modelValue: _ctx.invoice.installments,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.invoice.installments) = $event)),
                                  placeholder: "Number of Installments",
                                  prop: "installments",
                                  ref: "installments",
                                  size: "large"
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_50, [
                          _createElementVNode("div", _hoisted_51, [
                            _createVNode(_component_vc_input, {
                              disabled: "",
                              label: "Order Description",
                              modelValue: _ctx.invoice.order_description,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.invoice.order_description) = $event)),
                              placeholder: "Order Description",
                              prop: "order_description",
                              ref: "order_description",
                              maxLength: "100",
                              size: "large"
                            }, null, 8, ["modelValue"])
                          ])
                        ]),
                        (_ctx.invoice.payment_type == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                              _createElementVNode("div", _hoisted_53, [
                                _cache[38] || (_cache[38] = _createElementVNode("label", { class: "form-label" }, " Link Expiry date ", -1)),
                                _createVNode(_component_el_form_item, { prop: "expiry_date_time" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_vc_date_picker, {
                                      modelValue: _ctx.invoice.expiry_date_time,
                                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.invoice.expiry_date_time) = $event)),
                                      min: "1900-01-01",
                                      ref: "expiry_date_time",
                                      label: "Link Expiry date"
                                    }, null, 8, ["modelValue"]),
                                    _cache[37] || (_cache[37] = _createElementVNode("label", {
                                      for: "payment_holder",
                                      class: "form-label text-warning"
                                    }, "Only for online payment methods", -1))
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.invoice.payment_type == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                              _createElementVNode("div", _hoisted_55, [
                                _cache[39] || (_cache[39] = _createElementVNode("label", { class: "form-label" }, " Language ", -1)),
                                _createVNode(_component_el_form_item, { prop: "language" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_select, {
                                      modelValue: _ctx.invoice.language,
                                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.invoice.language) = $event)),
                                      size: "large"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_option, {
                                          label: "English",
                                          value: "en"
                                        }),
                                        _createVNode(_component_el_option, {
                                          label: "Arabic",
                                          value: "ar"
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        ([2, 3, 4, 5].includes(_ctx.invoice.payment_type))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                              _createElementVNode("div", _hoisted_57, [
                                _createVNode(_component_vc_input, {
                                  disabled: "",
                                  label: "Reference No.",
                                  modelValue: _ctx.invoice.reference_no,
                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.invoice.reference_no) = $event)),
                                  placeholder: "Reference No.",
                                  prop: "reference_no",
                                  ref: "reference_no",
                                  size: "large",
                                  maxLength: "100"
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        ([2, 3, 4, 5].includes(_ctx.invoice.payment_type))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                              _createElementVNode("div", _hoisted_59, [
                                _createVNode(_component_vc_input, {
                                  disabled: "",
                                  modelValue: _ctx.invoice.payment_date,
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.invoice.payment_date) = $event)),
                                  min: "1900-01-01",
                                  ref: "payment_date",
                                  label: "Payment Date",
                                  size: "large"
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        ([2, 3, 4, 5].includes(_ctx.invoice.payment_type))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                              _createElementVNode("div", _hoisted_61, [
                                _cache[41] || (_cache[41] = _createElementVNode("label", { class: "form-label" }, " Receipt (Payment Proof) ", -1)),
                                _createVNode(_component_el_form_item, { prop: "file_receipt" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("a", {
                                      class: "btn-active-color-primary btn-sm dropdown-item p-1",
                                      href: _ctx.invoice.invoice_doc.file_path,
                                      target: "_blank"
                                    }, _cache[40] || (_cache[40] = [
                                      _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                                      _createTextVNode(" View Receipt")
                                    ]), 8, _hoisted_62)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.invoice.is_split_payment && _ctx.invoice.cheque_doc)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                              _createElementVNode("div", _hoisted_64, [
                                _cache[43] || (_cache[43] = _createElementVNode("label", { class: "form-label" }, " Cheque ", -1)),
                                _createVNode(_component_el_form_item, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("a", {
                                      class: "btn-active-color-primary btn-sm dropdown-item p-1",
                                      href: _ctx.invoice.cheque_doc.file_path,
                                      target: "_blank"
                                    }, _cache[42] || (_cache[42] = [
                                      _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1),
                                      _createTextVNode(" View cheque doc")
                                    ]), 8, _hoisted_65)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                ])
              ])
            ])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_66, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-light me-5",
              onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.closeModal()))
            }, "Cancel")
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}