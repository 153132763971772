import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "svg-icon svg-icon-1 icon-lead-details" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_agents = _resolveComponent("vc-agents")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_vc_transfer = _resolveComponent("vc-transfer")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        md: 16,
        sm: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { gutter: 10 }, {
            default: _withCtx(() => [
              (_ctx.store.getters.showByRole([1, 9, 13, 14]))
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    md: 8,
                    sm: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_vc_agents, {
                        modelValue: _ctx.filter.agent,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.agent) = $event)),
                        placeholder: "Select agent view for",
                        label: "Mine",
                        clearable: "",
                        onAgent: _ctx.getAgentId,
                        class: _normalizeClass(["el-h-44", {'mb-2': _ctx.common.isMobile()}]),
                        updatedClass: _ctx.filter.agent == 'all' ? 'btn btn-outline' : 'btn btn-bg-info btn-color-light btn-color-light',
                        options: _ctx.agents,
                        onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeAgent('all')))
                      }, null, 8, ["modelValue", "onAgent", "class", "updatedClass", "options"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.roles.isAgent())
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 1,
                    md: 6,
                    sm: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        size: "large",
                        class: _normalizeClass(["btn btn-color-light btn-all el-h-44", {
                            'btn-bg-primary': _ctx.filter.agent !== 'all',
                            'btn-bg-info': _ctx.filter.agent === 'all',
                            'mb-2': _ctx.common.isMobile()
                                }]),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeAgent(-1)))
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Mine ")
                        ])),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_el_col, {
                md: 6,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    size: "large",
                    disabled: _ctx.role_id == 2,
                    class: _normalizeClass(["btn btn-color-light btn-all el-h-44", {
                            'btn-bg-primary': _ctx.filter.agent === 'all',
                            'btn-bg-info': _ctx.filter.agent !== 'all',
                            'mb-2': _ctx.common.isMobile()
                                }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeAgent('all')))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" All ")
                    ])),
                    _: 1
                  }, 8, ["disabled", "class"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { md: 10 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.filters.customerDateRangeLT,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.customerDateRangeLT) = $event)),
                    type: "daterange",
                    "unlink-panels": "",
                    "range-separator": "-",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    shortcuts: _ctx.shortcuts,
                    onChange: _ctx.getDates,
                    format: "DD/MM/YYYY",
                    "value-format": "YYYY-MM-DD",
                    class: _normalizeClass(["el-h-44", {'mb-2': _ctx.common.isMobile()}]),
                    style: {"width":"100%"},
                    size: "large",
                    clearable: false
                  }, null, 8, ["modelValue", "shortcuts", "onChange", "class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        md: 8,
        sm: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "lead-filter-button-group" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { md: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button_group, { class: "d-flex" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "light",
                        content: "Show Leads Filter",
                        placement: "bottom-start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            type: "button",
                            style: {"border-radius":"0"},
                            class: "btn btn-light-info btn-light-lead-filter ms-3",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showHide.leadsAndTasksLeadCountFilter = !_ctx.showHide.leadsAndTasksLeadCountFilter))
                          }, [
                            _createElementVNode("span", _hoisted_1, [
                              _createVNode(_component_inline_svg, { src: "media/background/lead-details.svg" })
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "light",
                        content: "Transfer lead",
                        placement: "bottom-start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            type: "button",
                            style: {"border-radius":"0"},
                            class: "btn btn-light-info btn-light-lead-filter ms-3",
                            disabled: _ctx.customer.checkedTransfer.length > 0 ? false : true,
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.transfer.modal(true)))
                          }, _cache[10] || (_cache[10] = [
                            _createElementVNode("i", { class: "far fa-paper-plane icon-size" }, null, -1)
                          ]), 8, _hoisted_2)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_vc_transfer, { ref: "transfer" }, null, 512),
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "light",
                        content: "Refresh",
                        placement: "bottom-start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            type: "button",
                            style: {"border-radius":"0"},
                            class: "btn btn-light-info btn-light-lead-filter ms-3",
                            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.refresh && _ctx.refresh(...args)))
                          }, [
                            _createElementVNode("span", _hoisted_3, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr029.svg" })
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}