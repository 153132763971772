import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered mb-5"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "align-self-center text-gray-700" }
const _hoisted_4 = { class: "ms-3" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  class: "tab-content",
  id: "group-details-content"
}
const _hoisted_7 = ["id", "aria-labelledby"]
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-sm-22" }
const _hoisted_10 = { class: "form-label" }
const _hoisted_11 = { class: "fw-bolder text-gray-700 ms-3 float-end" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  key: 0,
  class: "col-sm-2"
}
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = {
  key: 1,
  class: "col-sm-2"
}
const _hoisted_16 = { class: "mb-10" }
const _hoisted_17 = { class: "col-sm-2" }
const _hoisted_18 = { class: "mb-10" }
const _hoisted_19 = { class: "col-sm-2" }
const _hoisted_20 = { class: "mb-10" }
const _hoisted_21 = { class: "col-sm-2" }
const _hoisted_22 = { class: "mb-10" }
const _hoisted_23 = { class: "col-sm-2" }
const _hoisted_24 = { class: "mb-10" }
const _hoisted_25 = {
  key: 2,
  class: "col-sm-2"
}
const _hoisted_26 = { class: "mb-10" }
const _hoisted_27 = {
  key: 3,
  class: "col-sm-2"
}
const _hoisted_28 = { class: "mb-10" }
const _hoisted_29 = { class: "col-sm-2" }
const _hoisted_30 = { class: "mb-10" }
const _hoisted_31 = {
  key: 4,
  class: "col-sm-2"
}
const _hoisted_32 = { class: "mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_vc_input = _resolveComponent("vc-input")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, " Update Credit & Debit Note Information ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fw-bolder" }, "Policy Price:", -1)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.common.formatCurrency(_ctx.policy.main_invoice.sales_price)) + " " + _toDisplayString(_ctx.currency), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy.main_invoice.categories, (category, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass(["tab-pane fade", {'show active': _ctx.activeTab == i}]),
                id: `group-details-content-${i}`,
                role: "tabpanel",
                "aria-labelledby": `group-details-content-${i}`
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.invoice_based_categories, (invoice, j) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("label", _hoisted_10, _toDisplayString(invoice.merchant_reference) + " (" + _toDisplayString(invoice.amend_type == 2 ? 'Refund Amount' : 'Invoice Amount') + ": " + _toDisplayString(_ctx.common.formatCurrency(invoice.sales_price)) + " " + _toDisplayString(_ctx.currency) + ") / " + _toDisplayString(invoice?.invoice_merchant_reference), 1),
                        _createElementVNode("span", _hoisted_11, "[Member #: " + _toDisplayString(invoice.group_count) + " | Members > 60: " + _toDisplayString(invoice.members_above_60) + " | Non-Taxable Fees " + _toDisplayString(_ctx.common.formatCurrency(invoice.non_taxable_fee)) + "]", 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      (invoice.is_amend == 1 && invoice.amend_type == 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label required" }, " Start Date ", -1)),
                              _createVNode(_component_vc_date_picker, {
                                modelValue: invoice.amendment_start_date,
                                "onUpdate:modelValue": ($event: any) => ((invoice.amendment_start_date) = $event),
                                prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.amendment_start_date`,
                                ref_for: true,
                                ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.amendment_start_date`,
                                min: _ctx.policy.policy_start_date,
                                defaultValue: _ctx.policy.policy_start_date,
                                max: _ctx.common.subDays(_ctx.policy.policy_end_date,1),
                                rules: { validator: _ctx.Validations.amendment_start_date, trigger: ['blur', 'change'] },
                                disabled: _ctx.isDisableAllFields
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "min", "defaultValue", "max", "rules", "disabled"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (invoice.is_amend == 1 && invoice.amend_type == 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label required" }, " End Date ", -1)),
                              _createVNode(_component_vc_date_picker, {
                                modelValue: invoice.amendment_end_date,
                                "onUpdate:modelValue": ($event: any) => ((invoice.amendment_end_date) = $event),
                                prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.amendment_end_date`,
                                ref_for: true,
                                ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.amendment_end_date`,
                                min: _ctx.policy.policy_start_date,
                                max: _ctx.policy.policy_end_date,
                                disabled: (invoice.is_policy_completed && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields,
                                rules: { validator:(rule, value, callback) => {
                                        _ctx.Validations.amendment_end_date(rule, value, callback, invoice.amendment_start_date, invoice.is_policy_completed);
                                    }, trigger: ['blur', 'change'] }
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "min", "max", "disabled", "rules"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_vc_amount_v2, {
                            modelValue: invoice.credit_amount,
                            "onUpdate:modelValue": ($event: any) => ((invoice.credit_amount) = $event),
                            label: "CN",
                            subLabel2: invoice.amend_type == 2 ? `(${_ctx.common.formatCurrency(invoice.amount_with_vat)})(${_ctx.currency})` : `(${_ctx.currency})`,
                            placeholder: "CN",
                            size: "large",
                            isRequired: "",
                            disabled: (invoice.is_policy_completed && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields,
                            id: `main_invoice.categories.${i}.invoice_based_categories.${j}.credit_amount`,
                            prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.credit_amount`,
                            ref_for: true,
                            ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.credit_amount`,
                            rules: { validator: _ctx.Validations.credit_amount, trigger: ['blur', 'change'] },
                            onChange: ($event: any) => (_ctx.updateObject(invoice, 'credit_amount'))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "subLabel2", "disabled", "id", "prop", "rules", "onChange"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(_component_vc_input, {
                            modelValue: invoice.credit_notes_no,
                            "onUpdate:modelValue": ($event: any) => ((invoice.credit_notes_no) = $event),
                            label: "CN No.",
                            placeholder: "CN No.",
                            size: "large",
                            isRequired: "",
                            maxlength: 100,
                            prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.credit_notes_no`,
                            ref_for: true,
                            ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.credit_notes_no`,
                            rules: { validator: _ctx.Validations.credit_notes_no, trigger: ['blur', 'change'] },
                            disabled: _ctx.isDisableAllFields
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "rules", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_vc_amount_v2, {
                            isInput: "number",
                            modelValue: invoice.debit_amount,
                            "onUpdate:modelValue": ($event: any) => ((invoice.debit_amount) = $event),
                            label: "DN",
                            subLabel2: invoice.amend_type != 2 ? `(${_ctx.common.formatCurrency(invoice.amount_with_vat)})(${_ctx.currency})` : `(${_ctx.currency})`,
                            placeholder: "DN",
                            size: "large",
                            isRequired: "",
                            disabled: (invoice.is_policy_completed && !_ctx.isEnableAllFields) || _ctx.isDisableAllFields,
                            prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.debit_amount`,
                            ref_for: true,
                            ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.debit_amount`,
                            rules: {
                                        validator: (rule, value, callback) => {
                                            _ctx.Validations.debit_amount(rule, value, callback,_ctx.minDebitAmount(invoice.amount_with_vat, invoice.amend_type), (invoice.amount_with_vat + 1) , invoice.amend_type); // Pass 18 as a custom parameter
                                        }, trigger: ['blur', 'change']
                                    },
                            onChange: ($event: any) => (_ctx.updateObject(invoice, 'debit_amount'))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "subLabel2", "disabled", "prop", "rules", "onChange"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _createVNode(_component_vc_input, {
                            modelValue: invoice.debit_notes_no,
                            "onUpdate:modelValue": ($event: any) => ((invoice.debit_notes_no) = $event),
                            label: "DN No.",
                            placeholder: "DN No.",
                            size: "large",
                            isRequired: "",
                            maxlength: 100,
                            prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.debit_notes_no`,
                            ref_for: true,
                            ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.debit_notes_no`,
                            rules: { validator: _ctx.Validations.debit_notes_no, trigger: ['blur', 'change'] },
                            disabled: _ctx.isDisableAllFields
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "rules", "disabled"])
                        ])
                      ]),
                      (category.visa_emirates == 2 && invoice.amend_type != 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, [
                              _createVNode(_component_vc_amount_v2, {
                                isInput: "number",
                                modelValue: invoice.basmah,
                                "onUpdate:modelValue": ($event: any) => ((invoice.basmah) = $event),
                                label: "Basmah Charges",
                                subLabel2: '('+ _ctx.currency +')',
                                placeholder: "Basmah Charges",
                                size: "large",
                                isRequired: "",
                                disabled: !_ctx.isEnableAllFields || _ctx.isDisableAllFields,
                                prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.basmah`,
                                ref_for: true,
                                ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.basmah`
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "subLabel2", "disabled", "prop"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (category.visa_emirates != 2 && invoice.amend_type != 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createVNode(_component_vc_amount_v2, {
                                isInput: "number",
                                modelValue: invoice.icp,
                                "onUpdate:modelValue": ($event: any) => ((invoice.icp) = $event),
                                label: "ICP Charges",
                                subLabel2: '('+ _ctx.currency +')',
                                placeholder: "DN",
                                size: "large",
                                isRequired: "",
                                disabled: !_ctx.isEnableAllFields || _ctx.isDisableAllFields,
                                prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.icp`,
                                ref_for: true,
                                ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.icp`
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "subLabel2", "disabled", "prop"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label required" }, " Tax Invoice Date ", -1)),
                          _createVNode(_component_vc_date_picker, {
                            modelValue: invoice.tax_invoice_date,
                            "onUpdate:modelValue": ($event: any) => ((invoice.tax_invoice_date) = $event),
                            prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.tax_invoice_date`,
                            ref_for: true,
                            ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.tax_invoice_date`,
                            rules: { validator: _ctx.Validations.tax_invoice_date, trigger: ['blur', 'change'] },
                            disabled: _ctx.isDisableAllFields
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "rules", "disabled"])
                        ])
                      ]),
                      (invoice.is_amend == 1 && invoice.amend_type != 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_vc_input, {
                                modelValue: invoice.endorsement_no,
                                "onUpdate:modelValue": ($event: any) => ((invoice.endorsement_no) = $event),
                                label: "Endorsement No",
                                placeholder: "Endorsement No.",
                                size: "large",
                                isRequired: "",
                                prop: `main_invoice.categories.${i}.invoice_based_categories.${j}.endorsement_no`,
                                ref_for: true,
                                ref: `main_invoice.categories.${i}.invoice_based_categories.${j}.endorsement_no`,
                                rules: invoice.is_policy_completed ? '' : { validator: _ctx.Validations.endorsement_no, trigger: ['blur', 'change'] },
                                disabled: _ctx.isDisableAllFields
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "prop", "rules", "disabled"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ], 64))
                }), 128))
              ], 10, _hoisted_7))
            }), 128))
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "col-sm-12" }, " ** All prices are inclusive of VAT. ")
          ], -1))
        ])
      ]))
    : _createCommentVNode("", true)
}