import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "m-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "d-flex justify-content-center" }
const _hoisted_7 = {
  key: 0,
  class: "table-custom-invoice"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 3,
  class: ""
}
const _hoisted_13 = { class: "d-flex justify-content-center" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.open,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal(false))),
      "append-to-body": "",
      top: "1vh"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_image, {
          src: _ctx.link,
          fit: "fill"
        }, null, 8, ["src"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.openLog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openLog) = $event)),
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openLog = false)),
      title: "Document Logs",
      width: "35%",
      top: "10vh"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_table, {
            data: _ctx.store.getters.getDocumentLogs,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log, i) => {
                return (_openBlock(), _createBlock(_component_el_table_column, { key: i }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: log.label
                    }, null, 8, _hoisted_3)
                  ]),
                  default: _withCtx((scope) => [
                    (log.value === 'index')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.$index + 1), 1))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          innerHTML: scope.row[log.value]
                        }, null, 8, _hoisted_5))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["data"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.openDelete,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.openDelete) = $event)),
      title: "Delete Document",
      width: "25%",
      top: "20vh"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_vc_button, {
            type: "button",
            className: "btn btn-lg btn-light me-4",
            text: "Cancel",
            size: "large",
            onVcClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalDelete(false))),
            "is-regular": ""
          }),
          _createVNode(_component_vc_button, {
            loading: _ctx.loading,
            className: "btn btn-lg btn-info",
            text: "Confirm",
            size: "large",
            onVcClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.confirmDelete(_ctx.document_id)))
          }, null, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "my-5" }, [
          _createElementVNode("span", { class: "m-4 mt-4" }, "Are you sure to delete this?")
        ], -1))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.documents.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_el_table, {
            data: _ctx.documents,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tDocuments, (document, i) => {
                return (_openBlock(), _createBlock(_component_el_table_column, {
                  key: i,
                  width: document.width,
                  align: document.align
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: document.label
                    }, null, 8, _hoisted_8)
                  ]),
                  default: _withCtx((scope) => [
                    (document.value === 'index')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(scope.$index + 1), 1))
                      : (document.isPreview)
                        ? (_openBlock(), _createBlock(_component_el_avatar, {
                            key: 1,
                            fit: _ctx.fill,
                            shape: "square",
                            size: "large",
                            src: scope.row[document.value],
                            style: _normalizeStyle([scope.row.file_extension.toLowerCase() !== 'pdf' ? 'cursor: pointer' : 'cursor: pointer', {"background":"transparent"}]),
                            onClick: ($event: any) => (_ctx.makeAction(scope.row,3))
                          }, null, 8, ["fit", "src", "style", "onClick"]))
                        : (document.value === 'document_type')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (_ctx.isEdit && scope.row.is_temp === 1 && (!_ctx.agent_documents.includes(scope.row.type) || !_ctx.showDelete))
                                ? (_openBlock(), _createBlock(_component_el_select, {
                                    key: 0,
                                    modelValue: scope.row.type,
                                    "onUpdate:modelValue": ($event: any) => ((scope.row.type) = $event),
                                    size: "large",
                                    onChange: ($event: any) => (_ctx.updateDocumentType(scope.row)),
                                    filterable: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document_types, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: item.value,
                                          label: item.label,
                                          value: item.value,
                                          disabled: _ctx.disabledDocumentType(item.value)
                                        }, null, 8, ["label", "value", "disabled"]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"]))
                                : (scope.row.is_temp === 1 && (_ctx.agent_documents.includes(scope.row.type) && _ctx.showDelete))
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 1,
                                      innerHTML: _ctx.getDocumentType(scope.row.type)
                                    }, null, 8, _hoisted_10))
                                  : (_openBlock(), _createElementBlock("span", {
                                      key: 2,
                                      class: _normalizeClass({ 'text-success': scope.row.is_temp === 0 }),
                                      innerHTML: scope.row[document.value]
                                    }, null, 10, _hoisted_11))
                            ], 64))
                          : (document.value === 'action')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document_actions, (action, k) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                                      (action.show)
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            class: "ms-4 cursor-pointer icon-bg-common",
                                            onClick: ($event: any) => (_ctx.makeAction(scope.row, action.value))
                                          }, [
                                            _createVNode(_component_el_tooltip, {
                                              class: "box-item",
                                              content: action.tip,
                                              effect: "light",
                                              placement: "bottom",
                                              "show-after": 200,
                                              "hide-after": 0
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("i", {
                                                  class: _normalizeClass(action.icon)
                                                }, null, 2)
                                              ]),
                                              _: 2
                                            }, 1032, ["content"])
                                          ], 8, _hoisted_14))
                                        : _createCommentVNode("", true)
                                    ], 64))
                                  }), 128))
                                ])
                              ]))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 4,
                                class: _normalizeClass(["text-truncate", { 'cursor-pointer': _ctx.common.getTextWidth(scope.row[document.value]) > parseInt(document.width) }])
                              }, [
                                (_ctx.common.getTextWidth(scope.row[document.value]) > parseInt(document.width))
                                  ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                      key: 0,
                                      class: "box-item",
                                      effect: "light",
                                      content: scope.row[document.value],
                                      placement: "bottom",
                                      "show-after": 500,
                                      "hide-after": 0
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", {
                                          innerHTML: scope.row[document.value]
                                        }, null, 8, _hoisted_15)
                                      ]),
                                      _: 2
                                    }, 1032, ["content"]))
                                  : (_openBlock(), _createElementBlock("span", {
                                      key: 1,
                                      innerHTML: scope.row[document.value]
                                    }, null, 8, _hoisted_16))
                              ], 2))
                  ]),
                  _: 2
                }, 1032, ["width", "align"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["data"])
        ]))
      : (_openBlock(), _createBlock(_component_el_empty, {
          key: 1,
          description: "No Records Available"
        }))
  ]))
}