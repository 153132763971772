import { registration } from '@/store/stateless';

const invoice_columns = [
    {
        label: 'Action',
        value: 'action',
        width: '100',
        cn: 'dropdown-action',
        isFixed: true,
        isAction:true
    },
    {
        label: 'Quote Ref No',
        value: 'merchant_reference',
        width: '200',
        isFixed: true
    },
    {
        label: 'Invoice Status',
        value: 'invoice_status',
        width: '150',
        isStatus:true
    },
    {
        label: 'Email',
        value: 'email',
        width: '250',
        isCustomer: true,
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: '250',
    },
    {
        label: 'Reference No',
        value: 'reference_no',
        width: '200',
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: '200',
    },
    {
        label: 'Invoice Price('+registration.currency+')',
        value: 'sales_price',
        width: '200',
        isInvoicePrice:true
    },

    // {
    //     label: 'Admin Fee('+registration.currency+')',
    //     value: 'admin_fee_amount',
    //     width: '200',
    // },
    {
        label: 'Service Fee('+registration.currency+')',
        value: 'admin_extra_charges',
        width: '200',
    },
    
    
    {
        label: 'Split Payment',
        value: 'split_payment_text',
        width: '200',
    },
    {
        label: 'Transaction Type / Code',
        value: 'payment_type_text',
        width: '200',
    },
    {
        label: 'Underwriter <br/> Policy Agent',
        value: 'agent_details',
        width: '200',
    },
    {
        label: 'Invoice By & <br/> Created On',
        value: 'created_details',
        width: '200',
    },

    {
        label: 'Approved By & <br/> Approved On',
        value: 'approved_by',
        width: '200',
    },
    {
        label: 'Declined By & <br/> Declined On',
        value: 'declined_by',
        width: '200',
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: '150',
    },
]

const invoiceDefault = {
    invoice_id: null,
    quote_ref_no:null,
    customer_id: null,
    lead_id: null,
    insurance_plan: null,
    copay: null,
    policy_price: 0,
    invoice_amount:0,
    sales_price: 0,
    basmah:0,
    basmah_vat:0,
    is_discount_given: false,
    discount_amount: 0,
    is_split_payment: false,
    payment_type: null,
    pending_payment_type: null,
    installments: null,
    order_description: null,
    reference_no: null,
    payment_date: null,
    file_receipt: null,
    language: "en",
    expiry_date_time: null,
    client_classification:null,
    members: null,

  }


const group_columns = [
    
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        width: '150',
    },
    {
        label: 'M# (M > 60)',
        value: 'counts',
        width: '130',
    },
    // {
    //     label: 'Members > 60',
    //     value: 'members_above_60',
    //     width: '180',
    // },
    {
        label: 'Basmah <span class="fw-light text-gray-700 fs-8"> (incl. VAT) </span>',
        value: 'basmah',
        isBasmah: true,
        isFormat: true,
        width: '150',
    },
    {
        label: 'ICP <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'icp',
        isFormat: true,
        width: '150',
    },
    {
        label: 'Non taxable fee',
        value: 'non_taxable_fee',
        isFormat: true,
        width: '150',
    },
    {
        label: 'Amount <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'amount',
        isFormat: true,
        // width: '300',
    },
]


const group_amend_columns = [
    
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        // width: '350',
    },
    {
        label: 'M#',
        value: 'group_count',
        width: '100',
    },
    {
        label: 'M > 60',
        value: 'members_above_60',
        width: '100',
    },
    {
        label: 'Basmah <span class="fw-light text-gray-700 fs-8"> (incl. VAT) </span>',
        value: 'basmah',
        isBasmah: true,
        isFormat: true,
        width: '130',
    },
    {
        label: 'ICP <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span>',
        value: 'icp',
        isFormat: true,
        width: '150',
    },
    {
        label: 'Non taxable fee',
        value: 'non_taxable_fee',
        isFormat: true,
        width: '150',
    },
    {
        label: 'Amount <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'amount',
        isFormat: true,
        width: '300',
    },
]

const group_sub_columns = [
    
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        // width: '350',
    },
    {
        label: 'M#',
        value: 'group_counts',
        width: '100',
    },
    {
        label: 'M > 60',
        value: 'members_above_60s',
        width: '100',
    }
]


const group_invoice_columns = [
    {
        label: 'Action',
        value: 'action',
        width: '100',
        cn: 'dropdown-action',
        isFixed: true,
        isAction:true
    },
    {
        label: 'Quote Ref No',
        value: 'merchant_reference',
        width: '200',
        isFixed: true,
        isClicked: true
    },
    {
        label: 'Invoice Status',
        value: 'invoice_status',
        width: '150',
        isStatus:true
    },
    {
        label: 'Email',
        value: 'email',
        width: '250',
        isCustomer: true,
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: '250',
    },
    {
        label: 'Reference No',
        value: 'reference_no',
        width: '200',
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: '200',
    },
    {
        label: 'Invoice Price <br/>& VAT ('+registration.currency+')',
        value: 'sales_price',
        width: '200',
        isInvoicePrice:true
    },
    
    
    {
        label: 'Split Payment',
        value: 'split_payment_text',
        width: '200',
    },
    {
        label: 'Transaction Type',
        value: 'payment_type_text',
        width: '200',
    },
    {
        label: 'Underwriter <br/> Policy Agent',
        value: 'agent_details',
        width: '200',
    },
    {
        label: 'Invoice By & <br/> Created On',
        value: 'created_details',
        width: '200',
    },
    {
        label: 'Approved By & <br/> Approved On',
        value: 'approved_by',
        width: '200',
    },
    {
        label: 'Declined By & <br/> Declined On',
        value: 'declined_by',
        width: '200',
    },
]


const group_invoice_log = [
    
    {
        label: 'Quote Ref no.',
        value: 'merchant_reference',
        width: '150',
    },
    {
        label: 'Invoice no.',
        value: 'invoice_no',
        width: '150',
    },
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        width: '200',
    },
    {
        label: 'Members Count',
        value: 'group_count',
        width: '180',
    },
    {
        label: 'Members > 60',
        value: 'members_above_60',
        width: '180',
    },
    {
        label: 'Basmah <br/><span class="fw-light text-gray-700 fs-8"> (incl. VAT) </span>',
        value: 'basmah',
        width: '100',
    },
    {
        label: 'ICP <br/> <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span>',
        value: 'icp',
        width: '100',
    },
    {
        label: 'Amount <br/> <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span>',
        value: 'amount',
        width: '100',
    },
    {
        label: 'Amount VAT',
        value: 'amount_vat',
        width: '120',
    },
]

const sub_amend_group_columns = [
    
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        // width: '350',
    },
    {
        label: 'Members Count',
        value: 'group_count',
        width: '180',
    },
    {
        label: 'Members > 60',
        value: 'members_above_60',
        width: '180',
    },
    {
        label: 'Refund Amount (incl. VAT)',
        value: 'amount',
        width: '250',
    },
]


const new_sub_group_columns = [
    
    {
        label: 'Category',
        value: 'name',
        // width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates',
        // width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan_id',
        // width: '150',
    },
    {
        label: 'M#',
        value: 'group_count',
        width: '80',
    },
    {
        label: 'M > 60',
        value: 'members_above_60',
        width: '80',
    },
    {
        label: 'Basmah <span class="fw-light text-gray-700 fs-8"> (incl. VAT) </span>',
        value: 'basmah',
        isBasmah: true,
        isFormat: true,
        width: '180',
    },
    {
        label: 'ICP <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'icp',
        isFormat: true,
        width: '180',
    },
    {
        label: 'Non taxable fee',
        value: 'non_taxable_fee',
        isFormat: true,
        width: '180',
    },
    {
        label: 'Amount <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'amount',
        isFormat: true,
        width: '180',
    },
    {
        label: '',
        value: 'remove',
        align:'left',
        width: '50'
        // width: '300',
    },
]



const invoice_details_group_columns = [
    
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
    },
    {
        label: 'M# (M > 60)',
        value: 'counts',
        width: '130',
    },
    {
        label: 'Basmah <span class="fw-light text-gray-700 fs-8"> (incl. VAT) </span>',
        value: 'basmah',
        isBasmah: true,
        isFormat: true,
        // width: '130',
    },
    {
        label: 'ICP <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'icp',
        isFormat: true,
        width: '150',
    },
    {
        label: 'Non taxable fee',
        value: 'non_taxable_fee',
        isFormat: true,
        width: '150',
    },
    {
        label: 'Amount <span class="fw-light text-gray-700 fs-8"> (w/o VAT) </span> ',
        value: 'amount',
        isFormat: true,
        // width: '300',
    },
    {
        label: 'Amount VAT ',
        value: 'amount_vat',
        isFormat: true,
        // width: '300',
    },
]
export {
    invoice_columns,
    invoiceDefault,
    group_columns,
    group_amend_columns,
    group_invoice_columns,
    group_invoice_log,
    sub_amend_group_columns,
    group_sub_columns,
    new_sub_group_columns,
    invoice_details_group_columns
}