import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "view-policies m-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-4 ms-4 fs-5 w-100" }
const _hoisted_4 = { class: "nav-item cursor-pointer policy" }
const _hoisted_5 = {
  key: 0,
  class: "col-sm-3 policy-select-parent"
}
const _hoisted_6 = { class: "card-body card-custom-spacing-tblf-10 p-0" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "m-5"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 3,
  class: "m-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_vc_customer_customer_log_data_value = _resolveComponent("vc-customer-customer-log-data-value")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_vc_policies_table = _resolveComponent("vc-policies-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_vc_policy_documents = _resolveComponent("vc-policy-documents")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.view_policies.open,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.view_policies.open) = $event)),
      "append-to-body": "",
      onClosed: _ctx.close,
      fullscreen: "",
      class: "lead-policy-details"
    }, {
      header: _withCtx(() => _cache[6] || (_cache[6] = [
        _createElementVNode("div", { class: "d-flex justify-content-between" }, [
          _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Lead Policy Details ")
        ], -1)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_row, {
            gutter: 10,
            class: "mx-5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 10 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    filterable: "",
                    modelValue: _ctx.activeValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeValue) = $event)),
                    onChange: _ctx.changeValue,
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: i,
                          label: lead.name,
                          value: lead.lead_id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass({'col-sm-9': _ctx.activeSecondTab == 2, 'col-sm-12': _ctx.activeSecondTab == 1})
            }, [
              _createElementVNode("ul", _hoisted_3, [
                _createElementVNode("li", _hoisted_4, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link trunc text-truncate", { 'active': _ctx.activeSecondTab === 1 }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeSecondTab = 1))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("span", null, " Policy List ", -1)
                  ]), 2)
                ]),
                (_ctx.permissions.is_admin || _ctx.permissions.is_manager || _ctx.permissions.is_qca)
                  ? (_openBlock(), _createElementBlock("li", {
                      key: 0,
                      class: "nav-item cursor-pointer policy",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeSecondTab = 2))
                    }, [
                      _createElementVNode("a", {
                        class: _normalizeClass(["nav-link trunc text-truncate", { 'active': _ctx.activeSecondTab === 2 }])
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("span", null, " Policy Log ", -1)
                      ]), 2)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2),
            (_ctx.activeSecondTab == 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-select",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPolicy) = $event)),
                      onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.getPolicyLog && _ctx.getPolicyLog(...args)))
                    }, [
                      _cache[9] || (_cache[9] = _createElementVNode("option", { value: 0 }, "Select Policy", -1)),
                      (_ctx.lists)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.lists.data, (dropdown, index) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: index,
                              value: dropdown.id
                            }, [
                              (dropdown && dropdown.lead )
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(dropdown.main_invoice && dropdown.main_invoice ? dropdown.main_invoice.merchant_reference : ''), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(dropdown.policy ? dropdown.policy.policy_name.length > 0 ? dropdown.policy?.policy_name : dropdown.policy.main_invoice?.merchant_reference : ''), 1))
                            ], 8, _hoisted_7))
                          }), 128))
                        : _createCommentVNode("", true)
                    ], 544), [
                      [_vModelSelect, _ctx.selectedPolicy]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.selectedPolicy > 0 && _ctx.activeSecondTab == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (activity, i) => {
                  return (_openBlock(), _createBlock(_component_vc_customer_customer_log_data_value, {
                    key: i,
                    value: activity,
                    property: 'activity_log',
                    "active-tab": 1,
                    isPolicy: true
                  }, null, 8, ["value"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "card" }, [
                  _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (!_ctx.loading && _ctx.logs.length ==  0 && _ctx.activeSecondTab == 2)
            ? (_openBlock(), _createBlock(_component_el_empty, {
                key: 2,
                description: "No Records Available"
              }))
            : _createCommentVNode("", true),
          (_ctx.activeSecondTab === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_vc_policies_table, {
                      key: 0,
                      isView: "",
                      "is-customer-details": ""
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClosed"]),
    _createVNode(_component_vc_policy_documents)
  ], 64))
}