import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-4 ms-4 fs-5 w-100" }
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_vc_customer_customer_log_data_value = _resolveComponent("vc-customer-customer-log-data-value")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modal(false))),
    onOpened: _ctx.clickOpen,
    fullscreen: "",
    class: "lead-log-details-dialog"
  }, {
    header: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Lead Log Details ")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "mx-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  filterable: "",
                  modelValue: _ctx.activeValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeValue) = $event)),
                  onChange: _ctx.changeValue,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: i,
                        label: lead.name,
                        value: lead.lead_id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lead_activity, (activity, j) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item cursor-pointer policy",
              key: j
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link trunc text-truncate", {'active': _ctx.activityTab === activity.value}]),
                id: `activity_${_ctx.i}`,
                ref_for: true,
                ref: `activity_${activity.value}`,
                onClick: ($event: any) => (_ctx.fetchActivity(activity.value))
              }, [
                _createElementVNode("span", null, _toDisplayString(activity.label), 1)
              ], 10, _hoisted_2)
            ]))
          }), 128))
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.store.getters.getLeadActivityLogs), (logs, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logs[1], (activity, i) => {
                    return (_openBlock(), _createBlock(_component_vc_customer_customer_log_data_value, {
                      key: i,
                      value: activity,
                      property: logs[0],
                      "active-tab": _ctx.activityTab
                    }, null, 8, ["value", "property", "active-tab"]))
                  }), 128))
                ], 64))
              }), 128))
            ]))
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onOpened"]))
}