import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.modelValue,
    onChange: _ctx.updateValue,
    placeholder: _ctx.label,
    filterable: "",
    size: "large",
    ref: "inputRef",
    "no-match-text": "No Item Found",
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (!_ctx.pendingPaymentType)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paymentType, (item, index) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: index,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]))
          }), 128))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.pending_payment_type, (item, index) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: index,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]))
          }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onChange", "placeholder", "disabled"]))
}