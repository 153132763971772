import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "table-custom"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "bi bi-flag-fill text-danger me-1"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "card-body p-2" }
const _hoisted_10 = { class: "text-gray-600" }
const _hoisted_11 = {
  key: 0,
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_12 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_13 = {
  key: 1,
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_14 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_15 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_16 = {
  class: "p-3",
  nowrap: ""
}
const _hoisted_17 = {
  key: 0,
  class: "form-check form-check-sm custom-padding form-check-custom col-md-1 me-10"
}
const _hoisted_18 = ["name", "label", "value"]
const _hoisted_19 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
      ])))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.lists.length > 0)
          ? (_openBlock(), _createBlock(_component_el_table, {
              key: 0,
              data: _ctx.lists,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads_tasks, (lead, i) => {
                  return (_openBlock(), _createBlock(_component_el_table_column, {
                    "class-name": lead.cn,
                    key: i,
                    width: lead.width
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("span", {
                        innerHTML: lead.label
                      }, null, 8, _hoisted_3)
                    ]),
                    default: _withCtx((scope) => [
                      (lead.value === 'name' && scope.row.hot_enquiry)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                        : _createCommentVNode("", true),
                      (lead.isRoute)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            onClick: ($event: any) => (_ctx.toDetailed(scope.row)),
                            style: _normalizeStyle(lead.withMix ? _ctx.UserTypesMixin(scope.row.customer_status_id) : ''),
                            class: _normalizeClass({ 
                            'badge fs-7 fw-bold min-w-100 cursor-pointer': lead.withMix,
                            'cursor-pointer text-truncate fw-bolder text-gray-700': !lead.withCn && !lead.withMix,
                            'fw-bolder menu-link text-hover-primary text-gray-600': lead.withCn
                            })
                          }, [
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              effect: "light",
                              content: scope.row[lead.value],
                              placement: "bottom",
                              "show-after": 500,
                              "hide-after": 0
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.row[lead.value]), 1)
                              ]),
                              _: 2
                            }, 1032, ["content"])
                          ], 14, _hoisted_5))
                        : (lead.isCopy)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createElementVNode("span", {
                                class: "cursor-copy text-truncate",
                                onClick: ($event: any) => (_ctx.copyText.copyLink(scope.row.email))
                              }, [
                                _createVNode(_component_el_tooltip, {
                                  class: "box-item",
                                  effect: "light",
                                  content: scope.row.email,
                                  placement: "bottom",
                                  "show-after": 500,
                                  "hide-after": 0
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(scope.row.email), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ], 8, _hoisted_6),
                              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                              _createElementVNode("span", {
                                class: "text-muted fw-bold text-muted d-block fs-6 cursor-copy",
                                onClick: ($event: any) => (_ctx.copyText.copyLink(scope.row.phone_number_without_code))
                              }, _toDisplayString(scope.row.phone_number), 9, _hoisted_7)
                            ], 64))
                          : (scope.row.task_due_date && lead.isTimeLine)
                            ? (_openBlock(), _createBlock(_component_el_popover, {
                                key: 3,
                                placement: "left",
                                width: 320,
                                "popper-style": "box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                              }, {
                                reference: _withCtx(() => [
                                  _createElementVNode("span", {
                                    style: {"margin-right":"16px","cursor":"pointer"},
                                    class: _normalizeClass(lead.cni),
                                    plain: ""
                                  }, _toDisplayString(_ctx.formatDate(scope.row.task_due_date)), 3)
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", _hoisted_9, [
                                      _createElementVNode("table", _hoisted_10, [
                                        _createElementVNode("tbody", null, [
                                          _cache[7] || (_cache[7] = _createElementVNode("tr", {
                                            class: "text-gray-600",
                                            style: {"background-color":"#fff"}
                                          }, [
                                            _createElementVNode("th", {
                                              scope: "col",
                                              nowrap: "",
                                              colspan: "2",
                                              class: "text-center p-2"
                                            }, " Request Timeline ")
                                          ], -1)),
                                          (scope.row.current_visited_date_format)
                                            ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                                                _cache[4] || (_cache[4] = _createElementVNode("th", {
                                                  scope: "col",
                                                  class: "p-2",
                                                  nowrap: ""
                                                }, [
                                                  _createElementVNode("i", { class: "fa fa-genderless text-primary icon-xl" }),
                                                  _createTextVNode(" Current Visit Date ")
                                                ], -1)),
                                                _createElementVNode("td", _hoisted_12, _toDisplayString(scope.row.current_visited_date_format), 1)
                                              ]))
                                            : _createCommentVNode("", true),
                                          (
                              scope.row.last_visited_date_format &&
                              scope.row.last_visited_date_format !==
                                scope.row.current_visited_date_format
                            )
                                            ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                                                _cache[5] || (_cache[5] = _createElementVNode("th", {
                                                  scope: "col",
                                                  nowrap: "",
                                                  class: "p-2"
                                                }, [
                                                  _createElementVNode("i", { class: "fa fa-genderless text-danger icon-xl" }),
                                                  _createTextVNode(" Last Visit Date ")
                                                ], -1)),
                                                _createElementVNode("td", _hoisted_14, _toDisplayString(scope.row.last_visited_date_format), 1)
                                              ]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("tr", _hoisted_15, [
                                            _cache[6] || (_cache[6] = _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2",
                                              nowrap: ""
                                            }, [
                                              _createElementVNode("i", { class: "fa fa-genderless text-warning icon-xl" }),
                                              _createTextVNode(" Created Date ")
                                            ], -1)),
                                            _createElementVNode("td", _hoisted_16, _toDisplayString(scope.row.created_at), 1)
                                          ])
                                        ])
                                      ])
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            : (lead.isTransfer)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                  (scope.row.is_show_transfer)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                        _withDirectives(_createElementVNode("input", {
                                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.checkedTransfer) = $event)),
                                          name: scope.row.customer_id,
                                          label: scope.row.customer_id,
                                          value: scope.row.customer_id,
                                          class: "form-check-input rounded-0 transfer-checkbox",
                                          type: "checkbox",
                                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickTransfer && _ctx.clickTransfer(...args)))
                                        }, null, 8, _hoisted_18), [
                                          [_vModelCheckbox, _ctx.customer.checkedTransfer]
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 5,
                                  class: _normalizeClass(lead.cni),
                                  innerHTML: scope.row[lead.value]
                                }, null, 10, _hoisted_19))
                    ]),
                    _: 2
                  }, 1032, ["class-name", "width"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["data"]))
          : (_openBlock(), _createBlock(_component_el_empty, {
              key: 1,
              description: "No Records Available"
            }))
      ]))
}