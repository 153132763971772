import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import Validations from '@/core/services/etc/Validations';
import { DashboardMutationTypes } from '@/store/enums/Dashboard/Mutations';
import { DashboardActionTypes } from '@/store/enums/Dashboard/Actions';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
}

export const actions: ActionTree<State, RootState> & Actions = {
  
  async [DashboardActionTypes.GET_DASHBOARD_OVERVIEW]({ commit }, payload) {
    try {
      const url = Links.GET_DASHBOARD_OVERVIEW + 'overiew';
      // const url = Links.GET_DASHBOARD_OVERVIEW + payload.url;
      const data = await ApiService.post(url, payload);
      // const overview = {
      //   url: payload.url,
      //   value: data.data.data
      // }
      commit(DashboardMutationTypes.SET_DASHBOARD_OVERVIEW, data.data.data.overview);
      commit(DashboardMutationTypes.SET_OVERVIEW_DATA, data.data.data.result);
      // await commit(DashboardMutationTypes.SET_OVERVIEW_DATA, overview);
      return data
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_DASHBOARD_SALESPERSON_INFO]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_DASHBOARD_SALESPERSON_INFO, payload);
      const result = data.data.data;

      await commit(DashboardMutationTypes.SET_SALESPERSON_INFO, result);
      return data
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_SALES_DASHBOARD]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_SALES_DASHBOARD, payload);
      const result = data.data.data;

      // await commit(DashboardMutationTypes.SET_SALESPERSON_INFO, result);

      return result
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_DASHBOARD_STATISTICS]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_DASHBOARD_STATISTICS, payload);
      const result = data.data.data;
      await commit(DashboardMutationTypes.SET_DASHBOARD_STATISTICS, result);
      return data
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_DASHBOARD_OTHERS]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_DASHBOARD_OTHERS, payload);
      const result = data.data.data;
      await commit(DashboardMutationTypes.SET_DASHBOARD_OTHERS, result);
      return data
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_DASHBOARD_LEADS]({ commit }, payload) {
    try {
      const url = Links.GET_DASHBOARD_LEADS + payload.status;

      const data = await ApiService.post(url, payload);
      const result = data.data.data;
      result.status = payload.status
      await commit(DashboardMutationTypes.SET_DASHBOARD_LEADS, result);
      return data
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_DASHBOARD_TRANSACTIONS]({ commit }, payload) {
    try {

      let url = Links.GET_DASHBOARD_TRANSACTIONS + payload.paymentType;

      if(payload.type == 'group'){
        url = Links.GET_DASHBOARD_GROUP_TRANSACTIONS + payload.paymentType;
      }

      const data = await ApiService.post(url, payload);
      const result = data.data.data;
      result.paymentType = payload.paymentType
      result.type = payload.type
      await commit(DashboardMutationTypes.SET_DASHBOARD_TRANSACTIONS, result);
      return data
    } catch (error) {
      return error
    }
  },

  async [DashboardActionTypes.GET_DASHBOARD_UNDERWRITER]({commit}, payload) {
      try {
        const data = await ApiService.post(Links.GET_DASHBOARD_UNDERWRITER, payload);
        const result = data.data.data;

        commit(DashboardMutationTypes.SET_DASHBOARD_UNDERWRITER, result)
        return result
      } catch (error) {
        return error
      }
  },

  async [DashboardActionTypes.GET_DASHBOARD_ACCOUNTS]({commit}, payload) {
      try {
        const data = await ApiService.post(Links.GET_DASHBOARD_ACCOUNTS, payload);
        const result = data.data.data;

        commit(DashboardMutationTypes.SET_DASHBOARD_ACCOUNTS, result)
        return result
      } catch (error) {
        return error
      }
  },

  async [DashboardActionTypes.GET_DASHBOARD_MARKETING]({commit}, payload) {
      try {
        const data = await ApiService.post(Links.GET_DASHBOARD_MARKETING, payload);
        const result = data.data.data;

        commit(DashboardMutationTypes.SET_DASHBOARD_MARKETING, result)
        return result
      } catch (error) {
        return error
      }
  }

};
