import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mt-5"
}
const _hoisted_2 = { class: "form-check form-check-custom form-check-solid col-2" }
const _hoisted_3 = { class: "form-check form-check-custom form-check-solid col-2" }
const _hoisted_4 = { class: "form-label" }
const _hoisted_5 = { class: "form-label" }
const _hoisted_6 = { class: "form-label required" }
const _hoisted_7 = { class: "form-label text-gray-700 me-3" }
const _hoisted_8 = { class: "fv-row py-5 px-lg-8" }
const _hoisted_9 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_tasklist = _resolveComponent("vc-tasklist")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_lead_reason = _resolveComponent("vc-lead-reason")!
  const _component_vc_date_picker_time = _resolveComponent("vc-date-picker-time")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: _ctx.tasks,
      "status-icon": "",
      rules: _ctx.rule.task_details(_ctx.tasks.disposition_id),
      "scroll-to-error": "",
      id: "lead_status",
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.submitTask(_ctx.ruleFormRef)), ["prevent"])),
      "validate-on-rule-change": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "task-details"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: _ctx.isModal ? 24 : 8
            }, {
              default: _withCtx(() => [
                _createVNode(_component_vc_tasklist, {
                  modelValue: _ctx.tasks.disposition_id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tasks.disposition_id) = $event)),
                  prop: "disposition_id",
                  "is-required": "",
                  label: "Response From Customer",
                  lead_status_id: _ctx.lead_status_id,
                  onResponse: _ctx.reset,
                  lead_id: _ctx.lead_id
                }, null, 8, ["modelValue", "lead_status_id", "onResponse", "lead_id"]),
                (_ctx.lead.show_closed_task && _ctx.lead.lead_status_id == 7 && (_ctx.tasks.disposition_id == 8))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-gray-700 col-7 required" }, "Do You want to Close the Lead? ", -1)),
                      _createElementVNode("div", _hoisted_2, [
                        (_openBlock(), _createElementBlock("input", {
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCloseLead($event))),
                          class: "form-check-input",
                          type: "radio",
                          value: "1",
                          id: "closed_lead",
                          name: "closed_lead",
                          key: _ctx.index
                        }, null, 32)),
                        _cache[10] || (_cache[10] = _createElementVNode("label", {
                          class: "form-check-label",
                          for: "flexRadioChecked"
                        }, "Yes", -1))
                      ]),
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(), _createElementBlock("input", {
                          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeCloseLead($event))),
                          class: "form-check-input",
                          type: "radio",
                          value: "2",
                          id: "closed_lead",
                          name: "closed_lead",
                          key: _ctx.index
                        }, null, 32)),
                        _cache[11] || (_cache[11] = _createElementVNode("label", {
                          class: "form-check-label",
                          for: "flexRadioChecked"
                        }, "No", -1))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["span"]),
            _createVNode(_component_el_col, {
              span: _ctx.isModal ? 24 : 8
            }, {
              default: _withCtx(() => [
                (_ctx.tasks.disposition_id == 22)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("label", _hoisted_4, [
                        _cache[15] || (_cache[15] = _createTextVNode(" Lead Converted For : ")),
                        _createVNode(_component_el_tooltip, {
                          class: "box-item cursor-pointer",
                          effect: "light",
                          placement: "top",
                          "show-after": 500,
                          "hide-after": 0
                        }, {
                          content: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Select new deals "),
                            _createElementVNode("br", null, null, -1),
                            _createTextVNode(" for Lead Conversion")
                          ])),
                          default: _withCtx(() => [
                            _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fas fa-info-circle text-info" }, null, -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_component_el_form_item, { prop: "converted_leads" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: _ctx.tasks.converted_leads,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tasks.converted_leads) = $event)),
                            size: "large",
                            multiple: "",
                            "collapse-tags": "",
                            "max-collapse-tags": 1
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.common.getConvertedDeals(_ctx.lead_id), (item, index) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: index,
                                  label: `${item.name} (${item.lead_id})`,
                                  value: item.lead_id
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("label", _hoisted_5, [
                        _cache[18] || (_cache[18] = _createTextVNode(" Lost Lead Reason ")),
                        _createVNode(_component_el_tooltip, {
                          class: "box-item cursor-pointer",
                          effect: "light",
                          placement: "top",
                          "show-after": 500,
                          "hide-after": 0
                        }, {
                          content: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode("Lost Lead Reason is required"),
                            _createElementVNode("br", null, null, -1),
                            _createTextVNode("when the Response from customer "),
                            _createElementVNode("br", null, null, -1),
                            _createTextVNode("is LOST LEAD or FALSE LEAD")
                          ])),
                          default: _withCtx(() => [
                            _cache[17] || (_cache[17] = _createElementVNode("i", { class: "fas fa-info-circle text-info" }, null, -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_component_vc_lead_reason, {
                        modelValue: _ctx.tasks.lost_lead_reason_id,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tasks.lost_lead_reason_id) = $event)),
                        prop: "lost_lead_reason_id",
                        response: _ctx.tasks.disposition_id,
                        "is-required": [11, 8].includes(_ctx.tasks.disposition_id),
                        disabled: ![11, 8].includes(_ctx.tasks.disposition_id)
                      }, null, 8, ["modelValue", "response", "is-required", "disabled"])
                    ], 64))
              ]),
              _: 1
            }, 8, ["span"]),
            _createVNode(_component_el_col, {
              span: _ctx.isModal ? 24 : 8
            }, {
              default: _withCtx(() => [
                _createElementVNode("label", _hoisted_6, [
                  _cache[21] || (_cache[21] = _createTextVNode(" Follow Up Due Date & Time ")),
                  _createVNode(_component_el_tooltip, {
                    class: "box-item cursor-pointer",
                    effect: "light",
                    placement: "top",
                    "show-after": 500,
                    "hide-after": 0
                  }, {
                    content: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Follow up time is required "),
                      _createElementVNode("br", null, null, -1),
                      _createTextVNode("unless the Response from customer "),
                      _createElementVNode("br", null, null, -1),
                      _createTextVNode("is LOST LEAD or FALSE LEAD")
                    ])),
                    default: _withCtx(() => [
                      _cache[20] || (_cache[20] = _createElementVNode("i", { class: "fas fa-info-circle text-info" }, null, -1))
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_vc_date_picker_time, {
                  prop: "due_date",
                  modelValue: _ctx.tasks.due_date,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tasks.due_date) = $event)),
                  defaultTime: "09:00",
                  min: _ctx.common.disableToday(),
                  disabled: [8, 11, 17].includes(_ctx.tasks.disposition_id)
                }, null, 8, ["modelValue", "min", "disabled"])
              ]),
              _: 1
            }, 8, ["span"]),
            _createVNode(_component_el_col, {
              span: _ctx.isModal ? 24 : 16,
              class: "task-note"
            }, {
              default: _withCtx(() => [
                _createElementVNode("label", _hoisted_7, [
                  _cache[24] || (_cache[24] = _createTextVNode("Task Note ")),
                  _createVNode(_component_el_tooltip, {
                    class: "box-item cursor-pointer",
                    effect: "light",
                    placement: "top",
                    "show-after": 500,
                    "hide-after": 0
                  }, {
                    content: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("Task notes is required "),
                      _createElementVNode("br", null, null, -1),
                      _createTextVNode("when the Response from customer "),
                      _createElementVNode("br", null, null, -1),
                      _createTextVNode("is General Enquiry or Policy Enquiry ")
                    ])),
                    default: _withCtx(() => [
                      _cache[23] || (_cache[23] = _createElementVNode("i", { class: "fas fa-info-circle text-info" }, null, -1))
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_vc_input, {
                  "is-show-label": false,
                  placeholder: "Enter Task Notes",
                  prop: "task_notes",
                  ref: "task_notes",
                  size: "large",
                  isInput: "textarea",
                  maxlength: 255,
                  modelValue: _ctx.tasks.task_notes,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tasks.task_notes) = $event)),
                  rows: 2,
                  rules: [12, 13].includes(_ctx.tasks.disposition_id) && {required:true ,  message: 'Please enter note', trigger: ['blur', 'change'] }
                }, null, 8, ["modelValue", "rules"]),
                _createElementVNode("span", {
                  class: _normalizeClass([{'mt-5':[12, 13].includes(_ctx.tasks.disposition_id)}, "d-block fs-6 text-gray-600"])
                }, " Up to characters " + _toDisplayString(_ctx.tasks.task_notes.length) + " / 255", 3)
              ]),
              _: 1
            }, 8, ["span"]),
            _createVNode(_component_el_col, {
              span: _ctx.isModal ? 24 : 8,
              class: "task-details-submit"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["d-flex", _ctx.isModal ? 'justify-content-center' : ''])
                }, [
                  (_ctx.isModal)
                    ? (_openBlock(), _createBlock(_component_vc_button, {
                        key: 0,
                        className: "btn btn-lg btn-light me-4",
                        text: "Cancel",
                        size: "large",
                        onVcClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('closeModal'))),
                        "is-regular": ""
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_vc_button_save, {
                    loading: _ctx.loading,
                    disabled: _ctx.disableButton,
                    className: "btn btn-lg btn-info",
                    text: "Save",
                    size: "large"
                  }, null, 8, ["loading", "disabled"])
                ], 2)
              ]),
              _: 1
            }, 8, ["span"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.isModalOpen,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isModalOpen) = $event)),
      title: "Task Details",
      width: "30%",
      center: "",
      "show-close": false,
      "close-on-click-modal": false,
      onClose: _ctx.cancelModal
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_9, [
          _createVNode(_component_el_button, { onClick: _ctx.cancelModal }, {
            default: _withCtx(() => _cache[25] || (_cache[25] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            loading: _ctx.modalLoading,
            onClick: _ctx.proceed
          }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [
              _createTextVNode(" Proceed ")
            ])),
            _: 1
          }, 8, ["loading", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.closedLead ? `Are you sure you want to close lead` : `Are you sure this will delete the lead permanently?`), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}