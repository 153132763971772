import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "mx-2 mt-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "m-4" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.groupInvoiceLog.show)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _ctx.groupInvoiceLog.show,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.groupInvoiceLog.show) = $event)),
        width: "90%",
        onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal())),
        top: ""
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, "Group Log", -1)),
            _createElementVNode("span", _hoisted_2, [
              _cache[3] || (_cache[3] = _createTextVNode(" ( ")),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.counts, (count, i) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "fw-light text-gray-700",
                  key: count.label
                }, [
                  _createTextVNode(_toDisplayString(count.label) + " - " + _toDisplayString(count.value) + " ", 1),
                  (_ctx.counts.length != i + 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, " , "))
                    : _createCommentVNode("", true)
                ]))
              }), 128)),
              _cache[4] || (_cache[4] = _createTextVNode(" ) "))
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_table, {
              data: _ctx.log,
              stripe: "",
              style: {"width":"100%"},
              height: "650"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group_invoice_log, (group, i) => {
                  return (_openBlock(), _createBlock(_component_el_table_column, {
                    key: i,
                    width: group.width,
                    "header-align": "top",
                    "show-summary": ""
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("span", {
                        class: "d-block fs-6 fw-bolder",
                        innerHTML: group.label
                      }, null, 8, _hoisted_5)
                    ]),
                    default: _withCtx((scope) => [
                      (scope.$index < _ctx.log.length-1)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (group.value == 'invoice_no')
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  innerHTML: scope.row.invoice?.merchant_reference
                                }, null, 8, _hoisted_6))
                              : (group.value == 'group_count')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    (scope.row.invoice.amend_type == 2 && scope.row.group_count > 0)
                                      ? (_openBlock(), _createElementBlock("span", {
                                          key: 0,
                                          innerHTML: '- ' + scope.row.group_count
                                        }, null, 8, _hoisted_7))
                                      : (_openBlock(), _createElementBlock("span", {
                                          key: 1,
                                          innerHTML: scope.row.group_count
                                        }, null, 8, _hoisted_8))
                                  ], 64))
                                : (group.value == 'members_above_60')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                      (scope.row.invoice.amend_type == 2 && scope.row.members_above_60 > 0)
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            innerHTML: '- ' + scope.row.members_above_60
                                          }, null, 8, _hoisted_9))
                                        : (_openBlock(), _createElementBlock("span", {
                                            key: 1,
                                            innerHTML: scope.row.members_above_60
                                          }, null, 8, _hoisted_10))
                                    ], 64))
                                  : (group.value == 'amount')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                        (scope.row.invoice.amend_type == 2 && scope.row.amount > 0)
                                          ? (_openBlock(), _createElementBlock("span", {
                                              key: 0,
                                              innerHTML: '- ' + _ctx.common.formatCurrency(scope.row.amount)
                                            }, null, 8, _hoisted_11))
                                          : (_openBlock(), _createElementBlock("span", {
                                              key: 1,
                                              innerHTML: _ctx.common.formatCurrency(scope.row.amount)
                                            }, null, 8, _hoisted_12))
                                      ], 64))
                                    : (group.value == 'icp')
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                          (scope.row.invoice.amend_type == 2 && scope.row.icp > 0)
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                innerHTML: '- ' + _ctx.common.formatCurrency(scope.row.icp)
                                              }, null, 8, _hoisted_13))
                                            : (_openBlock(), _createElementBlock("span", {
                                                key: 1,
                                                innerHTML: _ctx.common.formatCurrency(scope.row.icp)
                                              }, null, 8, _hoisted_14))
                                        ], 64))
                                      : (group.value == 'basmah')
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                            (scope.row.invoice.amend_type == 2 && scope.row.basmah > 0)
                                              ? (_openBlock(), _createElementBlock("span", {
                                                  key: 0,
                                                  innerHTML: '- ' + _ctx.common.formatCurrency(scope.row.basmah)
                                                }, null, 8, _hoisted_15))
                                              : (_openBlock(), _createElementBlock("span", {
                                                  key: 1,
                                                  innerHTML: _ctx.common.formatCurrency(scope.row.basmah)
                                                }, null, 8, _hoisted_16))
                                          ], 64))
                                        : (_openBlock(), _createElementBlock("span", {
                                            key: 6,
                                            innerHTML: scope.row[group.value]
                                          }, null, 8, _hoisted_17))
                          ], 64))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            innerHTML: scope.row[group.value]
                          }, null, 8, _hoisted_18))
                    ]),
                    _: 2
                  }, 1032, ["width"]))
                }), 128)),
                _createVNode(_component_el_table_column)
              ]),
              _: 1
            }, 8, ["data"])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-light me-5",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
            }, " Cancel ")
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}