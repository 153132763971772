import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import moment from 'moment';


import { State } from './state';
import { store } from '@/store';
import { common } from '@/store/stateless';

export type Getters = {
  getMenus(state: State): any | null;
  getAgents(state: State): any | null;
  getInsuredTypes(state: State): any | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getMenus: (state) => state.menus,
  getAgents: (state) => state.agents,
  getInsuredTypes: (state) => state.insuredTypes,
  getTasks: (state) => (lead_status_id: number, lead_id:number) => {
    if(lead_status_id) {
      const users = window.localStorage.getItem("user");
      const user = users ? JSON.parse(users || "") : "";
      console.log(user)
      const leads = store.getters.getLeads(false);

      const otherLeadStatus = leads.filter((x) => {
          if(![1,2].includes(x.lead_status_id))
              return x;
      });

      // console.log({leads})

      const lead = leads.filter(x => x.lead_id == lead_id);

      const deals = leads.filter(x => x.lead_status_id == 4);

      let responses;
      switch(lead_status_id) {
        case 4: 
          return state.task_lists.deal_task_status; break;
        case 12: 
          return state.task_lists.cancelled_task_status;break;
        default: 
          responses = state.task_lists.task_status;
          break;
      }


      if(![4,12].includes(lead_status_id) && responses){
        const leadDetails = store.getters.getLeadsDetails()

        // console.log('leadDetails',leadDetails)
        // Check if lead has Pending invoices 
        if(leadDetails.check_pending_invoice){
          // remove False Lead, Redudantant , Lost Lead
          responses = responses.filter(x => ![17,11,8].includes(x.value))

        }

        if(leads.length <= 1 ){
          // BND-2033 : filter out redudant lead if only single lead present
            responses = responses.filter(x => x.value != 17)
        }
        
        if(otherLeadStatus.length > 0){
            // BND-2033 : filter out false lead if other leads are present
            responses = responses.filter(x => x.value != 11)
        }

        if(![1,2,3].includes(lead_status_id)){
          // BND-2033 : filter out redudant lead status is not new\QL\Pending
          responses = responses.filter(x => x.value != 17)
        }

        let showLeadConverted = false;

        const myLead = lead[0]
        // console.log({ myLead })

        if(myLead.lead && deals.length > 0) {
          // showLeadConverted = deals.some(x => {
          //   return moment(x.lead.main_invoice_approved_at, 'DD/MM/YYYY HH:mm').unix() > moment(myLead.lead.renewal_date, 'DD/MM/YYYY HH:mm').unix()
          // } )

          showLeadConverted = common.getConvertedDeals(myLead.lead_id).length > 0;
        }

        // (deals.length == 0 || ![4,5].includes(lead[0].old_status)) && 
        if(!showLeadConverted){
          // Renewal : removing lead converted task from task list
          responses = responses.filter(x => x.value != 22)

        }

        // if(!lead[0].show_closed_task){
        //   // Removing Closed task
        //   responses = responses.filter(x => x.value != 19) 
        // }
        // else {
        //   if(lead_status_id != 7)
        //     responses = responses.filter(x => x.value != 19) 
        // }

        responses = responses.filter(x => x.value != 19) 


        // Filter false and lost lead for other agents

        if([2,9,13].includes(user.role_id) && user.id != myLead.lead.agent){
          responses = responses.filter(x => x.value != 8)
          responses = responses.filter(x => x.value != 11)

        }



        return responses
      }

    }
  },
  getReasons: (state) => (response: number) => {
    if(response === 11) {
      return state.lead_reasons.false_lead_reasons
    } else {
      return state.lead_reasons.lost_lead_reasons
    }
  },

  getAllNotifications: (state) => {

    const data = state.allNotificationList
        
    if (data) {
        // console.log('invoice',data?.data)
        const result =  data.data.map((x) => {
          console.log(x)
          return {
            description:  x.description,
            notified_by:  x.notified_by.name,
            notified_to:  x.notified_to.name,
            show_date: x.show_date,
            url: x.url
          }
        });

        console.log(result);

        return result

    }
    return [] ;
  },

  getInsurancePlans:(state) => {
    const policies = state.policies;
        // console.log(policies);
        if(policies) {
           const data = policies.map((x:Object ) => {
              let name = x['insurance_provider']['provider_name'] + ' - ' + x['policy_name'] ;
              x['label'] = name;
              x['value'] = x['id'];
              return x;
            }).sort((a,b) => {return a['label'].localeCompare(b['label'])});
            // console.log(data);
            return data;
        }
        return [];
  },

  getIsVatSet:(state) => {
    const vat = window.localStorage.getItem('hl_vat')
    console.log('hl_vat',vat)
    if (vat && parseFloat(vat) > 0) {
      return true;
    }

    return false

  }
};
