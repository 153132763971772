import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "m-4 table-custom-invoice"
}
const _hoisted_3 = { class: "d-block fs-6 fw-bolder" }
const _hoisted_4 = {
  class: "sub-table",
  style: {"width":"100%"}
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal(false))),
    fullscreen: "",
    class: "quote-details-dialog"
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("div", { class: "d-flex" }, [
        _createElementVNode("h3", { class: "fw-bolder fs-12 text-gray-700" }, " Quote Details ")
      ], -1)
    ])),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "card" }, [
              _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.quotes.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_table, {
              data: _ctx.quotes,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { width: 80 }, {
                  header: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("span", null, "Sr. #", -1)
                  ])),
                  default: _withCtx((scope) => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(scope.$index + 1), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { width: 120 }, {
                  header: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("span", null, "Lead ID", -1)
                  ])),
                  default: _withCtx((scope) => [
                    _createElementVNode("span", null, _toDisplayString(scope.row.lead_id), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { width: 180 }, {
                  header: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("span", null, "Insured For / Name", -1)
                  ])),
                  default: _withCtx((scope) => [
                    _createElementVNode("span", null, _toDisplayString(scope.row.insured_type_text), 1),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(scope.row.name), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { align: "center" }, {
                  header: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("span", null, "Members", -1)
                  ])),
                  default: _withCtx((scope) => [
                    _createElementVNode("table", _hoisted_4, [
                      _createElementVNode("tr", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subHeading, (heading) => {
                          return (_openBlock(), _createElementBlock("th", {
                            key: heading.value
                          }, [
                            _createElementVNode("span", {
                              innerHTML: heading.label
                            }, null, 8, _hoisted_5)
                          ]))
                        }), 128))
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.members_array, (member, i) => {
                        return (_openBlock(), _createElementBlock("tr", { key: i }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subHeading, (heading) => {
                            return (_openBlock(), _createElementBlock("td", {
                              key: heading.value
                            }, [
                              (heading.value === 'is_married')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(member[heading.value] == 1 ? 'YES' : 'NO'), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(member[heading.value]), 1))
                            ]))
                          }), 128))
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { width: 150 }, {
                  header: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("span", null, "Quotation", -1)
                  ])),
                  default: _withCtx((scope) => [
                    _createElementVNode("span", null, _toDisplayString(scope.row.filename), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { width: 200 }, {
                  header: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createElementVNode("span", null, "Action", -1)
                  ])),
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quoteButtons, (action, k) => {
                        return (_openBlock(), _createElementBlock("span", { key: k }, [
                          _createElementVNode("span", {
                            class: "ms-4 cursor-pointer icon-bg-common",
                            onClick: ($event: any) => (_ctx.makeAction(scope.row, action.value))
                          }, [
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              content: action.tip,
                              effect: "light",
                              placement: "bottom",
                              "show-after": 200,
                              "hide-after": 0
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("i", {
                                  class: _normalizeClass(action.icon)
                                }, null, 2)
                              ]),
                              _: 2
                            }, 1032, ["content"])
                          ], 8, _hoisted_9)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.quotes.length == 0 )
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 2,
            description: "No Records Available"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}