
import { defineComponent, onMounted, computed, getCurrentInstance } from 'vue'
import { store } from "@/store";
import { customer, rule, common } from '@/store/stateless';
import Etcs from '@/core/services/Callable/Etcs';
import vcCountries from '@/components/reusables/vcCountries.vue';
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    components: { vcCountries },
    props: {
        isDisbaled: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabledMain: {
            type: Boolean,
            required: false,
            default: false
        },
        
        isShowHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        updateCustomer: {
            type: Boolean,
            required: false,
            default: false
        },
        isExpectedStartDateDisabled:{
            type:Boolean,
            required:false,
            default:true
        }

    },
    setup() {

        onMounted(() => {
            Etcs.getCountryCode();
        })

        const health = computed(() => {
            return store.state.customer.health;
        })

        const countryCodes = computed(() => {
            return store.state.etc.countryCodes;
        })

        const disabledDate = (time: any) => {
            return time.getTime() > new Date()
        }

        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            console.log(toFocus)
            if(toFocus !== undefined) {
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }
        const minStartDate = computed(() => {
            const datenow = new Date()
            const year = datenow.getFullYear();
            const month = datenow.getMonth() + 1;
            const day = ('0' + datenow.getDate()).slice(-2);
            return year + '-' + month + '-' + day;
        })

        const emailEvent = () => {
            Validations.email_taken = false
        }
        return {
            rule,
            focus,
            health,
            countryCodes,
            disabledDate,
            customer,
            common,
            minStartDate,
            emailEvent
        }
    },
})
