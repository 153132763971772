import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "table-custom-invoice"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body p-2" }
const _hoisted_7 = { class: "text-gray-600" }
const _hoisted_8 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_9 = {
  scope: "col",
  class: "p-2",
  colspan: "2",
  nowrap: ""
}
const _hoisted_10 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_11 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_12 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "card" }
const _hoisted_15 = { class: "card-body p-2" }
const _hoisted_16 = { class: "text-gray-600" }
const _hoisted_17 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_18 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_21 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_assigned_policies_action_drpdown = _resolveComponent("vc-assigned-policies-action-drpdown")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.policyData.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_table, {
            data: _ctx.policyData,
            style: {"width":"100%"},
            "row-key": "sid",
            lazy: "",
            load: _ctx.load,
            "tree-props": {hasChildren: 'hasChildren'}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy_columns, (policy, i) => {
                return (_openBlock(), _createBlock(_component_el_table_column, {
                  key: i,
                  fixed: policy.isFixed,
                  "class-name": policy.cn,
                  width: policy.width
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "d-block fs-6 fw-bolder",
                      innerHTML: policy.label
                    }, null, 8, _hoisted_3)
                  ]),
                  default: _withCtx((scope) => [
                    (policy.isAction && (!scope.row.amend_type || scope.row.amend_type == 1) && (!_ctx.isCustomerDetails || scope.row.status == 4))
                      ? (_openBlock(), _createBlock(_component_vc_assigned_policies_action_drpdown, {
                          policy: scope.row,
                          key: scope.row.id,
                          isView: _ctx.isView
                        }, null, 8, ["policy", "isView"]))
                      : (policy.isCustomer)
                        ? (_openBlock(), _createBlock(_component_el_popover, {
                            key: 1,
                            placement: "left",
                            width: "180",
                            trigger: "hover"
                          }, {
                            reference: _withCtx(() => [
                              _createElementVNode("span", {
                                style: {"cursor":"pointer"},
                                onClick: ($event: any) => (_ctx.copyText.copyLink(scope.row.customer.email)),
                                class: _normalizeClass({'in-active-policy': scope.row.is_active === 0})
                              }, _toDisplayString(scope.row.customer.email), 11, _hoisted_4)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("table", _hoisted_7, [
                                    _cache[1] || (_cache[1] = _createElementVNode("tr", {
                                      class: "text-gray-600",
                                      style: {"background-color":"#fff"}
                                    }, [
                                      _createElementVNode("th", {
                                        scope: "col",
                                        nowrap: "",
                                        colspan: "2",
                                        class: "text-center p-2"
                                      }, " Customer Name ")
                                    ], -1)),
                                    _createElementVNode("tr", _hoisted_8, [
                                      _createElementVNode("td", _hoisted_9, _toDisplayString(scope.row.customer.name), 1)
                                    ])
                                  ]),
                                  _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
                                  _createElementVNode("table", null, [
                                    _cache[2] || (_cache[2] = _createElementVNode("tr", {
                                      class: "text-gray-600",
                                      style: {"background-color":"#fff"}
                                    }, [
                                      _createElementVNode("th", {
                                        scope: "col",
                                        nowrap: "",
                                        class: "text-center p-2"
                                      }, " Name "),
                                      _createElementVNode("th", {
                                        scope: "col",
                                        nowrap: "",
                                        class: "text-center p-2"
                                      }, " Relationship "),
                                      _createElementVNode("th", {
                                        scope: "col",
                                        nowrap: "",
                                        class: "text-center p-2"
                                      }, " Sponsor ")
                                    ], -1)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.lead.lead_informations, (info, i) => {
                                      return (_openBlock(), _createElementBlock("tr", {
                                        class: "text-gray-600",
                                        style: {"background-color":"#fff"},
                                        key: i
                                      }, [
                                        _createElementVNode("td", _hoisted_10, _toDisplayString(info.member_name), 1),
                                        _createElementVNode("td", _hoisted_11, _toDisplayString(info.relationship), 1),
                                        _createElementVNode("td", _hoisted_12, _toDisplayString(scope.row.customer.name), 1)
                                      ]))
                                    }), 128))
                                  ])
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        : (policy.isPolicyPrice)
                          ? (_openBlock(), _createBlock(_component_el_popover, {
                              key: 2,
                              placement: "left",
                              width: "180",
                              trigger: "hover"
                            }, {
                              reference: _withCtx(() => [
                                _createElementVNode("span", {
                                  style: {"cursor":"pointer"},
                                  class: _normalizeClass({'in-active-policy': scope.row.is_active === 0})
                                }, [
                                  _createTextVNode(_toDisplayString(scope.row.main_invoice.sales_price
                    ? _ctx.common.formatCurrency(scope.row.main_invoice.sales_price)
                    : "") + " ", 1),
                                  (_ctx.store.getters.getIsVatSet)
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(scope.row.main_invoice.sales_price_vat
                      ? _ctx.common.formatCurrency(scope.row.main_invoice.sales_price_vat)
                      : ""), 1))
                                    : _createCommentVNode("", true)
                                ], 2)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("table", _hoisted_16, [
                                      _cache[6] || (_cache[6] = _createElementVNode("tr", {
                                        class: "text-gray-600",
                                        style: {"background-color":"#fff"}
                                      }, [
                                        _createElementVNode("th", {
                                          scope: "col",
                                          nowrap: "",
                                          colspan: "5",
                                          class: "text-center p-2"
                                        }, " Price Details ")
                                      ], -1)),
                                      _createElementVNode("tr", _hoisted_17, [
                                        _cache[5] || (_cache[5] = _createElementVNode("th", {
                                          scope: "col",
                                          class: "p-2",
                                          nowrap: ""
                                        }, "Quote Ref No.", -1)),
                                        _createElementVNode("th", _hoisted_18, [
                                          _cache[4] || (_cache[4] = _createTextVNode(" Policy Price ")),
                                          (_ctx.store.getters.getIsVatSet)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_19, "(VAT)"))
                                            : _createCommentVNode("", true)
                                        ])
                                      ]),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row
                          .main_invoice.all_invoices, (invoices, index) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                          class: "text-gray-600",
                                          style: {"background-color":"#fff"},
                                          key: index
                                        }, [
                                          _createElementVNode("th", _hoisted_20, _toDisplayString(invoices.merchant_reference), 1),
                                          _createElementVNode("th", _hoisted_21, [
                                            _createElementVNode("span", null, [
                                              _createTextVNode(_toDisplayString(_ctx.common.formatCurrency(invoices.policy_price)) + " ", 1),
                                              (_ctx.store.getters.getIsVatSet)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_22, " (" + _toDisplayString(_ctx.common.formatCurrency(invoices.policy_price_vat)) + ") ", 1))
                                                : _createCommentVNode("", true)
                                            ])
                                          ])
                                        ]))
                                      }), 128))
                                    ])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          : (policy.isCreated)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 3,
                                class: _normalizeClass({'in-active-policy': scope.row.is_active === 0})
                              }, [
                                _createTextVNode(_toDisplayString(scope.row.created_at) + " ", 1),
                                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.common.setDateTimeOnly(scope.row.issue_date)), 1)
                              ], 2))
                            : (_ctx.isCustomerDetails && policy.value == 'merchant_reference'  && scope.row.is_active == 1 &&  scope.row.status == 4)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 4,
                                  class: "badge badge-success",
                                  style: {"color":"#fff"},
                                  innerHTML: scope.row[policy.value]
                                }, null, 8, _hoisted_23))
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 5,
                                  innerHTML: scope.row[policy.value],
                                  class: _normalizeClass({'in-active-policy': scope.row.is_active === 0})
                                }, null, 10, _hoisted_24))
                  ]),
                  _: 2
                }, 1032, ["fixed", "class-name", "width"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["data", "load"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.policyData.length == 0)
      ? (_openBlock(), _createBlock(_component_el_empty, {
          key: 2,
          description: "No Records Available"
        }))
      : _createCommentVNode("", true)
  ], 64))
}