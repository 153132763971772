import { registration } from '@/store/stateless';

const policy_columns = [
    {
        label: 'Action',
        value: 'action',
        width: '100',
        cn: 'dropdown-action',
        isFixed: true,
        isAction:true
    },
    {
        label: 'Quote Ref No',
        value: 'merchant_reference',
        width: '200',
        isFixed: true
    },
    {
        label: 'Status',
        value: 'status_text',
        width: '150',
    },
    {
        label: 'Email',
        value: 'email',
        width: '250',
        isCustomer: true,
    },
    {
        label: 'Policy Price('+registration.currency+')',
        value: 'sales_price',
        width: '200',
        isPolicyPrice:true
    },
    
    {
        label: 'Transaction Type',
        value: 'transaction_type_text',
        width: '200',
    },
    
    
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: '150',
    },
    {
        label: 'Created on <br/> Issued / Completed',
        value: 'created_at',
        width: '250',
        isCreated: true
    },
    {
        label: 'Start Date <br/>End Date',
        value: 'start_end_date',
        width: '150',
    },
    {
        label: 'Underwriter <br/> Policy Agent',
        value: 'agent_details',
        width: '200',
    },
    {
        label: 'Invoice By & <br/> Created On',
        value: 'invoice_created_details',
        width: '200',
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text',
        width: '150',
    },
]

const group_policy_columns = [
    {
        label: 'Action',
        value: 'action',
        width: '100',
        cn: 'dropdown-action',
        isFixed: true,
        isAction:true
    },
    {
        label: 'Sub Quote Ref. No',
        value: 'sub_quote_ref_no',
        width: '200',
        isFixed: true
    },
    {
        label: 'Category',
        value: 'category',
        width: '150',
    },
    {
        label: 'Visa Issued',
        value: 'visa_issued',
        width: '180',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        width: '200',
    },
    
    {
        label: 'Policy No.',
        value: 'policy_no',
        width: '200',
    },
    {
        label: 'Start Date <br /> End Date',
        value: 'start_end_date',
        width: '150',
    },
    
    {
        label: 'Members',
        value: 'members',
        width: '250',
    },
    {
        label: 'Created On <br /> Issued / Completed',
        value: 'created_at',
        width: '250',
    },
    {
        label: 'Underwriter <br/> Policy Agent',
        value: 'agent_details',
        width: '200',
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_type',
        width: '200',
    }
]


const amend_doc_columns = [
    {
        label: 'Sr.#',
        value: 'index',
        width: '60',
        isFixed: true
    },
    {
        label: '',
        value: 'upload',
        width: '100',
        isFixed: true
    },
    {
        label: 'Document Type',
        value: 'title',
        // width: '200',
        // isFixed: true
    },

    {
        label: 'File Name',
        value: 'original_filename',
        width: '200',
        isFixed: true
    },
    
    {
        label: 'Amend Quote Ref No',
        value: 'quote_ref_no',
        // width: '200',
        isFixed: true
    },
    {
        label: 'Invoice By & <br/> Created On',
        value: 'invoice_created_details',
        // width: '300',
    },
    {
        label: 'Action',
        value: 'action',
        width: '200',
        // cn: 'dropdown-action',
        // isFixed: true,
        // isAction:true,
        align: 'center'

    },
]

const amend_doc_actions  = [
    
    {
        icon: 'icon-svg icon-download',
        tip: 'Download',
        value: 2,
        show: true
    },
    {
        icon: 'fa fa-eye text-info',
        tip: 'View',
        value: 3,
        show: true
    },
    
]

const email_columns = [
    
    {
        label: 'Activity',
        value: 'activity_desc',
        width: '500',
        isFixed: true
    },
    {
        label: 'Sent By',
        value: 'sent_by',
        // width: '150',
    },
    
    {
        label: 'Send on',
        value: 'created_at',
        // width: '250',
        isCreated: true
    },
    
]

const updatePolicyDetailsProp = ['insured_person','policy_holder','emirates_id','policy_holder_emirates_id','policy_start_date','policy_end_date','insurance_payment','entity_type']
const updatePolicyGroupProp = ['policy_start_date', 'policy_number']

const group_columns = [
    
    {
        label: 'Category',
        value: 'category_name',
        width: '120',
    },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        // width: '350',
    },
    {
        label: 'Members Count',
        value: 'group_count',
        width: '180',
    },
    {
        label: 'Members > 60',
        value: 'members_above_60',
        width: '180',
    },
    {
        label: 'Policy No.',
        value: 'policy_number',
        width: '300',
    },
]


const edit_policy_group_columns = [
    
    // {
    //     label: 'Category',
    //     value: 'category_name',
    //     width: '120',
    // },
    {
        label: 'Visa Issued',
        value: 'visa_emirates_text',
        width: '150',
    },
    {
        label: 'Insurance Plan',
        value: 'insurance_plan',
        // width: '350',
    },
    // {
    //     label: 'Members Count',
    //     value: 'group_count',
    //     width: '180',
    // },
    // {
    //     label: 'Members > 60',
    //     value: 'members_above_60',
    //     width: '180',
    // },
    {
        label: 'Policy Start Date',
        value: 'policy_start_date',
        width: '350',
    },
    {
        label: 'Policy No.',
        value: 'policy_number',
        width: '500',
    },
    // {
    //     label: 'CN',
    //     value: 'credit_amount',
    //     // width: '300',
    // },
    // {
    //     label: 'CN No.',
    //     value: 'credit_notes_no',
    //     // width: '300',
    // },
    // {
    //     label: 'DN',
    //     value: 'debit_amount',
    //     // width: '300',
    // },
    // {
    //     label: 'DN No.',
    //     value: 'debit_notes_no',
    //     // width: '300',
    // },
]

const policy_group_columns = [
    {
        label: 'Action',
        value: 'action',
        width: '100',
        cn: 'dropdown-action',
        isFixed: true,
        isAction:true
    },
    {
        label: 'Quote Ref No',
        value: 'merchant_reference',
        width: '200',
        isFixed: true
    },
    {
        label: 'Status',
        value: 'status_text',
        width: '150',
    },
    {
        label: 'Email',
        value: 'email',
        width: '250',
        isCustomer: true,
    },
    {
        label: 'Policy Price <br/>& VAT ('+registration.currency+')',
        value: 'sales_price',
        width: '200',
        isPolicyPrice:true
    },
    
    {
        label: 'Transaction Type',
        value: 'transaction_type_text',
        width: '200',
    },
    {
        label: 'Created on <br/> Issued / Completed',
        value: 'created_at',
        width: '250',
        isCreated: true
    },
    {
        label: 'Start Date <br/>End Date',
        value: 'start_end_date',
        width: '150',
    },
    {
        label: 'Underwriter <br/> Policy Agent',
        value: 'agent_details',
        width: '200',
    },
    {
        label: 'Invoice By & <br/> Created On',
        value: 'invoice_created_details',
        width: '200',
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text',
        width: '150',
    },
]


export {
    group_policy_columns,
    policy_columns,
    amend_doc_columns,
    amend_doc_actions,
    email_columns,
    updatePolicyDetailsProp,
    group_columns,
    policy_group_columns,
    updatePolicyGroupProp,
    edit_policy_group_columns
}