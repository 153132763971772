import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  href: "javascript:void(0)",
  class: "d-flex btn-active-color-primary btn-sm dropdown-item me-1"
}
const _hoisted_2 = { class: "icon-parent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, null, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, { class: "customer-details-action" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              (item.show)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: ($event: any) => (_ctx.$emit('action', item.click))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_1, [
                        _createElementVNode("span", _hoisted_2, [
                          _createElementVNode("i", {
                            class: _normalizeClass(item.icon)
                          }, null, 2)
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(item.hasIssue ? 'error' : '')
                        }, _toDisplayString(item.label), 3)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("button", { class: "btn btn-info" }, [
        _createElementVNode("i", {
          class: "fa fa-ellipsis-h",
          "aria-hidden": "true"
        })
      ], -1))
    ]),
    _: 1
  }))
}