import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = {
  key: 1,
  class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-5 w-100 mt-5"
}
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_vc_customer_tasks_drawer_data = _resolveComponent("vc-customer-tasks-drawer-data")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.view_task.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.view_task.open) = $event)),
    "with-header": false,
    "append-to-body": "",
    onClose: _ctx.close,
    size: _ctx.view_task.is_fullscreen ? '100%' : '50%',
    class: "task-list-dialog"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fw-bolder text-gray-700" }, "Task List", -1)),
        _createVNode(_component_el_button, {
          class: "header-icon",
          link: "",
          icon: _ctx.Close,
          onClick: _ctx.close
        }, null, 8, ["icon", "onClick"])
      ]),
      _createVNode(_component_el_divider, { class: "my-4" }),
      (_ctx.view_task.is_fullscreen)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            gutter: 10,
            class: "mx-5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 10 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    filterable: "",
                    modelValue: _ctx.activeValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeValue) = $event)),
                    onChange: _ctx.changeValue,
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: i,
                          label: lead.name,
                          value: lead.lead_id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "nav-item cursor-pointer policy",
                key: i
              }, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link trunc text-truncate", {'active': _ctx.activeTab === lead.lead_id,
                      'disabled': lead.tab_disabled}]),
                  id: `lead_${i}`,
                  ref_for: true,
                  ref: `lead_${lead.lead_id}`,
                  onClick: ($event: any) => (_ctx.fetchData(lead.lead_id))
                }, [
                  (_ctx.common.getTextWidth(lead.name) > 270)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        class: "box-item",
                        effect: "light",
                        content: lead.name,
                        placement: "bottom",
                        "show-after": 500,
                        "hide-after": 0
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(lead.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(lead.name), 1))
                ], 10, _hoisted_3)
              ]))
            }), 128))
          ])),
      _createVNode(_component_el_row, {
        gutter: 10,
        class: "mx-5"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              (_ctx.tasks)
                ? (_openBlock(), _createBlock(_component_vc_customer_tasks_drawer_data, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "size"]))
}