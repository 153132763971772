import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5 container" }
const _hoisted_2 = { class: "nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5 w-100" }
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "mb-10" }
const _hoisted_7 = { class: "col-sm-6" }
const _hoisted_8 = { class: "mb-10" }
const _hoisted_9 = { class: "col-sm-6" }
const _hoisted_10 = { class: "mb-10" }
const _hoisted_11 = { class: "col-sm-6" }
const _hoisted_12 = { class: "mb-10" }
const _hoisted_13 = {
  key: 1,
  class: "row"
}
const _hoisted_14 = { class: "col-sm-6" }
const _hoisted_15 = { class: "mb-10" }
const _hoisted_16 = { class: "col-sm-6" }
const _hoisted_17 = { class: "mb-10" }
const _hoisted_18 = { class: "col-sm-6" }
const _hoisted_19 = { class: "mb-10" }
const _hoisted_20 = {
  key: 0,
  class: "col-sm-6"
}
const _hoisted_21 = { class: "mb-10" }
const _hoisted_22 = { class: "d-flex justify-content-center mb-4 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_phone = _resolveComponent("vc-phone")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_vc_countries = _resolveComponent("vc-countries")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_gender = _resolveComponent("vc-gender")!
  const _component_vc_yes_no = _resolveComponent("vc-yes-no")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _ctx.open,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.open) = $event)),
        "append-to-body": "",
        width: _ctx.common.isMobile() ? '100%' : '55%',
        title: "Update Customer",
        onClosed: _cache[13] || (_cache[13] = ($event: any) => (_ctx.close(_ctx.ruleFormRef))),
        onOpened: _ctx.setFocusTofn,
        draggable: "",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            model: _ctx.health,
            "status-icon": "",
            rules: _ctx.rule.update_customer,
            id: "update_customer",
            ref: "ruleFormRef",
            "scroll-to-error": "",
            onSubmit: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.submitCustomer(_ctx.ruleFormRef)), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("ul", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.update_customer_tab, (log, i) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "nav-item cursor-pointer policy",
                      key: i
                    }, [
                      _createElementVNode("a", {
                        class: _normalizeClass(["nav-link", {'active': _ctx.activeTab === log.value}]),
                        id: `ctab_${i}`,
                        ref_for: true,
                        ref: `ctab_${log.value}`,
                        onClick: _withModifiers(($event: any) => (_ctx.activeTab = log.value), ["prevent"])
                      }, [
                        _createElementVNode("span", null, _toDisplayString(log.label), 1)
                      ], 10, _hoisted_3)
                    ]))
                  }), 128))
                ]),
                (_ctx.activeTab == 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_vc_phone, {
                            label: "Phone Number",
                            placeholder: "Enter Phone Number",
                            prop: "phone_number",
                            ref: "phone_number",
                            isInput: "text",
                            maxlength: _ctx.health.phone_country_code == '+961' ? '8' : '10',
                            class: "phone country-code-dropdown",
                            keydown: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')",
                            modelValue: _ctx.health.phone_number,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.health.phone_number) = $event)),
                            code: _ctx.health.phone_country_code,
                            "onUpdate:code": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.health.phone_country_code) = $event)),
                            disabled: _ctx.isDisbaled || _ctx.isDisabledMain,
                            isRequired: "",
                            onInput: _ctx.phoneEvent,
                            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('checkPhoneExist')))
                          }, null, 8, ["maxlength", "modelValue", "code", "disabled", "onInput"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_vc_input, {
                            label: "Full Name",
                            placeholder: "Enter Full Name",
                            prop: "name",
                            ref: "name",
                            size: "large",
                            modelValue: _ctx.health.name,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.health.name) = $event)),
                            disabled: _ctx.isDisbaled || _ctx.isDisabledMain,
                            focus: true,
                            isRequired: "",
                            keydown: "return /[a-zA-Zء-ي\\s'-]/i.test(event.key)"
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_vc_input, {
                            label: "Email",
                            isInput: "email",
                            placeholder: "Enter Email",
                            prop: "email",
                            ref: "email",
                            size: "large",
                            modelValue: _ctx.health.email,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.health.email) = $event)),
                            isPrefix: "",
                            isRequired: "",
                            disabled: (_ctx.isDisbaled && _ctx.ql) || _ctx.isDisabledMain,
                            onInput: _ctx.emailEvent,
                            keydown: "return /[-a-zA-Z0-9_@.]/i.test(event.key)"
                          }, null, 8, ["modelValue", "disabled", "onInput"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_vc_input, {
                            modelValue: _ctx.health.other_contact_info,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.health.other_contact_info) = $event)),
                            prop: "other_contact_info",
                            label: "Other Contact Information",
                            placeholder: "Enter contact information",
                            size: "large"
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label required" }, " DOB ", -1)),
                          _createVNode(_component_vc_date_picker, {
                            modelValue: _ctx.health.dob,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.health.dob) = $event)),
                            min: "1900-01-01",
                            prop: "dob",
                            ref: "dob",
                            label: "DOB",
                            disabled: _ctx.isDisbaled && _ctx.ql,
                            isRequired: "",
                            max: _ctx.common.minDate(),
                            defaultValue: _ctx.common.minDate()
                          }, null, 8, ["modelValue", "disabled", "max", "defaultValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label required" }, " Nationality ", -1)),
                          _createVNode(_component_el_form_item, { prop: "nationality_id" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_vc_countries, {
                                modelValue: _ctx.health.nationality_id,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.health.nationality_id) = $event)),
                                ref: "nationality_id",
                                label: "Select your nationality",
                                disabled: _ctx.isDisbaled && _ctx.ql
                              }, null, 8, ["modelValue", "disabled"])
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label required" }, " Gender ", -1)),
                          _createVNode(_component_el_form_item, {
                            prop: "gender",
                            class: "el-form-item-custom-radio"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_vc_gender, {
                                genderName: "gender",
                                modelValue: _ctx.health.gender,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.health.gender) = $event)),
                                ref: "gender",
                                disabled: _ctx.health.hasDealInvoice
                              }, null, 8, ["modelValue", "disabled"])
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      (_ctx.health.gender === 'FEMALE')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label required" }, " Is married? ", -1)),
                              _createVNode(_component_el_form_item, {
                                yesNoName: "yesNoIsMarried",
                                prop: "is_married",
                                class: "el-form-item-custom-radio"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_vc_yes_no, {
                                    modelValue: _ctx.health.is_married,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.health.is_married) = $event)),
                                    disabled: _ctx.health.hasDealInvoice
                                  }, null, 8, ["modelValue", "disabled"])
                                ]),
                                _: 1
                              })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
              ]),
              _createVNode(_component_el_divider),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_vc_button, {
                  text: "Cancel",
                  className: "btn btn-light me-3",
                  onVcClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.close(_ctx.ruleFormRef))),
                  "is-regular": ""
                }),
                _createVNode(_component_vc_button_save, { loading: _ctx.loading }, null, 8, ["loading"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["modelValue", "width", "onOpened"]))
    : _createCommentVNode("", true)
}