
    import {
        defineComponent,
        computed,
    } from "vue";
    import { store } from "@/store";
    import { common } from "@/store/stateless";
    import { table_others } from "@/core/utils/dashboard.js";
    import Dashboard from '@/core/services/Callable/Dashboard';

    export default defineComponent({
        name: "dashboard-overview",
        setup() {

            const filter = computed(() => {
                return store.state.dashboard.filter;
            })

            const tableOthers = computed(() => {
                return store.state.dashboard.otherStat
            })

            const newLeadsPagination = computed(() => {
                return store.state.dashboard.leadsNew;
            })

            const pendingLeadsPagination = computed(() => {
                return store.state.dashboard.leadsPending;
            })

            const lostLeadsPagination = computed(() => {
                return store.state.dashboard.leadsLost;
            })

            const newLeads = computed(() => {
                return store.getters.getNewLeads;
            })

            const pendingLeads = computed(() => {
                return store.getters.getPendingLeads;
            })

            const lostLeads = computed(() => {
                return store.getters.getLostLeads;
            })

            const codTransactions = computed(() => {
                return store.state.dashboard.codTransactions;
            })

            const directTransactions = computed(() => {
                return store.state.dashboard.directTransactions;
            })

            const chequeTransactions = computed(() => {
                return store.state.dashboard.chequeTransactions;
            })

            const onlineTransaction = computed(() => {
                return store.state.dashboard.onlineTransaction;
            })

            const suyoolTransaction = computed(() => {
                return store.state.dashboard.suyoolTransaction;
            })

            const cashDepositTransactions = computed(() => {
                return store.state.dashboard.cashDepositTransactions;
            })

            const omtTransactions = computed(() => {
                return store.state.dashboard.omtTransactions;
            })

            const otherAveragePoliciesData = computed(() => {
                return store.state.dashboard.otherAveragePoliciesData
            })

            const firstMedical = computed(() => {
                const data = store.state.dashboard.otherAveragePoliciesData
                if (data) {
                    return {
                        "Total Amount": data.first_amount,
                        "Average Amount": data.first_amount_avg,
                        "Sold Policies": data.first_sold_count
                    }
                } else {
                    return {}
                }
            })

            const secondClass = computed(() => {
                const data = store.state.dashboard.otherAveragePoliciesData
                if (data) {
                    return {
                        "Total Amount": data.second_amount,
                        "Average Amount": data.second_amount_avg,
                        "Sold Policies": data.second_sold_count
                    }
                } else {
                    return {}
                }
            })

            const thirdClass = computed(() => {
                const data = store.state.dashboard.otherAveragePoliciesData
                if (data) {
                    return {
                        "Total Amount": data.third_amount,
                        "Average Amount": data.third_amount_avg,
                        "Sold Policies": data.third_sold_count
                    }
                } else {
                    return {}
                }
            })

            const luxClass = computed(() => {
                const data = store.state.dashboard.otherAveragePoliciesData
                if (data) {
                    return {
                        "Total Amount": data.lux_amount,
                        "Average Amount": data.lux_amount_avg,
                        "Sold Policies": data.lux_sold_count
                    }
                } else {
                    return {}
                }
            })

            const groupPolicies = computed(() => {
                const data = store.state.dashboard.otherAveragePoliciesData
                if (data) {
                    return {
                        "Total Amount": data.group_amount,
                        "Average Amount": data.group_amount_avg,
                        "Sold Policies": data.group_sold_count
                    }
                } else {
                    return {}
                }
            })

            const closingRatio = computed(() => {
                const data = store.state.dashboard.otherClosingRatio
                    if(data) {
                        return {
                            "Total Deals" : data.deals,
                            "Total Leads" : data.leads,
                            "Closing Ratio": data.closing_ratio + ' %'
                        }
                    } else {
                        return {}
                    }
            })

            const getTransactions = (key, pagination = false) => {
                let data = null
                if (key == 'cod_transactions') {
                    data = codTransactions.value
                } else if (key == 'direct_transactions') {
                    data = directTransactions.value
                } else if (key == 'cheque_transactions') {
                    data = chequeTransactions.value
                } else if (key == 'online_transactions') {
                    data = onlineTransaction.value
                } else if (key == 'suyool_transactions') {
                    data = suyoolTransaction.value
                } else if (key == 'omt_transactions') {
                    data = omtTransactions.value
                } else if (key == 'cash_deposit_transactions') {
                    data = cashDepositTransactions.value
                }

                if (pagination && data)
                    return data['result'];

                if (data && data['result']) {
                    const result = data['result']['data'];
                    if (result) {
                        return result;
                    }
                }

                return [];
            }

            const getOtherData = (key) => {
                if (key == 'leadsNew') {
                    return newLeads.value;
                }

                else if (key == 'leadsPending') {
                    return pendingLeads.value;
                }

                else if (key == 'leadsLost') {
                    return lostLeads.value;
                }

                else {
                    return getTransactions(key)
                }


                return [];
            }

            const getPagination = (key) => {
                if (key == 'leadsNew') {
                    return newLeadsPagination.value;
                }

                else if (key == 'leadsPending') {
                    return pendingLeadsPagination.value;
                }

                else if (key == 'leadsLost') {
                    return lostLeadsPagination.value;
                }

                else {
                    return getTransactions(key, true)
                }
            }

            const paginationClick = async (key, page) => {
                console.log(key, page)
                const payload = { ...filter.value }
                if (key == 'leadsNew') {
                    payload.status = 'new';
                    payload.page = page;
                    await Dashboard.getDashboardLeads(payload)
                }

                else if (key == 'leadsPending') {
                    payload.status = 'pending';
                    payload.page = page;
                    await Dashboard.getDashboardLeads(payload)
                }

                else if (key == 'leadsLost') {
                    payload.status = 'lost';
                    payload.page = page;
                    await Dashboard.getDashboardLeads(payload)
                }
                else {

                    payload.type = key.includes('group') ? 'group' : 'individual';

                    if (key.includes('cod')) {
                        payload.paymentType = 'cod';
                    }

                    else if (key.includes('direct')) {
                        payload.paymentType = 'direct';
                    }

                    else if (key.includes('cheque')) {
                        payload.paymentType = 'cheque';
                    }

                    else if (key.includes('bank')) {
                        payload.paymentType = 'bank_transfer';
                    }

                    
                    payload.page = page;
                    await Dashboard.getDashboardTransactions(payload)
                }
            }

            return {
                common,
                table_others,
                tableOthers,
                getOtherData,
                paginationClick,
                getPagination,
                otherAveragePoliciesData,
                firstMedical,
                secondClass,
                thirdClass,
                luxClass,
                groupPolicies,
                closingRatio
            };
        },
    });
