import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-4" }
const _hoisted_2 = { class: "d-grid" }
const _hoisted_3 = { class: "dialog-footer" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

import {
    Check,
    Message,
    } from '@element-plus/icons-vue'
    import { computed, reactive, ref } from 'vue'
    import { common, notification } from '@/store/stateless/'
    import Validations from '@/core/services/etc/Validations'
    // import { useUpdateCustomerData } from '@/store/composable/Customer'
    import { useStore } from "vuex";
    import { useRouter } from "vue-router";
    import JwtService from "@/core/services/JwtService";
    import { AuthMutationTypes } from '@/store/enums/Auth/Mutations';
    import LocalStorageServices from '@/core/services/LocalStorageServices';
    import Auths from '@/core/services/Callable/Auths';
    import Etcs from '@/core/services/Callable/Etcs';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordReset',
  props: {
        isLogin: {
            type: Boolean,
            required: false,
            default: true
        },
    },
  setup(__props, { expose: __expose }) {

    const store = useStore();
    const router = useRouter();
    const ruleFormNameRef = ref()
    const loading = ref(false)
    const password = ref()
    const email = ref()
    const code = ref()
    const buttonIcon = ref('el-icon-message')
    const buttonIconLoading = ref(false)
    const buttonIconDisabled = ref(false)

    const props = __props

    const reset = computed(() => store.state.auth.reset)
    const open = computed(() => store.state.auth.open_reset)
    const reset_populated = computed(() => store.state.auth.reset_populated)

    const rules = reactive({
        email: [{ validator: Validations.username, trigger: ['blur', 'change'] }],
        password: [{ validator: Validations.passwordStrong, trigger: ['blur', 'change'] }],
        code: [{ validator: Validations.code, trigger: ['blur', 'change'] }],
        confirm_password: [{ validator: (rule, value, callback) => Validations.confirm_password(rule, value, callback, reset.value.password), trigger: ['blur', 'change'] }],
    })

    const isLengthValid = computed(() => {
        const lengthRequirement = /.{12,}/
        return lengthRequirement.test(reset.value.password)
    })

    const hasUppercase = computed(() => {
        const uppercaseRequirement = /[A-Z]/
        return uppercaseRequirement.test(reset.value.password)
    })

    const hasLowercase = computed(() => {
        const lowercaseRequirement = /[a-z]/
        return lowercaseRequirement.test(reset.value.password)
    })

    const hasDigit = computed(() => {
        const digitRequirement = /\d/
        return digitRequirement.test(reset.value.password)
    })

    const hasSpecialChar = computed(() => {
        const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/
        return specialCharRequirement.test(reset.value.password)
    })

    function closed() {
        loading.value = false
        Auths.AssignPasswordReset({
            open: false,
            reset_populated: false,
            reset: {
                email: '',
                password: '',
                confirm_password: '',
                code: '',
                app: 'health'
            }
        })
        ruleFormNameRef.value.resetFields()
    }

    async function handleSubmit(formEl) {
        resetValidations()

        if(!formEl) return

        formEl.validate(async(valid) => {
            if(valid) {
                reset.value.linkType = 'reset-password'

                loading.value = true
                const response = await Auths.PasswordReset(reset.value)
                loading.value = false

                if(response.status < 299) {
                    await Etcs.getRoutes()
                    const user = response.data.data.user;
                    JwtService.saveToken(response.data.data.access_token)
                    window.localStorage.setItem('refresh_token', response.data.data.refresh_token)
                    window.localStorage.setItem('expires_in', response.data.data.expires_in)
                    
                    store.commit(AuthMutationTypes.SET_AUTH_TOKEN, response.data.data.access_token)
                    store.commit(AuthMutationTypes.SET_AUTH_LOADING, false);
                    store.commit(AuthMutationTypes.SET_AUTH_USER, user)
                    LocalStorageServices.saveUser(JSON.stringify(user));

                    const localStorageRoutes = window.localStorage.getItem('currentRoute');
                    let currentRoute = localStorageRoutes ? JSON.parse(localStorageRoutes) : null;

                    const uid = window.localStorage.getItem('uid');
                    window.localStorage.setItem('uid', user.id);

                    const defaultRoute = '/dashboard'

                    notification.success(
                        "Reset Password",
                        "You have sucessfully reset the password!",
                        4500
                    );
                    

                    if(props.isLogin) {
                        router.push({ path: ((currentRoute && uid == user.id) 
                        ? currentRoute 
                        : defaultRoute) }).then(() => { router.go(0) })
                    }

                    closed()
                    
                    
                } else {
                    if(response.response.data.data.not_found) {
                        Validations.user_name_not_found = true
                        formEl.validateField('email')
                        email.value.focus()
                    } else if (response.response.data.data.password_is_used) {
                        Validations.is_password_used = true;
                        formEl.validateField('password')
                        password.value.focus()
                    } else if (response.response.data.data.not_active) {
                        Validations.user_name_not_active = true;
                        formEl.validateField('email')
                        email.value.focus()
                    } else if (response.response.data.data.code_not_valid) {
                        Validations.code_not_valid = true;
                        formEl.validateField('code')
                        code.value.focus()
                    } else if (response.response.data.data.code_expired) {
                        Validations.code_expired = true;
                        formEl.validateField('code')
                        code.value.focus()
                    }
                }
            }
        })
    }

    async function sendCode() {
        buttonIconLoading.value = true
        buttonIconDisabled.value = true
        const response = await Auths.requestResetPassword({
            email: reset.value.email,
            app: 'health'
        })

        if(response.status < 299) {
            buttonIcon.value = 'el-icon-check success'
            buttonIconLoading.value = false
            notification.success(
                        "Reset Password",
                        "Code has been sent to your email",
                        4500,
                    );
            setInterval(() => {
                buttonIconDisabled.value = false
                buttonIcon.value = 'el-icon-message'
            }, 5000);
        } else {
            buttonIconDisabled.value = false
            buttonIconLoading.value = false
            buttonIcon.value = 'el-icon-message'

            if(response.response.data.data.not_found) {
                Validations.user_name_not_found = true
                ruleFormNameRef.value.validateField('email')
                email.value.focus()
            }
        }

        

    }

    function resetValidations() {
        Validations.user_name_not_found = false
        Validations.is_password_used = false
        Validations.user_name_not_active = false
        Validations.code_not_valid = false
        Validations.code_expired = false
        Validations.unauthorized = false
    }

    __expose({ open })


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (open.value)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: open.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((open).value = $event)),
        width: _unref(common).isMobile() ? '90%' :'40%',
        "destroy-on-close": "",
        "append-to-body": "",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        onClose: closed,
        class: "close-button-dialog"
      }, {
        title: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("h3", { class: "text-left" }, "Reset Password", -1)
        ])),
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            (__props.isLogin)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  class: "btn btn-lg w-10 h-auto",
                  onClick: closed
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_button, {
              class: "btn btn-lg btn-info w-10 h-auto",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (handleSubmit(ruleFormNameRef.value))),
              disabled: loading.value,
              loading: loading.value
            }, {
              default: _withCtx(() => [
                (!loading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Save"))
                  : _createCommentVNode("", true),
                (loading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Please wait.."))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled", "loading"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_form, {
              ref_key: "ruleFormNameRef",
              ref: ruleFormNameRef,
              model: reset.value,
              rules: rules,
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { prop: "email" }, {
                  default: _withCtx(() => [
                    _cache[10] || (_cache[10] = _createElementVNode("label", { for: "user-email" }, " Username ", -1)),
                    _createVNode(_component_el_input, {
                      id: "user-email",
                      modelValue: reset.value.email,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((reset.value.email) = $event)),
                      placeholder: "Enter username",
                      ref_key: "email",
                      ref: email,
                      maxlength: "50",
                      disabled: reset_populated.value,
                      size: "large",
                      onInput: _cache[1] || (_cache[1] = ($event: any) => {_unref(Validations).user_name_not_found = false; _unref(Validations).user_name_not_active = false; _unref(Validations).unauthorized = false})
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          class: _normalizeClass({'email-sent': buttonIconDisabled.value}),
                          icon: buttonIconDisabled.value ? _unref(Check) : _unref(Message),
                          loading: buttonIconLoading.value,
                          disabled: buttonIconDisabled.value || reset.value.email.length == 0,
                          onClick: sendCode
                        }, null, 8, ["class", "icon", "loading", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { prop: "password" }, {
                  default: _withCtx(() => [
                    _cache[20] || (_cache[20] = _createElementVNode("label", { for: "user-password" }, " Password ", -1)),
                    _createVNode(_component_el_tooltip, {
                      effect: "light",
                      "open-delay": "200",
                      placement: "bottom-start"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("span", {
                          class: _normalizeClass(["svg-icon svg-icon-2hx me-4", isLengthValid.value ? 'svg-icon-success' : 'svg-icon-danger'])
                        }, [
                          _createVNode(_component_inline_svg, {
                            src: `media/icons/duotune/general/${isLengthValid.value ? 'gen037' : 'gen034'}.svg`
                          }, null, 8, ["src"]),
                          _cache[11] || (_cache[11] = _createTextVNode(" Password length >= 12 "))
                        ], 2),
                        _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", {
                          class: _normalizeClass(["svg-icon svg-icon-2hx me-4", hasUppercase.value ? 'svg-icon-success' : 'svg-icon-danger'])
                        }, [
                          _createVNode(_component_inline_svg, {
                            src: `media/icons/duotune/general/${hasUppercase.value ? 'gen037' : 'gen034'}.svg`
                          }, null, 8, ["src"]),
                          _cache[12] || (_cache[12] = _createTextVNode(" Upper case "))
                        ], 2),
                        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", {
                          class: _normalizeClass(["svg-icon svg-icon-2hx me-4", hasLowercase.value ? 'svg-icon-success' : 'svg-icon-danger'])
                        }, [
                          _createVNode(_component_inline_svg, {
                            src: `media/icons/duotune/general/${hasLowercase.value ? 'gen037' : 'gen034'}.svg`
                          }, null, 8, ["src"]),
                          _cache[13] || (_cache[13] = _createTextVNode(" Lower case "))
                        ], 2),
                        _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", {
                          class: _normalizeClass(["svg-icon svg-icon-2hx me-4", hasDigit.value ? 'svg-icon-success' : 'svg-icon-danger'])
                        }, [
                          _createVNode(_component_inline_svg, {
                            src: `media/icons/duotune/general/${hasDigit.value ? 'gen037' : 'gen034'}.svg`
                          }, null, 8, ["src"]),
                          _cache[14] || (_cache[14] = _createTextVNode(" Digit "))
                        ], 2),
                        _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", {
                          class: _normalizeClass(["svg-icon svg-icon-2hx me-4", hasSpecialChar.value ? 'svg-icon-success' : 'svg-icon-danger'])
                        }, [
                          _createVNode(_component_inline_svg, {
                            src: `media/icons/duotune/general/${hasSpecialChar.value ? 'gen037' : 'gen034'}.svg`
                          }, null, 8, ["src"]),
                          _cache[15] || (_cache[15] = _createTextVNode(" Special character "))
                        ], 2)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          id: "user-password",
                          modelValue: reset.value.password,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((reset.value.password) = $event)),
                          placeholder: "Enter Password",
                          ref_key: "password",
                          ref: password,
                          maxlength: "25",
                          size: "large",
                          onInput: _cache[3] || (_cache[3] = ($event: any) => (_unref(Validations).is_password_used = false)),
                          "show-password": ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { prop: "confirm_password" }, {
                  default: _withCtx(() => [
                    _cache[21] || (_cache[21] = _createElementVNode("label", { for: "user-confirm-password" }, " Confirm Password ", -1)),
                    _createVNode(_component_el_input, {
                      id: "user-confirm-password",
                      modelValue: reset.value.confirm_password,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((reset.value.confirm_password) = $event)),
                      placeholder: "Enter confirm password",
                      ref: "confirm_password",
                      maxlength: "25",
                      size: "large",
                      "show-password": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { prop: "code" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[22] || (_cache[22] = _createElementVNode("label", { for: "user-code" }, " Code ", -1)),
                      _createVNode(_component_el_input, {
                        id: "user-code",
                        modelValue: reset.value.code,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((reset.value.code) = $event)),
                        placeholder: "Enter code",
                        ref_key: "code",
                        ref: code,
                        maxlength: "6",
                        size: "large",
                        onInput: _cache[6] || (_cache[6] = ($event: any) => {_unref(Validations).code_not_valid = false; _unref(Validations).code_expired = false;}),
                        style: {"max-width":"180px"}
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "width"]))
    : _createCommentVNode("", true)
}
}

})