import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dashboard-section" }
const _hoisted_2 = { class: "row g-4 w-100 untouched-row" }
const _hoisted_3 = {
  key: 0,
  class: "card card-flush"
}
const _hoisted_4 = { class: "row w-100" }
const _hoisted_5 = { class: "col-sm-4 p-0" }
const _hoisted_6 = {
  key: 0,
  class: "col-sm-8 p-0 pull-right"
}
const _hoisted_7 = { class: "card-title d-flex flex-column" }
const _hoisted_8 = { class: "d-flex align-items-center digit-value" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row) => {
      return (_openBlock(), _createElementBlock("div", {
        key: row,
        class: "card mb-5 rounded-2 full-width"
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (overview) => {
            return (_openBlock(), _createElementBlock("div", {
              key: overview,
              class: _normalizeClass(["col-md-2 col-lg-2 col-xl-2 col-xxl-2", `${ overview.hide
                            ? 'hide-card'
                            : '' }`])
            }, [
              (!overview.hide)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["icon-svg", overview.icon])
                        }, null, 2)
                      ]),
                      (overview.secondary_value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("span", {
                              style: _normalizeStyle(`color: ${overview.color} !important; font-size: ${
                                            _ctx.getFontSize(overview.secondary_value) }`),
                              class: "fw-light fs-2"
                            }, "(" + _toDisplayString(overview.secondary_value) + ")", 5)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["card-header w-100", `bg-light-white`])
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", {
                          class: _normalizeClass([`${`text-gray-700`}`, "text-dark-400 pt-1 fw-bold fs-5"])
                        }, _toDisplayString(overview.title), 1),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("span", {
                            style: _normalizeStyle(`color: ${overview.color} !important; font-size: ${
                                                _ctx.getFontSize(overview.value)
                                            }`),
                            class: "fw-bold"
                          }, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.toClick(overview)),
                              class: _normalizeClass({'cursor-pointer': overview.isClick})
                            }, _toDisplayString(overview.value), 11, _hoisted_9)
                          ], 4)
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}