import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "table-custom-invoice"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onChange", "onUpdate:modelValue"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { style: {"cursor":"pointer"} }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-body p-2" }
const _hoisted_11 = { class: "text-gray-600" }
const _hoisted_12 = {
  scope: "col",
  class: "p-2",
  colspan: "2",
  nowrap: ""
}
const _hoisted_13 = {
  scope: "col",
  class: "p-2",
  colspan: "2",
  nowrap: ""
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "card" }
const _hoisted_16 = { class: "card-body p-2" }
const _hoisted_17 = { class: "text-gray-600" }
const _hoisted_18 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_19 = {
  scope: "col",
  class: "p-2",
  colspan: "2",
  nowrap: ""
}
const _hoisted_20 = { style: {"cursor":"pointer"} }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "card" }
const _hoisted_23 = { class: "card-body p-2" }
const _hoisted_24 = { class: "text-gray-600" }
const _hoisted_25 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_26 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "p-5" }
const _hoisted_37 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_group_invoice_action_dropdown = _resolveComponent("vc-group-invoice-action-dropdown")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_vc_invoice_approval_action_modal = _resolveComponent("vc-invoice-approval-action-modal")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.invoiceData.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_table, {
            data: _ctx.invoiceData,
            style: {"width":"100%"},
            "row-key": "sid",
            "tree-props": _ctx.hideChild ? {} : { children: 'child_invoices'}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (invoice, i) => {
                return (_openBlock(), _createBlock(_component_el_table_column, {
                  key: i,
                  fixed: invoice.isFixed,
                  "class-name": invoice.cn,
                  width: invoice.width
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "d-block fs-6 fw-bolder",
                      innerHTML: invoice.label
                    }, null, 8, _hoisted_3)
                  ]),
                  default: _withCtx((scope) => [
                    (invoice.isAction)
                      ? (_openBlock(), _createBlock(_component_vc_group_invoice_action_dropdown, {
                          invoice: scope.row,
                          key: scope.row.id,
                          showAction: _ctx.showAction
                        }, null, 8, ["invoice", "showAction"]))
                      : (invoice.isStatus)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (
                  (_ctx.permissions.is_admin || _ctx.permissions.is_accountant) &&
                  _ctx.allowUpdateInvoice
                )
                              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                                  (
                    scope.row 
                  )
                                    ? _withDirectives((_openBlock(), _createElementBlock("select", {
                                        key: 0,
                                        class: "form-select",
                                        "aria-label": "Select example",
                                        onChange: ($event: any) => (_ctx.apporvePolicy(scope.row, $event, 'standard')),
                                        "onUpdate:modelValue": ($event: any) => ((scope.row.status) = $event)
                                      }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceStatus, (status) => {
                                          return (_openBlock(), _createElementBlock("option", {
                                            value: status.id,
                                            key: status.name
                                          }, _toDisplayString(status.name), 9, _hoisted_6))
                                        }), 128))
                                      ], 40, _hoisted_5)), [
                                        [_vModelSelect, scope.row.status]
                                      ])
                                    : _createCommentVNode("", true)
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(scope.row.status_text), 1))
                          ], 64))
                        : (invoice.isClicked && _ctx.isShowCategories)
                          ? (_openBlock(), _createBlock(_component_el_popover, {
                              key: 2,
                              placement: "left",
                              width: "180",
                              trigger: "click",
                              "hide-after": 0
                            }, {
                              reference: _withCtx(() => [
                                _createElementVNode("span", _hoisted_8, _toDisplayString(scope.row[invoice.value]), 1)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_9, [
                                  _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("table", _hoisted_11, [
                                      _cache[4] || (_cache[4] = _createElementVNode("tr", {
                                        class: "text-gray-600",
                                        style: {"background-color":"#fff"}
                                      }, [
                                        _createElementVNode("th", {
                                          scope: "col",
                                          nowrap: "",
                                          colspan: "2",
                                          class: "text-center p-2"
                                        }, " Category "),
                                        _createElementVNode("th", {
                                          scope: "col",
                                          nowrap: "",
                                          colspan: "2",
                                          class: "text-center p-2"
                                        }, " Quote Ref. No. ")
                                      ], -1)),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.categories, (category, i) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                          class: "text-gray-600",
                                          style: {"background-color":"#fff"},
                                          key: i
                                        }, [
                                          _createElementVNode("td", _hoisted_12, _toDisplayString(category.category_name) + " (" + _toDisplayString(category.visa_emirates_text) + ") ", 1),
                                          _createElementVNode("td", _hoisted_13, _toDisplayString(category.merchant_reference), 1)
                                        ]))
                                      }), 128))
                                    ])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          : (invoice.isCustomer)
                            ? (_openBlock(), _createBlock(_component_el_popover, {
                                key: 3,
                                placement: "left",
                                width: "180",
                                trigger: "hover",
                                "show-after": 200,
                                "hide-after": 0
                              }, {
                                reference: _withCtx(() => [
                                  _createElementVNode("span", {
                                    style: {"cursor":"pointer"},
                                    onClick: ($event: any) => (_ctx.copyText.copyLink(scope.row.email))
                                  }, _toDisplayString(scope.row.email), 9, _hoisted_14)
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("div", _hoisted_16, [
                                      _createElementVNode("table", _hoisted_17, [
                                        _cache[5] || (_cache[5] = _createElementVNode("tr", {
                                          class: "text-gray-600",
                                          style: {"background-color":"#fff"}
                                        }, [
                                          _createElementVNode("th", {
                                            scope: "col",
                                            nowrap: "",
                                            colspan: "2",
                                            class: "text-center p-2"
                                          }, " Customer Name ")
                                        ], -1)),
                                        _createElementVNode("tr", _hoisted_18, [
                                          _createElementVNode("td", _hoisted_19, _toDisplayString(scope.row.name), 1)
                                        ])
                                      ])
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            : (invoice.isInvoicePrice)
                              ? (_openBlock(), _createBlock(_component_el_popover, {
                                  key: 4,
                                  placement: "left",
                                  width: "180",
                                  trigger: "hover"
                                }, {
                                  reference: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_20, [
                                      _createTextVNode(_toDisplayString(scope.row.sales_price ? _ctx.common.formatCurrency(scope.row.sales_price) : "") + " " + _toDisplayString(scope.row.is_manual_order == 1 &&
                    scope.row.total_manual_orders != 0
                      ? " / " + _ctx.common.formatCurrency(scope.row.total_manual_orders)
                      : "") + " ", 1),
                                      (scope.row.sales_price_vat)
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(scope.row.sales_price_vat ? _ctx.common.formatCurrency(scope.row.sales_price_vat) : ""), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_22, [
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("table", _hoisted_24, [
                                          _cache[6] || (_cache[6] = _createElementVNode("tr", {
                                            class: "text-gray-600",
                                            style: {"background-color":"#fff"}
                                          }, [
                                            _createElementVNode("th", {
                                              scope: "col",
                                              nowrap: "",
                                              colspan: "5",
                                              class: "text-center p-2"
                                            }, " Price Details ")
                                          ], -1)),
                                          _cache[7] || (_cache[7] = _createElementVNode("tr", {
                                            class: "text-gray-600",
                                            style: {"background-color":"#fff"}
                                          }, [
                                            _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2",
                                              nowrap: ""
                                            }, "Quote Ref No."),
                                            _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2",
                                              nowrap: ""
                                            }, " Policy Price(VAT) "),
                                            _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2 font-bold",
                                              nowrap: ""
                                            }, " Basmah (VAT) "),
                                            _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2 font-bold",
                                              nowrap: ""
                                            }, " ICP (VAT) "),
                                            _createElementVNode("th", {
                                              scope: "col",
                                              class: "p-2 font-bold",
                                              nowrap: ""
                                            }, " Non Taxable Fee ")
                                          ], -1)),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row[_ctx.allInvoices], (invoices, index) => {
                                            return (_openBlock(), _createElementBlock("tr", {
                                              class: "text-gray-600",
                                              style: {"background-color":"#fff"},
                                              key: index
                                            }, [
                                              _createElementVNode("th", _hoisted_25, _toDisplayString(invoices.merchant_reference), 1),
                                              _createElementVNode("th", _hoisted_26, [
                                                _createElementVNode("span", null, [
                                                  _createTextVNode(_toDisplayString(_ctx.common.formatCurrency(invoices.policy_price)) + " ", 1),
                                                  (invoices.policy_price_vat)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_27, " (" + _toDisplayString(_ctx.common.formatCurrency(invoices.policy_price_vat)) + ") ", 1))
                                                    : _createCommentVNode("", true)
                                                ])
                                              ]),
                                              _createElementVNode("th", _hoisted_28, [
                                                _createElementVNode("span", null, [
                                                  _createTextVNode(_toDisplayString(_ctx.common.formatCurrency(invoices.basmah)) + " ", 1),
                                                  (invoices.basmah_vat)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_29, " (" + _toDisplayString(_ctx.common.formatCurrency(invoices.basmah_vat)) + ") ", 1))
                                                    : _createCommentVNode("", true)
                                                ])
                                              ]),
                                              _createElementVNode("th", _hoisted_30, [
                                                _createElementVNode("span", null, [
                                                  _createTextVNode(_toDisplayString(_ctx.common.formatCurrency(Number(invoices.icp) + Number(invoices.icp_vat))) + " ", 1),
                                                  (invoices.icp_vat)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, " (" + _toDisplayString(_ctx.common.formatCurrency(invoices.icp_vat)) + ") ", 1))
                                                    : _createCommentVNode("", true)
                                                ])
                                              ]),
                                              _createElementVNode("th", _hoisted_32, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.common.formatCurrency(invoices.non_taxable_fee)), 1)
                                              ])
                                            ]))
                                          }), 128))
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              : (invoice.value == 'declined_by' && _ctx.declined)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                    _createTextVNode(_toDisplayString(scope.row['invoice_declined_by']?.name) + " ", 1),
                                    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                                    _createElementVNode("span", {
                                      innerHTML: scope.row['invoice_declined_at']
                                    }, null, 8, _hoisted_33)
                                  ], 64))
                                : (invoice.value == 'approved_by' && _ctx.approved)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                      _createTextVNode(_toDisplayString(scope.row['invoice_approved_by']?.name) + " ", 1),
                                      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                                      _createElementVNode("span", {
                                        innerHTML: scope.row['invoice_approved_at']
                                      }, null, 8, _hoisted_34)
                                    ], 64))
                                  : (_openBlock(), _createElementBlock("span", {
                                      key: 7,
                                      class: _normalizeClass(invoice.cni),
                                      innerHTML: scope.row[invoice.value]
                                    }, null, 10, _hoisted_35))
                  ]),
                  _: 2
                }, 1032, ["fixed", "class-name", "width"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["data", "tree-props"])
        ]))
      : (_openBlock(), _createBlock(_component_el_empty, {
          key: 2,
          description: "No Records Available"
        })),
    _createVNode(_component_vc_invoice_approval_action_modal, {
      onCancelModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
      onProceedModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmStatusAction()))
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.invoiceActioned,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.invoiceActioned) = $event)),
      "show-close": false,
      "close-on-click-modal": false,
      width: "500px",
      title: 'Invoice Actioned'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("p", null, _toDisplayString(_ctx.invoiceActionedMessage), 1)
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_37, [
          _createVNode(_component_vc_button_save, {
            type: "button",
            text: 'Continue',
            onClick: _ctx.invoiceActionedContinue
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}